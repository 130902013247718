import React, {useContext} from "react";
import {auth} from "@web/lib/firebase";
import {WindowContext} from '@web/hooks/context';
import {useAuthOven} from "@web/hooks/useAuthOven";
import Loading from "../Loading";
import GuidesRouter from "./GuidesRouter";
import PropTypes from "prop-types";
import appActions from "@web/redux/app/actions";
import {useDispatch} from "react-redux";

GuidesManager.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
  initialIndex: PropTypes.number,
  onCompleteStep: PropTypes.func,
  onUpdateStep: PropTypes.func,
  onExit: PropTypes.func,
  asList: PropTypes.bool,
  appPath: PropTypes.string,
}

export default function GuidesManager(props) {
  const dispatch = useDispatch();
  const [isMobile, width, height] = useContext(WindowContext);
  const [user, claims, loadingUser] = useAuthOven(auth);

  const setActiveDetail = React.useCallback((props, show) =>
    dispatch(appActions.setActiveDetail(props, show)),
  [dispatch]);

  if (loadingUser) {
    return <Loading inline />
  }

  return <GuidesRouter
    user={user}
    list={props.list || []}
    initialStepIndex={props.initialIndex ?? 0}
    isMobile={isMobile}
    width={width}
    height={height}
    onCompleteStep={props.onCompleteStep}
    onExit={props.onExit}
    onUpdateStep={props.onUpdateStep}
    setActiveDetail={setActiveDetail}
    asList={props.asList}
    appPath={props.appPath}
  />
}
