import React from "react";
import PropTypes from "prop-types";
import Day from "./Day";
import {List} from "antd";
import MarkdownTimeline from "./MarkdownTimeline";
import UserContentPreview from "./UserContentPreview";
import {useDispatch, useSelector} from "react-redux";
import {WindowContext} from "@web/hooks/context";
import {LinkOutlined} from "@web/assets/icons";
import UserAvatar from "./UserAvatar";
import appActions from "@web/redux/app/actions";
import ListItemActive from "./ListItemActive";

LinksListItem.propTypes = {
  path: PropTypes.string,
};

function LinksListItem(props) {
  const dispatch = useDispatch();
  const [isMobile] = React.useContext(WindowContext);
  const content = useSelector((state) => state.Content.data?.[props.path]);
  const onClick = () => {
    dispatch(appActions.setActiveDetail({path: props.path, content}));
  };
  return <List.Item
    onClick={onClick}
    // className={props.index === 0 ? "chat-feedback-view" : ""}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
  >
    <div style={{width: "100%", maxWidth: 800}}>
      <List.Item.Meta
        avatar={
          !isMobile && <UserAvatar
            size={40}
            icon={<LinkOutlined className="muted-icon" style={{position: "relative", top: 2}} />}
          />
        }
        title={<span className="font-lg bolded">{content?.payload?.title} <Day ts={content?.payload?.date}/></span>}
        description={<MarkdownTimeline markdown={content?.payload?.description} />}
      />
      <div className="space space-col" style={{marginLeft: isMobile ? 0 : 60}}>
        {!!content?.payload?.paths &&
          content?.payload?.paths.map((path) => {
            return <UserContentPreview path={path} />
          })}
      </div>
    </div>
    <ListItemActive path={props.path} offset={-8} />
  </List.Item>
}

export default LinksListItem;
