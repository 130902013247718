import React, {useContext} from "react";
import {Alert, Card, Divider, Empty, List} from "antd";
import CleanModeButton from "./CleanModeButton";
import PropTypes from "prop-types";
import {ThreadsListItem} from "./ThreadsListItem";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import Loading from "./Loading";
import {WindowContext} from "@web/hooks/context";

ThreadsList.propTypes = {
  list: PropTypes.array,
  hasMore: PropTypes.bool,
  pageNext: PropTypes.func,
  error: PropTypes.object,
  preview: PropTypes.bool,
}

function ThreadsList(props) {
  const [,, height] = useContext(WindowContext);

  if (props.error) {
    return <Alert message={props.error.message} type="error" showIcon closable />
  }

  if (props.preview) {
    return <List
      style={{marginLeft: '10px'}}
      // header={
      //   <Card
      //     bordered={false}
      //     style={{width: "100%", margin: "20px 0 0 0"}}
      //     className="card-simple card-rounded card-inline card-transparent card-no-separator card-slim-title"
      //   >
      //   </Card>
      // }
      className="list-item-no-divider list-empty-no-padding"
      dataSource={props.list?.slice?.(0, 2)}
      renderItem={([path, date]) => <ThreadsListItem key={path} path={path} date={date} />}
      locale={{
        emptyText: <Empty
          className="add-integrations-empty-wrapper"
          description={null}
          image={null}
          imageStyle={{display: "none"}}
          style={{maxWidth: "600px"}}
        >
          <div className="cursor-pointer" style={{width: "96%", padding: "0 0 12px"}}>
            <span>Urgent email messages will appear here for quick access</span>
            {/*<LaunchSourceManagerButton key="sources-manager-x" serviceId={"contacts"}>*/}
            {/*  <Typography.Title style={{marginBottom: 11}}>Content Links</Typography.Title>*/}
            {/*</LaunchSourceManagerButton>*/}
          </div>
          {/*<Card className="card-simple card-rounded card-no-header" bordered={false}>*/}
          {/*</Card>*/}
        </Empty>
      }}
    />
  }

  return <div
    id="services-threads-scroll-target"
    className="paging-list-wrapper"
    style={{height: height - 60, overflow: "auto", display: "flex", flexDirection: "column"}}
  >
    <Pager
      height={height - 60}
      loader={<Loading inline />}
      length={props.list?.length ?? 0}
      hasMore={props.hasMore}
      endMessage={!!props.list?.length &&
        <div className="space space-center-y" style={{position: "relative", top: "6px"}}><h6
          className="muted">End</h6>&nbsp;<LineConnector/>
        </div>}
      pageNext={props.pageNext}
      scrollContainerId="services-threads-scroll-target"
    >
      <List
        style={{marginLeft: '10px'}}
        header={
          <Card
            bordered={false}
            style={{width: "100%", margin: "20px 0 0 0", height: 90}}
            className="card-simple card-rounded card-inline card-transparent card-no-separator card-slim-title"
          >
            <div className="space space-between space-center-y" style={{marginTop: 40}}>
              <h6/>
              <CleanModeButton />
            </div>
          </Card>
        }
        className="list-item-no-divider list-empty-no-padding"
        dataSource={props.list}
        renderItem={([path, date]) =>
          <ThreadsListItem
            key={path}
            path={path}
            date={date}
          />
        }
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
            {/*<Card className="card-simple card-rounded card-no-header" bordered={false}>*/}
            {/*</Card>*/}
          </Empty>
        }}
      />
    </Pager>
  </div>
}

export default ThreadsList;
