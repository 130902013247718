import React, {useContext} from "react";
import {Alert, Card, Empty, List} from "antd";
import {WindowContext} from "@web/hooks/context";
import Loading from "./Loading";
import PropTypes from "prop-types";
import PaymentListItem from "./PaymentListItem";
import LineConnector from "./LineConnector";
import Pager from "@web/ui/containers/Pager";

PaymentsList.propTypes = {
  list: PropTypes.array,
  error: PropTypes.object,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
};

function PaymentsList(props) {
  const [,, height] = useContext(WindowContext);
  if (props.error) {
    return <Alert
      message={props.error.message}
      type="error"
      showIcon
      closable
    />;
  }

  return <div
    id="services-payments-scroll-target"
    style={{height: height - 60, overflow: 'auto', display: 'flex', flexDirection: 'column'}}
  >
    <Pager
      height={height - 60}
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-payments-scroll-target"
    >
      <List
        style={{marginTop: '20px'}}
        header={
          <Card
            bordered={false}
            style={{width: "100%", margin: "0", height: 30}}
            // title={}
            // title={
            //   <ServiceData serviceId="reminders" component={RemindersList} />
            // <GroupTitle
            //   title="Activity"
            //   style={{paddingLeft: "20px"}}
            // >
            //   <div className="space space-col" style={{width: "100%"}}>
            //     <div style={{width: "90%", paddingTop: "100px"}}>
            //     {/*<ContentFilter />*/}
            //     </div>
            //     {/*<FeedSelector />*/}
            //     <ServiceData serviceId="reminders" component={RemindersList} />
            //   </div>
            // </GroupTitle>
            className="card-simple card-rounded card-no-header card-transparent card-no-separator card-slim-title"
          ></Card>
        }
        className="services-payments-list list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        // renderItem={([path, date]) =>
        //   <MarkdownListItem
        //     key={path}
        //     path={path}
        //     date={date}
        //     dataKey="payment"
        //   />
        // }
        renderItem={([path, date]) =>
          <PaymentListItem key={path} path={path} date={date} />
        }
        // loading={props.dataLoading}
        // pagination={{
        //   pageSize: 30,
        //   showSizeChanger: false,
        // }}
        locale={{
          emptyText: <Empty
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px", margin: "0 auto"}}
          >
            <Card className="card-simple card-rounded card-no-header" bordered={false}>
              <span className="muted">No payments to show</span>
            </Card>
          </Empty>
        }}
      />
    </Pager>
  </div>
}

export default PaymentsList;
