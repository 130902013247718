import React from "react";
import PropTypes from "prop-types";
import {Card} from "antd";

ProductsContent.propTypes = {
  confirm: PropTypes.bool.isRequired,
  plan: PropTypes.string,
};

function ProductsContent(props) {
  return props.confirm ?
    <div style={{width: "100%", maxWidth: 600, margin: "0 auto"}}>
      <Card title={<h6 className="font-xl bolded">Success</h6>} className="card-simple card-rounded card-no-separator">
        <div className="space space-col middle">
          <p className="font-lg">Your purchase was successful.</p>
          <span className="ai-text fonr-lg">Hi, you will receive a confirmation email shortly as well as instructions to set up your creator account.</span>
        </div>
      </Card>
    </div> :
    <stripe-pricing-table theme="dark" button-text="Get Started"
    pricing-table-id="prctbl_1QxB1hJEZgSQPP4EfxA3Bth2"
    publishable-key="pk_live_51N0VTkJEZgSQPP4ETiVRbYbYct5pXDuDpzGPThqCsVDU7ibKU5xqa3VJySfzGdvfTRDHfyeUVkU9oLOXHFY124Sw00iPR0fcOv">
  </stripe-pricing-table>
}

export default ProductsContent;
