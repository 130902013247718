import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
// import PaymentsList from "./PaymentsList";
import DeliveriesList from "./DeliveriesList";

function DeliveriesContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="deliveries" component={DeliveriesList} />
    </section>
  </DashboardFullRow>
}

export default DeliveriesContent;
