import React from "react";
import {Typography, Button, Divider, List, Empty} from "antd";
import PropTypes from "prop-types";
import Icon from "./Icon";
import ContactDetailsListItem from "./ContactDetailsListItem";
import ExternalLink from "./ExternalLink";
import {LeftArrowOutlined} from "@web/assets/icons";
import VCardInfo from "./VCardInfo";
import AssistantVCard from "./AssistantVCard";
import MessengerVCard from "./MessengerVCard";

ContactActivityDetails.propTypes = {
  contextPath: PropTypes.string,
  activityList: PropTypes.arrayOf(PropTypes.array),
  itemsList: PropTypes.arrayOf(PropTypes.array),
  contact: PropTypes.object,
  onClose: PropTypes.func,
  loadAllContent: PropTypes.func,
};

function ContactActivityDetails(props) {
  console.log("ContactActivityDetails", props);
  if (!props.contact) {
    return null
  }

  // const displayName = props.contact?.name?.replaceAll('"', '') || props.contact?.email;

  return <section style={{position: "relative", maxWidth: '1200px', minHeight: '100vh', background: "#000"}}>
    <div style={{width: '100%', height: 10}}>
      {/*<div className="space space-between space-center-y">*/}
      {/*  <div className="space space-center-y">*/}
      {/*    <Button*/}
      {/*      ghost*/}
      {/*      className="icon-btn icon-left"*/}
      {/*      onClick={props.onClose}*/}
      {/*    >*/}
      {/*      <LeftArrowOutlined />*/}
      {/*    </Button>*/}
      {/*    <Typography.Title level={4}>*/}
      {/*      {displayName}*/}
      {/*    </Typography.Title>*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <ExternalLink*/}
      {/*      href={`https://${props.contact.domain}/`}*/}
      {/*      target="_blank"*/}
      {/*      className="lowercase"*/}
      {/*      showIcon*/}
      {/*    >*/}
      {/*      {props.contact?.domain}*/}
      {/*    </ExternalLink>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
    <div>
      <AssistantVCard onClose={props.onClose} drawer asCard contact={props.contact} path={props.itemPath} contactPath={props.contextPath}>
        <List
          className="list-item-no-divider"
          header={<Divider className="hidden small-top" />}
          loading={!props.itemsList}
          dataSource={props.itemsList || []}
          renderItem={([itemPath], index) =>
            <ContactDetailsListItem
              key={itemPath}
              contextPath={props.contextPath}
              itemPath={itemPath}
              primary={index === 0}
            />
          }
          locale={{
            emptyText: <Empty
              description={<span className="ai-text">No relevant activity</span>}
              imageStyle={{display: 'none'}}
            >
              <div className="space space-center-x">
                <Button
                  ghost
                  onClick={props.loadAllContent}
                >
                  <span className="muted font-thin underline">Load promotional low-relevance content</span>
                </Button>
              </div>
              {props.empty}
            </Empty>
          }}
        />
      </AssistantVCard>
    </div>
  </section>
}

export default ContactActivityDetails;
