import React, {useContext} from "react";
import PropTypes from "prop-types";
import {WindowContext} from "@web/hooks/context";
import Pager from "@web/ui/containers/Pager";
import Loading from "./Loading";
import LineConnector from "./LineConnector";
import {Alert, Card, Empty, List} from "antd";
import LineListItem from "./LinesListItem";

LinesList.propTypes = {
  list: PropTypes.array,
  error: PropTypes.object,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
};

function LinesList(props) {
  const [,, height] = useContext(WindowContext);

  console.log("LinesList:props:", props);

  if (props.error) {
    return <Alert message={props.error.message} type="error" showIcon closable />;
  }

  return <div
    id="services-payments-scroll-target"
    style={{height: height - 10, overflow: 'auto', display: 'flex', flexDirection: 'column'}}
  >
    <Pager
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      endMessage={
        !!props.list?.length &&
        <div className="space space-center-y" style={{position: "relative", top: "6px"}}>
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-payments-scroll-target"
    >
      <List
        style={{marginTop: '20px'}}
        header={<h6 style={{paddingLeft: '12px', height: 24}}></h6>}
        className="services-payments-list list-empty-no-padding list-item-lite-divider"
        dataSource={props.list}
        renderItem={([path, date]) => <LineListItem key={path} path={path} date={date} />}
        locale={{
          emptyText: <Empty
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{maxWidth: "600px"}}
          >
            <Card className="card-simple card-rounded card-no-header" bordered={false} style={{margin: "0 20px"}}>
              <span className="muted">No lines to show</span>
            </Card>
          </Empty>
        }}
      />
    </Pager>
  </div>;
}

export default LinesList;
