import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Tag} from "antd";

AppServiceLabel.propTypes = {
  path: PropTypes.string,
  sequenceNumber: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

function AppServiceLabel(props) {
  const appService = useSelector((state) => state.Content.data?.[props.path]);
  const getColor = (type) => {
    switch (type) {
      case "data":
        return "green";
      case "reasoning":
        return "blue";
      default:
        return "default";
    }
  }

  if (!appService) return <span className="font-lg">{props.placeholder}</span>;
  return <div className={props.className}>
    <Tag className="uppercase rounded" color={getColor(appService.type)}>{props.sequenceNumber} {appService.type}</Tag>
    <span className="font-lg">{appService.method}</span>
  </div>
}

export default AppServiceLabel;
