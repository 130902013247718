/** @namespace app */
import "./App.less";
import React, {lazy, Suspense} from "react";
import FeedLayout from "./FeedLayout";
import {Route, Switch, useLocation} from "wouter";
import Login from "@ai-antd/page/Login";
import SignUp from "@ai-antd/page/SignUp";
import {useAuthOven} from "@web/hooks/useAuthOven";
import {auth} from "@web/lib/firebase";
import {AppContext, LinesContext, SourcesContext, PayContext} from "@web/hooks/context";
import Loading from "@ai-antd/components/Loading";
import {RequireAuth} from "@web/ui/containers/RequireAuth";
import OauthCallback from "@ai-antd/page/OauthCallback";
import VerifyEmailLink from "@ai-antd/page/VerifyEmailLink";
import {Alert} from "antd";
import TrelloAuthCallback from "@ai-antd/components/TrelloAuthCallback";
import VideoModal from "@ai-antd/components/VideoModal";
import Home from "@ai-antd/page/Home";
import PhoneAuth from "@ai-antd/components/PhoneAuth";
import {getServices, getSolutions} from "./dashboard.config";
import AuthLayoutWrapper from "@ai-antd/components/AuthLayoutWrapper";
import {useDispatch} from "react-redux";
import WildcardRoute from "@ai-antd/components/WildcardRoute";
import ProductsPage from "@ai-antd/page/ProductsPage";

const appKey = process.env.REACT_APP_OVEN_APP_ID;
const configKey = appKey?.replace(/-/g, "_");
// const About = lazy(() => import("@ai-antd/page/About"));
const AccountPage = lazy(() => import("@ai-antd/page/AccountPage"));
const LinePage = lazy(() => import("@ai-antd/page/LinePage"));
const MessengerPage = lazy(() => import("@ai-antd/page/MessengerPage"));
// const AppsPage = lazy(() => import("@ai-antd/page/AppsPage"));
const LineOnboarding = lazy(() => import("@ai-antd/page/LineOnboarding"));
// const ActivityPage = lazy(() => import("@ai-antd/page/ActivityPage"));
const Splash = lazy(() => import("@ai-antd/components/Splash"));
const AuthEmailPage = lazy(() => import("@ai-antd/page/EmailAuthPage"));
// const ProOnboarding = lazy(() => import("@ai-antd/components/onboarding/ProOnboarding"));
const Pay = lazy(() => import("@ai-antd/page/Pay"));
const Paid = lazy(() => import("@ai-antd/components/Paid"));
const Logout = lazy(() => import("@ai-antd/page/Logout"));
// const SandboxEditor = lazy(() => import("@ai-antd/components/SandboxEditor"));
const Io = lazy(() => import("@ai-antd/page/Io"));
const LandingPage = lazy(() => import("@ai-antd/page/LandingPage"));
const AppStore = lazy(() => import("@ai-antd/page/AppStore"));

const solutions = getSolutions();
const services = getServices();

const App = () => {
  /** @type {useAuthOvenData} */
  const [
    user,
    claims,
    loadingUser,
    errorLoadingUser,
  ] = useAuthOven(auth);

  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  const navigateTo = React.useCallback((path) => setLocation(path), [setLocation]);

  React.useEffect(() => {
    // console.log("sync lines");
    user && !user.isAnonymous &&
    dispatch({type: "SYNC_LINES"});
  }, [dispatch, user]);

  if (loadingUser) return <Loading/>;

  if (errorLoadingUser) {
    return <Alert type="warning" banner message="Please refresh or try again later" description="TheScribe is currently unavailable." />;
  }

  if (!claims?.data) return <Loading/>;

  const appContextData = [{root: {key: appKey, configKey}, navigateTo}, user, claims, []];

  return <AppContext.Provider value={appContextData}>
    <SourcesContext.Provider value={{services, solutions}}>
    <FeedLayout>
      <Switch>
        <Route path="/">
          <Home />
        </Route>
        <Route path="/creators">
          <Suspense fallback={<Loading />}>
            <LandingPage />
          </Suspense>
        </Route>
        <Route path="/plans/creators">
          <RequireAuth allowAnonymous>
            <Suspense fallback={<Loading />}>
              <ProductsPage plan="creators" />
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/plans/creators/confirm">
          <RequireAuth allowAnonymous>
            <Suspense fallback={<Loading />}>
              <ProductsPage plan="creators" confirm />
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/apps">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/apps/store">
          <Suspense fallback={<Loading />}>
            <AppStore />
          </Suspense>
        </Route>
        <Route path="/apps/create">
          <LinesContext.Provider value={{serviceId: "create"}}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        {/*<Route path="/apps/:appId/install">*/}
        {/*  {(params) => <LinesContext.Provider value={{...params, serviceId: "install"}}>*/}
        {/*    <RequireAuth allowAnonymous={false}>*/}
        {/*      <Suspense fallback={<Loading />}>*/}
        {/*        <LinePage />*/}
        {/*      </Suspense>*/}
        {/*    </RequireAuth>*/}
        {/*  </LinesContext.Provider>}*/}
        {/*</Route>*/}
        <Route path="/apps/:appId">
          {(params) => <LinesContext.Provider value={{...params, serviceId: "app"}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading />}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>}
        </Route>
        <Route path="/apps/:appId/jobs/:jobId">
          {(params) => <LinesContext.Provider value={{appId: params.appId, jobId: params.jobId, serviceId: "apps"}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading />}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>}
        </Route>
        <Route path="/sandbox/:appId">
          {(params) => <LinesContext.Provider value={{...params, serviceId: "sandbox"}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>}
        </Route>
        <Route path="/sandbox/:appId/jobs/:jobId">
          {(params) => <LinesContext.Provider value={{appId: params.appId, jobId: params.jobId, serviceId: "sandbox"}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>}
        </Route>
        <Route path="/io">
          <Suspense fallback={<Loading />}>
            <Io />
          </Suspense>
        </Route>
        <Route path="/home">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/claim/:areaCode?">
          <RequireAuth allowAnonymous>
            <Suspense fallback={<Loading/>}>
              <Splash />
            </Suspense>
          </RequireAuth>
        </Route>
        <Route path="/start">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <LineOnboarding />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/lines">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/directory">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/subscribers">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/subscriptions">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/assistant">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/tasks">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/calls">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous={false}>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/contacts">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        {/*<Route path="/onboarding">*/}
        {/*  <LinesContext.Provider value={{}}>*/}
        {/*    <RequireAuth allowAnonymous={false}>*/}
        {/*      <Suspense fallback={<Loading/>}>*/}
        {/*        <ProOnboarding />*/}
        {/*      </Suspense>*/}
        {/*    </RequireAuth>*/}
        {/*  </LinesContext.Provider>*/}
        {/*</Route>*/}
        <Route path="/emails" key="emails">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/analyst" key="analyst">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/datasets" key="datasets">
          <LinesContext.Provider value={{}}>
            <RequireAuth allowAnonymous>
              <Suspense fallback={<Loading/>}>
                <LinePage />
              </Suspense>
            </RequireAuth>
          </LinesContext.Provider>
        </Route>
        <Route path="/links">
          <RequireAuth allowAnonymous={false}>
            <Suspense fallback={<Loading />}>
              <LinePage />
            </Suspense>
          </RequireAuth>
        </Route>
        {/*<Route path="/chat-demo" key="logout">*/}
        {/*  <Suspense fallback={<Loading />}>*/}
        {/*    <ChatDemoManager>*/}
        {/*      <ChatDemo />*/}
        {/*    </ChatDemoManager>*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
        <Route path="/logout" key="logout">
          <Suspense fallback={<Loading/>}>
            <Logout/>
          </Suspense>
        </Route>
        {/*<Route path="/genesis">*/}
        {/*  <Suspense fallback={<Loading/>}>*/}
        {/*    <Splash />*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
        {/*<Route path="/verify">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <Splash initialSlide="signup"/>*/}
        {/*    </Suspense>*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        {/*<Route path="/subscribe">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    /!*<RequireVerification>*!/*/}
        {/*      <Suspense fallback={<Loading/>}>*/}
        {/*        <Splash initialSlideKey="subscribe" />*/}
        {/*      </Suspense>*/}
        {/*    /!*</RequireVerification>*!/*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        <Route path="/pay/:priceId?">
          {(params) => <PayContext.Provider value={[params.priceId]}>
            <RequireAuth allowAnonymous={true}>
              <Suspense fallback={<Loading/>}>
                <Pay />
              </Suspense>
            </RequireAuth>
          </PayContext.Provider>}
        </Route>
        <Route path="/paid">
          <RequireAuth allowAnonymous={true}>
            {/*<RequireVerification>*/}
            <Suspense fallback={<Loading/>}>
              <Paid />
            </Suspense>
            {/*</RequireVerification>*/}
          </RequireAuth>
        </Route>
        {/*<Route path="/subscribed" key="subscribed">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    /!*<RequireVerification>*!/*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <BetaCountdown/>*/}
        {/*    </Suspense>*/}
        {/*    /!*</RequireVerification>*!/*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        {/*<Route path="/genesis-access">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <MemberBenefits/>*/}
        {/*    </Suspense>*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        {/*<Route path="/genesis-start">*/}
        {/*  <RequireAuth allowAnonymous={false}>*/}
        {/*    <Suspense fallback={<Loading/>}>*/}
        {/*      <Splash initialSlideKey="subscribe" />*/}
        {/*    </Suspense>*/}
        {/*  </RequireAuth>*/}
        {/*</Route>*/}
        <Route path="/email-link">
          <VerifyEmailLink/>
        </Route>
        <Route path="/verify-email">
          <AuthLayoutWrapper hideLinks>
            <AuthEmailPage intent="link" />
          </AuthLayoutWrapper>
        </Route>
        <Route path="/phone/:intent">
          {({intent}) => <AuthLayoutWrapper hideLinks>
            <PhoneAuth intent={intent} />
          </AuthLayoutWrapper>
          }
        </Route>
        <Route path="/oauth/:integrationId/callback" key="oauth-callback">
          {({integrationId}) => integrationId === "trello-com" ? <TrelloAuthCallback integrationId={integrationId}/> :
            <OauthCallback integrationId={integrationId}/>}
        </Route>
        <Route path="/login" key="login">
          <Login providers={["email"]} />
        </Route>
        <Route path="/sign-up" key="sign-up">
          <SignUp providers={["email"]}/>
        </Route>
        <Route path="/account" key="account">
          <RequireAuth allowAnonymous={false}>
            <Suspense fallback={<Loading/>}>
              <AccountPage/>
            </Suspense>
          </RequireAuth>
        </Route>
        {/*<Route path="/about" key="about">*/}
        {/*  <Suspense fallback={<Loading/>}>*/}
        {/*    <About/>*/}
        {/*  </Suspense>*/}
        {/*</Route>*/}
        <Route path="/:hotline">
          {(params) => <WildcardRoute param={params.hotline}>
            <LinesContext.Provider value={{...params, serviceId: "messenger"}}>
              <RequireAuth allowAnonymous={false}>
                <Suspense fallback={<Loading />}>
                  <MessengerPage />
                </Suspense>
              </RequireAuth>
            </LinesContext.Provider>
          </WildcardRoute>}
        </Route>
      </Switch>
      <VideoModal/>
      {/*<VoiceDrawer />*/}
    </FeedLayout>
    </SourcesContext.Provider>
  </AppContext.Provider>;
};
export default App;
