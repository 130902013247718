import React, { useState } from 'react'
import { createUserWithEmailAndPassword, sendSignInLinkToEmail, updateProfile, setPersistence, browserLocalPersistence } from 'firebase/auth'
import {Button, Input, Space, Divider, Card, Tag} from "antd";
import {auth, ga} from '@web/lib/firebase'
import {LockOutlined} from '@ant-design/icons'
import {notifyAndTrack} from '@ai-antd/components/notification'
import HotClick from '@web/ui/containers/HotClick'
import {AppContext, WindowContext} from "@web/hooks/context";
import {colls, setDocument} from '@web/lib/firestore.db'
import useLocalStorage from '@web/hooks/useLocalStorage'
import {Link, Redirect} from "wouter";
import PropTypes from "prop-types";
import {EmailIconOutlined, RightArrowOutlined} from "@web/assets/icons";

const domain = location.hostname === 'localhost' ?
  `http://localhost:${location.port}` :
  process.env.REACT_APP_OVEN_APP_DOMAIN;

console.log('domain', domain);
const saveNotes = (uid, notes) => {
  return setDocument(colls["user-profile"], uid, {notes}, true)
}
const fullWidthStyle = {width: '100%'}

const signUpEmailPassword = async (app, e, p, d, notes) => {
  if (!e || !p || !d) return notifyAndTrack.warn('Missing Info', 'All fields are required.')
  await setPersistence(auth, browserLocalPersistence)
  createUserWithEmailAndPassword(auth, e, p)
    .then(() => auth.currentUser && updateProfile(auth.currentUser, {displayName: d}))
    .then(() => auth.currentUser && notes && saveNotes(auth.currentUser.uid, notes))
    .then(() => auth.currentUser && auth.currentUser.sendEmailVerification())
    .then(() => ga.setUserId(auth.currentUser.uid))
    .catch(err => {
      switch (err.code) {
        case 'auth/email-already-in-use':
          notifyAndTrack.error('Email is Already In Use')
          break
        case 'auth/weak-password':
          notifyAndTrack.error('Weak Password', 'Password must be at least 6 characters.')
          break
        default:
          notifyAndTrack.error('Sign Up Failed', 'Try again later')
      }
    })
}

const defaultTitle = "Create Your Free AccountPage"
const containerStyle = {width: '100%', marginBottom: '10px'}

EmailAuthPage.propTypes = {
  intent: PropTypes.oneOf(['sign-in', 'link']).isRequired,
  title: PropTypes.string,
  withPassword: PropTypes.bool,
  cta: PropTypes.string,
  extra: PropTypes.arrayOf(PropTypes.shape({
    placeholder: PropTypes.string,
  })),
  redirectTo: PropTypes.string,
  actions: PropTypes.node,
}

export default function EmailAuthPage(props) {
  const [app, user] = React.useContext(AppContext);
  const [isMobile] = React.useContext(WindowContext);
  const [,setEmailLS] = useLocalStorage('signup-email')
  const [,setAnonUID] = useLocalStorage('anon-user-id')
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [notes, setNotes] = useState('')
  const [emailSent, setEmailSent] = useState(false);
  const redirectTo = props.redirectTo || "/";

  if (user && user.emailVerified) {
    return <Redirect to={redirectTo} replace />;
  }

  const signUpEmailLink = async (e) => {
    if (!e) return notifyAndTrack.warn('Incomplete info', 'All fields are required.')
    await setPersistence(auth, browserLocalPersistence)
    const actionCodeSettings = {
      url: domain + '/email-link' + (redirectTo ? `?redirectTo=${encodeURI(redirectTo)}` : ''),
      handleCodeInApp: true,
    }
    sendSignInLinkToEmail(auth, e, actionCodeSettings)
      .then(() => setEmailLS(email))
      .then(() => user?.isAnonymous && setAnonUID(user.uid))
      .then(() => setEmail(""))
      .then(() => setEmailSent(true))
      .catch(err => {
        console.log('Sign up failed', err, err.code)
        console.log('app.root.domain', app.root.domain)
        switch (err.code) {
          case 'auth/email-already-in-use':
            notifyAndTrack.error('This email is already in use')
            break
          default:
            notifyAndTrack.error('Sign Up Failed', 'Try again later')
        }
      })
  }

  if (props.intent === 'link' && !user) {
    console.log("EmailAuthPage:sign-in intent not supported for logged-in user")
    return <label className="fat-chip">
      <Link to="/login">Please login</Link>
    </label>
  }

  if (props.intent === 'sign-in' && user && !user.isAnonymous) {
    console.log("EmailAuthPage:sign-in intent not supported for logged-in user")
    return <label className="fat-chip">
      <Link to="/">You're signed in</Link>
    </label>
  }

  return <section className="flex-col middle center" style={fullWidthStyle}>
    {emailSent ?
      <Card
        bordered={false}
        title="We emailed you a login link"
        className="card-simple card-rounded card-inline card-glass"
        style={{...fullWidthStyle, marginTop: '20px', marginBottom: '10px', paddingBottom: 30, paddingTop: 20}}
      >
        <Card.Meta description="Check your email and click on the link to login." />
      </Card> :
      <Space direction="vertical" style={containerStyle}>
        <Divider>{props.title || defaultTitle}</Divider>
        {!!props.withPassword && <Input size="large" prefix={null} onChange={e => setDisplayName(e.target.value)} placeholder="Your Name"/>}
        <Input
          size="large"
          prefix={<EmailIconOutlined />}
          className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}
          onChange={e => setEmail(e.target.value)} placeholder=" Your Email"
        />
        {!!props.withPassword && <Input
          size="large"
          prefix={<LockOutlined/>}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
        />}
        {!!props.extra?.length && <Input.TextArea
          size="large"
          value={notes}
          rows={4}
          onChange={e => setNotes(e.target.value)}
          placeholder={props.extra?.[0]?.placeholder || "Type here"}
        />}
        <div className="space space-center-y space-between">
          {props.actions || <div/>}
          <Button
            shape="round"
            size="large"
            className="icon-btn"
            block={isMobile}
            onClick={() =>
              props.withPassword ?
                signUpEmailPassword(app, email, password, displayName, notes) :
                signUpEmailLink(email, notes)
            }
            type="ghost"
          >
            {props.cta || props.withPassword ? "Sign Up" : "Send Link"} <RightArrowOutlined />
          </Button>
        </div>
      </Space>
    }
  </section>
}
