import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

ListItemActive.propTypes = {
  path: PropTypes.string,
  offset: PropTypes.string,
  style: PropTypes.object,
};

function ListItemActive(props) {
  const isActive = useSelector((state) => {
    return !!props.path && state.App[state.App.activeDetailServiceId]?.props?.path === props.path;
  });

  // console.log("ListItemActive", {isActive, props});

  return <div
    className={`list-item-selected ${isActive ? 'selected' : 'not-selected'}`}
    style={props.offset ? {right: props.offset, ...props.style || null} : props.style || null}
  ></div>;
}

export default ListItemActive;
