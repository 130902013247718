import React, {useCallback, useContext} from "react";
import {Button, Tabs} from "antd";
import {SourcesContext, WindowContext} from "@web/hooks/context";
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import FilteredContentList from "@ai-antd/components/FilteredContentList";
import {BackArrowIcon} from "@web/assets/icons";
import {useLocation} from "wouter";
import useLocalStorage from "@web/hooks/useLocalStorage";
import LaunchSourceManagerButton from "@ai-antd/components/LaunchSourceManagerButton";
import ServiceSelector from "@ai-antd/components/ServiceSelector";
import ContentFilter from "@ai-antd/components/ContentFilter";

function ServicesTabs() {
  const [isMobile] = useContext(WindowContext);
  const dispatch = useDispatch();
  const {services} = React.useContext(SourcesContext);
  const [location] = useLocation();
  const locationServiceId = location.split("/")?.[1];
  const activeServiceId = useSelector((state) => state.App.activeServiceId);
  // console.log("ServicesTabs:location", locationServiceId);
  const [solutionServicesKeys, setSolutionServicesKeys] = React.useState(services[locationServiceId]?.children?.map?.((serviceId) => services[serviceId])?.filter?.(Boolean)?.filter?.((service) => service.filter?.active === true).map(service => service.key));
  // const solutionServices = services[locationServiceId]?.children?.map?.((serviceId) => services[serviceId])?.filter?.(Boolean)?.filter?.((service) => service.filter?.active === true);
  const [userTabs, setUserTabs] = useLocalStorage("user-tabs");
  const [activeTabKey, setActiveTabKey] = React.useState(solutionServicesKeys?.[0] || activeServiceId);

  const onServiceChange = useCallback((serviceId) => {
    setActiveTabKey(serviceId);
    dispatch(contentActions.loadContent({serviceId}));
  }, [dispatch]);

  const onSelectorChange = useCallback((e) => {
    onServiceChange(e.key);
  }, [onServiceChange]);

  const showFilter = useSelector((state) => !!state.Content.showFilter);
  const [, setLocation] = useLocation();

  const onEditTabs = useCallback((targetKey, action) => {
    if (action === "remove") {
      setUserTabs(userTabs.filter((tab) => tab !== targetKey));
    }
    if (action === "add") {
      setUserTabs([...userTabs, targetKey]);
    }
  }, [userTabs, setUserTabs]);

  React.useEffect(() => {
    const keys = services[locationServiceId]?.children?.map?.((serviceId) => services[serviceId])?.filter?.(Boolean)?.filter?.((service) => service.filter?.active === true).map((service) => service.key);
    // console.log("ServicesTabs:useEffect", keys);
    setSolutionServicesKeys(keys);
  }, [locationServiceId]);

  // console.log("ServicesTabs:render", solutionServices);

  return <div className="w-full relative">
    <div className="services-tabs-header" style={{backgroundColor: isMobile ? "rgba(0,0,0,0.75)" : ""}}>
      <div className="space space-center-y space-between">
        <ServiceSelector
          key={locationServiceId}
          onClick={onSelectorChange}
          items={solutionServicesKeys?.map?.((serviceId) => services[serviceId])?.map((item) => {
            return {
              label: item.label,
              value: item.key,
              key: item.key,
              icon: item.icon,
            };
          })}
          text={services[activeTabKey]?.label}
        />
        <div className="space space-center-y">
          <ContentFilter />
        </div>
      </div>
    </div>
    {showFilter ?
      <FilteredContentList /> :
      <Tabs
        centered
        className={isMobile ? "services-tabs-mobile" : "services-tabs"}
        // tabBarStyle={isMobile ? {height: 50} : {height: "auto", paddingTop: 0}}
        key={"tabs-all"}
        activeKey={activeTabKey}
        items={solutionServicesKeys?.map?.((serviceId) => services[serviceId])}
        onChange={onServiceChange}
        onEdit={onEditTabs}
        tabBarExtraContent={{
          right: <LaunchSourceManagerButton
            key="sources-manager-x"
          />,
          left: <Button
            key="content"
            style={{marginTop: '6px'}}
            className="icon-right icon-btn"
            ghost
            onClick={() => setLocation("/line")}
          >
            <BackArrowIcon className="menu-icon green-icon" />
          </Button>,
        }}
      />}
  </div>
}

export default ServicesTabs;
