import React from "react";
import PropTypes from "prop-types";
import {Skeleton, Button, Card, List, notification} from "antd";
import {AppContext} from "@web/hooks/context";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import AssistantSetupCard from "./AssistantSetupCard";

VCardInfo.propTypes = {

};

function VCardInfo(props) {
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const assistantPath = ["user", user?.uid, "lines", "assistant"].join("/");
  const assistant = useSelector((state) => state.Lines.data?.[assistantPath]);
  const [editing, setEditing] = React.useState(false);

  const onClickShare = () => {
    if (!assistant) {
      return notification.warn({message: "A Line is not selected"});
    }
    try {
      if (!navigator.share) {
        notification.warn({message: "Share not supported on this device"});
      }
      navigator.share({
        title: "Share Contact",
        text: "Share this contact",
        url: `https://getline.ai/${assistant?.number}`
      });
    } catch (e) {
      console.error("Share not supported", e);
      notification.warn({message: e.message});
    }
  }

  const onClickFavorite = () => {
    if (!assistant) {
      return notification.warn({message: "A Line is not selected"});
    }
    notification.success({message: "Added to Favorites"});
  }

  return <div className="space space-col w-full">

    <Card bordered={false} className="card-simple card-inline font-lg m-6">
      <h4 className="mb-0">number</h4>
      <Skeleton loading={!assistant} paragraph={{rows: 1}}>
        <a className="blue-color bolded b-600" href={`tel:${assistant?.number}`}>{assistant?.numberFormatted || assistant?.number}</a>
      </Skeleton>
    </Card>
    <Card bordered={false} className="card-simple card-roundeds card-inline font-lg m-6">
      <h4 className="mb-0">email</h4>
      <Skeleton loading={!assistant} paragraph={{rows: 1}}>
        <a className="blue-color bolded b-600" href={`mailto:${assistant?.email}`}>{assistant?.number.replace("+", "")}@getline.email</a>
      </Skeleton>
    </Card>
    <Card bordered={false} className="card-simple card-roundeds card-inline font-lg m-6">
      <h4 className="mb-0">homepage</h4>
      <Skeleton loading={!assistant} paragraph={{rows: 1}}>
        <a className="blue-color bolded b-600"
           href={`https://getline.ai/${assistant?.number}`}>https://getline.ai/{assistant?.number}</a>
      </Skeleton>
    </Card>
    <Card bordered={false} className="card-simple card-inline font-lg m-6">
      <Skeleton loading={!assistant} paragraph={{rows: 4}}>
        <div className="space space-col small bolded b-600">
        <h6>home</h6>
        <label>1 LINE AVE W</label>
        <label>SAN FRANCISCO</label>
        <label>CALIFORNIA 94108</label>
        <label>UNITED STATES</label>
      </div>
      </Skeleton>
    </Card>
    <Card bordered={false} className="card-simple card-roundeds card-inline font-lg m-6">
      <h4 className="mb-0">birthday</h4>
      <Skeleton loading={!assistant} paragraph={{rows: 1}}>
        <a className="blue-color bolded b-600">{dayjs(assistant?.createdTs).format("MMMM DD, YYYY")}</a>
      </Skeleton>
    </Card>
    <Card bordered={false} className="card-simple card-roundeds card-inline font-lg m-6" style={{height: 180}}>
      <h4 className="mb-0">Notes</h4>
    </Card>
    <Card bordered={false} className="card-simple card-roundeds card-inline font-lg m-6" style={{height: 180}}>
      <List className="list-item-lite-divider">
        <List.Item>
          <a className="blue-color font-lg" href={"/chat"}>Start Chat</a>
        </List.Item>
        <List.Item>
          <a className="blue-color font-lg" onClick={onClickShare}>Share Contact</a>
        </List.Item>
        <List.Item>
          <a className="blue-color font-lg" onClick={onClickFavorite}>Add to Favorites</a>
        </List.Item>
      </List>
    </Card>
    {/*<Card bordered={false} className="card-simple card-roundeds card-inline font-lg m-6">*/}
    {/*  <a className="blue-color">Add Emergency Details</a>*/}
    {/*</Card>*/}
    <Card bordered={false} className="card-simple card-roundeds card-inline font-lg m-6">
      <a className="orange-color">Unsubscribe</a>
    </Card>
  </div>
}

export default VCardInfo;
