import React from 'react';
// import DashboardCenterRow from "./DashboardCenterRow";
// import HotlineIdentity from "./HotlineIdentity";
import GuidesManager from "./guides/GuidesManager";
import {AppContext, LinesContext, SandboxContext} from "@web/hooks/context";
import {buildQuery, Fields, generateID, setPath} from "@web/lib/firestore.db";
import now from "lodash/now";
// import useQueryFirestore from "@web/hooks/useQueryFirestore";
import {Link, useLocation} from "wouter";
// import AudioStreamData from "@web/ui/containers/AudioStreamData";
// import AudioStreamManager from "./AudioStreamManager";
import {api} from "@web/lib/api";
import {
  // AppAddService,
  AppService,
  // AppDataSourceCrawl,
  // AppDataSourceSearch,
  // AppDataSourceUrl,
  // AppSummary,
  // AppTraining,
  AppServiceContainer,
} from "./guides/AppCards";
// import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {Card, List, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import appsActions from "@web/redux/apps/actions";
import appActions from "@web/redux/app/actions";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import contentActions from "@web/redux/content/actions";
import aiActions from "@web/redux/ai/actions";
import SandboxJob from "./SandboxJob";

SandboxEditor.propTypes = {}

function SandboxEditor(props) {
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const linesContext = React.useContext(LinesContext);
  const [, setLocation] = useLocation();
  const appPath = ["user", user.uid, "sandbox", linesContext.appId].join("/");
  const activeServiceId = useSelector((state) => state.App.activeServiceId);
  const [sandboxApp, loadingSandboxApp] = useDocumentFirestore(appPath);

  const onCompleteStep = React.useCallback((path) => {
    const stepName = path.split("/").pop();
    switch (stepName) {
      default:
        setPath(path, {seen: true, date: now()}, true)
        .then(() => console.log("completed:", path));
    }
  }, []);

  /**
   * @param {string} servicePath
   * @param {object} updates
   * @type {(function(*, *): void)|*}
   * @return {object}
   */
  const onUpdateService = React.useCallback((servicePath, updates) => {
    // console.log("onUpdateService", {servicePath, updates});
    return api.LINE(`/apps/${linesContext.appId}/update`, {servicePath, updates})
    .then((response) => {
      console.log("onUpdateStep", response);
      return response;
    })
    .catch((error) => error);
  }, []);

  // /**
  //  * @param {string} servicePath
  //  * @param {object} updates
  //  * @type {(function(*, *): void)|*}
  //  * @return {object}
  //  */
  // const onUpdateApp = React.useCallback((updates) => {
  //   return api.LINE(`/apps/${linesContext.appId}/update`, {servicePath, updates})
  //   .then((response) => {
  //     console.log("onUpdateStep", response);
  //     return response;
  //   })
  //   .catch((error) => error);
  // }, []);

  const onExit = React.useCallback(() => {
    setLocation("/apps/create");
  }, []);

  React.useEffect(() => {
    // console.log("SandboxEditor useEffect");
    if (!linesContext?.serviceId) return;
    dispatch(appsActions.loadUserApps());
    if (activeServiceId !== linesContext.serviceId) {
      dispatch(appActions.setActiveServiceId(linesContext.serviceId))
    }
    dispatch(aiActions.setActiveApp(appPath, "sandbox"));
  }, [appPath, activeServiceId, linesContext.serviceId]);

  // React.useEffect(() => {
  //   if (activeDetailItemPath !== appPath) {
  //     dispatch(aiActions.setActiveApp(appPath));
  //   }
  // }, [appPath]);

  React.useEffect(() => {
    // console.log("loadUserAppServices", {linesContext, sandboxApp});
    sandboxApp?.servicesPaths?.length &&
    // dispatch(appsActions.loadUserAppServices(appPath));
    dispatch(contentActions.loadContext(appPath));
  }, [appPath, sandboxApp?.servicesPaths]);

  if (linesContext.appId && !sandboxApp) {
    return <Card
      title={<div className="font-lg">App not found</div>}
      extra={<Link to={"/apps/create"}>My apps</Link>}
      className="card-rounded"
      bordered={false}
      style={{width: "100%", maxWidth: 600, margin: "0 auto"}}
    >
      <Typography.Paragraph className="muted">
        The selected app was not found. Please create a new app or select an existing app from the list.
      </Typography.Paragraph>
    </Card>;
  }

  console.log("SandboxEditor", linesContext);

  if (linesContext.jobId) {
    return <SandboxJob appPath={appPath} app={sandboxApp} jobId={linesContext.jobId} />;
  }

  const serviceSteps = sandboxApp?.servicesPaths?.map?.((servicePath) => {
    return [AppServiceContainer, servicePath];
  });

  return <SandboxContext.Provider value={{app: sandboxApp, appPath}}>
    <GuidesManager
      asList
      list={[[AppService, appPath], ...serviceSteps || []].filter(Boolean)}
      appPath={appPath}
      onCompleteStep={onCompleteStep}
      onUpdateStep={onUpdateService}
      onExit={onExit}
    />
  </SandboxContext.Provider>
}

export default SandboxEditor;
