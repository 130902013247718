import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import ContentData from "@web/ui/containers/ContentData";
import ContactInfoCard from "./ContactInfoCard";
import ListItemActive from "./ListItemActive";
import appActions from "@web/redux/app/actions";
import {useDispatch, useSelector} from "react-redux";

ContactsListItem.propTypes = {
  path: PropTypes.string,
  index: PropTypes.number,
  date: PropTypes.number,
};

function ContactsListItem(props) {
  const dispatch = useDispatch();
  const contact = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const onClick = () => {
    dispatch(appActions.setActiveDetail({path: props.path, contact}));
  };

  return <List.Item
    key={props.path}
    onClick={onClick}
    className="relative contacts-list-item isMobile pt-0 pb-0"
  >
    <ContactInfoCard path={props.path} date={props.date} contact={contact} />
    <ListItemActive path={props.path} />
  </List.Item>
}

export default ContactsListItem;
