import React from 'react';
import PropTypes from 'prop-types';
import ContactActivityListItem from "./ContactActivityListItem";
import {ThreadsListItem} from "./ThreadsListItem";
import FileListItem from "./FileListItem";
import {List} from "antd";
import MarkdownListItem from "./MarkdownListItem";
import PaymentListItem from "./PaymentListItem";

FilteredContentListItem.propTypes = {
  path: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  filterMatch: PropTypes.object.isRequired,
};

function FilteredContentListItem(props) {
  const serviceId = props.path.split("/")[2];

  switch (serviceId) {
    case "threads":
    case "messages": {
      return <ThreadsListItem
        key={props.path}
        path={props.path}
      />;
    }
    case "files": {
      return <FileListItem
        grid
        path={props.path}
        id={props.path
        .split("/")
        .pop()}
      />;
    }
    case "contacts": {
      return <ContactActivityListItem
        key={props.path}
        id={props.path
        .split("/")
        .pop()}
        path={props.path}
      />;
    }
    case "payments": {
      return <PaymentListItem
        key={props.path}
        path={props.path}
      />
    }
    case "deliveries": {
      return <MarkdownListItem
        key={props.path}
        path={props.path}
        dataKey="deliveries"
      />
    }
    case "calendars": {
      return <List.Item>
        <span className="fat-chip">Calendar Event</span>
      </List.Item>
    }
    default: {
      // console.log('FilteredContentListItem:unmatched-service-id', props.path);
      return <List.Item>
        <span className="fat-chip">
          {props.path
          .split("/")[2]}
        </span>
      </List.Item>
    }
  }
}

export default FilteredContentListItem;
