import React from "react";
import PropTypes from "prop-types";
import ContactActivityDetails from "./ContactActivityDetails";
import ContactActivityData from "@web/ui/containers/ContactActivityData";

ContactDetailsWrapper.propTypes = {
 contactId: PropTypes.string,
};

function ContactDetailsWrapper(props) {
  return <ContactActivityData {...props}>
    <ContactActivityDetails />
  </ContactActivityData>
}

export default ContactDetailsWrapper;
