import React, {useContext} from 'react'
import {AppContext} from '@web/hooks/context'
import useQueryFirestore from '@web/hooks/useQueryFirestore'
import {buildQuery, colls} from '@web/lib/firestore.db'
import Loading from '@ai-antd/components/Loading'
import PropTypes from "prop-types";

FaqData.propTypes = {
  lookupKey: PropTypes.string,
  children: PropTypes.element.isRequired,
}

export default function FaqData(props) {
  const [{root = {}}] = useContext(AppContext) || {}
  const [pairs = [], loadingData, error] = useQueryFirestore(
    buildQuery(
      colls['app-faq'], {
        '==': { active: true },
        'array-contains': {app: props.lookupKey || root.key},
      },
      {order: [['rank', 'desc']], limit: 30}
    ))
  if (loadingData) return <Loading />
  if (error) return null

  return React.cloneElement(React.Children.only(props.children), {pairs})
}
