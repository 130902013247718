import React from "react";
import PropTypes from "prop-types";
import {Button, Card} from "antd";
import {PlayIcon, PlusIcon} from "@web/assets/icons";
import {useSelector} from "react-redux";
import {useLocation} from "wouter";

AppStoreCard.propTypes = {
  path: PropTypes.string,
  collapsed: PropTypes.bool,
  className: PropTypes.string,
};

function AppStoreCard(props) {
  const [, setLocation] = useLocation();
  const app = useSelector((state) => state.Content.data?.[props.path]);

  const onGetApp = () => {
    setLocation(`/apps/${app.appKey}`);
  }

  return <Card
    bordered={false}
    extra={<div className="space space-center-y">
      <Button onClick={onGetApp} shape="round" className="icon-btn"><PlusIcon size={20} /> Get</Button>
  </div>}
    className={`card-rounded card-no-separator card-simple extra-round ${props.className} card-body-scroll`}
    title={<h6 className="font-lg bolded">{app.appName}</h6>}
  >
    <div className="space space-col">
      <span className="font-lg bolded">{app.title}</span>
      <span className="ai-text font-lg">{app.description}</span>
      {app.userId && <div className="w-full" style={{marginTop: 12}}>
        <Button size="large" shape="round" className="icon-btn orange-color"><PlayIcon /> Run App</Button>
      </div>}
    </div>
  </Card>
}

export default AppStoreCard;
