import React, {useState, useContext, useEffect} from 'react'
import {AppContext} from '@web/hooks/context'
import {Button, Collapse, Divider, Space, Typography} from 'antd'
import {EditOutlined} from '@ant-design/icons'
import FaqFormModal from './FaqFormModal'
import Icon from './Icon'
import {RightArrowOutlined} from '@web/assets/icons'

const faqParagraph = {opacity: 0.75}
export default function Faq({pairs = [], expanded = false, icon}) {
  const [{about = {}}, user] = useContext(AppContext) || {}
  const [faqId, setFaqId] = useState()
  const [showFaqModal, setShowFaqModal] = useState(false)
  const faqConfig = about?.tabs?.find((tab) => tab.key === 'faq')
  useEffect(() => {
    if (faqId) setShowFaqModal(true)
  }, [faqId])
  const onClickEdit = (e, faqId) => {
    e.preventDefault()
    e.stopPropagation()
    setFaqId(faqId)
  }
  const optionalProps = {};
  if (expanded) {
    optionalProps.activeKey = pairs.map(([faqId]) => faqId)
  }
  return <>
    {user?.uid && faqConfig && faqConfig.allowAdd && <div>
      <div className="space space-between">
        <div />
        <FaqFormModal
          faqId={faqId}
          show={showFaqModal}
          onShow={show => {
            if (!show) setFaqId('')
            setShowFaqModal(show)
          }}
        />
    </div>
    <Divider className="small" />
    </div>
    }
    <Collapse
      ghost
      {...optionalProps}
      expandIcon={({isActive}) => icon || <RightArrowOutlined size={20} style={isActive ? {transform: 'rotate(90deg)', verticalAlign: 'middle'} : {verticalAlign: 'middle'}} />}>
    {pairs.map(([faqId, faq]) => {
      return <Collapse.Panel
        header={
        <Typography.Title level={5}>
          {faq.title}
        </Typography.Title>}
        key={faqId}
        extra={user?.uid && faqConfig?.allowEdit && <Button
          size="small"
          className="border-none"
          icon={<EditOutlined />}
          onClick={(e) => onClickEdit(e, faqId)}
        />
      }>
        <div style={faqParagraph}>
          <p className="render-line-breaks">{faq.body}</p>
          {!!faq?.actions?.length &&
            <Space direction="vertical">
              {faq?.actions?.map(action => <Space key={action.text}>
                  <Icon name="LinkOutlined" size={20} /> <a href={action.path}>{action.text}</a>
                </Space>
              )}
            </Space>}
          {!!faq?.links?.length &&
            <Space direction="vertical">
              {faq?.links?.map(link => <Space key={link.text}>
                <Icon name="LinkOutlined" size={20} /> <a href={link.url}>{link.text}</a>
              </Space>
              )}
            </Space>}
        </div>
      </Collapse.Panel>
    })}
  </Collapse></>
}
