import React from 'react';
import {useDispatch, useSelector} from "react-redux";
// import ChatInputTextarea from "./ChatInputTextarea";
import contentActions from "@web/redux/content/actions";
import FacetsPopup from "./FacetsPopup";
import {XIcon} from "@web/assets/icons";
import {Input} from "antd";
import {WindowContext} from "@web/hooks/context";
// import ChatInputSelect from "./ChatInputSelect";

function ContentFilter(props) {
  const dispatch = useDispatch();
  const [isMobile] = React.useContext(WindowContext);
  const [text, setText] = React.useState("");
  const showFilter = useSelector((state) => !!state.Content.showFilter);
  const [isFocused, setIsFocused] = React.useState(false);

  const [showFacets, setShowFacets] = React.useState(false);
  const onClickClose =
    React.useCallback(() => {
      setShowFacets(false);
    }, []);

  const onFocus = () => {
    // console.log("onFocus", {e, isFocused});
    setIsFocused(true);
    showFilter === true && setShowFacets(true);
  };
  const onBlur = (e, isFocused) => {
    // console.log("onFocus", {e, isFocused});
    setIsFocused(false);
    showFilter === true && isFocused === true && setShowFacets(true);
  };

  const onTextChange = React.useCallback((e) => {
    console.log("onTextChange", e.target.value);
      setText(e.target.value);
      dispatch(contentActions.filterActivity({q: e.target.value}));
    }, [dispatch]);

  React.useEffect(() => {
    if (showFilter === false) {
      setText("");
    }
  }, [showFilter]);

  return <FacetsPopup
      onClose={onClickClose}
      show={showFacets}
    >
      <Input
        text={text}
        size="large"
        placeholder="Filter"
        className={isFocused || isMobile ? "input-bold small" : "rounded"}
        // dictate={false}
        maxLength={200}
        onChange={onTextChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      />
    </FacetsPopup>
}

export default ContentFilter;
