/** @namespace chatStream */
import React from "react";
import now from "lodash/now";
import {api} from "@web/lib/api";
import {AppContext, ChatContext, LinesContext} from "./context";
import debounce from "lodash/debounce";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "wouter";

/**
 * @typedef {object} SendRequestOptionsData
 * @memberOf chatStream
 * @property {string} [appPath]
 * @property {string[]} [contextPaths]
 * @property {models.Chat.Data[]|number} [history]
 * @property {string} [collectionPath]
 * @property {boolean} [isRetry]
 */

const getEndpoint = (location, appPath, messengerId) => {
  switch (true) {
    case !!messengerId:
      return process.env.NODE_ENV === "production" ?
        `https://line-v1-7zujc5azra-uw.a.run.app/messenger/${messengerId}` :
        `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/messenger/${messengerId}`;
    case !!appPath:
      return process.env.NODE_ENV === "production" ?
        `https://line-v1-7zujc5azra-uw.a.run.app/assistant/app` :
        `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant/app`;
    case location.startsWith("/start"):
      return process.env.NODE_ENV === "production" ?
        "https://line-v1-7zujc5azra-uw.a.run.app/guide" :
        "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/guide";
    case location.startsWith("/assistant"):
    case location.startsWith("/emails"):
    case location.startsWith("/lines"):
    case location.startsWith("/tasks"):
    case location.startsWith("/calls"):
    case location.startsWith("/contacts"):
    case location.startsWith("/links"):
      return process.env.NODE_ENV === "production" ?
        "https://line-v1-7zujc5azra-uw.a.run.app/assistant" :
        "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant";
    case location.startsWith("/today"):
      return process.env.NODE_ENV === "production" ?
        "https://line-v1-7zujc5azra-uw.a.run.app/chat" :
        "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/chat";
    // case !!linesContext?.appId:
    //   return process.env.NODE_ENV === "production" ?
    //     `https://line-v1-7zujc5azra-uw.a.run.app/assistant/apps/${linesContext.appId}` :
    //     `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant/apps/${linesContext.appId}`;
    case location.startsWith("/apps"):
      return process.env.NODE_ENV === "production" ?
        "https://line-v1-7zujc5azra-uw.a.run.app/assistant/apps" :
        "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant/apps";
    case location.startsWith("/sandbox"):
      return process.env.NODE_ENV === "production" ?
        "https://line-v1-7zujc5azra-uw.a.run.app/assistant/sandbox" :
        "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant/sandbox";
    default:
      return "";
    // return process.env.NODE_ENV === "production" ?
    //   `https://line-v1-7zujc5azra-uw.a.run.app/messenger/${linesContext?.hotline}` :
    //   `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/messenger/${linesContext?.hotline}`;
  }
};

//
// const url = (location, linesContext, chatContext) => {
//   console.log("useChatStream:url:", location, linesContext, chatContext);
//   switch (true) {
//     case !!chatContext?.appId:
//       return process.env.NODE_ENV === "production" ?
//         `https://line-v1-7zujc5azra-uw.a.run.app/assistant/apps/${chatContext.appId}` :
//         `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant/apps/${chatContext.appId}`;
//     case location.startsWith("/start"):
//       return process.env.NODE_ENV === "production" ?
//         "https://line-v1-7zujc5azra-uw.a.run.app/guide" :
//         "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/guide";
//     case location.startsWith("/chat"):
//     case location.startsWith("/tasks"):
//     case location.startsWith("/calls"):
//     case location.startsWith("/contacts"):
//     case location.startsWith("/links"):
//       return process.env.NODE_ENV === "production" ?
//         "https://line-v1-7zujc5azra-uw.a.run.app/assistant" :
//         "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant";
//     case location.startsWith("/today"):
//       return process.env.NODE_ENV === "production" ?
//         "https://line-v1-7zujc5azra-uw.a.run.app/chat" :
//         "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/chat";
//       // return process.env.NODE_ENV === "production" ?
//       //   "https://ai-chat-7zujc5azra-uw.a.run.app" :
//       //   "http://127.0.0.1:5001/admin-85663/us-west1/ai-chat";
//     case !!linesContext?.appId:
//       return process.env.NODE_ENV === "production" ?
//         `https://line-v1-7zujc5azra-uw.a.run.app/assistant/apps/${linesContext.appId}` :
//         `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant/apps/${linesContext.appId}`;
//     case location.startsWith("/apps"):
//       return process.env.NODE_ENV === "production" ?
//         "https://line-v1-7zujc5azra-uw.a.run.app/assistant/apps" :
//         "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/assistant/apps";
//     default:
//       return process.env.NODE_ENV === "production" ?
//         `https://line-v1-7zujc5azra-uw.a.run.app/messenger/${linesContext?.hotline}` :
//         `http://127.0.0.1:5001/admin-85663/us-west1/line-v1/messenger/${linesContext?.hotline}`;
//   }
// };

const debouncedSendRequest = debounce((endpoint, {linesContext, agentId, userId, text, appPath, history, onData, onError, path, isRetry = false, contextPaths = []}) => {
  const ts = now();
  const prompt = {
    text,
    agentId,
    userId,
    createdTs: ts,
    updatedTs: ts,
    history,
    path,
    isRetry,
    contextPaths,
    appPath,
    // ...linesContext || null,
  }
  // console.log("useChatStream:debouncedSendRequest:", endpoint);
  try {
    api.STREAM({prompt, url: endpoint}, onData, onError);
  } catch (error) {
    console.log("Error streaming chat", error);
    onError && onError(error);
  }
}, 500, {leading: false, trailing: true});

/**
 * @function useChatStream
 * @memberof chatStream
 * @param {string} [agentId="ai-assistant"]
 * @param {boolean} [withDispatch]
 * @returns {[models.Answer.Data[], function(text:string, options:SendRequestOptionsData):void, Error, boolean, string]}
 */
function useChatStream(agentId, withDispatch) {
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const linesContext = React.useContext(LinesContext);
  const chatContext = React.useContext(ChatContext);
  // const activeServiceId = useSelector((state) => state.App.activeServiceId);
  // const activeAppPath = useSelector((state) => state.AI?.[activeServiceId]?.activeApp?.path);
  const [answers, setAnswers] = React.useState([]);
  const [chatPath, setChatPath] = React.useState("");
  const [streamDone, setStreamDone] = React.useState(false);
  const [streamError, setStreamError] = React.useState(null);
  const [location, setLocation] = useLocation();

  const onData = (stream) => {
    setChatPath(stream.path);
    setStreamDone(!!stream.done);
    setAnswers([...stream.chat?.answers]);
    withDispatch &&
    dispatch({
      type: "AI_WRITE_STREAM",
      agentId,
      stream,
      streamDone: streamDone,
    });
  }
  const onError = (error) => {
    setStreamError(error);
  }

  /**
   * @function sendRequest
   * @memberof chatStream
   * @param {string} text
   * @param {SendRequestOptionsData} options
   * @return {void}
   */
  function sendRequest(text, options){
    console.log("useChatStream:sendRequest:", text);
    const {appPath, contextPaths = [], history, collectionPath, isRetry} = options;
    setStreamError(null);
    setStreamDone(false);
    !!text &&
    debouncedSendRequest(getEndpoint(location, appPath), {
      agentId,
      userId: user.uid,
      text,
      appPath,
      history: history ?? 0,
      contextPaths,
      path: collectionPath || chatContext.agent?.collectionPath,
      linesContext,
      isRetry,
      onData,
      onError,
    });
  }

  return [answers, sendRequest, streamError, streamDone, chatPath];
}

export default useChatStream;
