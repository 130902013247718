import React, {useContext} from "react";
import {Empty, List, Divider, Button, Card} from "antd";
import {AppContext, LinesContext, WindowContext} from "@web/hooks/context";
// import ContactActivityListItem from "./ContactActivityListItem";
// import Loading from "./Loading";
// import Pager from "@web/ui/containers/Pager";
// import LineConnector from "./LineConnector";
import PropTypes from "prop-types";
// import ActivityData from "@web/ui/containers/ActivityData";
// import LaunchSourceManagerButton from "./LaunchSourceManagerButton";
import AppsListItem from "./AppsListItem";
// import ContentData from "@web/ui/containers/ContentData";
// import {RightArrowOutlined} from "@web/assets/icons";
import SimplePager from "./SimplePager";
import {addPath, ServerTimestamp} from "@web/lib/firestore.db";
import {useLocation} from "wouter";

SandboxList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
}

function SandboxList(props) {

  const [, user] = useContext(AppContext);
  const [location, setLocation] = useLocation();

  const onSelectApp = React.useCallback((appPath) => {
    setLocation(`/sandbox/${appPath.split("/").pop()}`);
  }, [location]);

  const onClickNewApp = () => {
    addPath(`user/${user.uid}/sandbox`, {
      name: "New App",
      created: ServerTimestamp(),
      updated: ServerTimestamp(),
    })
    .then((ref) => onSelectApp(ref.path));
  }

  const onClickItem = React.useCallback((data, e) => {
    e.stopPropagation();
    e.preventDefault();
    onSelectApp(data.path);
  }, [location]);

  if (!props.list) {
    return null;
  }

  return <SimplePager
      dataSource={props.list}
      loading={false}
      pageSize={5}
      hidePageOnSinglePage={true}
    >
      <List
        header={
          props.list?.length ?
            <div className="space space-center-y space-between">
            <h6/>
            <Button className="green-color" shape="round" size="large" onClick={onClickNewApp}>New App</Button>
          </div> : null
        }
        className="services-contacts-list list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date]) => {
          return <AppsListItem key={path} path={path} date={date} onClick={onClickItem} />;
        }}
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper space space-col middle"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{width: "100%", maxWidth: "100%", margin: "0 auto"}}
          >
            {/*<div className="bgx cursor-pointer" style={{width: "100%", padding: "12px 0"}}>*/}
            {/*  <LaunchSourceManagerButton key="sources-manager-x" serviceId={"contacts"}>*/}
            {/*    <Typography.Title style={{marginBottom: 11}}>Apps</Typography.Title>*/}
            {/*  </LaunchSourceManagerButton>*/}
            {/*</div>*/}

            <Card hoverable className="card-simple card-rounded card-no-header card-highlight" onClick={onClickNewApp}>
              <span className="font-lg ai-text">Create my first app</span>
              {/*<SourceManager group={{sourceIds:["gmail", "outlook", "web"]}} serviceId="contacts" />*/}
            </Card>
          </Empty>
        }}
        // footer={
        // <div className="bgx cursor-pointer space space-center-x" style={{padding: "12px 0"}}>
        //   <LaunchSourceManagerButton key="sources-manager-x" serviceId={"apps"}>
        //     <Typography.Title style={{marginBottom: 11}}>Browse Apps</Typography.Title>
        //   </LaunchSourceManagerButton>
        // </div>}
      />
  </SimplePager>
}

export default SandboxList;
