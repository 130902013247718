import React, {useContext, useEffect} from 'react';
import PropTypes from "prop-types";
import {AppContext} from '@web/hooks/context'
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import appActions from "@web/redux/app/actions";
import Progress from "@ai-antd/page/Progress";

ContactActivityData.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function ContactActivityData({path, empty, children, ...props}) {
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);
  const activeServiceId = useSelector((state) => state.App.activeServiceId);

  const clearActiveDetail = React.useCallback(() => {
    dispatch(appActions.clearActiveDetail());
  }, []);

  const loadAllContent = React.useCallback(() => {
    dispatch(contentActions.loadAllContent({path}));
  }, [path]);

  const contact = useSelector((state) => {
    return state.Content?.data?.[path];
  });

  const activityList = useSelector((state) =>
    state.Content.activity?.[path]?.activityList);

  const itemsList = useSelector((state) =>
    state.Content.activity?.[path]?.itemsList);

  useEffect(() => {
    console.log(path);
    path && dispatch(contentActions.loadContent({
      path,
      serviceId: activeServiceId, //: "activity",
    }));
  }, [path]);

  // console.log("ContactActivityData", {path, contact, activityList, itemsList});

  if (!user?.uid) {
    return null;
  }

  if (!path) {
    return null;
    // return <Progress />;
  }

  return React.cloneElement(
    React.Children.only(children), {
      ...props,
      contextPath: path,
      activityList,
      itemsList,
      contact: contact || props.contact,
      onClose: clearActiveDetail,
      loadAllContent,
    });
}

export default ContactActivityData;
