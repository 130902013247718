import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import ListItemActive from "./ListItemActive";
import FileCard from "./FileCard";
import {List} from "antd";
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";

FileListItem.propTypes = {
  path: PropTypes.string.isRequired,
  grid: PropTypes.bool,
};

function FileListItem(props) {
  const dispatch = useDispatch();
  const file = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const setActiveDetail = useCallback(() =>
    dispatch(appActions.setActiveDetail(
      {path: props.path, file},
      true)),
    [props.path, file]);

  return <List.Item style={{width: "100%", position: 'relative', height: 300, maxHeight: 300}}>
    <ListItemActive path={props.path} offset={"-20px"} />
    <FileCard
      path={props.path}
      file={file}
      preview={false}
      onClick={setActiveDetail}
    />
    {/*<DocumentItemData*/}
    {/*  id={props.path.split("/").pop()}*/}
    {/*  path={props.path}*/}
    {/*>*/}
    {/*{props.grid ?*/}
    {/*  <FileCard*/}
    {/*    file={file}*/}
    {/*    preview={false}*/}
    {/*    onClick={setActiveDetail}*/}
    {/*  /> :*/}
    {/*  <DocumentListItem/>}*/}
    {/*</DocumentItemData>*/}
  </List.Item>
}

export default FileListItem;
