import React from 'react';
import PropTypes from 'prop-types';
import {LeftArrowOutlined, RightArrowOutlined, UserTodoIcon, XIcon} from "@web/assets/icons";
import {Button, Card, List, Tooltip} from "antd";
import {useSelector} from "react-redux";

FacetsPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

function FacetsPopup(props) {
  const facets =
    useSelector((state) =>
      state.Content.activity?.filter?.facets);

  const members =
    useSelector((state) =>
      state.Content?.members);

  const serverHits =
    useSelector((state) =>
      state.Content?.activity?.filter?.serverHits);

  const [
    showFacets,
    setShowFacets,
  ] = React.useState(!!facets?.length);

  const showFilter =
    useSelector(
      (state) =>
        !!state.Content.showFilter);

  React.useEffect(() => {
    if (!showFilter) {
      return;
    }
    setShowFacets(!!facets?.length);
  }, [facets, showFilter]);

  React.useEffect(() => {
    !showFilter &&
    setShowFacets(false);
    props.onClose &&
    props.onClose(false);
  }, [showFilter]);

  const onClickClose =
    React.useCallback(() => {
      setShowFacets(false);
      props.onClose &&
      props.onClose(false);
    }, []);

  return <Tooltip
    placement={"bottomRight"}
    open={!!facets?.length && (showFacets || props.show)}
    showArrow={false}
    align={{
      offset: [480, -40],
    }}
    title={
      <Card
        style={{margin: 10, width: 420}}
        className="card-rounded card-simple card-body-scroll card-h-300"
        bordered={false}
        title={
          <Button
            ghost
            onClick={onClickClose}
            className="icon-btn icon-left"
          >
            <LeftArrowOutlined className="green-icon" />
          </Button>
        }
        extra={serverHits && <span className="muted">{serverHits} hits</span>}
      >
        <List
          className="list-item-no-divider list-item-relative"
          style={{width: '100%'}}
          dataSource={facets || []}
          renderItem={
            ([facetName, count]) =>
              <List.Item>
                <div className="space space-between">
                  <div className="space space-center-y">
                    <UserTodoIcon size={22} className="muted-icon" />
                    <span className="muted">
                      {members?.[facetName.split(":").pop()]?.displayName || facetName.split(":").pop()}
                    </span>
                  </div>
                  <span className="muted">
                      {count}
                    </span>
                </div>
              </List.Item>
          }
        />
      </Card>
    }
  >
    {props.children}
  </Tooltip>
}

export default FacetsPopup;
