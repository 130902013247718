import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
import ContactsList from "./ContactsList";

function ContactsContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="contacts" component={ContactsList} />
    </section>
  </DashboardFullRow>
}

export default ContactsContent;
