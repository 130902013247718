import get from 'lodash/get.js';

export const toHTML = (text) => {
  const parser = new DOMParser();
  return parser.parseFromString(text, 'text/xml');
}

/**
 * @summary Get hydrated text from a template
 * @param {String} template
 * @param {Object} data
 * @return {String}
 */
export const fromTemplate = (template, data ={}) => {
  return template.replace(/\{([^}]+)\}/g, (match, key) => get(data, key));
  // return output;
}

/**
 * @summary Parse a JSON object for syntax highlighting
 * @param {object} json
 * @return {*|string}
 */
export function syntaxHighlight(json) {
  if (!json) return ""; //no JSON from response

  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
}
