import React from "react";
import {Menu, Layout, Divider, Card} from "antd";
import {AppContext} from "@web/hooks/context";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "wouter";
import PropTypes from "prop-types";
import voiceActions from "@web/redux/voice/actions";
import appActions from "@web/redux/app/actions";
import {
  AccountIcon,
  AppstoreIcon, AtOutlineIcon,
  BriefcaseIcon,
  ContactsOutlinedIcon, FeatherIcon, HomeOutlined,
  InboxIcon, LeftArrowOutlined,
  LinkOutlined, MenuIcon,
  MessageOutlined,
  PhoneCallIcon, SettingsIcon, StarIcon, TerminalIcon,
  TodoIcon, UserTodoIcon, WebsiteIcon,
} from "@web/assets/icons";
import LaunchSourceManagerButton from "./LaunchSourceManagerButton";

DashboardHeader.propTypes = {
  right: PropTypes.node,
  isMobile: PropTypes.bool,
};

function DashboardHeader(props) {
  const dispatch = useDispatch();
  const [, user, claims] = React.useContext(AppContext);
  const registering = useSelector((state) => state.Voice.registering);
  const registered = useSelector((state) => state.Voice.registered);
  const collapseMenu = useSelector((state) => state.App.collapseMenu);
  const menuWidth = useSelector((state) => state.App.menuWidth);
  const menuCollapsedWidth = useSelector((state) => state.App.menuCollapsedWidth);
  const [location, setLocation] = useLocation();
  const [current, setCurrent] = React.useState(location);

  React.useEffect(() => {
    if (registered || registering) return;
    dispatch(voiceActions.register());
  }, []);

  React.useEffect(() => {
    setCurrent(location);
  }, [location]);

  const onCollapse = (collapsed, type) => {
    console.log("onCollapse:", collapsed, type);
    dispatch(appActions.toggleMenu(collapsed));
  }

  const items = [];
  const bottomItems = [
    {type: "divider"},
    {
      label: 'Setup',
      key: '/start',
      icon: <FeatherIcon size={20} style={{position: "relative", top: 3}} />,
    },
    {type: "divider"},
    {
      label: 'Account',
      key: '/account',
      icon: <AccountIcon style={{position: "relative", top: 6}} />,
    },
  ];

  // user &&
  // items.push({
  //   label: "Home",
  //   icon: <HomeOutlined size={20} style={{position: "relative", top: 5}} />,
  //   // label: <NavigationLabel text="Chat" active={location.startsWith("/pro")} />,
  //   key: '/home',
  // });
  user &&
  items.push({
    label: "Home",
    icon: <MessageOutlined size={20} style={{position: "relative", top: 5}} />,
    // label: <NavigationLabel text="Chat" active={location.startsWith("/pro")} />,
    key: '/home',
  });
  claims?.data?.subs?.includes("email") && items.push({
    label: 'Calls',
      key: `/calls`,
    icon: <PhoneCallIcon style={{position: "relative", top: 4}} />,
  },
  // {
  //   label: 'Contacts',
  //   key: '/contacts',
  //   icon: <ContactsOutlinedIcon style={{position: "relative", top: 4}} />,
  // },
  );
  items.push(
    // {
    //   label: 'Links',
    //   key: '/links',
    //   icon: <LinkOutlined style={{position: "relative", top: 5}} />,
    // },
    // {
    //   label: 'Lines',
    //   key: '/lines',
    //   icon: <WebsiteIcon style={{position: "relative", top: 5}} />,
    // },
    {type: "divider"},
    claims?.data?.subs?.includes("creator") && {
      label: 'Apps',
      key: 'apps',
      icon: <AppstoreIcon style={{position: "relative", top: 5}} />,
      children: [
        { label: 'Store', key: '/apps/store' },
        { label: 'Installed', key: '/apps' },
        { label: 'Create', key: '/apps/create' },
      ],
    },
    // claims?.data?.subs?.includes("email") &&
    {
      label: 'Email',
      key: `/emails`,
      icon: <InboxIcon style={{position: "relative", top: 4}} />,
    },
    // claims?.data?.subs?.includes("tasks") &&
    {
      label: "Tasks", // <NavigationLabel text="Tasks" active={location.startsWith("/line")} />,
      key: "/tasks",
      icon: <TodoIcon style={{position: "relative", top: 3}} />,
      disabled: false,
    },
    {type: "divider"},
  );

  const onClick = ({key, keyPath}) => {
    const path = keyPath.reverse().join("");
    console.log("onClick:", key, keyPath);
    setLocation(key);
    setCurrent(key);
  }

  return <Layout.Sider
    breakpoint="md"
    width={menuWidth}
    collapsedWidth={menuCollapsedWidth}
    collapsed={collapseMenu}
    collapsible={true}
    onCollapse={onCollapse}
    className="app-sider"
    trigger={<LeftArrowOutlined style={{position: "relative", top: 4, color: "gray"}} />}
  >
    <div style={{padding: "20px 0 11px 16px"}}>
      <a href="/">
        {collapseMenu ?
          <img width={24} src="/icons/icon_sky.png" /> :
          <img width={82} src={"/icons/line-on-dark.png"} />
        }
      </a>
    </div>
    <Divider className="small-top" />
    <Menu
      className="transparent-bg"
      theme={"dark"}
      onClick={onClick}
      selectedKeys={[current]}
      mode="vertical"
      items={items}
    />
    <LaunchSourceManagerButton key="sources-manager" serviceId={"threads"}>Connect</LaunchSourceManagerButton>
    <Menu inlineIndent={0}
      className="transparent-bg bottom-links"
      theme={"dark"}
      onClick={onClick}
      selectedKeys={[current]}
      mode="vertical"
      items={bottomItems}
    />
  </Layout.Sider>;
}

export default DashboardHeader;
