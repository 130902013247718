import React, {useEffect, useRef} from "react";
import {
  signInWithPhoneNumber,
  linkWithPhoneNumber,
  RecaptchaVerifier,
  // RecaptchaParameters,
  // User,
  // UserInfo,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from '@web/lib/firebase';
import Loading from "./Loading";
import {Input, Select, Button, Card} from "antd";
import HotClick from "@web/ui/containers/HotClick";
import {
  AlertIcon,
  ApplicationIcon,
  CheckIcon,
  DownArrowOutlined,
} from "@web/assets/icons";
import PropTypes from "prop-types";
import {Link} from "wouter";
import {AppContext} from "@web/hooks/context";
import {notifyAndTrack} from "./notification";
// import callingCodes from 'country-calling-code'

PhoneAuth.propTypes = {
  intent: PropTypes.oneOf(['link', 'sign-in']).isRequired,
}

function PhoneAuth(props) {
  const [user, loadingUser, errorLoadingUser] = useAuthState(auth);
  const [,, claims] = React.useContext(AppContext);
  const [phoneNumber, setPhoneNumber] = React.useState(claims?.data?.phoneNumber?.replace?.("+1", "") || '');
  const [showCodeView, setShowCodeView] = React.useState(false);
  const [confirmationCode, setConfirmationCode] = React.useState("");
  const [recaptchaVerified, setRecaptchaVerified] = React.useState(false);
  const [optIn, setOptIn] = React.useState(false);
  const [authError, setAuthError] = React.useState(false);
  const [authMessage, setAuthMessage] = React.useState('');
  const [smsError, setSMSError] = React.useState(false);
  const [smsMessage, setSMSMessage] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('+1');
  const confirmation = useRef(null);

  const toggleOptIn = () => {
    setOptIn(!optIn);
  }

  const sendVerificationCode = () => {
    if (!countryCode) return;
    if (!phoneNumber) return;
    console.log('useEffect', countryCode, phoneNumber)
    setPersistence(auth, browserLocalPersistence)
    .then(() => {
      console.log('Persistence set to browser local', window.recaptchaVerifier);
      if (props.intent === 'link') {
        if (!user) throw new Error('PhoneAuth:link intent not supported for anonymous user');
        if (user.isAnonymous) throw new Error('PhoneAuth:link intent not supported for anonymous user');
        return linkWithPhoneNumber(auth.currentUser, countryCode + phoneNumber, window.recaptchaVerifier)
      } else if(props.intent === 'sign-in') {
        if (user && !user.isAnonymous) throw new Error('PhoneAuth:sign-in intent not supported for logged-in user');
        return signInWithPhoneNumber(auth, countryCode + phoneNumber, window.recaptchaVerifier);
      }
      // return user && !user.isAnonymous && props.intent === 'link' ?
      //   linkWithPhoneNumber(auth.currentUser, countryCode + phoneNumber, window.recaptchaVerifier) :
      //   signInWithPhoneNumber(auth, countryCode + phoneNumber, window.recaptchaVerifier)
      // // todo - allow both sign in and link options via props
    })
    .then((confirmationResult) => {
      console.log('SMS Sent')
      confirmation.current = confirmationResult;
      setShowCodeView(true);
    })
    .catch((error) => {
      console.log('Error Sending SMS', error);
      setSMSError(true);
      setSMSMessage('Please try again.');
      window.recaptchaVerifier?.reset?.(window.recaptchaWidgetId);
    });
  };

  useEffect(() => {
    if (!confirmationCode) return
    if (confirmationCode.length < 6) return
    confirmation.current.confirm(confirmationCode)
    .then((result) => {
      console.log('Code Confirmed', result)
      setAuthError(false)
      setAuthMessage('')
      window.location.href = '/';
      // window.recaptchaVerifier?.reset?.(window.recaptchaWidgetId);
    })
    .catch((error) => {
      console.log('Error Validating Code', error)
      setAuthError(true)
      setAuthMessage('Please try again.')
      window.recaptchaVerifier?.reset?.(window.recaptchaWidgetId);
    });
  }, [confirmationCode])

  if (loadingUser) {
    return <Loading/>
  }

  if (props.intent === 'link' && !user) {
    return <Card className="card-simple card-rounded" bordered={false}>
      <Link to="/login">Please login</Link>
    </Card>
  }

  if (props.intent === 'sign-in' && user && !user.isAnonymous) {
    console.log("PhoneAuth:sign-in intent not supported for logged-in user")
    return <label className="fat-chip">
      <Link to="/">You're signed in</Link>
    </label>
  }

  const handleLogin = () => {
    try {
      if (!optIn) {
        setSMSError(true)
        setSMSMessage('Please agree to the terms above.')
        return;
      }
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
        "login-button", {
          "size": "invisible",
          "callback": (response) => {
            // console.log("reCAPTCHA solved", {response});
            sendVerificationCode();
            setRecaptchaVerified(true);
          },
          "expired-callback": () => {
            // console.log("reCAPTCHA expired");
            notifyAndTrack.warn("reCAPTCHA expired", "Please try again.");
            setRecaptchaVerified(false);
          },
          "error-callback": (error) => {
            console.log("reCAPTCHA error", {error});
            notifyAndTrack.warn("reCAPTCHA error", "Please try again.");
            setRecaptchaVerified(false);
          },
        });
      }

      window.recaptchaVerifier
      .verify()
      .then((widgetId) => {
        console.log('reCAPTCHA', {widgetId})
        window.recaptchaWidgetId = widgetId;
      })
    } catch(e) {
      console.log('reCAPTCHA error', e);
      notifyAndTrack.warn("reCAPTCHA error", "Please try again.");
      setSMSError(true)
      setSMSMessage('Please try again.')
    }
  }

  const getContent = (showCodeView) => {
    if (showCodeView) {
      return <div style={{width: "100%", maxWidth: 300}} className="space space-col">
        <span className="font-lg bolded">Enter SMS Code</span>
        <Input.Password
          key="code-input"
          placeholder="_ _ _ _ _ _"
          className="input-bold input-code mt-20"
          onChange={(e) => setConfirmationCode(e.target.value)}
        />
      </div>
    }

    const disabled = phoneNumber.length < 10 || !optIn;

    return <div className="space space-col space-center-y space-center-x">
      <Select
        className="select select-bordered w-full focus:outline-0"
        onSelect={(e) => setCountryCode(e)}
        // onChange={(e) => setCountryCode(e.target.value)}
        value={countryCode}
        suffixIcon={<DownArrowOutlined />}
      >
        <Select.Option value='+1'>United States</Select.Option>
        {/*{callingCodes.map(({country, countryCodes, isoCode3}, index) =>*/}
        {/*  <option key={index} value={countryCodes[0]}>{country}</option>*/}
        {/*)}*/}
      </Select>
      <div className="w-full">
        <Input.Group size="large">
          {/*<Input value={countryCode} />*/}
          <Input
            addonBefore={countryCode}
            key="phone-input"
            value={phoneNumber}
            // type="text"
            placeholder="Mobile phone number"
            className="bolded"
            onChange={e => setPhoneNumber(e.target.value)}
            addonAfter={
              <Button
                id="login-button"
                type="link"
                className={disabled ? "" : "green-color"}
                onClick={handleLogin}
                disabled={disabled}
              >Send Code
              </Button>
            }
          />
        </Input.Group>
        <br/>
        <Button
          ghost
          onClick={toggleOptIn}
          className="icon-btn icon-left"
          size="large"
          style={{position: "relative", top: "3px"}}
        >
          {optIn ? <CheckIcon /> : <ApplicationIcon size={20} />}
          <span>
            Agree
          </span>
        </Button>
        <label className="font-lg muted">
          By checking this box, I agree to receive text messages relating to content that I request, related notifications, customer support, and security alerts at the mobile number provided above. Message and data rates may apply. Message frequency varies. Reply HELP for help or STOP to cancel. View our Privacy Policy and <HotClick className="display-inline"><a href="/legal/terms.html">Terms of Service.</a></HotClick>
        </label>
      </div>
      {/*<Button*/}
      {/*  id="login-button"*/}
      {/*  onClick={handleLogin}*/}
      {/*  // className="btn btn-block btn-active btn-primary"*/}
      {/*  disabled={phoneNumber.length < 10}*/}
      {/*>*/}
      {/*  Send SMS*/}
      {/*</Button>*/}
    </div>
  }

  return <div className="spaace space-col space-center space-center-y" style={{width: "100%", maxWidth: 600}}>
    <div></div>
    {getContent(showCodeView)}
    {/*<div className="space space-center-y space-center-x">{process.env.REACT_APP_OVEN_APP_NAME}</div>*/}
  </div>
}

export default PhoneAuth;
