import React from "react";
import {DownArrowOutlined} from "@web/assets/icons";
import {Dropdown} from "antd";
import PropTypes from "prop-types";

ServiceSelector.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

function ServiceSelector(props) {
  return <Dropdown.Button
    className="unblock"
    placement="bottomLeft"
    size="large"
    overlayClassName="dropdown-overlay"
    menu={{items: props.items, onClick: props.onClick}}
    icon={
      <DownArrowOutlined size={24} style={{position: "relative", top: "4px"}} />
    }
  >
    <span className="font-lg">{props.text}</span>
  </Dropdown.Button>
}

export default ServiceSelector;
