import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ProgressBar} from './ProgressBar'
import {AppContext} from '@web/hooks/context'
import {Button, Card, Typography} from 'antd'
import {saveToStorage} from '@web/hooks/useLocalStorage'
import {ga} from '@web/lib/firebase'
import {useLocation} from 'wouter'
import useStripeSubscriptionCheckout from '@web/hooks/useStripeSubscriptionCheckout'
import {notifyAndTrack} from './notification'
import PriceCardFallback from './PriceCardFallback'
import {track} from '@web/lib/analytics'

PriceCard.propTypes = {
  productId: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

/**
 * @summary Get the price from the product
 * @param {Object[]} prices
 * @param {String<'month'|'year'>} pricingInterval
 * @return {String}
 */
const getPrice = (prices, pricingInterval) => {
  const value = prices?.[pricingInterval]?.[1]?.unit_amount
  if (value === 0) return "Free"
  if (!value) return "Contact Us"
  const formatCurrency = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: prices?.[pricingInterval]?.[1]?.currency,
  });
  return formatCurrency.format(value / 100)
}

function PriceCard(props) {
  const [app, user] = useContext(AppContext)
  const [_, setLocation] = useLocation();
  const [[productId, product], setProduct] = useState( props.productId && app.products?.pairs?.find(([productId]) => productId === props.productId) || [])
  const [pricingInterval, setPricingInterval] = useState('month')
  const [checkoutUrl, loadingCheckoutUrl, errorCheckoutUrl, requestStripeCheckoutUrl, stopProcessing] = useStripeSubscriptionCheckout()
  useEffect(() => {
    errorCheckoutUrl && handleError(errorCheckoutUrl)
  }, [errorCheckoutUrl])

  if (checkoutUrl) window.location.assign(checkoutUrl)
  const handleError = (error) => {
    stopProcessing()
    notifyAndTrack.info('Oops, something didn\'t work .',error.message,{
      log: {
        severity: 'critical',
        priceId: props.selected,
        app: app?.root?.key,
      },
    })
  }
  // console.log('product', product, props.productId)
  if (!props.productId || !Object.keys(product?.prices || {})?.length) {
    return <PriceCardFallback />
  }
  const startTrial = (priceId, role) => {
    if (!user || user?.isAnonymous) {
      saveToStorage('product-selection', {priceId})
      track('payment_checkout', {priceId, message: 'Redirecting to /sign-up'})
      return setLocation('/sign-up')
    }
    const {options = {}} = product
    const checkoutData = {
      userId: user.uid,
      priceId,
      successUrl: window.location.href,
      cancelUrl: window.location.href,
      allowTrial: false,
      allowPromoCode: true,
      applyPromoCode: null,
      collectShippingAddress: false,
      ...options,
    }
    props.onSelect && props.onSelect(priceId)
    requestStripeCheckoutUrl(checkoutData)
    ga.log('payment_checkout', checkoutData)
    track('payment_checkout', checkoutData)
  }
  return <Card
    title={<Typography.Title style={{paddingLeft: '15px'}} level={5}>{product?.name}</Typography.Title>} bordered
  >
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '260px', padding: '0 15px'}}>
      <Typography.Paragraph type="secondary">{product?.description}</Typography.Paragraph>
      <div>
        <div className="space space-center-y mb-12">
          <Typography.Title level={3}>{getPrice(product.prices, pricingInterval)}</Typography.Title>
          <Typography className="muted">per {pricingInterval}</Typography>
        </div>
        {props.button ? props.button :
          <Button
            block
            type="primary"
            size="large"
            disabled={!!(loadingCheckoutUrl && loadingCheckoutUrl === product.prices?.[pricingInterval]?.[0])}
            onClick={() => startTrial(product?.prices?.[pricingInterval]?.[0])}
          >
            {loadingCheckoutUrl && loadingCheckoutUrl === product?.prices?.[pricingInterval]?.[0] ?
              <ProgressBar visible float={false} /> :
              product.cta || 'Subscribe'
            }
          </Button>
        }
      </div>
    </div>
  </Card>
}

export default PriceCard;
