import React from 'react';
// import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
import ServiceData from "@web/ui/containers/ServiceData";
import FeedList from "./FeedList";
// import AppsList from "./AppsList";
// import {Card, Collapse, Typography} from "antd";
// import {DownArrowOutlined, DownIcon} from "@web/assets/icons";
// import LineAppTasks from "./LineAppTasks";

function FeedContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="assistant" component={FeedList} />
    </section>
  </DashboardFullRow>
}

export default FeedContent;
