import React from "react";
import PropTypes from 'prop-types';
import {Tag, Comment} from "antd";
import Day from "./Day";
import EmailAction from "./EmailAction";
import VoiceCallButton from "./VoiceCallButton";
import UserAvatar from "./UserAvatar";
import ExternalLink from "./ExternalLink";

ContactInfoCard.propTypes = {
  path: PropTypes.string.isRequired,
  contact: PropTypes.object,
};

function ContactInfoCard(props) {
  const contact = props.contact;
  const displayName = contact.name?.substring(0, 20).replaceAll('"', '') || contact.email;
  if (!contact) {
    return null;
  }
  return <Comment
    avatar={
    <UserAvatar
      name={contact.name}
      displayName={contact.name}
      src={contact.photoURL}
    />
    }
    author={
    <div className="space space-col">
      <div className="space space-center-y">
        <h6 className="capitalize">
          {displayName}
        </h6>
        <span key="ts" className="muted font-sm">
            <Day ts={contact.activityTs} />
          </span>
      </div>
    </div>}
    // datetime={<Day ts={contact.activityTs} format={"MMM DD YYYY"} />}
    // actions={[<EmailAction key={contact.email} toEmails={[{email: contact.email, name: contact.name}]} action=""><EmailIconOutlined /></EmailAction>]}
    content={
      <div>
        <ExternalLink href={`https://${contact.domain}`} showIcon className="muted">{contact.companyName || contact?.domain}</ExternalLink>
        {/*<EmailAction key={contact.email} toEmails={[{email: contact.email, name: contact.name}]} action="">*/}
        {/*  <span className="muted">{contact.email}</span>*/}
        {/*</EmailAction>*/}
        {contact.phoneNumbers?.map(({number, type}, index) => {
          return <div className="space space-center-y" key={number + index} style={{paddingTop: 3, paddingBottom: 5}}>
            <span key={number} className="muted">
              <Tag style={{width: 50}}>{type}</Tag> {number}
            </span>
            <VoiceCallButton
              to={number}
              name={contact.name}
              numberType={type}
              contactPath={props.path}
              size="small"
            />
          </div>;
        })}
      </div>
    }
  />
}

export default ContactInfoCard;
