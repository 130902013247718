import React from "react";
import AppIntegrationsData from "@web/ui/containers/AppIntegrationsData";
import AppIntegrationsList from "./AppIntegrationsList";
import {SourcesContext} from "@web/hooks/context";
import {useSelector} from "react-redux";

function SourceManager() {
  const activeServiceId = useSelector((state) => state.App.activeServiceId);
  const sources = React.useContext(SourcesContext);
  const group = sources.services[activeServiceId];

  return <section>
    <section style={{padding: "18px"}}>
      <AppIntegrationsData tags={group.sourceIds} filter={"all"}>
        <AppIntegrationsList/>
      </AppIntegrationsData>
    </section>
  </section>;
}

export default SourceManager;
