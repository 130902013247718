import React, {useCallback} from "react";
import {List, Tag} from "antd";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
import useDomainIcon from "@web/hooks/useDomainIcon";
import ListItemActive from "./ListItemActive";
import {reverseKey} from "@web/lib/firestore.db";

function LinesListItem(props) {
  const dispatch = useDispatch();
  const isMobile = true;
  const line = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });
  const subscriptions = useSelector((state) => {
    console.log("LinesListItem:subscriptions:", (state.Content?.activity?.lines || state.Content?.activity?.directory)?.subscriptions, props.path.split("/").pop());
    return (state.Content?.activity?.lines || state.Content?.activity?.directory)?.subscriptions?.[props.path.split("/").pop()];
  });
  const subscribers = useSelector((state) => {
    return (state.Content?.activity?.lines || state.Content?.activity?.directory)?.subscribers?.[props.path.split("/").pop()];
  });

  console.log("LinesListItem:line:", subscriptions, subscribers);

  const DomainIcon = useDomainIcon(line?.domain, line?.indicators);
  const onClick = useCallback((e) => {
    const data = {
      path: props.path, line,
    };

    if (props.onClick) {
      return props.onClick(data, e);
    }
    dispatch(appActions.setActiveDetail(data, true));
  }, [dispatch, props]);

  if (!line) {
    return null
  }

  return <List.Item
    key={props.path}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[!!subscribers && <Tag>Subscribers</Tag>]}
    onClick={onClick}
    extra={!!subscriptions && <Tag>You're Subscribed</Tag>}
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={DomainIcon}
          userId={props.path.split("/").pop()}
          // name={line.number}
          // displayName={displayName}
          src={line.photoURL}
        />}
      title={
        <div className="space space-center-y">
          <h6 className="capitalize">
            {line.assistantName || line.details?.friendlyName}
            {/*{line.details?.friendlyName || line.number || displayName}*/}
            {/*&nbsp;*/}
            {/*{line.assistantName}*/}
          </h6>
          <span key="ts" className="muted font-sm">
            <Day ts={line.activityTs}/>
          </span>
        </div>}
      description={
      <div className="space space-col">
        <span>
          {line.clientName || line.intelligence?.callerId || (line.assistantName && line.number)}
        </span>
        <span>
          {line.location?.name}
        </span>
      </div>}
    />
    <ListItemActive
      path={props.path}
      offset={'5px'}
    />
  </List.Item>
}

export default LinesListItem;
