import React, {useContext} from 'react';
import {Typography, Divider, Button, Row, Col, Card} from 'antd'
import {WindowContext} from '@web/hooks/context'
import PriceTable from '@ai-antd/components/PriceTable'
import HotClick from '@web/ui/containers/HotClick'
import {fromTemplate} from '@web/lib/parse'
import {Link} from 'wouter'
import LogoStrip from '@ai-antd/components/LogoStrip'
import Icon from '@ai-antd/components/Icon'
import Faq from '@ai-antd/components/Faq'
import FaqData from '@web/ui/containers/FaqData'
// import useRemoteConfig from '@web/hooks/useRemoteConfig'
import {
  BookIcon, CustomerIcon,
  GMailIcon,
  HeadphonesIcon,
  InboxIcon, InstagramIcon,
  InvoiceIcon, LikeIcon, LineChartIcon, LinkedInIcon,
  PaymentIcon, PhoneCallIcon,
  QuoteLeftIcon, RightArrowOutlined, ShoppingCartIcon, TruckIcon,
} from "@web/assets/icons";
import PropTypes from 'prop-types'
// import VideoModal from "@ai-antd/components/VideoModal";
import Video from "@ai-antd/components/Video";
// import DashboardCenterRow from "@ai-antd/components/DashboardCenterRow";

const SplashWrapper = ({isMobile, height, children}) => {
  return (<div
    className={`splash-card-wrapper${isMobile ? ' splash-card-wrapper-mobile' : ''}`}
    style={{
      height: isMobile ? '98vh' : height || '580px',
      textAlign: 'center',
      boxSizing: 'border-box',
      padding: isMobile ? '50px 50px' : '60px 12% 20px 10%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >{children}
  </div>)
}

const BodyWrapper = ({children, isMobile, narrow, wide, id}) => {
  return <div id={id} className={`landing-body-wrapper${(narrow && !isMobile) ? ' landing-body-wrapper-narrow' : (wide && isMobile) ? ' landing-body-wrapper-wide' : ''}`}>
    {children}
  </div>
}

const FooterWrapper = ({isMobile, height, children}) => {
  return <div
    className="landing-footer-wrapper"
    style={{
      textAlign: 'center',
      backgroundColor: '@card-background',
      minHeight: isMobile ? '300px' : '300px',
    }}
  >{children}
  </div>
}

const app = {
  landing: {
    hero: {
      title: "Create No-Code Line Apps & Monetize your Expertise",
      // title: "Monetize your Expertise with No-Code Apps Powered by Line AI",
      // title: "Create Apps Powered by Line AI Using Only your Expertise and Earn up to $1000 a Week per App",
      // title: "Create new add-on Services for your Business or apps for the public and Earn up to $1000 a Week per App",
      subtitle: "Subject-matter knowledge and experience is all you need to start earning",
      // actions: [
      //   {cta: "Get Started", href: "/sign-up"},
      //   {cta: "Learn More", href: "/features"},
      // ],
      link: {href: "/plans/creators", text: "Reserve my spot"},
    },
    hooks: {
      title: "Apps can be public or a private service offering",
      video: "https://www.youtube.com/embed/1a1v1a1a1a1",
      cards: [{
        title: "Earn up to $1000 a Week per App",
        description: "Create new add-on Services for your Business or apps for the public and Earn up to $1000 a Week per App",
      },{
        title: "New Revenue Streams",
        description: "Create new add-on Services for your Business and generate new revenue streams",
      }],
    },
    problems: [
      {title: "Feature 1", description: "Description 1", icon: "check-circle"},
      {title: "Feature 2", description: "Description 2", icon: "check-circle"},
      {title: "Feature 3", description: "Description 3", icon: "check-circle"},
    ],
    features: [
      {title: "Apps for your industry", description: "Build apps for your industry and monetize your experience and expertise.", icon: "InvoiceIcon", link: "/creators/industry"},
      {title: "Apps for Fun", description: "Build apps that solve everyday chores, assist people or make life easy and fun", icon: "HeadphonesIcon", link: "/creators/fun"},
      {title: "Apps for your Business", description: "Build new service offerings that you can sell as add-ons or automate existing service offerings for your business", icon: "BusinessIcon", link: "/creators/business"},
      {title: "Apps for Education & Learning", description: "Whether you are an educator or you make online courses, easily build apps with voice, internet and intelligence for your students and customers.", icon: "BookIcon", link: "/creators/education"},
    ],
    usecases: [
      {title: "Integrate Voice", description: "Use voice and phone calls to solve problems or add a phone number to deliver an experience over the phone to users anywhere", sort: 9},
      {title: "Integrate Email & SMS", description: "Integrate Email, SMS into your apps to enable quick communication, trigger alerts, take action based on emails, draft, send, for powerful functionality that is useful every day", sort: 8},
      {title: "Integrate Social Media", description: "Social media apps can pull from social media, make posts, trigger alerts, take action based on social posts and signals", sort: 7},
      {title: "Integrate Data", description: "Pull data from many sources and integrate with your app, store it, take action, and more", sort: 6},
      {title: "Integrate Internet", description: "Search and crawl the internet to deliver powerful realtime functionality, communication, trigger alerts, take action, and much more.", sort: 5},
      {title: "Integrate Files & Documents", description: "Parse documents and files, answer questions, make decisions and more", sort: 4},
      {title: "Integrate Tools", description: "Connect to existing apps and tools people already use like Quickbooks, and more", sort: 3},
      {title: "Composability", description: "Combine voice, email, sms, social media, data sources, files, documents and tools for powerful functionality limited only by imagination", sort: 3},
    ],
    howItWorks: {
      steps: [
        {title: "Sign up", description: "Sign up and choose your free line phone number"},
        {title: "Choose a creator plan", description: <span>Choose whether you want to create public apps or private service offerings for your business &nbsp;<a href="/plans/creators">See creator plans</a></span>
        },
        {title: "Start creating", description: "Publish and share your app"},
      ],
      logos: {
        items: [
          {name: "GMailIcon"},
          {name: "InboxIcon"},
          {name: "HeadphonesIcon"},
          {name: "PDFIcon"},
          {name: "PaymentIcon"},
          {name: "ShoppingCartIcon"},
          {name: "TruckIcon"},
          {name: "LineChartIcon"},
          {name: "LikeIcon"},
          {name: "PhoneCallIcon"},
          {name: "LinkedInIcon"},
          {name: "InstagramIcon"},
        ],
      },
    },
    faq: {
      title: "Frequently Asked Questions",
      questions: [
        {question: "Who is a creator", answer: "Answer 1"},
        {question: "Do I have to know how to code", answer: "Answer 1"},
        {question: "What is an app", answer: "Answer 1"},
        {question: "How do they work", answer: "Answer 2"},
        {question: "Do apps work with ChatGPT", answer: "Answer 3"},
        {question: "How can I use this for my business", answer: "Answer 4"},
        {question: "Do apps have to be installed", answer: "Answer 4"},
        {question: "How do I get paid?", answer: "Answer 4"},
      ],
    },
    sliders1: {
      header: "What people are saying",
      items: [{
        title: "Now anything is possible",
        description: "Build apps for your industry and monetize your experience and expertise.",
        icon: "StarIcon",
        // link: "/creators/industry",
      }],
    },
    sliders2: {
      header: "Apps for your industry",
      items: [{
        title: "Apps for your industry",
        description: "Build apps for your industry and monetize your experience and expertise.",
        icon: "InvoiceIcon",
        link: "/creators/industry",
      }],
    }
  }
};

LandingPage.propTypes = {
  agentId: PropTypes.string,
};

function LandingPage() {
  const [isMobile, width, height] = useContext(WindowContext)
  // const [landingHeroTitleText] = useRemoteConfig('Landing_hero_title_text')
  // console.log('landingHeroTitleText', landingHeroTitleText)
  // if (!app.agents) return null
  return <div
    style={{
      // minHeight: height,
      backgroundColor: "#0e161f",
      width: "100%",
      // backgroundColor: "#000",
    }}
  >
    <div style={{maxWidth: 1200, margin: "0 auto"}}>
      {/*<DashboardCenterRow>*/}
      {/*<Col sm={0} md={0} lg={4} xl={5} xxl={6}></Col>*/}
      {/*<Col sm={24} md={24} lg={16} xl={14} xxl={12}>*/}
      <SplashWrapper
        isMobile={isMobile}
        height={height - 200}
      >
        <div className="space space-col space-between">
          {/*<InvoiceIcon*/}
          <Typography.Title level={isMobile ? 1 : 1}>
            {app.landing?.hero?.title || app.root.description}
          </Typography.Title>
          <Typography.Title level={5}>{app.landing?.hero?.subtitle}</Typography.Title>
          <div className="space space-center space-center-x">
            {app?.landing?.hero?.actions?.map(({cta, href, hide}, index) => fromTemplate(href, app).startsWith("http") ?
              <HotClick href={fromTemplate(href, app)} key={index}>
                <Button style={{marginTop: "30px", minWidth: "160px"}} size="large">
                  <Typography.Link href={fromTemplate(href, app)}>{cta}</Typography.Link>
                </Button>
              </HotClick> :
              <HotClick key={index}>
                <Button style={{marginTop: "30px", minWidth: "160px"}} size="large">
                  <Link className="hero-link" to={fromTemplate(href, app)}>{fromTemplate(cta, app)}</Link>
                </Button>
              </HotClick>,
            )}
          </div>
          {app.landing?.hero?.link && <HotClick href={fromTemplate(app.landing?.hero?.link?.href, app)}>
            <Button className="green-color" shape="round" size="large"
                    href={fromTemplate(app.landing.hero?.link?.href, app)}
                    style={{marginTop: "20px"}}>{app.landing.hero?.link?.text}</Button>
          </HotClick>}
          <div style={{marginTop: "60px"}}>
          </div>
        </div>
      </SplashWrapper>
      <BodyWrapper id="features">
        {app.landing?.features?.map(({title, description, classNames = {}, icon = "", link, media = null}, index) =>
          <Row key={index}>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
            <Col xs={0} sm={0} md={10} lg={10} xl={10}>
              <div
                className={`features-col features-left-col ${classNames.left || ""} ${(index % 2 > 0) ? "odd" : "even"}`}>
                {!!media?.src && <Image src={media.src} />}
              </div>
            </Col>
            <Col xs={20} sm={20} md={10} lg={10} xl={10}>
              <div className={`features-col features-right-col ${classNames.right || ""}`}>
                <span className="feature-icon"><Icon name={icon} className="muted" size={36} /></span>
                <Typography.Title level={4}>{title}</Typography.Title>
                {!!description && <Typography.Paragraph type="secondary">{description}</Typography.Paragraph>}
                <div className="space space-center-y space-end" style={{width: "90%"}}>{!!link &&
                  <Link to={link}>More</Link>}<RightArrowOutlined className="green-icon" /></div>
              </div>
            </Col>
          </Row>,
        )}
      </BodyWrapper>
      <div style={{width: "100%", height: 100}} />
      {/*<Divider className="hidden" />*/}
      <BodyWrapper id="video-top" isMobile={isMobile} height={isMobile ? (width * 0.5625) : 720}>
        <div className="space space-col space-center-x space-center-y"
          // style={{minHeight: isMobile ? height - 300 : height - 200, width: "100%"}}
        >
          <Video width={isMobile ? width : 900} height={isMobile ? (width * 0.5625) : 720} />
        </div>
      </BodyWrapper>
      <div style={{width: "100%", height: 100}} />
      <BodyWrapper id="video-middle" isMobile={isMobile}>
        <SplashWrapper
          isMobile={isMobile}
          height={isMobile ? (width * 0.5625) : 720}
        >
          <div className="space space-col space-center-y middle" style={{height: "100%"}}>
            <Typography.Title level={1}>Drag & Drop Simplicity</Typography.Title>
            <Typography.Title level={4}>Create apps with no code using only your expertise</Typography.Title>
          </div>
        </SplashWrapper>
      </BodyWrapper>
      <div style={{width: "100%", height: 100}} />
      <BodyWrapper id="use-cases" isMobile={isMobile}>
        <div className="solutions-cards-wrapper space space-col large" style={{maxWidth: 900, margin: "0 auto"}}>
          {app.landing?.usecases?.sort((a, b) => b.sort - a.sort)?.map(({
                                                                          title,
                                                                          description,
                                                                          extra = null,
                                                                          icons = null,
                                                                        }) =>
            <Card
              bordered={false}
              title={title}
              key={title}
              actions={icons && [<LogoStrip
                rowLength={12}
                items={icons}
                wrapperClassName="muted solutions-icons-strip"
              />]}
              className="card-simple card-rounded extra-round extra-padding"
              extra={
                extra &&
                <Button>{extra.icon && <Icon name={extra.icon} />}
                  <Typography.Link href={extra.href}>{extra.cta}</Typography.Link></Button>}
            >
              <Card.Meta description={<span className="font-lg">{description}</span>} />
            </Card>)}
        </div>
      </BodyWrapper>
      {/*<BodyWrapper id="about" isMobile={isMobile}>*/}
      {/*  {app.landing?.about?.map(({title, description, classNames = {}, icon = '', media = null}, index) => <Row key={index}>*/}
      {/*      <Col xs={0} sm={0} md={10} lg={10} xl={10}>*/}
      {/*        <div className={`features-col features-left-col ${classNames.left || ''} ${(index % 2 > 0) ? 'odd' : 'even'}`}>*/}
      {/*          {!!media?.src && <Image src={media.src}/>}*/}
      {/*        </div>*/}
      {/*      </Col>*/}
      {/*      <Col xs={24} sm={24} md={14} lg={14} xl={14}>*/}
      {/*        <div className={`features-col features-right-col ${classNames.right || ''}`}>*/}
      {/*          <span className="feature-icon"><Icon name={icon} className="muted" size={26} /></span>*/}
      {/*          <Typography.Title level={4}>{title}</Typography.Title>*/}
      {/*          {!!description && <Typography.Paragraph type="secondary">{description}</Typography.Paragraph>}*/}
      {/*        </div>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  )}*/}
      {/*</BodyWrapper>*/}
      <br />
      <br />
      {app?.landing?.howItWorks &&
        <BodyWrapper id="how-it-works" isMobile={isMobile} narrow>
          <Card
            className="card-simple card-rounded card-no-separator"
            title={<div/>}
            style={{maxWidth: 900, margin: "0 auto"}}
          >
            {app.landing.howItWorks.steps?.length > 0 &&
              <Typography.Title level={3} style={{textAlign: "center"}}>How it works</Typography.Title>}
            <ul style={{
              width: "90%",
              maxWidth: "560px",
              margin: "60px auto 80px auto",
            }}>{app.landing.howItWorks.steps?.map(({title, description, image}, index) =>
              <li className="how-it-works-step" key={index}>
                <Typography.Title level={5}>{title}</Typography.Title>
                {description && <Typography.Paragraph type="secondary">{description}</Typography.Paragraph>}
              </li>)
            }</ul>
            <Button block type="primary" size="large" shape="round"><a href="/plans/creators" className="text-color">Continue</a></Button>
          </Card>
          {app.landing?.howItWorks?.logos &&
            <SplashWrapper isMobile={isMobile}>
              <LogoStrip
                rowLength={isMobile ? 4 : 4}
                items={app.landing?.howItWorks?.logos?.items}
                size={isMobile ? 40 : 52}
                wrapperClassName="muted"
              />
            </SplashWrapper>}
        </BodyWrapper>}
      <Divider />
      <BodyWrapper isMobile={isMobile} narrow id="pricing">
        <br />
        <Typography.Title level={1}>Reserve Your Creator Spot Now</Typography.Title>
        <Typography.Title level={4}>Seats are limited and available first come first serve</Typography.Title>
        <br />
      </BodyWrapper>
      <Divider />
      <BodyWrapper isMobile={isMobile} id="pricing">
        <PriceTable
          button={<Button
            block
            type="primary"
            size="large">
            <Typography.Link href={fromTemplate(app.landing?.appUrl + "/sign-up", app)}>Get Started
              Now</Typography.Link>
          </Button>}
          scope={[0, 1, 2, 3]}
        />
      </BodyWrapper>
      <BodyWrapper isMobile={isMobile} id="faq" narrow wide>
        {app?.landing?.faq && <div><FaqData>
          <Faq expanded icon={<QuoteLeftIcon size={16} className="muted-icon" />} />
        </FaqData>
          <br /><br />
          <Divider />
        </div>}
      </BodyWrapper>
      <FooterWrapper isMobile={isMobile} height={height}>
        {app?.landing?.footer?.hero && <section className="footer-hero flex-col middle center">
          <Typography.Title level={4}>{app.landing.footer.hero.title}</Typography.Title>
          <Button type="primary" size="large">
            <Typography.Link
              href={fromTemplate(app.landing.footer?.hero?.actions?.[0]?.href, app)}>
              {app.landing.footer?.hero?.actions?.[0]?.cta}
            </Typography.Link>
          </Button>
        </section>}
        <Divider />
        <iframe src="https://embeds.beehiiv.com/3a7790c2-5781-4bb7-97c3-f4f422cba3c6" data-test-id="beehiiv-embed"
                width="100%" height="320" frameBorder="0" scrolling="no"
                style={{
                  borderRadius: "4px",
                  border: "2px solid #e5e7eb",
                  margin: "40px 0",
                  backgroundColor: "transparent",
                }} />
      </FooterWrapper>
    </div>
    {/*</Col>*/}
    {/*<Col sm={0} md={0} lg={4} xl={5} xxl={6}></Col>*/}
    {/*</DashboardCenterRow>*/}
  </div>
}

export default LandingPage
