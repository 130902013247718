import React from 'react'
import {Link} from 'wouter'
import {Button} from 'antd'

const ScribeCreateButton = ({text = "Create", type}) => {
  return <Button type={type} size="middle" style={{minWidth: "80px"}}>
      <Link href="/create">{text}</Link>
    </Button>;
};

export default ScribeCreateButton
