import React from 'react';
import PropTypes from 'prop-types';
import Toggle from "./Toggle";
import {api} from "@web/lib/api";
import {useSelector} from "react-redux";
import {Button, Card, Divider, notification, Space, Tag, Tooltip, Typography} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import Day from "./Day";
import {InboxIcon, XIcon} from "@web/assets/icons";
// import LineConnector from "./LineConnector";

RecommendedTodo.propTypes = {
  eventId: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  expand: PropTypes.bool,
};

function RecommendedTodo(props) {
  const event = useSelector((state) => state.Content.events?.[props.eventId]);
  const [status, setStatus] = React.useState(event?.recommendedTodo?.status);
  const [userTaskPath, setUserTaskPath] = React.useState(event?.userTaskPath);
  const activeUserTaskPath = useSelector((state) => state.AI.userTask?.path);
  // const [dismissed, setDismissed] = React.useState(false);
  // const [keep, setKeep] = React.useState(false);
  const [error, setError] = React.useState(null);

  if (!event) {
    return null;
  }

  if (!event?.recommendedTodo) {
    return null;
  }

  // const setActiveTask = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //
  //   api.call(`/todo/${props.eventId}/keep`, {
  //     activityPath: event.activityPath,
  //     eventId: props.eventId,
  //   });
  //   return false;
  // }

  const dismissTodo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("dismissTodo:event", props.eventId, event);
    api.LINE(`/user/todo/${props.eventId}/dismiss`, {activityPath: event.activityPath})
    .then((data) => {
      console.log("dismissTodo:dismissed", data);
      return data;
    })
    .then((data) => notification.success({
      message: data.message,
      top: 64,
    }))
    .then(() => {
      setStatus("dismissed");
    })
    .catch((error) => {
      setError(error.message);
    });
  }

  const keepTodo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    api.LINE(`/user/todo/${props.eventId}/keep`, {activityPath: event.activityPath})
    .then((data) => {
      notification.success({
        message: data.message,
        top: 64,
      });
      setUserTaskPath(data.path);
    })
    .catch((error) => {
      setError(error.message);
    });
  }

  return <Toggle
    key="recommended-todo"
    trigger={
    !props.defaultExpanded &&
      !props.expand &&
      <span className="font-sm uppercase underline muted">Todo</span>
    }
    defaultExpanded={props.defaultExpanded}
    expand={props.expand}
  >
    <Card title={<span className="muted">Recommended Task</span>} className={`card-simple card-rounded card-no-separator todo-card status-${status}`} bordered={false}>
      <div
        className="space space-col gap-small"
        // style={{paddingLeft: '22px', borderLeft: 'solid 4px rgba(255,255,255,.1)', marginBottom: '16px'}}
        // onClick={setActiveTask}
      >
        {/*<label className="muted font-xl">{props.contentItemActivity.recommendedTodo.dueDateOfTodo && "By " + dayjs(props.contentItemActivity.recommendedTodo.dueDateOfTodo).format("dddd MMMM D h:mm a")}</label>*/}
        {/*<Tag color="magenta">GOAL</Tag>*/}
        <label className={`${props.primary ? "font-xl" : "font-lg"}`}>
          Goal:&nbsp;
          {(!!userTaskPath &&
            activeUserTaskPath === userTaskPath) ?
            <Tag color="magenta">TASK</Tag> :
            status === "open" ? null : <Tag color="orange">DISMISSED</Tag>
          }
          {event.recommendedTodo.goal}
        </label>
        <Typography.Text
          className={`${props.primary ? " font-xl" : ""}${status === "open" ? " ai-text" : " muted"}`}>
          {event.recommendedTodo.summary} By <Day ts={event.recommendedTodo.dueDate} />.
          {/*style={{fontSize: '24px', lineHeight: '1.4em', marginBottom: '15px'}}*/}
        </Typography.Text>
        {/*<h6>*/}
        <div className="space space-center-y space-between" style={{marginTop: "10px"}}>
          {userTaskPath ?
            <Button shape="round" className="green-color" size="large"><a href={`/task/${userTaskPath.split("/").pop()}}`} target="_blank">Go to Task</a></Button> :
            status === "open" ?
            <div className="space space-center-y space-between" style={{width: 100}}>
              {/*<Tooltip title="Review" placement="bottom"><UserAvatar icon={UserAvatar}/></Tooltip>*/}
              <Tooltip title="Dismiss" placement="bottom">
                <Button className="icon-btn orange-color" shape="round" size="large" ghost onClick={dismissTodo}>
                  <XIcon className="orange-icon" size={20} />
                  Dismiss
                </Button>
              </Tooltip>
              <Tooltip title="Keep" placement="bottom">
                <Button className="icon-btn green-color" shape="round" size="large" ghost onClick={keepTodo}>
                  <InboxIcon className="green-icon" size={20} />
                  Accept
                </Button>
              </Tooltip>
              {/*<StatusActions*/}
              {/*  path={event.activityPath}*/}
              {/*  initialStatus={event.recommendedTodo.status}*/}
              {/*  iconClassName="green-icon"*/}
              {/*  actions={["done", "dismiss"]}*/}
              {/*  size="middle"*/}
              {/*/>*/}
            </div> :
            null
          }
        </div>
        {/*</h6>*/}
      </div>
    </Card>

  </Toggle>;
}

export default RecommendedTodo;
