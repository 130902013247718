import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
import AppsList from "./AppsList";
import AppStore from "@ai-antd/page/AppStore";

function AppsContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="apps" activeKey="installed" component={AppStore} />
    </section>
  </DashboardFullRow>
}

export default AppsContent;
