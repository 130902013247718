import React, {useCallback} from "react";
import {List, Tag} from "antd";
import {RightArrowOutlined} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
import ListItemActive from "./ListItemActive";
import MarkdownListItem from "./MarkdownListItem";

function PaymentListItem(props) {
  const dispatch =
    useDispatch();

  const isMobile = true;

  const payment =
    useSelector((state) => {
      return state.Content?.data?.[props.path];
    });

  // const DomainIcon =
  //   useDomainIcon(
  //     contact?.domain,
  //     contact?.indicators,
  //   );

  const setActiveDetail = useCallback((props, show) =>
      dispatch(appActions.setActiveDetail(props, show)),
    [dispatch]);

  if (!payment) {
    return null
  }

  if (!payment.card) {
    return <MarkdownListItem
      key={props.path}
      path={props.path}
      date={props.date}
      dataKey="payment"
    />
  }

  const membersList =
    Object.entries(payment.members || {});

  const [label, ...labels] =
    payment.card?.labels || [];

  const amount =
    payment.card?.amount ||
    +payment.card?.subtitle?.replaceAll(/[$,]/g, "")?.trim();

  const subtitle =
    isFinite(amount) ?
      `$${amount}` :
      payment.card.subtitle;

  return <List.Item
    key={props.path}
    className={`relative list-item-lite-divider contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[
      <RightArrowOutlined
        className="muted-icon hidden"
        size={20}
      />
    ]}
    onClick={() => setActiveDetail({path: props.path, payment}, true)}
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={null}
          userId={membersList[0]?.[0]?.split("/").pop()}
          name={membersList[0]?.[1]?.displayName}
          displayName={membersList[0]?.[1]?.displayName}
          src={""}
        />}
      title={
        <div className="space space-center-y">
          <span>
            {membersList[0]?.[1]?.displayName}
          </span>
          <span key="ts" className="muted font-sm">
            <Day ts={payment.updatedTs}/>
          </span>
        </div>}
      description={
        <div className="space space-col">
          <div className="space space-center-y">
            <span className="ai-text">{payment.card.title} {subtitle}</span>
          </div>
          <div>
            <Tag className="muted">{label}</Tag>
          </div>
        </div>}
    />
    <ListItemActive
      path={props.path}
      offset={'5px'}
    />
  </List.Item>
}

export default PaymentListItem;
