import React, {useContext} from "react";
import {Typography, Empty, List, Divider} from "antd";
import {WindowContext} from "@web/hooks/context";
import Loading from "./Loading";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import PropTypes from "prop-types";
import LaunchSourceManagerButton from "./LaunchSourceManagerButton";
import CallsListItem from "./CallsListItem";

CallsList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
}

function CallsList(props) {
  const [,, height] = useContext(WindowContext);

  return <div
    id="services-contacts-scroll-target"
    style={{
      height: height - 3,
      overflow: 'auto',
      // display: 'flex', flexDirection: 'column',
    }}>
    <Pager
      height={height - 3}
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      // initialScrollY={200}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px"}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-contacts-scroll-target"
    >
      <List
        header={<Divider/>}
        className="services-contacts-list list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date], index) => <CallsListItem key={path} path={path} index={index} />}
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper space space-col middle"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{width: "100%", maxWidth: "100%", height: height - 140, margin: "0 auto"}}
          >
            <div className="bgx cursor-pointer" style={{width: "84%", padding: "12px 0"}}>
              <LaunchSourceManagerButton key="sources-manager-x" serviceId={"contacts"}>
                <Typography.Title style={{marginBottom: 11}}>Calls</Typography.Title>
              </LaunchSourceManagerButton>
            </div>

            {/*<Card title="Connect your Email" className="card-simple card-rounded card-no-header" bordered={false}>*/}
            {/*  <Link to="/">Connect your email to use assistant features</Link>*/}
            {/*  /!*<SourceManager group={{sourceIds:["gmail", "outlook", "web"]}} serviceId="contacts" />*!/*/}
            {/*</Card>*/}
          </Empty>
        }}
      />
    </Pager>
  </div>
}

export default CallsList;
