import React from "react";
import {Button, Card, Input, Radio} from "antd";
import {useSelector} from "react-redux";
import {AppContext, WindowContext} from "@web/hooks/context";
import {updatePath} from "@web/lib/firestore.db";
import {notifyAndTrack} from "./notification";
import {api} from "@web/lib/api";
import {useLocation} from "wouter";

const endpoint = (process.env.NODE_ENV === "production") ?
  "https://line-v1-7zujc5azra-uw.a.run.app/onboarding" :
  "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/onboarding";

function AssistantSetupCard() {
  const [, user] = React.useContext(AppContext);
  const [location, setLocation] = useLocation();
  const [isMobile, width, height] = React.useContext(WindowContext);
  const [assistantName, setAssistantName] = React.useState("");
  const assistantPath = ["user", user?.uid, "lines", "assistant"].join("/");
  const assistant = useSelector((state) => state.Lines.data?.[assistantPath]);

  /**
   * @param {string} step
   * @param {object} updates
   * @type {(function(*, *): void)|*}
   * @return {Promise<object|Error>}
   */
  const onUpdateStep = (step, updates) => {
    return api.POST(endpoint, {step, updates})
    .then((response) => response)
    .catch((error) => error);
  };

  const personasMap = {
    visionary: "Your forward-thinking partner, perfect for business owners looking to stay ahead of the curve. Thrives on innovation, offering proactive insights and strategic advice. Specializes in breaking down complex problems, identifying trends, and helping businesses plan for sustainable growth. Their tone is confident, inspiring, and subtly playful, keeping you engaged and motivated.",
    efficient: "Your go-to for getting things done quickly and smoothly. Detail-oriented, reliable, and resourceful—like that coworker who always knows where to find the answer. Friendly, approachable, and straight to the point - perfect when you want a fast, no-nonsense assistant?.",
    creative: "The imaginative and empathetic partner, ideal for fresh ideas and collaboration. Loves brainstorming, exploring creative solutions, and finding ways to turn big ideas into reality. A warm tone, encouraging, and curious, fostering a sense of camaraderie that inspires you to think outside the box.",
  }

  const assistantNameOnChange = (e) => {
    const name = e.target.value.trim();
    setAssistantName(name);
  }

  const getVoiceName = (persona = "efficient", gender = "neutral") => {
    const voiceKey = `${persona}-${gender}`;
    switch (voiceKey) {
      case "efficient-man":
        return "echo";
      case "efficient-woman":
        return "sage";
      case "efficient-neutral":
        return "alloy";
      case "visionary-man":
        return "verse";
      case "visionary-woman":
        return "shimmer";
      case "visionary-neutral":
        return "alloy";
      case "creative-man":
        return "ash";
      case "creative-woman":
        return "shimmer";
      case "creative-neutral":
        return "alloy";
    }
  }

  const saveVoice = ({gender = assistant?.gender, persona = assistant?.persona}) => {
    updatePath(assistantPath, {
      voice: {
        ...assistant?.voice || null,
        name: getVoiceName(persona, gender),
      }
    });
  }

  const saveGender = (gender) => {
    updatePath(assistantPath, {gender: gender})
    .then(() => notifyAndTrack.success("Success", "Voice updated"))
    .then(() => saveVoice({gender}))
  };

  const saveAssistantName = () => {
    onUpdateStep(assistantPath, {
      assistantName: assistantName.trim(),
    })
    .then(() => {
      setAssistantName("");
      notifyAndTrack.success("Success", "Assistant Name updated");
    });
  };

  const savePersona = (persona) => {
    updatePath(assistantPath, {persona, personaDescription: personasMap[persona]})
    .then(() => notifyAndTrack.success("Success", `Persona ${persona}`))
    .then(() => saveVoice({persona}))
  };

  const isComplete = () => {
    return assistant?.gender &&
      assistant?.persona &&
      assistant?.assistantName &&
      (!assistantName || assistant?.assistantName === assistantName);
  }

  return <div className="w-full">
    <br/>
    <Card className={`card-simple card-rounded`} style={{margin: "60px 10px 0 10px"}}>
      <section className="space space-col between" style={{height: 480, paddingTop: 10, paddingBottom: 10}}>
        <div className="space space-col w-full">
          <h4 className="mb-12 font-xl">Persona</h4>
          <Radio.Group size="large" shape="round" value={assistant?.persona} onChange={(e) => savePersona(e.target.value)}>
            <Radio.Button value="visionary">visionary</Radio.Button>
            <Radio.Button value="efficient">efficient</Radio.Button>
            <Radio.Button value="creative">creative</Radio.Button>
          </Radio.Group>
        </div>
        <div className="space space-col w-full">
          <h4 className="mb-12 font-xl">Voice</h4>
          <Radio.Group size="large" value={assistant?.gender} shape="round" onChange={(e) => saveGender(e.target.value)}>
            <Radio.Button value="man">man</Radio.Button>
            <Radio.Button value="neutral">neutral</Radio.Button>
            <Radio.Button value="woman">woman</Radio.Button>
          </Radio.Group>
        </div>
        <div className="space space-col w-full">
          <h4 className="mb-12 font-xl">Assistant Name</h4>
          <div className="space space-center-y relative">
            <Input
              maxLength={10}
              value={assistantName}
              placeholder={assistant?.assistantName || "Type assistant name"}
              size="large"
              className="input-bold small"
              onChange={assistantNameOnChange}
              suffix={<Button disabled={!assistantName || assistantName.length < 3} type="link" onClick={saveAssistantName}>Save</Button>}
            />
          </div>
        </div>
      </section>
    </Card>
    <div className="space space-center-y space-center-x">
      <Button disabled={!isComplete()} onClick={() => setLocation("/")} shape="round" size="large" className="input-bold" style={{margin: "16px auto", width: "96%", boxSizing: "border-box"}}>Continue</Button>
    </div>
  </div>
}

export default AssistantSetupCard;
