import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

AppStoreListData.propTypes = {
  tag: PropTypes.string,
  component: PropTypes.elementType,
};

function AppStoreListData(props) {
  const list = useSelector((state) => {
    // console.log("AppStoreListData:selector",  state.Content.activity?.store?.tags?.[props.tag]);
    return state.Content.activity?.store?.tags?.[props.tag];
  });
  console.log("AppStoreListData:render",  list);
  return React.createElement(props.component, {list, ...props});
}

export default AppStoreListData;
