import React from "react";
import PropTypes from "prop-types";
import {Button, Card} from "antd";
import {EditIcon, PlayIcon, XIcon} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux";
import aiActions from "@web/redux/ai/actions";
import useChatStream from "@web/hooks/useChatStream";
import now from "lodash/now";
import {useLocation} from "wouter";
import {generateID} from "@web/lib/firestore.db";
import {api} from "@web/lib/api";
import {notifyAndTrack} from "./notification";
// import {AppContext} from "@web/hooks/context";

SandboxActiveAppCard.propTypes = {
  path: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickClear: PropTypes.func,
  onClickRun: PropTypes.func,
  onAddContextPath: PropTypes.func,
  onRemoveContextPath: PropTypes.func,
};

function SandboxActiveAppCard(props) {
  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  // const [, user] = React.useContext(AppContext);
  const app = useSelector((state) => state.Content.data?.[props.path]);
  // Get job run
  // Get serivces jobs
  // Highest index === current

  // const lineAssistantPath = ["user", user.uid, "lines", "assistant"].join("/");
  // const assistant = useSelector((state) => {
  //   return state.Lines.data[lineAssistantPath];
  // });

  const [stream, sendRequest] = useChatStream(app?.agentId, true);
  const [chatStartTs, setChatStartTs] = React.useState(null);

  const onClearApp = React.useCallback(() => {
    props.onClickClear ?
      props.onClickClear(props.path, app) :
    dispatch(aiActions.clearActiveApp());
  }, [dispatch]);

  const onEditApp = () => {
    props.onClickEdit ?
      props.onClickEdit(props.path, app) :
      setLocation(`/sandbox/${app.appKey}`);
  }

  const onRunApp = () => {
    props.onClickRun ?
      props.onClickRun(props.path, app) :
      setLocation(`/sandbox/${app.appKey}`);

    api.LINE("sandbox/run", {
      appPath: props.path,
      // runId: "",
    })
    .then(() => notifyAndTrack.info("App is running"));
  }

  // React.useEffect(() => {
  //   props.onAddContextPath &&
  //   props.onAddContextPath(props.path);
  // }, [props.path]);

  React.useEffect(() => {
    if (app && !stream?.length && !chatStartTs) {
      setChatStartTs(now());
      // console.log(">>>>>>>>>ActiveAppCard:sendRequest:app:", JSON.stringify(app, null, 2));
      // return sendRequest(
      //   "This app",
      //   {
      //     contextPaths: [props.path],
      //     history: 3,
      //     collectionPath: props.path + `/${props.agentId}`,
      //   });
    }
  }, [app, stream]);

  if (!app) {
    return null;
  }
  if (!app.servicesPaths) {
    return null;
  }

  return <Card
    bordered={false}
    extra={<div className="space space-center-y">
      <Button ghost onClick={onEditApp} shape="round" className="icon-btn icon-right"><EditIcon /></Button>
      <Button ghost onClick={onClearApp} className="icon-btn icon-right"><XIcon /></Button>
  </div>}
    className={`card-rounded card-no-separator card-simple extra-round${props.collapsed ? "" : " card-h-600"} card-body-scroll`}
    title={app?.appName}
  >
    <div className="space space-col">
      <span className="font-lg bolded">{app?.title}</span>
      <span className="ai-text font-lg">{app?.description}</span>
      <div className="w-full" style={{marginTop: 12, height: 40}}>
        {!!app?.servicesPaths?.length && <Button size="large" shape="round" className="icon-btn orange-color" onClick={onRunApp}><PlayIcon /> Run App</Button>}
      </div>
    </div>
  </Card>
}

export default SandboxActiveAppCard;
