import React, {useCallback} from "react";
import {List} from "antd";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
import ListItemActive from "./ListItemActive";
import {ApplicationIcon} from "@web/assets/icons";
import aiActions from "@web/redux/ai/actions";
import PropTypes from "prop-types";

AppsListItem.propTypes = {
  path: PropTypes.string,
  date: PropTypes.number,
  onClick: PropTypes.func,
}

function AppsListItem(props) {
  const dispatch = useDispatch();
  const isMobile = true;
  const app = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const onClick = useCallback((e) => {
    const data = {
      path: props.path, app
    };

    if (props.onClick) {
      return props.onClick(data, e);
    }
    dispatch(appActions.setActiveDetail(data, true));
    dispatch(aiActions.setActiveApp(props.path));
  }, [dispatch,props]);

  if (!app) {
    return null
  }
  console.log("AppsListItem", app.created);

  return <List.Item
    key={props.path}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[]}
    onClick={onClick}
  >
    <List.Item.Meta
      avatar={<UserAvatar icon={<ApplicationIcon className="muted-icon" />} />}
      title={
      <div className="space space-center-y">
        <h6 className={"capitalize"}>
          {app.appName || app.name}
        </h6>
        {/*{!!eventId && <ActivityIconStrip eventId={eventId} />}*/}
        <span key="ts" className="muted font-sm">
          <Day ts={app.date || props.date}/>
        </span>
      </div>
    }
      description={<label className="muted line-clamp-1" style={{width: "90%"}}>{app?.title || "NO TITLE"}</label>}
    />
    <ListItemActive
      path={props.path}
      offset={"5px"}
    />
  </List.Item>
}

export default AppsListItem;
