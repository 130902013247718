import React, {Fragment} from 'react';
import {Button} from 'antd'
import ScribeSettings from '@ai-antd/page/ScribeSettings'
import PageDrawer from '@ai-antd/page/PageDrawer'

function ShareScribeButton(props) {
  const [showPage, setShowPage] = React.useState(false)
  const onClose = React.useCallback(() => setShowPage(false), [])
  return <Fragment>
    <Button ghost onClick={() => setShowPage(true)} type="link">
      Share
    </Button>
    { showPage &&
      <PageDrawer backText="Scribe" onClose={onClose} noNav>
        <ScribeSettings scribeId={props.scribeId} />
      </PageDrawer>
    }
  </Fragment>
}

export default ShareScribeButton
