import React from "react";
import PropTypes from "prop-types";
import {Button, Comment, List, Tag} from "antd";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import Day from "./Day";
import {useDispatch, useSelector} from "react-redux";
import UserAvatar from "./UserAvatar";
import {PlayIcon, RepeatIcon, ShoppingCartIcon, UserTodoIcon} from "@web/assets/icons";
import appActions from "@web/redux/app/actions";
import ListItemActive from "./ListItemActive";
import {ChatContext} from "@web/hooks/context";
import {notifyAndTrack} from "./notification";
import {api} from "@web/lib/api";

ServiceJobListItem.propTypes = {
  servicePath: PropTypes.string.isRequired,
  serviceJobPath: PropTypes.string.isRequired,
};

function ServiceJobListItem(props) {
  console.log("ServiceJobListItem:props:", props);
  const dispatch = useDispatch();
  const chatContext = React.useContext(ChatContext);
  const service = useSelector((state) => state.Content.data?.[props.servicePath]);
  const [serviceJob, loadingServiceJob] = useDocumentFirestore(props.serviceJobPath);
  // console.log("ServiceJobListItem:serviceJob:", {serviceJob, service});

  const onClick = () => {
    // console.log("ServiceJobListItem:onClick:", {serviceJob, service});
    if (serviceJob) {
      chatContext.setAgent({
        ...chatContext.agent,
        agentId: serviceJob.agentId,
        collectionPath: [props.serviceJobPath, "managers", serviceJob.agentId, "ai-workflow"].join("/"),
      });
      dispatch(appActions.setActiveDetail({
        serviceJob: serviceJob,
        service: service,
        path: props.serviceJobPath,
      }));
    } else {
      notifyAndTrack.info("This has not been run", "Service Job not found");
    }
  }

  const runFromHere = () => {
    if (!serviceJob) return;
    const pathname = props.servicePath.includes("/sandbox-services/") ? "/sandbox/run" : "/app/run";
    api.LINE(pathname, {
      appPath: serviceJob.appPath,
      serviceJobPath: props.serviceJobPath,
    })
    .then(() => notifyAndTrack.info("Started"));
  }


  return <List.Item
    className="relative"
    onClick={onClick}
    extra={
    serviceJob?.finished ?
      <Tag className="rounded" color="green">Finished</Tag> :
      serviceJob?.started ?
        <Tag className="rounded" color="pink">Started</Tag> :
        <Tag className="rounded muted">Waiting</Tag>
    }>
    <Comment
      avatar={<UserAvatar icon={<UserTodoIcon />} />}
      author={<span className="font-lg">{service?.method.replace(":", " ")}</span>}
      datetime={serviceJob?.started ? <Day ts={serviceJob?.started?.toMillis()}/> : "Not started"}
      content={<span className="font-lg line-clamp-1">{service?.summary || service?.description}</span>}
      actions={[<div className="space space-center-y">
        <Button ghost size="large" shape="circle"><ShoppingCartIcon className={!!serviceJob?.payload ? "" : "muted-icon"} size={18} style={{position: "relative", top: 2}} /></Button>
        {serviceJob?.finished ?
          <Button size="large" shape="circle" className="icon-btn" ghost onClick={runFromHere}><RepeatIcon className={!serviceJob ? "muted-icon" : ""} /></Button> :
          null}
      </div> ]}
    />
    <ListItemActive path={props.serviceJobPath} offset={-20} />
  </List.Item>;
}

export default ServiceJobListItem;
