import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
// import ContactsList from "./ContactsList";
// import ThreadsList from "./ThreadsList";
import FileList from "./FileList";

function FilesContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="files" component={FileList} />
      {/*<ServiceData serviceId="contacts" component={ContactsList} />*/}
    </section>
  </DashboardFullRow>
}

export default FilesContent;
