import React from 'react';
import DashboardFullRow from "./DashboardFullRow";
import CreateApp from "./CreateApp";
import {AppContext, WindowContext} from "@web/hooks/context";
import LandingPage from "@ai-antd/page/LandingPage";

function CreateContent() {
  const [,, height] = React.useContext(WindowContext);
  const [,, claims] = React.useContext(AppContext);
  // const linesContext = React.useContext(LinesContext);

  return <DashboardFullRow>
    <section style={{width: "100%", backgroundColor: "#0e161f", minHeight: height}}>
      {claims?.data?.subs?.includes("creator") || claims?.data?.subs?.includes("creator-pro") ?
        <CreateApp /> :
        <LandingPage />}
    </section>
  </DashboardFullRow>
}

export default CreateContent;
