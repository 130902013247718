import React from "react";
import PropTypes from "prop-types";
import AppsServicesSelector from "./AppsServicesSelector";
import {XIcon} from "@web/assets/icons";
import AppServiceLabel from "./AppServiceLabel";
import {Button, List} from "antd";
// import {useSelector} from "react-redux";
import {notifyAndTrack} from "./notification";
import {SandboxContext} from "@web/hooks/context";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";

ServiceInputsManager.propTypes = {
  // appPath: PropTypes.string.isRequired,
  servicePath: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  excludePaths: PropTypes.array,
  onClickServiceAction: PropTypes.func,
  onUpdateStep: PropTypes.func,
};

function ServiceInputsManager(props) {
  const sandbox = React.useContext(SandboxContext);
  const [service] = useDocumentFirestore(props.servicePath);

  const addServiceInput = (pathToAdd) => {
    if (props.onChange) {
      props.onChange(pathToAdd);
      return;
    }

    props.onUpdateStep?.(props.servicePath, {
      inputPaths: [...service.inputPaths || [], pathToAdd],
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  const onClickRemoveInput = (pathToRemove) => {
    props.onClickServiceAction ?
      props.onClickServiceAction(pathToRemove) :
    props.onUpdateStep(props.servicePath, {
      inputPaths: service.inputPaths.filter((path) => path !== pathToRemove),
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Input removed"))
  }

  return <div className="space space-col">
    <List
      header={
        <div className="space space-center-y space-between">
          <AppsServicesSelector
            placeholder={"Add"}
            appPath={sandbox.appPath}
            onChange={addServiceInput}
            exclude={props.excludePaths}
          />
          <span className="font-lg"></span>
        </div>
      }
      locale={{emptyText: "No Inputs"}}
      dataSource={service?.inputPaths || []}
      renderItem={(servicePath) =>
        <List.Item
          extra={
            <Button ghost className="btn-icon" onClick={() => onClickRemoveInput?.(servicePath)}>
              <XIcon className="green-icon" />
            </Button>
          }>
          <AppServiceLabel path={servicePath} className="space space-center-y fat-chip primary" />
        </List.Item>
      }
    />
  </div>
}

export default ServiceInputsManager;
