import React from 'react';
// import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
import ServiceData from "@web/ui/containers/ServiceData";
import AssistantList from "./AssistantList";
// import AppsList from "./AppsList";
// import {Card, Collapse, Typography} from "antd";
// import {DownArrowOutlined, DownIcon} from "@web/assets/icons";
// import LineAppTasks from "./LineAppTasks";

function AssistantContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="assistant" component={AssistantList} />
      {/*<ServiceData serviceId="progress" component={LineAppTasks} />*/}
      {/*<Tabs*/}
      {/*  style={{width: "80%"}}*/}
      {/*  centered*/}
      {/*  items={[*/}
      {/*    {key: "links", label: "Links"},*/}
      {/*    {key: "tasks", label: "Topics"},*/}
      {/*    // {key: "notes", label: "Notes"},*/}
      {/*  ]}*/}
      {/*/>*/}
      {/*<Card className="card-rounded" bordered={false} style={{width: 450, marginLeft: "24px", marginTop: 80}}>*/}
      {/*  <Collapse*/}
      {/*    ghost*/}
      {/*    accordion*/}
      {/*    bordered={false}*/}
      {/*    // style={{width: "76%"}}*/}
      {/*    expandIconPosition={"right"}*/}
      {/*    expandIcon={() => <DownArrowOutlined />}*/}
      {/*  >*/}
      {/*    <Collapse.Panel*/}
      {/*      key={"links"}*/}
      {/*      header={<span className="font-lg bolded" style={{textAlign: "center"}}>Links</span>}*/}
      {/*      // className="bgx x"*/}
      {/*    >*/}
      {/*    </Collapse.Panel>*/}
      {/*    <Collapse.Panel*/}
      {/*      key={"topics"}*/}
      {/*      header={<span className="font-lg bolded"  style={{textAlign: "center"}}>Topics</span>}*/}
      {/*      // className="bgx x"*/}
      {/*      style={{marginTop: "6px"}}*/}
      {/*    >*/}
      {/*    </Collapse.Panel>*/}
      {/*  </Collapse>*/}
      {/*</Card>*/}
    </section>
  </DashboardFullRow>
}

export default AssistantContent;
