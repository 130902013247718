import React from 'react';
import PropTypes from 'prop-types';
// import useChatStream from "@web/hooks/useChatStream";
// import MarkdownTimeline from "./MarkdownTimeline";
import {Card, Divider, Progress} from "antd";
import useSaveUploads from "@web/hooks/useSaveUploads";
import FileCard from "./FileCard";
import {useDispatch} from "react-redux";
import appActions from "@web/redux/app/actions";

ChatMediaManager.propTypes = {
  uploads: PropTypes.object,
  onMediaReady: PropTypes.func,
};

/**
 * ChatMediaManager
 * @param {object} props
 * @param {Object<string, {ref: StorageReference, metadata: FullMetadata, originFilename:string, percentComplete:number, state:string}>} props.uploads
 * @param {function(...filePath:string):void} props.onMediaReady
 * @returns {React.JSX.Element}
 * @constructor
 */
function ChatMediaManager(props) {
  const dispatch = useDispatch();
  const [uploads, setUploads] =
    React.useState(props.uploads || {});
  const [savedUploads, saveUpload, submittedKeys = []] = useSaveUploads();
  const onFileClick = React.useCallback((props, show) =>
    dispatch(appActions.setActiveDetail(props, show)),
    [dispatch]);

  React.useEffect(() => {
    setUploads(props.uploads);
  }, [props.uploads]);

  React.useEffect(() => {
    console.log("ChatMediaManager:useEffect:uploads:", uploads, "savedUploads", savedUploads);
    const uploadsToSave =
      Object
      .entries(uploads)
      .filter(([gcsFilename, upload]) =>
        upload.percentComplete === 100 &&
        upload.status === "complete" &&
        !submittedKeys.includes(gcsFilename))
      .map(([gcsFilename, upload]) => {
        return [gcsFilename, {
          gsUrl: upload.ref.toString(),
          originFilename: upload.originFilename,
        }];
      });

    console.log("ChatMediaManager:readyToSave:", uploadsToSave, submittedKeys);

    !!uploadsToSave?.length &&
    saveUpload(Object.fromEntries(uploadsToSave));

    // Object.keys(savedUploads || {})?.length &&
    // props.onMediaReady(...Object.values(savedUploads)
    // .map((savedUpload) => savedUpload.filePath)
    // .filter(Boolean));
  }, [uploads]);

  React.useEffect(() => {
    console.log("ChatMediaManager:useEffect:savedUploads:", savedUploads);
    Object.keys(savedUploads || {})?.length &&
    props.onMediaReady(...Object.values(savedUploads)
    .map((savedUpload) => savedUpload.filePath)
    .filter(Boolean));
  }, [savedUploads]);

  // const onContinue = () => {
  //   const uploadsList = Object.entries(uploads)
  //   .map(([gcsFilename, upload]) => {
  //     if (processing.includes(gcsFilename)) {
  //       return;
  //     }
  //     setProcessing(uniq([...processing, gcsFilename]));
  //     return {
  //       gsUrl: upload.ref.toString(),
  //       originFilename: upload.originFilename,
  //     };
  //   });
  //   sendRequest(`Upload the following:\n${JSON.stringify(uploadsList)}`);
  // }
  // const ProcessingIndicator =
  //   (processing.length &&
  //     (!answers?.length ||
  //       !answers?.some((answer) =>
  //         !!answer.text)
  //     ) &&
  //     <ProgressBar loop steps={10} visible color="tan" />) || null;
  // console.log("ChatMediaManager:uploadsList:", uploads, processing, answers);

  return <div className="chat-media-manager mb-12">
    {!!Object.keys(uploads)?.length &&
      <div className="space space-center-y">
        {Object.entries(uploads).map(([gcsFilename, upload]) => {
          console.log("gs-ref:", upload.ref.toString());
          return <Card
            bordered={false}
            key={upload.ref.toString()}
            className="card-simple card-no-titles card-rounded card-slim card-inline card-no-titles"
          >
            {savedUploads?.[gcsFilename]?.filePath ?
              <FileCard
                file={savedUploads?.[gcsFilename]?.file}
                path={savedUploads?.[gcsFilename]?.filePath}
                preview
                onClick={() =>
                  onFileClick({
                      file: savedUploads?.[gcsFilename]?.file,
                      path: savedUploads?.[gcsFilename]?.filePath,
                    },
                    true)
                }/> :
              <Progress percent={upload.percentComplete || 0}/>
            }
            <Divider className="small-top no-bottom hidden" />
            <span className="muted line-clamp-1">
              &nbsp;&nbsp;{upload.originFilename}
            </span>
          </Card>;
        })}
      </div>
    }
    {/*{!!answers?.length &&*/}
    {/*  answers.map((answer, index, i) =>*/}
    {/*  answer?.text &&*/}
    {/*  <MarkdownTimeline*/}
    {/*    key={index}*/}
    {/*    markdown={answer.text}*/}
    {/*  />)*/}
    {/*}*/}
    {/*<ShortCodeMedia code="Megyk">*/}
    {/*  uploads*/}
    {/*</ShortCodeMedia>*/}
    {/*{!!Object.keys(uploads).length &&*/}
    {/*  !processing.length &&*/}
    {/*  <React.Fragment>*/}
    {/*    <Divider className="small-top small-bottom" />*/}
    {/*    <Button onClick={onContinue}>Continue</Button>*/}
    {/*  </React.Fragment>*/}
    {/*}*/}
  </div>
}

export default ChatMediaManager;
