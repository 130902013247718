import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'
import chunk from 'lodash/chunk'

LogoStrip.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string,
    sort: PropTypes.number,
  })),
}

/**
 * @summary Render a strip of logos as icons
 * @param {Object} props
 * @param {Object[]} props.items
 * @return {JSX.Element}
 */
function LogoStrip({items = [], size, rowLength = 6, wrapperClassName}) {
  const rows = chunk(items, rowLength)
  return <div className={`space-col middle center extra-large gap-30 ${wrapperClassName}`}>
    {rows.map((row) => <div className="space space-center-y space-evenly" key={row[0]?.name}>
      {row.length ?
        row.map(({name}) => <Icon key={name} name={name} size={size} />) :
        null
      }
    </div>)}
  </div>
}

export default LogoStrip
