import React from "react";
import PropTypes from "prop-types";
// import useQueryFirestore from "@web/hooks/useQueryFirestore";
// import {buildQuery} from "@web/lib/firestore.db";
// import {AppContext} from "@web/hooks/context";
import ServiceSelector from "./ServiceSelector";
// import {useSelector} from "react-redux";
import AppServiceLabel from "./AppServiceLabel";
// import without from "lodash/without";
import {SandboxContext} from "@web/hooks/context";

AppsServicesSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  // appPath: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  exclude: PropTypes.array,
};

function AppsServicesSelector(props) {
  const sandbox = React.useContext(SandboxContext);
  // const app = useSelector((state) => state.Apps.data?.[props.appPath]);

  if (!sandbox.app) {
    return <div className="fat-chip primary">Input</div>;
  }

  return <div className="space space-center-y">
    <ServiceSelector
      onClick={(e) => props.onChange(e.key)}
      items={(sandbox.app?.servicesPaths || [])?.map?.((servicePath, index) => ({
        label: <AppServiceLabel path={servicePath} className="space space-between" sequenceNumber={index + 1} />,
        value: servicePath,
        key: servicePath,
        disabled: props.exclude?.includes(servicePath),
      }))
    }
      text={
      <AppServiceLabel
        path={props.value}
        placeholder={props.placeholder || "Select a service"}
      />
      }
    />
  </div>
}

export default AppsServicesSelector;
