import React, {useContext} from "react";
import {Typography, Empty, List, Divider, Comment, Card, Avatar, Button} from "antd";
import {WindowContext} from "@web/hooks/context";
// import ContactActivityListItem from "./ContactActivityListItem";
import Loading from "./Loading";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import PropTypes from "prop-types";
// import ActivityData from "@web/ui/containers/ActivityData";
import LaunchSourceManagerButton from "./LaunchSourceManagerButton";
// import ContactInfoCard from "./ContactInfoCard";
// import ContentData from "@web/ui/containers/ContentData";
// import ListItemActive from "./ListItemActive";
import ContactsListItem from "./ContactsListItem";
import UserAvatar from "./UserAvatar";
import {RightArrowOutlined} from "@web/assets/icons";
import {useLocation} from "wouter";
import AssistantListItem from "./AssistantListItem";
import ServiceData from "@web/ui/containers/ServiceData";
import AssistantList from "./AssistantList";
import LinksList from "./LinksList";
import ThreadsList from "./ThreadsList";

FeedList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
}

function FeedList(props) {
  const [isMobile,, height] = useContext(WindowContext);
  const [, setLocation] = useLocation();

  return <div
    id="services-feed-scroll-target"
    style={{
      height: height - 3,
      overflow: 'auto',
      // display: 'flex', flexDirection: 'column',
    }}>
    <Pager
      height={height - 0}
      loader={<Loading inline />}
      length={props.list?.length ?? 0}
      hasMore={false}
      endMessage={!!props.list?.length &&
        <div className="space space-center-y" style={{position: "relative", top: "6px"}}><h6
          className="muted"></h6>&nbsp;<LineConnector />
        </div>}
      pageNext={props.pageNext}
      scrollContainerId="services-feed-scroll-target"
    >
    <div className="w-full">
      <Divider style={{marginTop: 68}} className="small-bottom hidden" />
    </div>
    {/*<Card*/}
    {/*  title={<h6>Lines</h6>}*/}
    {/*  extra={<Button ghost className="icon-btn icon-right" onClick={() => setLocation("/lines")}>Browse <RightArrowOutlined /></Button>}*/}
    {/*  bordered={false}*/}
    {/*  style={{margin: "0 10px"}}*/}
    {/*  className="card-simple card-rounded card-inline card-no-separator card-dark"*/}
    {/*>*/}
    {/*  /!*<List*!/*/}
    {/*  /!*  // header={<h6>Lines</h6>}*!/*/}
    {/*  /!*  className="list-item-no-divider">*!/*/}
    {/*  /!*  <List.Item extra={<Button ghost className="icon-btn icon-right"><RightArrowOutlined /></Button>}>*!/*/}
    {/*  /!*    <List.Item.Meta avatar={<UserAvatar />} title={"Fiona"} description={"+1(628) 444-1211"}></List.Item.Meta>*!/*/}
    {/*  /!*  </List.Item>*!/*/}
    {/*  /!*  <List.Item extra={<Button ghost className="icon-btn icon-right"><RightArrowOutlined /></Button>}>*!/*/}
    {/*  /!*    <List.Item.Meta avatar={<UserAvatar />} title={"Daphne"} description={"+1(415) 322-7655"}></List.Item.Meta>*!/*/}
    {/*  /!*  </List.Item>*!/*/}
    {/*  /!*</List>*!/*/}
    {/*</Card>*/}
    <Card
      title={<h6></h6>}
      extra={<Button className="icon-btn icon-right" onClick={() => setLocation("/assistant")} ghost>Browse <RightArrowOutlined /></Button>}
      bordered={false}
      className="card-simple card-rounded card-no-separator card-dark card-highlight"
      style={{margin: "10px 10px 0 10px", boxSizing: "border-box"}}
    >
      <ServiceData serviceId={"assistant"} preview height={405} component={AssistantList} />
    </Card>
    <Card
      title={<h6></h6>}
      extra={<Button className="icon-btn icon-right" onClick={() => setLocation("/links")} ghost>Links <RightArrowOutlined /></Button>}
      bordered={false}
      className="card-simple card-rounded card-no-separator card-dark card-highlight"
      style={{margin: "10px 10px 0 10px", boxSizing: "border-box"}}
    >
      <ServiceData serviceId={"links"} preview height={405} component={LinksList} />
    </Card>
    <Card
      title={<h6></h6>}
      extra={<Button className="icon-btn icon-right" onClick={() => setLocation("/emails")} ghost>Emails <RightArrowOutlined /></Button>}
      bordered={false}
      className="card-simple card-rounded card-no-separator card-dark card-highlight"
      style={{margin: "10px 10px 0 10px", boxSizing: "border-box"}}
    >
      <ServiceData serviceId={"threads"} preview limit={3} height={405} component={ThreadsList} />
    </Card>
    </Pager>
      {/*<List*/}
      {/*  // layout="horizontal"*/}
      {/*  // header={<Divider/>}*/}
      {/*  header={*/}
      {/*    <div className="w-full">*/}
      {/*      {!isMobile && <Divider style={{marginTop: 56}} className="small-bottom" />}*/}
      {/*      <Card*/}
      {/*        title={<h6>Today</h6>}*/}
      {/*        extra={<Button ghost className="icon-btn icon-right" onClick={() => setLocation("/lines")}>Browse <RightArrowOutlined /></Button>}*/}
      {/*        bordered={false}*/}
      {/*        style={{margin: "0 10px"}}*/}
      {/*        className="card-simple card-rounded card-inline card-no-separator card-dark"*/}
      {/*      >*/}
      {/*        /!*<List*!/*/}
      {/*        /!*  // header={<h6>Lines</h6>}*!/*/}
      {/*        /!*  className="list-item-no-divider">*!/*/}
      {/*        /!*  <List.Item extra={<Button ghost className="icon-btn icon-right"><RightArrowOutlined /></Button>}>*!/*/}
      {/*        /!*    <List.Item.Meta avatar={<UserAvatar />} title={"Fiona"} description={"+1(628) 444-1211"}></List.Item.Meta>*!/*/}
      {/*        /!*  </List.Item>*!/*/}
      {/*        /!*  <List.Item extra={<Button ghost className="icon-btn icon-right"><RightArrowOutlined /></Button>}>*!/*/}
      {/*        /!*    <List.Item.Meta avatar={<UserAvatar />} title={"Daphne"} description={"+1(415) 322-7655"}></List.Item.Meta>*!/*/}
      {/*        /!*  </List.Item>*!/*/}
      {/*        /!*</List>*!/*/}
      {/*      </Card>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*  className="services-contacts-list list-empty-no-padding list-item-no-divider"*/}
      {/*  // dataSource={props.list}*/}
      {/*  // renderItem={([path, date], index) => {*/}
      {/*  //   return <AssistantListItem path={path} date={date} index={index} />*/}
      {/*  // }}*/}
      {/*  locale={{*/}
      {/*    emptyText: <Empty*/}
      {/*      className="add-integrations-empty-wrapper space space-col middle"*/}
      {/*      description={null}*/}
      {/*      image={null}*/}
      {/*      imageStyle={{display: "none"}}*/}
      {/*      style={{width: "100%", maxWidth: "100%", margin: "0 auto"}}*/}
      {/*    >*/}
      {/*    </Empty>*/}
      {/*  }}*/}
      {/*>*/}
      {/*/!*<List.Item>*!/*/}
      {/*/!*  <Card bordered={false} classsName="card-simple card-rounded card-h-600 card-dark" style={{width: "100%", margin: "10px 10px 0 10px", boxSizing: "border-box"}}>*!/*/}
      {/*/!*    /!*<ServiceData serviceId={"assistant"} preview component={AssistantList} />*!/*!/*/}
      {/*/!*  </Card>*!/*/}
      {/*/!*</List.Item>*!/*/}
      {/*</List>*/}
  </div>
}

export default FeedList;
