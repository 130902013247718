import React, {useContext} from 'react'
import {Button, Divider, Form, Input, InputNumber, Select, Space, Switch} from 'antd'
import {buildQuery, colls, safeValueForDB, setDocument} from '@web/lib/firestore.db'
import {notifyAndTrack} from './notification'
import {AppContext} from '@web/hooks/context'
import * as regex from '@web/lib/regex'
import {MinusOutlined} from '@ant-design/icons'
import useQueryFirestore from '@web/hooks/useQueryFirestore'
import useDocumentFirestore from '@web/hooks/useDocumentFirestore'
import Loading from './Loading'

export default function FaqForm({faqId}) {
  const [app] = useContext(AppContext)
  const [initialValues = {}, loadingFaq, error] = useDocumentFirestore([colls['app-faq'], faqId])
  const [form] = Form.useForm()
  const [appPairs = []] = useQueryFirestore(buildQuery(colls['oven-apps'], {}))
  if (!app) return null
  if (loadingFaq) return <Loading />
  if (faqId && !initialValues) return null
  const apps = (appPairs.length) ? appPairs.map(([,appData]) => ({
    value: appData.root.key,
    label: appData.root.key,
  })) : []
  const onFinish = values => {
    const key = faqId ? faqId : regex.string.replacePunctuationAndSpaces(values.title.toLowerCase(), '-')
    setDocument(colls['app-faq'], key, safeValueForDB(values))
      .then(() => notifyAndTrack.success('Submitted. Thank you.'))
      .catch(() => notifyAndTrack.error('Unable to submit at this time.'))
  }
  const onReset = () => form.resetFields()
  return <Form
    form={form}
    size="medium"
    name="faq"
    layout="vertical"
    onFinish={onFinish}
    initialValues={initialValues}
  >
    <Form.Item
      name="active"
      label="Active"
      valuePropName="checked"
      initialValue={true}
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Question"
      name="title"
      rules={[{required: true, message: "Please enter a title."}]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Answer"
      name="body"
      rules={[{required: true, message: "Please enter an answer."}]}
    >
      <Input.TextArea autoSize={{minRows: 3, maxRows: 10}} maxLength={1200}/>
    </Form.Item>
    <Divider className="small" />
    <Form.List
      name="links"
      shouldUpdate
    >
      {(fields, {add, remove}, {errors}) => (
        <>
          {fields.map(({name, key, ...restField}, index) => (
            <Form.Item key={key}>
              <Form.Item
                label="URL"
                name={[name, "url"]}
                {...restField}
                shouldUpdate
                rules={[{required: true, message: "Please enter a valid URL"}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Display text"
                name={[name, "text"]}
                {...restField}
                shouldUpdate
              >
                <Input />
              </Form.Item>
              {fields.length > 0 &&
                <Button
                  icon={<MinusOutlined/>}
                  onClick={() => remove(index)}
                />
              }
            </Form.Item>
          ))
          }
          <Form.Item>
            <Button type="dashed" onClick={() => add()}>Add Link</Button>
          </Form.Item>
        </>
      )}
    </Form.List>
    <Divider className="small" />
    <Form.List name="Actions" shouldUpdate>
      {(fields, {add, remove}, {errors}) => (
        <>
          {fields.map(({name, key, ...restField}, index) => (
            <Form.Item key={key}>
              <Form.Item
                label="Path"
                name={[name, "path"]}
                {...restField}
                shouldUpdate
                rules={[{required: true, message: "Please enter a valid URL"}]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Action"
                name={[name, "text"]}
                {...restField}
                shouldUpdate
              >
                <Input />
              </Form.Item>
              {fields.length > 0 &&
                <Button
                  icon={<MinusOutlined/>}
                  onClick={() => remove(index)}
                />
              }
            </Form.Item>
          ))
          }
          <Form.Item>
            <Button type="dashed" onClick={() => add()}>Add Action</Button>
          </Form.Item>
        </>
      )}
    </Form.List>
    <Divider className="small" />
    <Form.Item
      name="rank"
      label="Rank"
      initialValue={1}
    >
      <InputNumber />
    </Form.Item>
    <Form.Item
      label="Assign To"
      name="app"
      rules={[{required: true}]}
    >
      <Select mode="tags" options={apps}/>
    </Form.Item>
    <Divider className="small" />
    <Form.Item>
      <Space>
        <Button htmlType="reset" onClick={onReset}>
          Reset
        </Button>
        <Button type="primary" htmlType="submit">
          Finish
        </Button>
      </Space>
    </Form.Item>
  </Form>
}