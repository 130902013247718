import React from 'react';
import {Button, Popconfirm} from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import {colls, deleteDocument} from '@web/lib/firestore.db'
import {notifyAndTrack} from './notification'

function ScribeDeleteButton(props) {
  const doIt = () => {
    const {scribeId} = props
    deleteDocument(colls['ai-scribe'], scribeId)
      .then((() => notifyAndTrack.info("Scribe deleted.")))
  }
  return <Popconfirm
    title="Are you sure to delete this Scribe?"
    onConfirm={doIt}
    okText="Yes"
    cancelText="No"
  >
    <Button icon={<DeleteOutlined />}>
      Delete Scribe
    </Button>
  </Popconfirm>
}

export default ScribeDeleteButton;
