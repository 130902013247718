import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
// import LineAppTasks from "./LineAppTasks";
// import CallsList from "./CallsList";
import LinksList from "./LinksList";

function LinksContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="links" component={LinksList} />
    </section>
  </DashboardFullRow>
}

export default LinksContent;
