import React from "react";
import PropTypes from "prop-types";
import {Button} from "antd";
import {LeftArrowOutlined, RightArrowOutlined} from "@web/assets/icons";
import Loading from "./Loading";

SimplePager.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  defaultCurrent: PropTypes.number,
  centered: PropTypes.bool,
  hidePageOnSinglePage: PropTypes.bool,
  showPagerOnHover: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

function SimplePager(props) {
  console.log("SimplePager:render", props);
  const [currentPage, setCurrentPage] = React.useState(props.defaultCurrent || 1);

  const onPagePrev = React.useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  const onPageNext = React.useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);

  // if (props.loading) {
  //   return <Loading inline />
  // }

  const numPages = () => {
    return (props.dataSource?.length || 1) / (props.pageSize || 1);
  }

  const classNames = [
    "list-simple-pager",
    props.hidePageOnSinglePage && numPages() < 2 && "hide-on-single-page",
    props.showPagerOnHover && "hidden-pager",
    props.className || "",
  ].filter(Boolean).join(" ");

  return<React.Fragment>
    {React.cloneElement(
      props.children, {
        className: classNames,
        dataSource: props.dataSource,
        count: props.dataSource?.length || 0,
        current: currentPage,
        pagination: {
          pageSize: props.pageSize || 1,
          hideOnSinglePage: props.hidePageOnSinglePage ?? false,
          defaultCurrent: 1,
          current: currentPage,
          showLessItems: true,
        },
        footer: props.dataSource?.length ?
          <div className={`list-simple-pager-buttons space space-center-y ${props.centered ? 'space-center-x' : ''}`}>
            <Button
              ghost
              icon={<LeftArrowOutlined />}
              onClick={onPagePrev}
              disabled={currentPage <= 1}
            />
            <Button
              ghost
              icon={<RightArrowOutlined />}
              onClick={onPageNext}
              disabled={currentPage >= numPages()}
            />
          </div> :
          null,
      })}
  </React.Fragment>
}

export default SimplePager;
