import React from "react";
import {AppContext} from "@web/hooks/context";
import {Redirect} from "wouter";
import WithLineOnboarding from "./WithLineOnboarding";

ConsoleRouter.propTypes = {};

function ConsoleRouter() {
  const [,, claims] = React.useContext(AppContext);

  return claims.data?.subs?.length ?
    <WithLineOnboarding>
      <Redirect to="/home" />
    </WithLineOnboarding> :
    <WithLineOnboarding>
      <Redirect to="/home" />
    </WithLineOnboarding>;
}

export default ConsoleRouter;
