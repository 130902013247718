import React, { useState } from 'react';
import {Form, Select, Button, Switch, Typography, Divider, Input, Collapse} from 'antd';

const defaults = {
  options: {
    trigger: 'floatingButton',
    tone: 'default',
  },
  goals: {
    inform: true,
    call: true,
    email: true,
  },
}
function ScribeEmbedCodeForm(props) {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [fieldChanged, setFieldChanged] = useState(false);
  const handleFinish = (values) => {
    console.log('Generated embed code with values:', values);
    saveEmbedData(values);
  }
  console.log('fields touched', form.isFieldsTouched())
  const saveEmbedData = (data) => {
    console.log('Saving embed data:', data);
    // Perform any further actions with the form data
  }
  const onFieldsChange = (fields) => {
    console.log('fields changed', fields)
    setFieldChanged(!!fields.length);
  }
  // const goals = ['Inform', 'Call', 'Email', 'Prevent Call', 'Prevent Email', 'Recommend']
  return (
    <div>
      <Form
        form={form}
        onFinish={handleFinish}
        name="embedOptions"
        size="medium"
        layout="vertical"
        initialValues={defaults}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item shouldUpdate>
          <Collapse expandIconPosition="right">
            <Collapse.Panel key="customize" header="Customize" extra={<span className="muted">Optional</span>}>
              <Form.Item label="Show As" name={["options", "trigger"]} initialValue="button">
                <Select>
                  <Option value="button">Floating Button</Option>
                  <Option value="chatbox">Text Input</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Tone">
                <Form.Item name={["options", "tone"]} noStyle initialValue="default">
                  <Select>
                    <Option value="default">Match content tone</Option>
                    <Option value="formal">Formal</Option>
                    <Option value="informal">Informal</Option>
                  </Select>
                </Form.Item>
                {/*<div className="space space-between space-center-y">*/}
                {/*  <Typography.Text>1st Person</Typography.Text>*/}
                {/*  <Form.Item name="firstPerson" noStyle>*/}
                {/*    <Switch checked={true} size="small" />*/}
                {/*  </Form.Item>*/}
                {/*</div>*/}
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Form.Item>
        <Form.Item shouldUpdate>
          <Collapse expandIconPosition="right">
            <Collapse.Panel key="goals" header="Goals" extra={<span className="muted">Advanced</span>}>
              <div className="space space-between space-center-y">
                <Typography.Text>Inform</Typography.Text>
                <Form.Item name={["goals", "inform"]} noStyle>
                  <Switch checked={true} disabled size="small" />
                </Form.Item>
              </div>
              <Divider className="small-top small-bottom" />
              <div className="space space-between space-center-y">
                <Typography.Text>Call</Typography.Text>
                <Form.Item name={["goals", "call"]} noStyle>
                  <Switch checked={true} size="small" />
                </Form.Item>
              </div>
              <div className="space space-between space-center-y">
                <Typography.Text>Email</Typography.Text>
                <Form.Item name={["goals", "email"]} noStyle>
                  <Switch checked={true} size="small" />
                </Form.Item>
              </div>
              <div className="space space-between space-center-y">
                <Typography.Text>Minimize contact</Typography.Text>
                <Form.Item name={["goals", "minimizeContact"]} noStyle>
                  <Switch checked={false} size="small" />
                </Form.Item>
              </div>
              {/*<Divider className="small-top small-bottom" />*/}
              {/*<div className="space space-between space-center-y">*/}
              {/*  <Typography.Text>Recommend</Typography.Text>*/}
              {/*  <Form.Item name={["goal", "recommend"]} noStyle>*/}
              {/*    <Switch checked={false} size="small" />*/}
              {/*  </Form.Item>*/}
              {/*</div>*/}
            </Collapse.Panel>
          </Collapse>
        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" disabled={!fieldChanged} size="large" block htmlType="submit">Update Embed Code</Button>
        </Form.Item>
      </Form>
      <Divider />
      <p>Copy and paste the following code onto your website:</p>
      <Input.TextArea
        rows="5"
        cols="50"
        readOnly
        value={`<script src="https://thescribe.chat/embed.js" data-trigger="${form.getFieldValue(["options", "trigger"]) || "button"}" data-1st-person="${!!props.scribe.firstPerson}" data-tone="${props.scribe.tone || "default"}"></script>`}></Input.TextArea>
    </div>
  )
}

export default ScribeEmbedCodeForm;
