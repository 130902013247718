import React from 'react'
import {FiArrowUpRight, FiSliders, FiSidebar, FiArrowUp, FiBriefcase, FiPieChart,FiGlobe, FiChevronLeft, FiUpload, FiChevronRight, FiChevronDown, FiCreditCard, FiEye, FiUser, FiHome, FiMessageSquare, FiInfo, FiLink2, FiX, FiCpu, FiLayers, FiDatabase, FiHash, FiSettings, FiSlash, FiDownload, FiPlusCircle, FiPlus, FiSearch, FiThumbsUp, FiThumbsDown, FiLogOut, FiFeather, FiHeart, FiTruck, FiShoppingCart, FiBook, FiFile, FiHeadphones, FiAlertTriangle, FiFlag, FiClock, FiArrowDown, FiCheckCircle, FiCheckSquare, FiLock, FiImage, FiActivity, FiCalendar, FiMail, FiArrowLeft, FiRepeat, FiStar, FiChevronUp, FiInbox, FiPlayCircle, FiPauseCircle, FiTerminal, FiEdit3} from 'react-icons/fi'
import {BsFillBookmarkFill, BsHeartFill, BsCalendarDate, BsCheckLg, BsApp, BsKanban} from 'react-icons/bs'
import {BiBrain, BiUpArrowAlt, BiBookAdd, BiPlanet, BiBookmark, BiUserPlus, BiSpaceBar, BiUserCheck, BiPhoneCall, BiSolidUserDetail, BiPurchaseTag, BiDotsVerticalRounded, BiMenu, BiCopy, BiVoicemail, BiExpandHorizontal, BiArrowFromTop, BiLineChart} from 'react-icons/bi'
import {ImEmbed2, ImAttachment} from 'react-icons/im'
import {HiOutlineAtSymbol, HiOutlineOfficeBuilding, HiOutlinePresentationChartBar} from 'react-icons/hi'
import {SiAudiomack, SiGmail, SiIntuit, SiAmazon, SiAmazonwebservices, SiMicrosoft, SiQuickbooks, SiTrello, SiAsana, SiFacebook, SiInstagram, SiLinkedin, SiAirtable, SiSalesforce, SiGoogledrive, SiDropbox, SiSlack, SiMicrosoftexcel, SiGoogle, SiMicrosoftoutlook, SiGooglesheets, SiMicrosoftword} from 'react-icons/si'
import {MdPictureAsPdf, MdOutlinePermContactCalendar, MdPlaylistAdd, MdOutlineAccountBalanceWallet, MdOutlinePayments, MdAccountBalance, MdPersonalVideo, MdOutlineOndemandVideo, MdOutlineMobileFriendly} from 'react-icons/md'
import {FaQuoteLeft, FaQuoteRight, FaShopify} from 'react-icons/fa'
import {RiDriveLine, RiAppleLine, RiAppsLine, RiDeleteBinLine, RiContactsBook2Line, RiAccountCircleLine} from 'react-icons/ri'
import {AiOutlineAppstore, AiOutlineDelete, AiOutlineAudio, AiFillAudio} from 'react-icons/ai'
import {LuTimerOff, LuTimer, LuReply, LuForward, LuRepeat2} from 'react-icons/lu'
import {TbUserShare} from 'react-icons/tb'
import {IoDocumentTextOutline} from 'react-icons/io5'

// import {BiVoicemail, BiExpandHorizontal, BiArrowFromTop, BiLineChart} from 'react-icons/bi'
// import {ImEmbed2, ImAttachment} from 'react-icons/im'
// import {HiOutlineAtSymbol, HiOutlineOfficeBuilding, HiOutlinePresentationChartBar} from 'react-icons/hi'
// import {SiGmail, SiIntuit, SiAmazon, SiAmazonwebservices, SiMicrosoft, SiQuickbooks, SiTrello, SiAsana, SiFacebook, SiInstagram, SiLinkedin, SiAirtable, SiSalesforce, SiGoogledrive, SiDropbox, SiSlack, SiMicrosoftexcel, SiGoogle, SiMicrosoftoutlook, SiGooglesheets, SiMicrosoftword, SiAudiomack} from 'react-icons/si'
// import {MdPictureAsPdf, MdOutlinePermContactCalendar, MdPlaylistAdd, MdOutlineAccountBalanceWallet, MdOutlinePayments, MdAccountBalance, MdPersonalVideo, MdOutlineOndemandVideo} from 'react-icons/md'
// import {FaQuoteLeft, FaQuoteRight, FaShopify} from 'react-icons/fa'
// import {RiDriveLine} from 'react-icons/ri'
// import {AiOutlineAppstore, AiOutlineDelete, AiOutlineAudio, AiFillAudio} from 'react-icons/ai'
// import {LuTimerOff, LuTimer, LuReply, LuForward} from 'react-icons/lu'
// import {TbUserShare} from 'react-icons/tb'
// import {IoDocumentTextOutline} from 'react-icons/io5'

export const AudioIconFilled = ({size = 22, style = null, className}) => <AiFillAudio size={size} style={style} className={className} />
export const AudioIcon = ({size = 22, style = null, className}) => <AiOutlineAudio size={size} style={style} className={className} />
export const KanbanIcon = ({size = 22, style = null, className}) => <BsKanban size={size} style={style} className={className} />
export const WaveformIcon = ({size = 22, style = null, className}) => <SiAudiomack size={size} style={style} className={className} />
export const BackArrowIcon = ({size = 22, style = null, className}) => <FiArrowLeft size={size} style={style} className={className} />
export const BriefcaseIcon = ({size = 22, style = null, className}) => <FiBriefcase size={size} style={style} className={className} />
export const EditIcon = ({size = 20, style = null, className}) => <FiEdit3 size={size} style={style} className={className} />
export const TerminalIcon = ({size = 22, style = null, className}) => <FiTerminal size={size} style={style} className={className} />
export const LeftArrowOutlined = ({size = 22, style = null, className}) => <FiChevronLeft size={size} style={style} className={className} />
export const RightArrowOutlined = ({size = 22, style = null, className}) => <FiChevronRight size={size} style={style} className={className} />
export const DownArrowOutlined = ({size = 22, style = null, className}) => <FiChevronDown size={size} style={style} className={className} />
export const DownIcon = ({size = 22, style = null, className}) => <FiArrowDown size={size} style={style} className={className} />
export const DownFromLineIcon = ({size = 22, style = null, className}) => <BiArrowFromTop size={size} style={style} className={className} />
export const UpRightArrowOutlined = ({size = 22, style = null, className}) => <FiArrowUpRight size={size} style={style} className={className} />
export const UpArrowIcon = ({size = 22, style = null, className}) => <FiArrowUp size={size} style={style} className={className} />
export const UpChevron = ({size = 22, style = null, className}) => <FiChevronUp size={size} style={style} className={className} />
export const ReplyIcon = ({size = 22, style = null, className}) => <LuReply size={size} style={style} className={className} />
export const ForwardIcon = ({size = 22, style = null, className}) => <LuForward size={size} style={style} className={className} />
export const UserOutlined = ({size = 22, style = null, className}) => <FiUser size={size} style={style} className={className} />
export const AddUserOutlined = ({size = 22, style = null, className}) => <BiUserPlus size={size} style={style} className={className} />
export const AddUserCheckOutlined = ({size = 22, style = null, className}) => <BiUserCheck size={size} style={style} className={className} />
export const ContactsOutlinedIcon = ({size = 22, style = null, className}) => <RiContactsBook2Line size={size} style={style} className={className} />
export const HomeOutlined = ({size = 22, style = null, className}) => <FiHome size={size} style={style} className={className} />
export const MessageOutlined = ({size = 22, style = null, className}) => <FiMessageSquare size={size} style={style} className={className} />
export const InfoOutlined = ({size = 22, style = null, className}) => <FiInfo size={size} style={style} className={className} />
export const LinkOutlined = ({size = 22, color, style = null, className}) => <FiLink2 size={size} color={color} style={style} className={className} />
export const Brainstorm = ({size = 22, style = null, className}) => <BiBrain size={size} style={style} className={className} />
export const SendArrow = ({size = 22, style = null, className, color}) => <BiUpArrowAlt size={size} color={color} style={style} className={className} />
export const SaveOutlineIcon = ({size = 22, style = null, className}) => <BiBookmark size={size} style={style} className={className} />
export const SavedFilled = ({size = 22, style = null, className}) => <BsFillBookmarkFill size={size} style={style} className={className} />
export const HeartIconFilled = ({size = 22, style = null, className}) => <BsHeartFill size={size} style={style} className={className} />
export const HeartIcon = ({size = 22, style = null, className}) => <FiHeart size={size} style={style} className={className} />
export const XIcon = ({size = 22, style = null, className = ""}) => <FiX size={size} style={style} className={className} />
export const UploadIcon = ({size = 22, style = null, className}) => <FiUpload size={size} style={style} className={className} />
export const DatabaseIcon = ({size = 22, style = null, className}) => <FiDatabase size={size} style={style} className={className} />
export const SettingsIcon = ({size = 22, style = null, className}) => <FiSliders size={size} style={style} className={className} />
export const LayersIcon = ({size = 22, style = null, className}) => <FiLayers size={size} style={style} className={className} />
export const EmbedIcon = ({size = 22, style = null, className, color}) => <ImEmbed2 size={size} color={color} style={style} className={className} />
export const PieChartIcon = ({size = 22, style = null, className}) => <FiPieChart size={size} style={style} className={className} />
export const LineChartIcon = ({size = 22, style = null, className}) => <BiLineChart size={size} style={style} className={className} />
// export const LineAgentsIcon = ({size = 22, style = null, className}) => <RiAppleLine size={size} style={style} className={className} />
export const AddDocumentIcon = ({size = 22, style = null, className}) => <BiBookAdd size={size} style={style} className={className} />
export const CPUIcon = ({size = 22, style = null, className}) => <FiCpu size={size} style={style} className={className} />
export const GMailIcon = ({size = 22, style = null, className}) => <SiGmail size={size} style={style} className={className} />
export const SlackIcon = ({size = 22, style = null, className}) => <SiSlack size={size} style={style} className={className} />
export const FacebookIcon = ({size = 22, style = null, className}) => <SiFacebook size={size} style={style} className={className} />
export const InstagramIcon = ({size = 22, style = null, className}) => <SiInstagram size={size} style={style} className={className} />
export const LinkedInIcon = ({size = 22, style = null, className}) => <SiLinkedin size={size} style={style} className={className} />
export const AmazonIcon = ({size = 22, style = null, className}) => <SiAmazon size={size} style={style} className={className} />
export const AmazonAwsIcon = ({size = 22, style = null, className}) => <SiAmazonwebservices size={size} style={style} className={className} />
export const AirtableIcon = ({size = 22, style = null, className}) => <SiAirtable size={size} style={style} className={className} />
export const SalesforceIcon = ({size = 22, style = null, className}) => <SiSalesforce size={size} style={style} className={className} />
export const GDriveOutlineIcon = ({size = 22, style = null, className}) => <RiDriveLine size={size} style={style} className={className} />
export const GDriveIcon = ({size = 22, style = null, className}) => <SiGoogledrive size={size} style={style} className={className} />
export const GCalendarIcon = ({size = 22, style = null, className}) => <BsCalendarDate size={size} style={style} className={className} />
export const DropboxIcon = ({size = 22, style = null, className}) => <SiDropbox size={size} style={style} className={className} />
export const TrelloIcon = ({size = 22, style = null, className}) => <SiTrello size={size} style={style} className={className} />
export const AsanaIcon = ({size = 22, style = null, className}) => <SiAsana size={size} style={style} className={className} />
export const QuickbooksIcon = ({size = 22, style = null, className}) => <SiQuickbooks size={size} style={style} className={className} />
export const ExcelIcon = ({size = 22, style = null, className}) => <SiMicrosoftexcel size={size} style={style} className={className} />
export const PDFIcon = ({size = 22, style = null, className}) => <MdPictureAsPdf size={size} style={style} className={className} />
export const WebsiteIcon = ({size = 22, style = null, className}) => <BiPlanet size={size} style={style} className={className} />
export const GlobeIcon = ({size = 22, style = null, className}) => <FiGlobe size={size} style={style} className={className} />
export const QuoteLeftIcon = ({size = 20, style = null, className}) => <FaQuoteLeft size={size} style={style} className={className} />
export const QuoteRightIcon = ({size = 20, style = null, className}) => <FaQuoteRight size={size} style={style} className={className} />
export const GoogleIcon = ({size = 22, style = null, className}) => <SiGoogle size={size} style={style} className={className} />
export const AtOutlineIcon = ({size = 22, style = null, className}) => <HiOutlineAtSymbol size={size} style={style} className={className} />
export const DownloadOutlineIcon = ({size = 22, style = null, className}) => <FiDownload size={size} style={style} className={className} />
export const AppstoreIcon = ({size = 22, style = null, className}) => <RiAppsLine size={size} style={style} className={className} />
export const BusinessIcon = ({size = 22, style = null, className}) => <HiOutlineOfficeBuilding size={size} style={style} className={className} />
export const EmailIconOutlined = ({size = 22, style = null, className}) => <FiMail size={size} style={style} className={className} />
export const PaymentIcon = ({size = 20, style = null, className}) => <FiCreditCard size={size} style={style} className={className} />
export const AddItemIcon = ({size = 22, style = null, className}) => <MdPlaylistAdd size={size} style={style} className={className} />
export const OutlookIcon = ({size = 22, style = null, className}) => <SiMicrosoftoutlook size={size} style={style} className={className} />
export const AccountIcon = ({size = 22, style = null, className}) => <RiAccountCircleLine size={size} style={style} className={className} />
export const InvoiceIcon = ({size = 22, style = null, className}) => <MdOutlinePayments size={size} style={style} className={className} />
export const ShopifyIcon = ({size = 22, style = null, className}) => <FaShopify size={size} style={style} className={className} />
export const BankIcon = ({size = 22, style = null, className}) => <MdAccountBalance size={size} style={style} className={className} />
export const PhoneCallIcon = ({size = 22, style = null, className}) => <BiPhoneCall size={size} style={style} className={className} />
export const MicrosoftIcon = ({size = 22, style = null, className}) => <SiMicrosoft size={size} style={style} className={className} />
export const HashIcon = ({size = 22, style = null, className}) => <FiHash size={size} style={style} className={className} />
export const EyeIcon = ({size = 22, style = null, className}) => <FiEye size={size} style={style} className={className} />
export const CustomerIcon = ({size = 22, style = null, className}) => <BiPurchaseTag size={size} style={style} className={className} />
export const TodoIcon = ({size = 20, style = null, className}) => <FiCheckSquare size={size} style={style} className={className} />
export const UserTodoIcon = ({size = 24, style = null, className}) => <BiSolidUserDetail size={size} style={style} className={className} />
export const SpamIcon = ({size = 22, style = null, className}) => <FiSlash size={size} style={style} className={className} />
export const DeleteIcon = ({size = 22, style = null, className}) => <AiOutlineDelete size={size} style={style} className={className} />
export const PlusCircle = ({size = 22, style = null, className}) => <FiPlusCircle size={size} style={style} className={className} />
export const RepeatIcon = ({size = 22, style = null, className}) => <LuRepeat2 size={size} style={style} className={className} />
export const ShareIcon = ({size = 22, style = null, className}) => <TbUserShare size={size} style={style} className={className} />
export const AttachmentIcon = ({size = 18, style = null, className}) => <ImAttachment size={size} style={style} className={className} />
export const PlusIcon = ({size = 22, style = null, className}) => <FiPlus size={size} style={style} className={className} />
export const SearchIcon = ({size = 22, style = null, className}) => <FiSearch size={size} style={style} className={className} />
export const LikeIcon = ({size = 22, style = null, className}) => <FiThumbsUp size={size} style={style} className={className} />
export const DislikeIcon = ({size = 22, style = null, className}) => <FiThumbsDown size={size} style={style} className={className} />
export const ReminderIcon = ({size = 22, style = null, className}) => <LuTimer size={size} style={style} className={className} />
export const ReminderOffIcon = ({size = 22, style = null, className}) => <LuTimerOff size={size} style={style} className={className} />
export const CheckIcon = ({size = 22, style = null, className}) => <BsCheckLg size={size} style={style} className={className} />
export const CaptureIcon = ({size = 22, style = null, className}) => <MdPersonalVideo size={size} style={style} className={className} />
export const SubmenuIcon = ({size = 22, style = null, className}) => <BiDotsVerticalRounded size={size} style={style} className={className} />
export const LogoutIcon = ({size = 22, style = null, className}) => <FiLogOut size={size} style={style} className={className} />
export const MenuIcon = ({size = 22, style = null, className}) => <BiMenu size={size} style={style} className={className} />
export const FeatherIcon = ({size = 22, style = null, className}) => <FiFeather size={size} style={style} className={className} />
export const CopyIcon = ({size = 22, style = null, className}) => <BiCopy size={size} style={style} className={className} />
export const TruckIcon = ({size = 22, style = null, className}) => <FiTruck size={size} style={style} className={className} />
export const ShoppingCartIcon = ({size = 22, style = null, className}) => <FiShoppingCart size={size} style={style} className={className} />
export const BookIcon = ({size = 22, style = null, className}) => <FiBook size={size} style={style} className={className} />
export const FileIcon = ({size = 22, style = null, className}) => <FiFile size={size} style={style} className={className} />
export const HeadphonesIcon = ({size = 22, style = null, className}) => <FiHeadphones size={size} style={style} className={className} />
export const AlertIcon = ({size = 22, style = null, className}) => <FiAlertTriangle size={size} style={style} className={className} />
export const FlagIcon = ({size = 22, style = null, className}) => <FiFlag size={size} style={style} className={className} />
export const ClockIcon = ({size = 22, style = null, className}) => <FiClock size={size} style={style} className={className} />
export const RecordIcon = ({size = 24, style = null, className}) => <BiVoicemail size={size} style={style} className={className} />
export const LockIcon = ({size = 22, style = null, className}) => <FiLock size={size} style={style} className={className} />
export const GoogleSheetsIcon = ({size = 22, style = null, className}) => <SiGooglesheets size={size} style={style} className={className} />
export const ApplicationIcon = ({size = 22, style = null, className}) => <BsApp size={size} style={style} className={className} />
export const ImageIcon = ({size = 22, style = null, className}) => <FiImage size={size} style={style} className={className} />
export const VideoIcon = ({size = 22, style = null, className}) => <MdOutlineOndemandVideo size={size} style={style} className={className} />
export const DocumentIcon = ({size = 22, style = null, className}) => <IoDocumentTextOutline size={size} style={style} className={className} />
export const SlidesIcon = ({size = 22, style = null, className}) => <HiOutlinePresentationChartBar size={size} style={style} className={className} />
export const MicrosoftWordIcon = ({size = 22, style = null, className}) => <SiMicrosoftword size={size} style={style} className={className} />
export const ActivityIcon = ({size = 22, style = null, className}) => <FiActivity size={size} style={style} className={className} />
export const CalendarIcon = ({size = 22, style = null, className}) => <FiCalendar size={size} style={style} className={className} />
export const SpaceBarIcon = ({size = 22, style = null, className}) => <BiSpaceBar size={size} style={style} className={className} />
export const ExpandHorizontalIcon = ({size = 22, style = null, className}) => <BiExpandHorizontal size={size} style={style} className={className} />
export const IntuitIcon = ({size = 22, style = null, className}) => <SiIntuit size={size} style={style} className={className} />
export const StarIcon = ({size = 22, style = null, className}) => <FiStar size={size} style={style} className={className} />
export const InboxIcon = ({size = 22, style = null, className}) => <FiInbox size={size} style={style} className={className} />
export const PlayIcon = ({size = 22, style = null, className}) => <FiPlayCircle size={size} style={style} className={className} />
export const PauseIcon = ({size = 22, style = null, className}) => <FiPauseCircle size={size} style={style} className={className} />
export const MobilePhoneCheckIcon = ({size = 22, style = null, className}) => <MdOutlineMobileFriendly size={size} style={style} className={className} />
export const SidebarIcon = ({size = 22, style = null, className}) => <FiSidebar size={size} style={style} className={className} />
