import React from "react";
import {
  AccountIcon,
  AppstoreIcon, ContactsOutlinedIcon, EmailIconOutlined, FeatherIcon, HashIcon, HomeOutlined,
  InboxIcon, LayersIcon, LineChartIcon, LinkOutlined, MessageOutlined,
  PaymentIcon, PhoneCallIcon, StarIcon, TodoIcon, TruckIcon,
} from "@web/assets/icons";
import IconTip from "@ai-antd/components/IconTip";
import ServiceData from "@web/ui/containers/ServiceData";
import ContactActivityList from "@ai-antd/components/ContactActivityList";
import ThreadsList from "@ai-antd/components/ThreadsList";
import FileList from "@ai-antd/components/FileList";
import PaymentsList from "@ai-antd/components/PaymentsList";
import DeliveriesList from "@ai-antd/components/DeliveriesList";
import Datasets from "@ai-antd/components/Datasets";
import Analyst from "@ai-antd/page/Analyst";
// import dayjs from "dayjs";
import ContactExtra from "@ai-antd/components/ContactExtra";
// import LinesContent from "@ai-antd/components/LinesContent";
import MessengerContent from "@ai-antd/components/MessengerContent";
import AssistantContent from "@ai-antd/components/AssistantContent";
import TasksContent from "@ai-antd/components/TasksContent";
import CallsContent from "@ai-antd/components/CallsContent";
import AppsContent from "@ai-antd/components/AppsContent";
import LinksContent from "@ai-antd/components/LinksContent";
import ContactsContent from "@ai-antd/components/ContactsContent";
import ActivityContent from "@ai-antd/components/ActivityContent";
import TodayContent from "@ai-antd/components/TodayContent";
import ThreadsContent from "@ai-antd/components/ThreadsContent";
import FilesContent from "@ai-antd/components/FilesContent";
import PaymentsContent from "@ai-antd/components/PaymentsContent";
import DeliveriesContent from "@ai-antd/components/DeliveriesContent";
import LinesContent from "@ai-antd/components/LinesContent";
import ContactDetailsWrapper from "@ai-antd/components/ContactActivityWrapper";
// import MessengerInfoCard from "@ai-antd/components/MessengerInfoCard";
// import MessengerIdentity from "@ai-antd/components/MessengerIdentity";
import LineDetail from "@ai-antd/components/LineDetail";
import AssistantList from "@ai-antd/components/AssistantList";
import FeedList from "@ai-antd/components/FeedList";
import FeedContent from "@ai-antd/components/FeedContent";
import CreateContent from "@ai-antd/components/CreateContent";
// import Today from "@ai-antd/page/Today";
import DirectoryContent from "@ai-antd/components/DirectoryContent";
import LinesList from "@ai-antd/components/LinesList";
import SubsList from "@ai-antd/components/SubsList";
import SandboxContent from "@ai-antd/components/SandboxContent";
import AppContent from "@ai-antd/components/AppContent";
import ContactsList from "@ai-antd/components/ContactsList";

/** @typedef {String<('today'|'threads'|'calendars'|'activity'|'files'|'voice'|'website'|'payments'|'contacts'|'todos'|'trello'|'deliveries'|'workflow'|'chat'|'store'|'source'|'datasets'|'quote')>} dashboard.ServiceId */

/**
 * @typedef {Object} dashboard.SolutionsServiceConfig
 * @property {dashboard.ServiceId} key
 * @property {string} solutionId
 * @property {string} label
 * @property {string} path
 * @property {React.ReactNode} icon
 * @property {React.ReactNode} children
 * @property {React.ReactNode} search
 * @property {React.ReactNode} item
 * @property {string[]} sourceIds
 * @property {boolean} filter
 * @property {Object} chat
 * @property {string} chat.agentId
 * @property {string} chat.agentName
 * @property {function} chat.getCollectionPath
 */

/** @typedef {Object<dashboard.ServiceId, dashboard.SolutionsServiceConfig>} dashboard.SolutionsServicesConfig */
const services = {
  home: {
    key: "home",
    solutionId: 'activity',
    label: <span>Home</span>,
    path: "/home",
    icon: <HomeOutlined className="menu-icon"/>,
    closable: false,
    sourceIds: ["gmail", "outlook", "web"],
    children: ["feed", "assistant", "directory", "reminders"], // <ServiceData serviceId="activity" component={ContactActivityList} />,
    // item: ContactActivityWrapper,
    item: TodayContent,
    detail: ContactDetailsWrapper,
    extra: null, // ContactExtra,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getAgentId: (app) => {
        return app?.agentId || "ai-assistant";
      },
      getActions: async (user, {contact}) => {
        const prompts = [];
        switch (true) {
          case contact?.email &&
          (!contact.email.toLowerCase().includes("noreply") &&
            !contact.email.toLowerCase().includes("no_reply") &&
            !contact.email.toLowerCase().includes("no-reply")) &&
          contact.email !== user?.email: {
            prompts.push({
              text: "Send email",
            });
            break;
          }
          default:
            break;
        }
        return prompts;
      }
    },
  },
  feed: {
    key: "feed",
    solutionId: 'activity',
    label: <span>Feed</span>,
    path: "/feed",
    icon: <HomeOutlined className="menu-icon"/>,
    children: <ServiceData serviceId="assistant" component={FeedList} />,
    item: FeedContent,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item}) => { // todo - this should probably move to line/assistant coll
      //   return item?.path ?
      //     `${item.path}/${agentId}` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  assistant: {
    key: "assistant",
    solutionId: 'activity',
    label: <span>Assistant</span>,
    path: "/assistant",
    icon: <MessageOutlined className="menu-icon"/>,
    children: <ServiceData serviceId="assistant" component={AssistantList} />,
    item: AssistantContent,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item}) => { // todo - this should probably move to line/assistant coll
      //   return item?.path ?
      //     `${item.path}/${agentId}` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  tasks: {
    key: "tasks",
    solutionId: 'activity',
    label: <span>Tasks</span>,
    path: "/tasks",
    icon: <TodoIcon className="menu-icon"/>,
    children: null,
    item: TasksContent,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item}) => { // todo - this should probably move to line/assistant coll
      //   return item?.path ?
      //     `${item.path}/${agentId}` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  apps: {
    key: "apps",
    solutionId: 'activity',
    label: <span>Apps</span>,
    path: "/apps",
    icon: <AppstoreIcon className="menu-icon"/>,
    children: null, //<ServiceData serviceId="apps" component={AppsList} />,
    item: AppsContent,
    detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: false, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        // console.log("chat:getAgentId", app);
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item, app}) => { // todo - this should probably move to line/assistant coll
      //   if (app?.path) return `${app.path}/managers/${agentId}/ai-workflow`;
      //   return item?.path ?
      //     `${item.path}/managers/${agentId}/ai-workflow` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Help",
          agentId: "ai-line-apps-assistant",
        },{
          text: "Run this app",
          agentId: "ai-line-apps-assistant",
        }];
      }
    },
  },
  create: {
    key: "create",
    solutionId: 'activity',
    label: <span>Create</span>,
    path: "/apps/create",
    icon: <AppstoreIcon className="menu-icon"/>,
    children: null, //<ServiceData serviceId="apps" component={AppsList} />,
    item: CreateContent,
    sourceIds: [],
    filter: {desktop: false, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        console.log("chat:getAgentId", app);
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item, app}) => { // todo - this should probably move to line/assistant coll
      //   if (app?.path) return `${app.path}/managers/${agentId}/ai-workflow`;
      //   return item?.path ?
      //     `${item.path}/managers/${agentId}/ai-workflow` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Help",
          agentId: "ai-line-apps-assistant",
        },{
          text: "Run this app",
          agentId: "ai-line-apps-assistant",
        }];
      }
    },
  },
  app: {
    key: "app",
    solutionId: 'activity',
    label: <span>App</span>,
    path: "/apps/:appId",
    icon: <AppstoreIcon className="menu-icon"/>,
    children: null,
    item: AppContent,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-app-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        // console.log("chat:getAgentId", app);
        return app?.agentId || "ai-line-app-assistant";
      },
      // getCollectionPath: ({user, agentId, item, app}) => { // todo - this should probably move to line/assistant coll
      //   if (app?.path) return `${app.path}/managers/${agentId}/ai-workflow`;
      //   return item?.path ?
      //     `${item.path}/managers/${agentId}/ai-workflow` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Help",
          agentId: "ai-line-apps-assistant",
        },{
          text: "Run this app",
          agentId: "ai-line-apps-assistant",
        }];
      }
    },
  },
  sandbox: {
    key: "sandbox",
    solutionId: 'activity',
    label: <span>Sandbox</span>,
    path: "/sandbox/:appId",
    icon: <AppstoreIcon className="menu-icon"/>,
    children: null, //<ServiceData serviceId="apps" component={AppsList} />,
    item: SandboxContent,
    // detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-app-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        // console.log("chat:getAgentId", app);
        return app?.agentId || "ai-line-app-assistant";
      },
      // getCollectionPath: ({user, agentId, item, app}) => { // todo - this should probably move to line/assistant coll
      //   if (app?.path) return `${app.path}/managers/${agentId}/ai-workflow`;
      //   return item?.path ?
      //     `${item.path}/managers/${agentId}/ai-workflow` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Help",
          agentId: "ai-line-apps-assistant",
        },{
          text: "Run this app",
          agentId: "ai-line-apps-assistant",
        }];
      }
    },
  },
  store: {
    key: "store",
    solutionId: 'activity',
    label: <span>Store</span>,
    path: "/apps/store",
    icon: <AppstoreIcon className="menu-icon"/>,
    children: null, //<ServiceData serviceId="apps" component={AppsList} />,
    item: AppsContent,
    detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: false, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        console.log("chat:getAgentId", app);
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item, app}) => { // todo - this should probably move to line/assistant coll
      //   if (app?.path) return `${app.path}/managers/${agentId}/ai-workflow`;
      //   return item?.path ?
      //     `${item.path}/managers/${agentId}/ai-workflow` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Help",
          agentId: "ai-line-apps-assistant",
        },{
          text: "Run this app",
          agentId: "ai-line-apps-assistant",
        }];
      }
    },
  },
  calls:  {
    key: "calls",
    solutionId: 'activity',
    label: <span>Calls</span>,
    path: "/calls",
    icon: <PhoneCallIcon className="menu-icon"/>,
    children: null, //<ServiceData serviceId="subscribed" component={SubsList} />,
    item: CallsContent,
    detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item}) => { // todo - this should probably move to line/assistant coll
      //   return item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  contacts: {
    key: "contacts",
    solutionId: 'activity',
    label: <span>Contacts</span>,
    path: "/contacts",
    icon: <ContactsOutlinedIcon className="menu-icon"/>,
    children: <ServiceData serviceId="contacts" component={ContactsList} />,
    item: ContactsContent,
    detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item}) => { // todo - this should probably move to line/assistant coll
      //   return item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  links: {
    key: "links",
    solutionId: 'activity',
    label: <span>Links</span>,
    path: "/link",
    icon: <LinkOutlined className="menu-icon"/>,
    children: null, //<ServiceData serviceId="apps" component={AppsList} />,
    item: LinksContent,
    detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      // getCollectionPath: ({user, agentId, item}) => { // todo - this should probably move to line/assistant coll
      //   return item?.path ?
      //     `${item.path}/${agentId}` :
      //     `user/${user.uid}/managers/${agentId}/ai-workflow`;
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "Help",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  lines: {
    key: "lines",
    solutionId: 'activity',
    label: <span>Lines</span>,
    path: "/lines",
    icon: <StarIcon className="menu-icon"/>,
    children: ["directory", "calls", "subscriptions", "subscribers"], // <ServiceData serviceId="subscribed" component={SubsList} />,
    item: TodayContent,
    // detail: LineDetail,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  directory: {
    key: "directory",
    solutionId: 'activity',
    label: <span>Public Directory</span>,
    path: "/directory",
    icon: <StarIcon className="menu-icon"/>,
    children: <ServiceData serviceId="directory" component={LinesList} />,
    item: DirectoryContent,
    detail: LineDetail,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  subscriptions: {
    key: "subscriptions",
    solutionId: 'activity',
    label: <span>Subscriptions</span>,
    path: "/subscriptions",
    icon: <AccountIcon className="menu-icon"/>,
    children: <ServiceData serviceId="subscriptions" component={SubsList} />,
    item: LinesContent,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  subscribers: {
    key: "subscribers",
    solutionId: 'activity',
    label: <span>Subscribers</span>,
    path: "/subscribers",
    icon: <AccountIcon className="menu-icon"/>,
    children: <ServiceData serviceId="subscribers" component={SubsList} />,
    item: LinesContent,
    detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-assistant",
      agentName: "Assistant",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-assistant";
      },
      getActions: async (user, {path}) => {
        return [{
          text: "Call me",
          agentId: "ai-line-assistant",
        }];
      }
    },
  },
  messenger: {
    key: "messenger",
    solutionId: 'activity',
    label: <span>Messenger</span>,
    path: "/messenger",
    icon: <FeatherIcon className="menu-icon"/>,
    children: null, // <ChatPage />,
    item: MessengerContent,
    detail: ContactDetailsWrapper,
    sourceIds: [],
    filter: {desktop: true, active: true, mobile: true},
    chat: {
      agentId: "ai-line-messenger",
      agentName: "Messenger",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-line-messenger";
      },
      // getCollectionPath: ({user, messenger, claims}) => {
      //   const basePath = `user/${user.uid}/lines/messenger`;
      //   if (!messenger || messenger === claims?.data?._h) {
      //     return [basePath, "ai-workflow"].join("/");
      //   }
      //   return [basePath, "subscriber", messenger, "ai-workflow"].join("/");
      // },
      // getContext: (item) => {
      //   console.log("chat:getContext", item);
      //   return {
      //     path: item?.path,
      //     label: "Chat",
      //     // title: item?.file?.other?.displayName || item?.file?.originFilename,
      //   };
      // },
      getActions: async (user, {path}) => {
        return [{
          text: "latest topics",
          agentId: "ai-line-messenger",
        }];
      }
    },
  },
  activity: {
    key: "activity",
    solutionId: 'activity',
    label: <span>Activity</span>,
    path: "/activity",
    icon: <InboxIcon className="menu-icon"/>,
    closable: false,
    sourceIds: ["gmail", "outlook", "web"],
    children: <ServiceData serviceId="activity" component={ContactActivityList} />,
    // item: ContactActivityWrapper,
    item: ActivityContent,
    detail: ContactDetailsWrapper,
    extra: null, // ContactExtra,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getAgentId: (app) => {
        return app?.agentId || "ai-assistant";
      },
      getActions: async (user, {contact}) => {
        const prompts = [];
        switch (true) {
          case contact?.email &&
          (!contact.email.toLowerCase().includes("noreply") &&
            !contact.email.toLowerCase().includes("no_reply") &&
            !contact.email.toLowerCase().includes("no-reply")) &&
          contact.email !== user?.email: {
            prompts.push({
              text: "Send email",
            });
            break;
          }
          default:
            break;
        }
        return prompts;
      }
    },
  },
  emails: {
    key: "emails",
    solutionId: 'activity',
    label: <span>Email</span>,
    path: "/emails",
    icon: <InboxIcon className="menu-icon"/>,
    closable: false,
    sourceIds: ["gmail", "outlook", "web"],
    children: ["activity", "threads", "files", "contacts", "payments", "deliveries"], // <ServiceData serviceId="activity" component={ContactActivityList} />,
    item: TodayContent,
    detail: ContactDetailsWrapper,
    extra: null, // ContactExtra,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getAgentId: (app) => {
        return app?.agentId || "ai-assistant";
      },
      getActions: async (user, {contact}) => {
        const prompts = [];
        switch (true) {
          case contact?.email &&
          (!contact.email.toLowerCase().includes("noreply") &&
            !contact.email.toLowerCase().includes("no_reply") &&
            !contact.email.toLowerCase().includes("no-reply")) &&
          contact.email !== user?.email: {
            prompts.push({
              text: "Send email",
            });
            break;
          }
          default:
            break;
        }
        return prompts;
      }
    },
  },
  // progress: {
  //   key: "progress",
  //   solutionId: 'activity',
  //   label: <IconTip key="act" title="Tasks" placement="right">
  //     {/*<div><TodoIcon className="menu-icon"/></div>*/}
  //     <span>Tasks</span>
  //   </IconTip>,
  //   title: "Progress",
  //   path: "/progress",
  //   icon: <UserOutlined className="menu-icon"/>,
  //   sourceIds: [],
  //   children: <ServiceData serviceId="progress" component={TaskList} />,
  //   item: ContentDetailWrapper,
  //   extra: ContactExtra,
  //   filter: {active: true, desktop: true, mobile: false},
  //   chat: {
  //     agentId: "ai-progress",
  //     agentName: "Assistant",
  //     getCollectionPath: ({user, agentId, item}) =>
  //       item?.path ?
  //         `${item.path}/managers/${agentId}/ai-workflow` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       return {
  //         path: item?.path,
  //         label: "Contact",
  //       };
  //     },
  //     getActions: async (user) => {
  //       return [{
  //         text: "Call me",
  //         agentId: "ai-assistant",
  //       }];
  //     }
  //   },
  // },
  threads: {
    key: "threads",
    solutionId: "activity",
    label: <span>Email</span>,
    path: "/threads",
    icon: <EmailIconOutlined className="menu-icon" />,
    children: <ServiceData serviceId="threads" component={ThreadsList} />,
    item: ThreadsContent,
    detail: ContactDetailsWrapper,
    extra: null,
    sourceIds: ["gmail", "outlook"],
    filter: {active: true, desktop: true, mobile: true},
    overlayChat: true,
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getAgentId: (app) => {
        return app?.agentId || "ai-assistant";
      },
      getActions: async (user, {message}) => {
        const actions = [];
        if (!message) {
          return actions;
        }
        if (!message.from) {
          return actions;
        }
        switch (true) {
          case message.from?.email &&
          ((!message.from.email.toLowerCase().includes("noreply") &&
              !message.from.email.toLowerCase().includes("no_reply") &&
              !message.from.email.toLowerCase().includes("no-reply")) ||
            message.replyTo?.email) &&
          message.from.email !== user?.email: {
            actions.push({
              text: "Reply",
              agentId: "ai-email-assistant",
            });
            break;
          }
          default:
            break;
        }
        actions.push({
          text: "Forward",
          agentId: "ai-email-assistant",
        });
        actions.push({
          text: "summarize email",
          agentId: "ai-assistant",
        });
        return actions;
      },
    },
  },
  // calendars: {
  //   key: "calendars",
  //   solutionId: 'activity',
  //   label: <IconTip key="calendar" title="calendar" placement="right">
  //     <div><CalendarIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/calendar",
  //   icon: <CalendarIcon className="menu-icon"/>,
  //   children: <CalendarData key="calendar" component={CalendarEventsList}/>,
  //   item: CalendarEventDetailWrapper,
  //   sourceIds: ["gmail", "outlook", "calendar"],
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: ({user, agentId, item}) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("calendars:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Calendar",
  //         // title: item.data.name,
  //       };
  //     },
  //   }
  // },
  // activity: {
  //   key: "activity",
  //   solutionId: 'activity',
  //   label: <IconTip key="feed" title="Feed" placement="right">
  //     <div><HashIcon/></div>
  //   </IconTip>,
  //   path: "/activity",
  //   icon: <Icon name="ActivityIcon" className="menu-icon"/>,
  //   children: null, // <FeedData key="feed"><EventsList/></FeedData>,
  //   sourceIds: [],
  //   item: ActivityDetailsWrapper,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: ({user, agentId, item}) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("activity:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Activity",
  //         // title: item.data.name,
  //       };
  //     },
  //   },
  // },
  files: {
    key: "files",
    solutionId: 'activity',
    label: <span>Files</span>,
    path: "/files",
    icon: <LayersIcon className="menu-icon"/>,
    sourceIds: ["drive", "files", "upload", "sheets"],
    children: <ServiceData serviceId="files" component={FileList} />,
    // item: FileDetailsWrapper,
    item: FilesContent,
    detail: ContactDetailsWrapper,
    extra: ContactExtra,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getAgentId: (app) => {
        return app?.agentId || "ai-assistant";
      },
      getActions: async (user, {file}) => {
        if (!file) {
          return [];
        }
        if (file.alert) {
          return [];
        }
        const contentType = file.mimeType.split("/")[0].toLowerCase();
        const actions = [];
        switch (true) {
          case contentType === "audio": {
            if (!file.transcriptPath) {
              actions.push({
                text: "transcribe audio",
                agentId: "ai-audio-manager",
              });
            }
            if (file.transcriptPath && file.transcriptTs) { // transcript is available and ready
              actions.push({
                text: "search transcript",
                agentId: "ai-audio-manager",
              });
            }
            break;
          }
          case file.mimeType === "application/pdf":
            actions.push({
              text: "summarize document",
            });
            break;
          case file.mimeType === "application/json":
          case file.mimeType === "text/csv":
            if (!file.datasetPath) {
              actions.push({
                text: "Create dataset for analysis",
                agentId: "ai-analyst",
              });
            }
            break;
          default:
            break;
        }
        return actions;
      }
    },
  },
  payments: {
    key: "payments",
    solutionId: 'activity',
    label: <span>Payments</span>,
    path: "/payments",
    icon: <PaymentIcon className="menu-icon"/>,
    children: <ServiceData serviceId="payments" component={PaymentsList} />,
    sourceIds: ["stripe", "spreadsheet", "theScribe"],
    // item: PaymentDetail,
    item: PaymentsContent,
    detail: ContactDetailsWrapper,
    extra: null, //ContactExtra,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getAgentId: (app) => {
        return app?.agentId || "ai-assistant";
      },
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "About payments",
          agentId: "ai-assistant",
        });
        return prompts;
      }
    },
  },
  // todos: {
  //   key: "todos",
  //   solutionId: 'activity',
  //   label: <IconTip key="todos" title="Todos" placement="right">
  //     <div><TodoIcon size={20}/></div>
  //   </IconTip>,
  //   path: "/todos",
  //   icon: <TodoIcon className="menu-icon" size={24}/>,
  //   children: <TodosData component={TodosList} />,
  //   sourceIds: ["trello", "asana", "gmail", "outlook", "voice"],
  //   item: TodoDetailWrapper,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Assistant",
  //     getCollectionPath: (user, agentId, item) =>
  //       item?.path ?
  //         `${item.path}/${agentId}` :
  //         `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("todos:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Todo",
  //         // title: item?.file?.other?.displayName || item?.file?.originFilename,
  //       };
  //     },
  //   },
  // },
  // trello: {
  //   key: "trello",
  //   solutionId: 'activity',
  //   label: <IconTip key="trello" title="Trell" placement="left">
  //     <div><TrelloIcon size={20}/></div>
  //   </IconTip>,
  //   path: "/trello",
  //   icon: <TrelloIcon className="menu-icon" size={24}/>,
  //   children: <TasksList list={[]} title="Projects" />,
  //   sourceIds: ["trello"],
  //   item: TrelloDetailWrapper,
  //   filter: {active: false},
  // },
  deliveries: {
    key: "deliveries",
    solutionId: 'activity',
    label: <IconTip key="shipping" title="Shipping" placement="right">
      <span>Deliveries</span>
    </IconTip>,
    path: "/deliveries",
    icon: <TruckIcon className="menu-icon" />,
    children: <ServiceData serviceId="deliveries" component={DeliveriesList} />,
    sourceIds: ["shipping"],
    item: DeliveriesContent,
    detail: ContactDetailsWrapper,
    extra: null, //ContactExtra,
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-assistant",
      agentName: "Assistant",
      getAgentId: (app) => {
        return app?.agentId || "ai-assistant";
      },
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "today's deliveries",
          agentId: "ai-assistant",
        });
        return prompts;
      }
    },
  },
  // automations: {
  //   key: "automations",
  //   solutionId: 'activity',
  //   label: <IconTip key="add" title="Automations" placement="right">
  //     <div><CPUIcon size={22} style={{position: "relative", top: "1px"}}/></div>
  //   </IconTip>,
  //   path: "/automations",
  //   icon: <CPUIcon size={22} className="menu-icon"/>,
  //   children: null, // <FeatureList/>,
  //   sourceIds: [],
  //   item: null,
  //   filter: {active: false, desktop: true},
  //   chat: {
  //     agentId: "ai-automations-manager",
  //     agentName: "Manager",
  //     getCollectionPath: ({user, agentId, item}) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("automations:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Automation",
  //         // title: item?.file?.other?.displayName || item?.file?.originFilename,
  //       };
  //     },
  //     getActions: async () => {
  //       return [];
  //     }
  //   },
  // },
  // stores: {
  //   key: "stores",
  //   solutionId: 'analyst',
  //   label: <IconTip key="products" title="Store" placement="right">
  //     <div><CustomerIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/store",
  //   icon: <CustomerIcon className="menu-icon"/>,
  //   children: <ProductsList />,
  //   sourceIds: [],
  //   item: null,
  //   filter: {active: true, desktop: true},
  //   chat: {
  //     agentId: "ai-assistant",
  //     agentName: "Analyst",
  //     getCollectionPath: ({user, agentId, item}) => item?.path ? `${item.path}/${agentId}` : `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("stores:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Store",
  //         // title: item?.file?.other?.displayName || item?.file?.originFilename,
  //       };
  //     },
  //   },
  // },
  // web: {
  //   key: "web",
  //   solutionId: 'analyst',
  //   label: <IconTip key="web" title="Web" placement="right">
  //     <span>Web</span>
  //   </IconTip>,
  //   path: "/web",
  //   icon: <GlobeIcon className="menu-icon"/>,
  //   children: null,
  //   sourceIds: [],
  //   item: null,
  //   filter: {active: true, desktop: true},
  //   chat: {
  //     agentId: "ai-analyst",
  //     agentName: "Analyst",
  //     getAgentId: (app) => {
  //       return app?.agentId || "ai-analyst";
  //     },
  //     getActions: async () => {
  //       const prompts = [];
  //       prompts.push({
  //         text: "explore a website",
  //         agentId: "ai-analyst",
  //       });
  //       prompts.push({
  //         text: "search the internet",
  //         agentId: "ai-analyst",
  //       });
  //       return prompts;
  //     }
  //   },
  // },
  analyst: {
    key: "analyst",
    solutionId: 'activity',
    label: <IconTip key="analyst" title="Analyst" placement="right">
      <span>Analyst</span>
    </IconTip>,
    path: "/analyst",
    icon: <LineChartIcon className="menu-icon"/>,
    // children: <Datasets />,
    children: ["datasets", "twitter-search-basic-data"],
    item: TodayContent,
    // item: Analyst,
    detail: Analyst,
    sourceIds: [],
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-analyst",
      agentName: "Analyst",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-analyst";
      },
      getActions: async (user, {data}) => {
        if (!data?.questions) return [];
        return data.questions.map(({question: text}) => {
          return {text};
        });
      }
    },
  },
  datasets: {
    key: "datasets",
    solutionId: 'activity',
    label: <IconTip key="analyst" title="Analyst" placement="right">
      <span>Datasets</span>
    </IconTip>,
    path: "/analyst",
    icon: <LineChartIcon className="menu-icon"/>,
    children: <Analyst path="user/roHpDlqYSeRAy0xfV8sjgVsFLG33/datasets/wCzJ62pdvpM1sUujL5Y3" />,
    // children: ["twitter-search-basic-data", "threads", "files", "contacts", "payments", "deliveries"],
    // item: TodayContent,
    item: Analyst,
    detail: Analyst,
    sourceIds: [],
    filter: {active: true, desktop: true, mobile: true},
    chat: {
      agentId: "ai-analyst",
      agentName: "Analyst",
      overlayChat: true,
      getAgentId: (app) => {
        return app?.agentId || "ai-analyst";
      },
      getActions: async (user, {data}) => {
        if (!data?.questions) return [];
        return data.questions.map(({question: text}) => {
          return {text};
        });
      }
    },
  },
  // forms: { // Will be handled under datasets and automations
  //   key: "forms",
  //   solutionId: 'workflows',
  //   label: <IconTip key="quotes" title="Forms" placement="right">
  //     <div><InboxIcon className="menu-icon"/></div>
  //   </IconTip>,
  //   path: "/forms",
  //   icon: <InboxIcon className="menu-icon"/>,
  //   children: null, // <QuotesList/>,
  //   sourceIds: [],
  //   item: null,
  //   filter: {active: false},
  //   chat: {
  //     agentId: "ai-manager",
  //     agentName: "Manager",
  //     getCollectionPath: ({user, agentId}) => `user/${user.uid}/managers/${agentId}/ai-workflow`,
  //     getContext: (item) => {
  //       console.log("quotes:getContext", item);
  //       return {
  //         path: item?.path,
  //         label: "Form",
  //       };
  //     },
  //   },
  // },
};

/** @typedef {('activity'|'analyst'|'product'|'workflows')} dashboard.SolutionId */

/**
 * @typedef {object} dashboard.SolutionConfig
 * @property {dashboard.SolutionId} key
 * @property {string} label
 * @property {string} agentName
 * @property {string} defaultService
 */

/** @typedef {Object<dashboard.SolutionId, dashboard.SolutionConfig>} dashboard.solutionsConfig */
const solutions = {
  activity: {
    key: "activity",
    label: "Activity",
    agentName: "Assistant",
    defaultService: "activity",
    chat: {
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "summarize my latest activity",
        });
        return prompts;
      }
    },
  },
  analyst: {
    key: "analyst",
    label: "Analyst",
    agentName: "Analyst",
    defaultService: "datasets",
    chat: {
      getActions: async () => {
        const prompts = [];
        prompts.push({
          text: "About Analyst",
        });
        prompts.push({
          text: "Analyze dataset",
        });
        prompts.push({
          text: "Explore a website",
        });
        prompts.push({
          text: "search the internet",
        });
        return prompts;
      }
    },
  },
  lines: {
    key: "lines",
    label: "Lines",
    agentName: "Assistant",
    defaultService: "subscribed",
    path: "/lines",
    chat: {
      getActions: async (userRecord) => {
        // console.log("lines:get-actions:", userRecord);
        const prompts = [];
        prompts.push({text: "explore"});
        prompts.push({text: "call me"});
        prompts.push({text: "introductions", agentId: "ai-line-assistant"});
        return prompts;
      }
    },
  }
}

export const getSolutions = () => solutions;
export const getServices = () => services;

/**
 * @summary Chat App Agents
 * @description These agentIds are handled by chat apps and excluded from the primary stream (chat feedback)
 * @type {{emailWriter: {agentId: string}}}
 */
export const chatAppAgents = {
  emailWriter: {
    agentIds: ["ai-email-body-writer"], // ai-email-body-writer
  },
  progressViewer: {
    agentIds: ["ai-calls-manager"],
  }
  // userTaskProgress: {
  //   agentIds: ["ai-progress"],
  // },
}

export const chatAppAgentIds =
  Object.values(chatAppAgents)
  .map(({agentIds = []}) => agentIds)
  .flat();
