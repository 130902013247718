import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Space, Tag, Tooltip} from "antd";
import {
  AddUserOutlined,
  CheckIcon,
  ClockIcon, ContactsOutlinedIcon,
  DownArrowOutlined,
  DownIcon, EmailIconOutlined, GMailIcon,
  ReminderIcon,
  RightArrowOutlined, UserOutlined,
  XIcon
} from "@web/assets/icons";
import appActions from "@web/redux/app/actions";
import Markdown from "./Markdown";
import {AppContext, WindowContext} from "@web/hooks/context";
import StatusActions from "./StatusActions";
import TimeAgo from "@web/ui/components/TimeAgo";
import now from "lodash/now";
import MarkdownTimeline from "./MarkdownTimeline";
import UserAvatar from "./UserAvatar";

TodoCard.propTypes = {
  eventId: PropTypes.string.isRequired,
  event: PropTypes.object,
  // path: PropTypes.string.isRequired,
};

function TodoCard(props) {
  // const todoId = props.todoId || props.path?.split("/")?.[3];
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const event = useSelector(state => state.Content.events[props.eventId]);
  const [isMobile] = React.useContext(WindowContext);
  const setActiveDetail = useCallback((params, show) => dispatch(appActions.setActiveDetail(params, show)), [dispatch]);
  const showDetail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveDetail({eventId: props.eventId, path: props.path}, true);
    return false;
  }
  if (!props.event && !event) {
    return null;
  }

  const {userActions = {}, todoTags = {}, dueDate, todo: text, todoSummary, todoGoal = text, goal = todoGoal, summary = todoSummary, subject, senderEmail} = props.todo || todo;
  // console.log('todo', props.todo, todo);
  return <Card className="card-rounded card-simple ant-card-body" bordered={false} hoverable onClick={showDetail}>
    <div style={{position: 'relative'}} className="space space-col space-between">
      <div style={{height: '200px', overflow: isMobile ? "hidden" : "auto", paddingBottom: '30px'}}>
        <div style={{width: '100%'}}>
          <span style={{display: "inline-block", marginLeft: "0"}}>{
            (todoTags?.due && dueDate > now()) ?
              todoTags?.due :
              dueDate && <TimeAgo date={dueDate}/> || "As needed"}
          </span>
          <h3 className="todo-card-title ai-text">{summary || goal}</h3>
          <div className="space space-center-y"><EmailIconOutlined className="muted-icon" style={{minWidth: '20px'}} /><span
            className="muted line-clamp-1">{subject?.replace("Re:", "")?.replace("Fwd:", "")?.replace("FW:", "")?.replace("RE:", "")?.trim()}</span>
          </div>
          {/*<UserAvatar size="30px" displayName={senderEmail?.split('@')?.[0]} email={senderEmail} />*/}
          <div className="space space-center-y">
            <UserOutlined className="muted-icon" style={{minWidth: '20px'}} />
            <span className="muted">{senderEmail?.split("@")?.[0]}</span>
          </div>
        </div>
        </div>
        <h6 style={{position: "absolute", bottom: "-12px", left: "0", right: "0", paddingTop: "6px"}}>
        <div className="space space-center-y space-between">
          <div/>
          <StatusActions
            path={["user", user.uid, "todos", todoId].join("/")}
            itemId={todoId}
            initialStatus={todo?.status}
            iconClassName="ai-text"
            actions={['done', 'dismiss']}
          />
        </div>
      </h6>
    </div>
  </Card>
}

export default TodoCard;
