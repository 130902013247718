import React from 'react';
import {Button, Drawer} from 'antd'
import {colls, updateDocument} from '@web/lib/firestore.db.js'
import now from 'lodash/now'
import {notifyAndTrack} from './notification'
import {AppContext, WindowContext} from '@web/hooks/context'
import useDocumentFirestore from '@web/hooks/useDocumentFirestore'
import PriceTable from './PriceTable'
import PageDrawer from '@ai-antd/page/PageDrawer'
import HotClick from '@web/ui/containers/HotClick'

function ScribePublishButton({scribeId, text = "Publish", block, type = "primary", size="default"}) {
  const [isMobile] = React.useContext(WindowContext)
  const [scribe = {}, loading = false, error = false] = useDocumentFirestore(colls["ai-scribe"], scribeId)
  const [showPriceTable, setShowPriceTable] = React.useState(false)
  if (!scribeId) return null;
  if (!scribe) return null;
  const onClickActivate = () => {
    setShowPriceTable(!showPriceTable)
  }
  const onCloseDrawer = () => {
    setShowPriceTable(false)
  }
  const onClickPublish = () => {
    updateDocument(colls["ai-scribe"], scribeId, {
      publishTs: now(),
    }).then(() => notifyAndTrack.success("success", "Your Scribe is now published."))
  }
  return <>
    {scribe.subscriptionId ?
      <HotClick><Button onClick={onClickPublish} block={block} type={type} size={size}>Get Code</Button></HotClick> :
      <HotClick><Button type={type} size={size} onClick={onClickActivate} block={block}>{isMobile ? text : showPriceTable ? "Cancel" : text}</Button></HotClick>}
    {showPriceTable &&
      <PageDrawer noNav onClose={onCloseDrawer} noProfile>
        <PriceTable scope={[1, 2, 3]} />
      </PageDrawer>}
  </>
}

export default ScribePublishButton;
