import React, {useContext} from "react";
import Pager from "@web/ui/containers/Pager";
import Loading from "./Loading";
import LineConnector from "./LineConnector";
import {Card, Empty, List} from "antd";
import GroupTitle from "./GroupTitle";
import contentActions from "@web/redux/content/actions";
import {useDispatch, useSelector} from "react-redux";
import FilteredContentListItem from "./FilteredContentListItem";
import {WindowContext} from "@web/hooks/context";

function FilteredContentList() {
  const dispatch = useDispatch();
  const [,, height] = useContext(WindowContext);

  const itemsByRelevance = useSelector((state) =>
    state.Content.activity?.filter?.itemsByRelevance);

  const serverHits = useSelector((state) =>
    state.Content.activity?.filter?.serverHits);

  const currentPage = useSelector((state) =>
    state.Content.activity?.filter?.page);

  console.log("FilteredContentList:", itemsByRelevance);

  const [
    loadingPage,
    setLoadingPage,
  ] = React.useState(currentPage ?? 1);

  const pageNext = React.useCallback(() => {
    console.log("pageNext:", {loadingPage, currentPage});
    if (currentPage === undefined) return;
    const pageNext = currentPage + 1;
    if (loadingPage === pageNext) return;
    setLoadingPage(pageNext);
    dispatch(contentActions.pageFilteredNext());
  },
  [dispatch, loadingPage, currentPage]);

  console.log("FilteredContentList:", {
    hasMore: (serverHits > (itemsByRelevance?.length ?? 0)),
    length: itemsByRelevance?.length ?? 0,
  });

  return <div
    id="filtered-list-paging-wrapper"
    className="paging-list-wrapper"
    style={{
      height: height - 3,
      overflow: 'auto',
      // display: 'flex', flexDirection: 'column',
    }}
  >
    <Pager
      height={height - 3}
      loader={<Loading/>}
      length={itemsByRelevance?.length || 0}
      hasMore={
        !!itemsByRelevance?.length &&
        (serverHits > itemsByRelevance.length)
      }
      endMessage={
        !!itemsByRelevance?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px", left: "84px"}}
        >
          <h6 className="muted">End</h6>
          &nbsp;
          <LineConnector/>
        </div>
      }
      pageNext={pageNext}
      scrollContainerId="filtered-list-paging-wrapper"
    >
      <div
        style={{
          marginLeft: '64px',
          paddingLeft: '20px',
          borderLeft: "solid 1px rgba(255, 255, 255, 0.1)",
          boxSizing: "border-box",
        }}
      >
        <List
          header={
            <Card
              bordered={false}
              style={{width: "100%", margin: "0", height: 70}}
              className="card-simple card-rounded card-no-header card-transparent card-no-separator card-slim-title"
            ></Card>
          }
          className="list-empty-no-padding list-item-no-divider"
          dataSource={itemsByRelevance}
          renderItem={(item) => <FilteredContentListItem
            key={item[0]}
            path={item[0]}
            date={item[1]}
            filterMatch={item[2]}
          />
          }
          locale={{
            emptyText: <Empty
              className="add-integrations-empty-wrapper"
              description={<span>3 or more words to filter</span>}
              image={null}
              imageStyle={{display: "none"}}
              style={{maxWidth: "600px"}}
            >
            </Empty>
          }}
        />
      </div>
    </Pager>
  </div>;
}

export default FilteredContentList;
