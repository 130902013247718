import React from 'react';
import {Button, Card, Typography} from 'antd'
import FeedbackModal from './FeedbackModal'

function PriceCardFallback() {
  const [cta, setCTA] = React.useState("Send instant message")
  const product = {}
  return <Card
    title={<Typography.Title style={{paddingLeft: '15px'}} level={5}>Product Info</Typography.Title>} bordered
  >
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '0 15px'}}>
      <Typography.Paragraph type="secondary">{product?.description}</Typography.Paragraph>
      <div>
        <div className="space space-center-y mb-12">
          <Typography className="muted">Please get in touch for product and pricing details.</Typography>
        </div>
        <FeedbackModal onSend={() => setCTA("Thank you!")}>
          <Button block type="primary" size="large">
            {cta}
          </Button>
        </FeedbackModal>
      </div>
    </div>
  </Card>
}

export default PriceCardFallback;
