import {Avatar, Button, Card, List, notification} from "antd";
import React from 'react'
import Icon from './Icon'
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";
import {
  AppstoreIcon, ContactsOutlinedIcon, EmailIconOutlined, GlobeIcon, HomeOutlined,
  MessageOutlined, PaymentIcon,
  PhoneCallIcon,
} from "@web/assets/icons";
import {AppContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import {useLocation} from "wouter";

AssistantVCard.propTypes = {
  drawer: PropTypes.bool,
  contact: PropTypes.object,
  onClose: PropTypes.func,
  asCard: PropTypes.bool,
}

function AssistantVCard(props) {
  const dispatch = useDispatch();
  const [, user, claims] = React.useContext(AppContext);
  const [, setLocation] = useLocation();
  const assistantPath = ["user", user?.uid, "lines", "assistant"].join("/");
  const assistant = useSelector((state) => state.Lines.data?.[assistantPath]);
  const activeServiceId = useSelector((state) => state.App.activeServiceId);
  //state.App.activeDetailServiceId
  const activeDetail = useSelector((state) => state.App[activeServiceId]);
  const [editing, setEditing] = React.useState(false);

  console.log("AssistantVCard", {assistant, activeDetail, claims, user});

  const onCollapse = () => {
    props.onClose ?
      props.onClose() :
      dispatch(appActions.toggleMobileMenu());
  }

  // const onClickEdit = () => {
  //   setLocation("/start");
  //   // setEditing(true);
  // }

  const launchSms = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const body = activeDetail?.props?.path ? `Here: ${new URL(activeDetail?.props?.path, "https://getline.ai").href}` : "";
    location.href = [`sms:${assistant?.number}`, body].filter(Boolean).join("?body=");
  }

  const launchPhoneCall = (e) => {
    e.preventDefault();
    e.stopPropagation();
    location.href = `tel:${assistant?.number}`;
  }

  const onClickNav = (path) => {
    props.onNav && props.onNav(path);
  }

  const onClickShare = () => {
    try {
      if (!navigator.share) {
        notification.warn({message: "Share not supported on this device"});
      }
      navigator.share({
        title: "Share Contact",
        text: "Share this contact",
        url: `https://getline.ai/${assistant?.number}`
      });
    } catch (e) {
      console.error("Share not supported", e);
      notification.warn({message: e.message});
    }
  }

  const collapse = props.path || props.contactPath;

  const onClickFavorite = () => {
    notification.success({message: "Added to Favorites"});
  }

  return <section className="w-full" style={{backgroundColor: "#000", paddingTop: props.drawer ? 0 : 60}}>
    <div className={`${!props.asCard ? "sky-blue-bg drawer" : "sky-blue-bg as-card"}`}>
      <div className={`space space-col space-between`} style={{height: collapse ? 150 : 320, padding: "6px 6px 16px 6px"}}>
        {props.drawer ?
          <div className="space space-center-y space-between w-full">
            <Button onClick={onCollapse} ghost icon={<Icon name="LeftArrowOutlined" />} />
            <Button shape="round" disabled={!assistant} onClick={() => onClickNav("/start")}>Edit</Button>
            {/*<img width={82} src={"/icons/line-on-dark.png"} />*/}
          </div> :
          <div style={{height: 30}}></div>
        }
        {!collapse && <div className="space space-col w-full center">
          <Avatar size={80} />
          <span className="font-xl bolded">{assistant?.assistantName || <span className="muted">No Line</span>}</span>
        </div>}
        <div className="space space-center-y space-evenly">
          <Button
            size="large"
            // disabled={!assistant}
            className="bga space space-col center middle no-border"
            onClick={() => onClickNav("/home")}
            style={{height: 70, width: 80, borderRadius: 10}}
          >
            <HomeOutlined size={28} />
            <span className="font-sm">Home</span>
          </Button>
          <Button size="large" disabled={!assistant} className="bga  space space-col center middle no-border" onClick={launchPhoneCall} style={{height: 70, width: 80, borderRadius: 10}}>
            <PhoneCallIcon size={28} />
            <span className="font-sm">Call</span>
          </Button>
          <Button size="large" bordered={false} disabled={!assistant} className="bga space space-col center middle no-border" onClick={launchSms} style={{height: 70, width: 80, borderRadius: 10}}>
            <MessageOutlined size={28} />
            <span className="font-sm">Message</span>
          </Button>
          {/*<Button size="large" className="bga  space space-col center middle no-border"*/}
          {/*        onClick={() => onClickNav("/apps")} style={{height: 70, width: 80, borderRadius: 10}}>*/}
          {/*  <AppstoreIcon size={28} />*/}
          {/*  <span className="font-sm">Apps</span>*/}
          {/*</Button>*/}
          {claims?.data?.subs?.includes("creator") ?
            <Button size="large" disabled={!assistant} className="bga space space-col center middle no-border" onClick={() => onClickNav("/apps/store")} style={{height: 70, width: 80, borderRadius: 10}}>
              <AppstoreIcon size={28} />
              <span className="font-sm">Apps</span>
            </Button> :
            <Button size="large" disabled={!assistant} className="bga space space-col center middle no-border" onClick={() => onClickNav("/emails")} style={{height: 70, width: 80, borderRadius: 10}}>
            <AppstoreIcon size={28} />
            <span className="font-sm">Email</span>
          </Button>}
        </div>
      </div>
    </div>
    <div className="w-full space space-col large">
      {props.children}
    </div>
  </section>
};
export default AssistantVCard
