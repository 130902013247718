import React from 'react'
import {Col, Row} from 'antd'
import PriceCard from './PriceCard';
import {loadStripe} from '@stripe/stripe-js';
import {PaymentElement, Elements, useStripe, useElements} from '@stripe/react-stripe-js';

// import {StripeManager} from "@web/lib/stripe.manager";

export default function PriceTable(props) {
  // const [app = {}] = useContext(AppContext)
  const [_, setSelected] = React.useState(true);
  const [products, setProducts] = React.useState([]);


  const pairs = [];
  console.log('products', products);

  // const pairs = props.scope ?
  //   props.scope.reduce((acc, productIndex) => {
  //     return acc.push(app.products.pairs[productIndex]) && acc;
  //   }, []):
  //   app.products.pairs;

  return <Row gutter={[16, 24]}>
    {pairs.map(([productId]) => {
      return <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={8} key={productId}>
        <PriceCard
          button={props.button}
          productId={productId}
          onSelect={setSelected}
        />
      </Col>
    })}
  </Row>
}
