import React from "react";
import PropTypes from "prop-types";
import {AppContext, WindowContext} from "@web/hooks/context";
import {api} from "@web/lib/api";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {signInWithCustomToken} from "firebase/auth";
import {auth} from "@web/lib/firebase";
import {Link, useLocation} from "wouter";
import {Button, Typography} from "antd";
import {CheckIcon, MobilePhoneCheckIcon, RightArrowOutlined} from "@web/assets/icons";

const lineReserveUrl = () => {
  return (process.env.NODE_ENV === "production") ?
    "https://line-v1-7zujc5azra-uw.a.run.app/reserve" :
    "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/reserve";
}

LineReservationManager.propTypes = {
  size: PropTypes.number,
  initial: PropTypes.string,
};

function LineReservationManager(props) {
  const [, user, userClaims] = React.useContext(AppContext);
  const [isMobile] = React.useContext(WindowContext);
  const [reservedLines, setReservedLines] = React.useState(null);
  const [assistantLine, smsLineClaimLoading] = useDocumentFirestore(["user", user.uid, "lines", "assistant"].join("/"));
  const [, setLocation] = useLocation();
  const [loadingReservations, setLoadingReservations] = React.useState(false);
  const [reservationAttempted, setReservationAttempted] = React.useState(false);
  const [loadReservationsError, setLoadReservationsError] = React.useState(null);
  // const [claimComplete, setClaimComplete] = React.useState(false);

  React.useEffect(() => {
    // console.log("LineReservationManager:lineHold:useEffect", reservedLines, userClaims?.data);
    if (!user?.uid) return;
    if (userClaims?.data?._h) return;
    if (smsLineClaimLoading) return;
    if (assistantLine) return;
    if (loadingReservations) return;
    if (reservedLines !== null) return;
    if (reservationAttempted) return;

    setReservationAttempted(true);
    setLoadReservationsError(null);

    api.POST(lineReserveUrl(), {
      filter: {
        areaCode: props.areaCode,
      },
    })
    .then((response) => {
      setReservedLines(response.lines || []);
      setLoadingReservations(false);
    })
    .catch((error) => {
      console.error("LineReservationManager:twilio-holdLine:error:", error);
      setReservedLines([]);
      setLoadReservationsError(error);
      setLoadingReservations(false);
    })
    .finally(() => {
      setReservationAttempted(true);
      setLoadingReservations(false);
    });
  }, [user?.uid, userClaims?.data?._h, reservedLines, smsLineClaimLoading, assistantLine, loadingReservations, reservationAttempted]);

  const onClickContinue = () => {
    !!assistantLine?.claim?.customToken &&
    !userClaims?.data?._h ?
    signInWithCustomToken(auth, assistantLine.claim.customToken)
    .then((response) => {
      console.log("LineReservationManager:signInWithCustomToken:response:", response.user);
      userClaims.refresh();
    }) :
    setLocation("/start");
  };

  React.useEffect(() => {
    // console.log("LineReservationManager:useEffect:", assistantLine, userClaims?.data);
    assistantLine?.claim?.customToken &&
    userClaims?.data?._h &&
    (location.href = "/start");
    // console.log("assistantLine.will-redirect-to-start:", assistantLine?.claim?.customToken);
    // setLocation("/start");
  }, [userClaims, assistantLine]);

  if (!user) return null;

  return <div style={{width: "100%"}}>
    <div className="space space-col space-evenly" style={{height: 240}}>
      <div className="bgx">
        {isMobile && <h1 style={{fontSize: 30, paddingRight: 5}}>Your Line is</h1>}
        <div className="space space-center-y space-center-x line-number-hero" style={{gap: 10, opacity: (!reservedLines?.length && !assistantLine) ? 0.25 : 1}}>
          {!isMobile && <h1 style={{fontSize: props.size || 60, paddingRight: 5}}>Your Line is</h1>}
          <h1 style={{fontSize: props.size || 60}}>
            {(assistantLine || reservedLines?.[0])?.number?.slice(2, 5) || "000"}
          </h1>
          <h1 style={{fontSize: props.size || 60}}>
            {(assistantLine || reservedLines?.[0])?.number?.slice(5, 8) || "000"}
          </h1>
          <h1 style={{fontSize: props.size || 60}}>
            {(assistantLine || reservedLines?.[0])?.number?.slice(8, 12) || "000"}
          </h1>
        </div>
      </div>
      <div style={{height: 200}}>
        {reservedLines !== null ?
          <Typography.Paragraph className="line-number-cta ai-text font-xl bgx">
            {assistantLine ?
              <Button
                type="link"
                // href="/start"
                onClick={onClickContinue}
                className="green-color icon-btn"
                shape="round"
                size="large"
              >
                Click here to continue <RightArrowOutlined className="green-icon" />
              </Button> :
              reservedLines?.length ?
              <span>Text <span className="text-color font-sms bolded" style={{fontSize: "18px"}}> START </span>{isMobile ? " to begin" : " from your mobile phone to begin"}</span> :
              <span>All released Hotlines have been claimed. <Link to="sign-up">Sign up</Link> or <Link to="login">login</Link></span>
            }
          </Typography.Paragraph> :
          userClaims?.data?._h && (assistantLine?.claim?.customToken || !user.isAnonymous) &&
          <Button onClick={() => setLocation("/start")} className="green-color icon-btn" shape="round" size="large">
            <CheckIcon className="green-icon" /> Continue
          </Button>
        }
      </div>
    </div>
  </div>
}

export default LineReservationManager;
