import React, {useContext} from 'react'
import {LeftArrowOutlined} from '@web/assets/icons'
import {WindowContext} from '@web/hooks/context'
import {Button, Drawer} from 'antd'
import ShareScribeButton from './ShareScribeButton'
import {useDispatch, useSelector} from 'react-redux'
import appActions from '@web/redux/app/actions'

const headerStyle = {backgroundColor: '#000'}
const drawerTransparent = {backgroundColor: 'transparent'}

function OptionsDrawer(props) {
  const dispatch = useDispatch();
  const [isMobile, width] = useContext(WindowContext)
  const {show = false} = useSelector(state => state.App.optionsDrawer);
  const onClose = React.useCallback(() => dispatch(appActions.hideOptionsDrawer()), [dispatch]);

  return <Drawer
    mask={!!props.mask}
    width={isMobile ? width : props.width || 420}
    className={`feature-drawer ${props.hideHeader ? 'drawer-no-header' : ''} ${props.className || ''}`}
    title={<Button size="large" ghost icon={<LeftArrowOutlined />} onClick={onClose}>{props.title}</Button>}
    extra={!!props.props?.scribeId && <ShareScribeButton {...props?.props||null} />}
    destroyOnClose={true}
    open={show}
    onClose={onClose}
    placement={props.placement || "right"}
    closable={false}
    headerStyle={props.hideHeader ? {display: 'none'} : headerStyle}
    drawerStyle={drawerTransparent}
    contentWrapperStyle={drawerTransparent}
    style={props.style}
  >
    {props.children}
  </Drawer>
}

export default OptionsDrawer
