import React from "react";
import PropTypes from "prop-types";
import {Button, Card, List, Tag} from "antd";
// import {Link} from "wouter";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import ServiceJobListItem from "./ServiceJobListItem";
import Day from "./Day";
import {getPath} from "@web/lib/firestore.db";
import {RightArrowOutlined} from "@web/assets/icons";
import {api} from "@web/lib/api";
import {notifyAndTrack} from "./notification";
// import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
// import {useSelector} from "react-redux";

SandboxJob.propTypes = {
  appPath: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
};

function SandboxJob(props) {
  const appJobPath = [props.appPath, "jobs", props.jobId].join("/");
  const [job, loadingJob] = useDocumentFirestore(appJobPath);
  const [serviceJobs, setServiceJobs] = React.useState([]);

  React.useEffect(() => {
    if (!job?.jobs?.length) return;
    Promise.all(job.jobs.map((jobPath) => getPath(jobPath)))
    .then((jobs) => {
      setServiceJobs(jobs.map((serviceJob) => [serviceJob.ref.path, serviceJob.data()]));
    });
  }, [job?.jobs]);

  const runJob = () => {
    const pathname = props.appPath.includes("/sandbox/") ? "/sandbox/run" : "/app/run";
    api.LINE(pathname, {
      appPath: props.appPath,
      appJobPath,
    })
    .then(() => notifyAndTrack.info("Started"));
  }

  console.log("SandboxJob:job:", job, serviceJobs);
  return <Card
    title={<div className="font-lg">{props.app?.appName}</div>}
    extra={job?.finished ?
      <div className="muted">Finish <Day ts={job?.finished?.toMillis()} format="HH:mm a" /></div> :
      <Tag className="rounded">Running</Tag>}
    className="card-simple card-rounded card-transparent"
    bordered={false}
    style={{width: "100%", maxWidth: 800, margin: "auto auto"}}
  >
    <List
      className="list-item-no-padding"
      header={
      <div className="space space-col large">
        <div className="font-lg space space-center-y space-between muted">
          {/*<span>Started <Day ts={job?.started?.toMillis()} format="MMM DD YYYY HH:mm a" /></span>*/}
        </div>
        <div className="font-lg space space-center-y space-between muted">
          <span>Started <Day ts={job?.started?.toMillis()} format="MMMM DD, HH:mm a" /></span>
          <Button shape="round" size="large" className="icon-btn icon-right" onClick={runJob}>Continue <RightArrowOutlined /></Button>
        </div>
      </div>}
      dataSource={props.app?.servicesPaths.map((servicePath) => {
        return [servicePath, ...serviceJobs?.find((serviceJob) => serviceJob[1].servicePath === servicePath) || []];
      })}
      renderItem={([servicePath, serviceJobPath], index) => <ServiceJobListItem
        key={servicePath}
        servicePath={servicePath}
        serviceJobPath={serviceJobPath}
      />
    }/>
  </Card>;
}

export default SandboxJob;
