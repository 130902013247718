import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Divider, Typography, Alert, Empty, Collapse, Space} from 'antd'
import {Redirect} from 'wouter'
import {LinkOutlined, EmbedIcon} from '@web/assets/icons'
import useDocumentFirestore from '@web/hooks/useDocumentFirestore'
import {colls} from '@web/lib/firestore.db'
import ScribePublishButton from '@ai-antd/components/ScribePublishButton'
import Loading from '@ai-antd/components/Loading'
import ScribeCreateButton from '@ai-antd/components/ScribeCreateButton'
import ScribeDeleteButton from '@ai-antd/components/ScribeDeleteButton'
import {AppContext, WindowContext} from '@web/hooks/context'
import useAvailableStripeSubscriptions from '@web/hooks/useAvailableStripeSubscriptions'
import PriceCard from '@ai-antd/components/PriceCard'
import useProgressCounts from '@web/hooks/useProgressCounts'
import AvailableSubscriptions from '@ai-antd/components/AvailableSubscriptions'
import ScribeEmbedCodeModal from '@ai-antd/components/ScribeEmbedCodeModal'

const cardStyle = {
  width: "100%",
  maxWidth: "800px",
}
ScribeSettings.propTypes = {
  scribeId: PropTypes.string.isRequired,
};

function ScribeSettings({scribeId}) {
  const [app, user] = React.useContext(AppContext)
  const [isMobile] = React.useContext(WindowContext)
  const [scribe = {}, loadingScribe = false, loadingScribeError = false] = useDocumentFirestore([colls["ai-scribe"], scribeId])
  const [{found = 0} = {}, loadingCounts, loadingCountsError] = useProgressCounts(`scribe/${scribeId}/pages`);
  const [subscriptionPairs = [], loadingSubscriptions, loadingSubscriptionsError] = useAvailableStripeSubscriptions(user)
  if (!user) return <Redirect to="/login" />
  if (loadingScribe || loadingSubscriptions || loadingCounts) return <Loading />
  if (loadingScribeError || loadingSubscriptionsError || loadingCountsError) return <Alert
    type="info"
    message="Unavailable"
    description="We are currently unable to display your scribe settings. Please try back later."
  />
  if (!scribe.createdTs) return <Empty description="This Scribe does not exist."><ScribeCreateButton type="primary" text="Create New" /></Empty>
  if (!scribe.userId === user.uid) return <Empty description="This Scribe is not available for this account." />

  console.log('scribe', scribe)
  const getRecommendedProductIds = () => {
    const recommend = []
    switch(true) {
      case found > 300:
        recommend.push(3)
        break;
      case found > 30:
        recommend.push(2)
        break;
      case found > 10:
        recommend.push(1)
        break;
      default:
        !subscriptionPairs.length ? recommend.push(0) : recommend.push(1)
    }
    if (recommend[0] === 3) recommend.push(4)
    else recommend.push(recommend[0] + 1)
    return recommend.map(index => app?.products?.list?.[index])
  }
  const moreSubscriptionOptions = <ScribePublishButton scribeId={scribeId} block text="More Subscription Options" type="secondary" size="large" />
  const recommendedProducts = getRecommendedProductIds()
  return <div className="flex flex-col middle center gap-30">
    {/*<Typography.Title level={4}>Publish & Share</Typography.Title>*/}
    <Card
      title={<div className="space space-center-y"><EmbedIcon size={22} color="green" /><Typography>Embed in my Website</Typography></div>}
      extra={scribe.subscriptionId &&
        <ScribeEmbedCodeModal
          scribeId={scribeId}
          scribe={scribe}
        />
      }
      style={cardStyle}
      bordered={false}
    >
      <p>Add this Scribe to your website to quickly answer visitor and customers questions.<Typography.Link>See how it works</Typography.Link></p>
      {!scribe.subscriptionId && <div className="pt-8">
        <AvailableSubscriptions scribeId={scribeId} />
        {!!subscriptionPairs.length && moreSubscriptionOptions}
        {!subscriptionPairs.length &&
        <Space direction={isMobile ? "vertical" : "horizontal"}>
          {recommendedProducts[0] && <PriceCard productId={recommendedProducts[0]} recommended/>}
          {recommendedProducts[1] && <PriceCard productId={recommendedProducts[1]}/>}
        </Space>}
        <div className="p-6">
        </div>
        {app.products?.list?.length && !subscriptionPairs.length && moreSubscriptionOptions}
      </div>}
      <Space direction="vertical">
        <div className="p-6">
        </div>
      </Space>
    </Card>
    <Card
      title={<div className="space space-center-y"><LinkOutlined size={22} color="green" />Your Free Scribe Page</div>}
      style={cardStyle}
      bordered={false}
      extra={<Button><Typography.Text>Activate</Typography.Text></Button>}
    >
      <div className="space space-center-y">
        <Typography.Text type="secondary">https://</Typography.Text>
        <Typography.Link
          href={`https://thescribe.chat/${scribeId}`}
          target="_blank"
          rel="noreferrer"
        >
          thescribe.chat/{scribeId}
        </Typography.Link>
      </div>
    </Card>
    {/*<Card*/}
    {/*  title={<div className="space space-center-y"><HomeOutlined size={20} />Custom Sub/Domain</div>}*/}
    {/*  extra={<Link href="/waitlist">Join waitlist</Link>}*/}
    {/*  style={cardStyle}*/}
    {/*  bordered={false}*/}
    {/*>*/}
    {/*  <p>Give your customers, team, and community instant access to answers with your Scribe as a subdomain or page on your own website. Unlock knowledge!</p>*/}
    {/*  <p className="muted">Ex. https://chat.yourwebsite.com</p>*/}
    {/*</Card>*/}
    {/*<Divider />*/}
    {/*<div style={cardStyle}>*/}
    {/*  <Typography.Title level={4}>Helpers</Typography.Title>*/}
    {/*  <Typography.Text type="secondary">Helpers are AI add-ons that, once activated, enable your scribe to perform tasks in addition to providing information, giving you powerful options. </Typography.Text>*/}
    {/*</div>*/}
    {/*<List*/}
    {/*  style={cardStyle}*/}
    {/*  locale={{emptyText: "Create your first Scribe"}}*/}
    {/*  header={<div className="space space-between">*/}
    {/*    <Typography.Text type="secondary">My Helpers</Typography.Text>*/}
    {/*  </div>}*/}
    {/*  dataSource={helpersList}*/}
    {/*  renderItem={([agentId, agent]) => <List.Item key={agentId}>*/}
    {/*    <div className="space space-between">*/}
    {/*      <Typography.Text type={!agent.active && "secondary"}>{agent.name}</Typography.Text>*/}
    {/*      {agent.subscribed ? <Tag color="success" icon={<CheckOutlined />}>Active</Tag> : <Button size="small" type="link">Notify Me</Button>}*/}
    {/*    </div>*/}
    {/*  </List.Item>}*/}
    {/*/>*/}
    <Divider />
    <Collapse style={cardStyle}>
      <Collapse.Panel header="Advanced Settings" key="delete">
        <Space direction="vertical">
          <ScribeDeleteButton scribeId={scribeId} />
          <Typography.Paragraph type="warning">
            Please note that deleting a Scribe is permanent and cannot be undone.
          </Typography.Paragraph>
        </Space>
      </Collapse.Panel>
    </Collapse>
  </div>
}

export default ScribeSettings
