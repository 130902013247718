import contentActions from "../content/actions";
const actions = {
  AI_WRITE_STREAM: "AI_WRITE_STREAM",
  AI_CLEAR_STREAM: "AI_CLEAR_STREAM",
  SET_ACTIVE_EMAIL_DRAFT: "SET_ACTIVE_EMAIL_DRAFT",
  CLEAR_ACTIVE_EMAIL_DRAFT: "CLEAR_ACTIVE_EMAIL_DRAFT",
  SET_ACTIVE_USER_TASK: "SET_ACTIVE_USER_TASK",
  CLEAR_ACTIVE_USER_TASK: "CLEAR_ACTIVE_USER_TASK",
  LOG_ITEM_VISIBILITY: "LOG_ITEM_VISIBILITY",
  SET_ACTIVE_APP: "SET_ACTIVE_APP",
  CLEAR_ACTIVE_APP: "CLEAR_ACTIVE_APP",
  clearStream: (chatId) =>
    ({type: actions.AI_CLEAR_STREAM, chatId}),
  /**
   * @function setEmailDraft
   * @param {string} draftPath
   * @returns {{draftPath:string}}
   */
  setActiveEmailDraft: (draftPath) => {
    return (dispatch, getState) => {
      const serviceId = getState().App.activeServiceId;
      dispatch({
        type: actions.SET_ACTIVE_EMAIL_DRAFT,
        draftPath,
        serviceId,
      });
    }
  },
  clearActiveEmailDraft: () => {
    return (dispatch, getState) => {
      const serviceId = getState().App.activeServiceId;
      dispatch({
        type: actions.CLEAR_ACTIVE_EMAIL_DRAFT,
        serviceId,
      });
    }
  },
  setActiveUserTask: (path, targetServiceId) => {
    return (dispatch, getState) => {
      if (!path) {
        throw new Error("Missing task path");
      }
      const serviceId = targetServiceId || getState().App.activeServiceId;
      const activeUserTask = getState()?.[serviceId]?.userTask;
      if (activeUserTask?.path === path) return;

      dispatch({
        type: actions.SET_ACTIVE_USER_TASK,
        path,
        serviceId,
      });
    };
  },
  setActiveApp: (path, targetServiceId) => {
    return (dispatch, getState) => {
      if (!path) {
        throw new Error("Missing task path");
      }
      const serviceId = targetServiceId || getState().App.activeServiceId;
      const activeApp = getState()?.[serviceId]?.app;
      if (activeApp?.path === path) return;

      dispatch({
        type: actions.SET_ACTIVE_APP,
        path,
        serviceId,
      });
    };
  },
  clearActiveApp: (targetServiceId) => {
    return (dispatch, getState) => {
      const serviceId = targetServiceId || getState().App.activeServiceId;
      // const activeApp = getState().AI?.[serviceId]?.app;

      dispatch({
        type: actions.CLEAR_ACTIVE_APP,
        serviceId,
      });
    }
  },
  clearActiveUserTask: (targetServiceId) => {
    return (dispatch, getState) => {
      const serviceId = targetServiceId || getState().App.activeServiceId;
      const activeUserTask = getState().AI?.[serviceId]?.userTask;
      console.log("clearActiveUserTask", getState().AI?.[serviceId]);

      if (!activeUserTask?.path) {
        return;
      }

      dispatch({
        type: actions.CLEAR_ACTIVE_USER_TASK,
        serviceId,
      });
      // dispatch(contentActions.unSyncItem(activeUserTask.path));
    }
  }
};

export default actions;
