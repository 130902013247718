import React from "react";
import {Layout} from "antd";
import {WindowContext} from "@web/hooks/context";
import ProductsContent from "@ai-antd/components/ProductsContent";
import PropTypes from "prop-types";

ProductsPage.propTypes = {
  plan: PropTypes.string,
  confirm: PropTypes.bool,
};

function ProductsPage(props) {
  const [isMobile,, height] = React.useContext(WindowContext);

  return <Layout
    hasSider={false}
    className={`app-dashboard-wrapper`}
    style={{
      padding: 0,
      minHeight: height,
      backgroundColor: "#0e161f",
      overflowY: "auto",
    }}
  >
    <Layout.Content>
      {isMobile ?
        <ProductsContent confirm={props.confirm} plan={props.plan} /> :
        <div className="space space-col middle" style={{height, width: "100%"}}>
          <ProductsContent confirm={props.confirm} plan={props.plan} />
        </div>
      }
    </Layout.Content>
  </Layout>
}

export default ProductsPage;
