import React from 'react'
import {useObjectVal} from 'react-firebase-hooks/database'
import {database} from '../lib/firebase'
import {ref} from 'firebase/database'
/**
 * @summary use progress counts from RTDB
 * @param path
 * @return {[(Record<"", string> & Record<"", DatabaseReference>),boolean,Error]}
 */
const useProgressCounts = (path) => {
  const [counts, loading, error] = useObjectVal(ref(database, path));

  return [counts || {}, loading, error];
}

export default useProgressCounts
