import React from 'react';
import {Button, Modal, Typography} from 'antd'
import ScribeEmbedCodeForm from './ScribeEmbedCodeForm'
import FeatureDrawer from './OptionsDrawer'

function ScribeEmbedCodeModal(props) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  }
  return <>
    <Button type={props.type} onClick={showModal}>
      Get Code
    </Button>
    <FeatureDrawer
      className="scribe-embed-drawer"
      show={isModalOpen}
      size={400}
      onClose={() => setIsModalOpen(false)}
    >
      <ScribeEmbedCodeForm scribeId={props.scribeId} scribe={props.scribe} />
    </FeatureDrawer>
  </>
}

export default ScribeEmbedCodeModal
