import React from 'react';
import {ProgressBar} from './ProgressBar'
import useStripeSubscriptions from '@web/hooks/useAvailableStripeSubscriptions'
import {AppContext} from '@web/hooks/context'
import {Alert, Button, Collapse, Empty, List, Typography} from 'antd'
import ScribePublishButton from './ScribePublishButton'
import {colls, updateDocument} from '@web/lib/firestore.db'
import {notifyAndTrack} from './notification'

/**
 * Returns a list of available subscriptions for the user
 * @param {Object} props
 * @param {String} props.scribeId
 * @return {JSX.Element|null}
 * @constructor
 */
function AvailableSubscriptions({scribeId}) {
  const [app, user] = React.useContext(AppContext)
  const [subscriptionPairs = [], isLoadingSubscriptions, loadingSubscriptionsError] = useStripeSubscriptions(user)

  if (isLoadingSubscriptions) return <ProgressBar visible />
  if (loadingSubscriptionsError) return <Alert type="info" message="Unable to load subscriptions" />
  if (!subscriptionPairs?.length) return null

  const applySubscription = (subscriptionId, productId) => {
    updateDocument(colls["ai-scribe"], scribeId, {
      subscriptionId: subscriptionId,
      productId: productId,
    }).then(() => notifyAndTrack.success("success", "Your Scribe is now ready to publish."))
  }
  return <Collapse style={{marginBottom: "10px"}} defaultActiveKey={subscriptionPairs[0][0]}>
    {subscriptionPairs.map(([subscriptionId, subscription]) => {
      return <Collapse.Panel
        key={subscriptionId}
        header={
        <Typography.Text type="secondary">
          {subscriptionPairs.length ? "You Have Available Subscriptions" : "Available Subscriptions"}
        </Typography.Text>
      }>
        <List
          itemLayout="horizontal"
          dataSource={subscription?.items}
          locale={<Empty description="No subscriptions available"><ScribePublishButton scribeId={scribeId} /></Empty>}
          renderItem={(item) => {
            const {plan} = item
            const [productId, product] = app.products?.pairs?.find(([productId]) => productId === plan.product)
            return <List.Item>
              <List.Item.Meta
                title={<Typography.Text>{product.name}</Typography.Text>}
                description={<Typography.Text type="secondary">{product.description}</Typography.Text>}
              />
              {scribeId && <Button
                  type="primary"
                  onClick={() => applySubscription(subscriptionId, productId)}
                >
                Use This
              </Button>
              }</List.Item>
          }}
        />
      </Collapse.Panel>
    })}
  </Collapse>
}

export default AvailableSubscriptions;
