import React from "react";
import {StorageReference, FullMetadata} from "firebase/storage";
import {PauseIcon, PlusIcon, UploadIcon, XIcon} from "@web/assets/icons";
import {Button, Card, Divider, List, Popover, Progress} from "antd";
import PropTypes from "prop-types";
import {generateID} from "@web/lib/firestore.db";
import {uploadToStorageResumable, TaskState} from "@web/lib/cloud.storage";
import sum from "lodash/sum.js";
import AppsList from "./AppsList";
import AppsListItem from "./AppsListItem";
import {useSelector} from "react-redux";

UploadButton.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  ghost: PropTypes.bool,
};

/**
 * @function UploadButton
 * @param {object} props
 * @param {function(Object<string, {ref:StorageReference, metadata:FullMetadata, originFilename:string, percentComplete:number, state:string}>):void} props.onChange
 * @param {String<('small'|'medium'|'large')>|any} [props.size]
 * @param {boolean} [props.ghost]
 * @param {object} [props.children]
 * @returns {React.JSX.Element}
 * @constructor
 */

function UploadButton(props) {
  const uploader = React.useRef();
  const task = React.useRef({});
  const [progressStatus, setProgressStatus] = React.useState("normal");
  const [isRunning, setIsRunning] = React.useState(false);
  const [pauseUpload, setPauseUpload] = React.useState();
  const [isPaused, setIsPaused] = React.useState(false);
  const [bytesTransferred, setBytesTransferred] = React.useState(0);
  const [totalBytes, setTotalBytes] = React.useState({});

  React.useEffect(() => {
    if (pauseUpload === true) {
      Object.entries(task.current).map(([gcsFilename, uploadTask]) => {
        try {
          uploadTask.pause();
          setIsPaused(true);
          console.log("Upload paused");
        } catch (e) {
          console.error("Error pausing uploads", e.message, uploadTask);
        }
      });
    }
    if (pauseUpload === false) {
      Object.entries(task.current).map(([gcsFilename, uploadTask]) => {
        try {
          uploadTask.resume();
          setIsPaused(false);
          console.log("Upload resumed");
        } catch (e) {
          console.error("Error resuming uploads", e.message, uploadTask);
        }
      });
    }
  }, [pauseUpload]);

  const onChange = (e) => {
    setIsRunning(true);
    const fileList = e.target.files;
    if (!fileList?.length) {
      setIsRunning(false);
      return;
    }
    setProgressStatus("active");
    try {
      Array.from(fileList)
      .forEach((file) => {
        const {type, name: originFilename} = file;
        const ext = type.split("/")[1] ?? "";
        const gcsFilename = `${generateID()}.${ext}`;
        const uploadTask = uploadToStorageResumable(file, gcsFilename);
        task.current[gcsFilename] = uploadTask;

        const unsubscribe = uploadTask.on(
          TaskState.CHANGED,
          (snapshot) => {
            const percentComplete = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setBytesTransferred(bytesTransferred + snapshot.bytesTransferred);

            (totalBytes[gcsFilename] ?? 0) !== (snapshot.totalBytes ?? 0) &&
            setTotalBytes({
              ...totalBytes,
              [gcsFilename]: snapshot.totalBytes ?? 0,
            });

            switch (snapshot.state) {
              case TaskState.PAUSED:
                props.onChange &&
                props.onChange({
                  [gcsFilename]: {
                    ref: uploadTask.snapshot?.ref,
                    metadata: uploadTask.snapshot?.metadata,
                    originFilename,
                    percentComplete,
                    state: TaskState.PAUSED,
                  }});
                break;
              case TaskState.RUNNING:
                props.onChange &&
                props.onChange({
                  [gcsFilename]: {
                    ref: uploadTask.snapshot?.ref,
                    metadata: uploadTask.snapshot?.metadata,
                    originFilename,
                    percentComplete,
                    state: TaskState.RUNNING,
                  }});
                break;
              case TaskState.CANCELED:
                props.onChange &&
                props.onChange({
                  [gcsFilename]: {
                    ref: uploadTask.snapshot?.ref,
                    metadata: uploadTask.snapshot?.metadata,
                    originFilename,
                    percentComplete,
                    state: TaskState.CANCELED,
                  }});
                break;
            }
          },
          (error) => {
            setProgressStatus("exception");
            props.onChange &&
            props.onChange({
              [gcsFilename]: {
                ref: uploadTask.snapshot?.ref,
                metadata: uploadTask.snapshot?.metadata,
                originFilename,
                percentComplete: 0,
                status: "error",
              }});
          },
          () => {
            setProgressStatus("success");
            setIsRunning(false);
            props.onChange &&
            props.onChange({
              [gcsFilename]: {
                ref: uploadTask.snapshot?.ref,
                metadata: uploadTask.snapshot?.metadata,
                originFilename,
                percentComplete: 100,
                status: "complete",
              }});
            unsubscribe();
          });
      });
    } catch (e) {
      console.error("Error uploading files", e.message);
      setProgressStatus("exception");
    }
  };

  const onClickUpload = (e) => {
    e.preventDefault();
    e.stopPropagation();

    /** reset all */
    if (progressStatus === "exception") {
      setIsRunning(false);
      setProgressStatus("normal");
      setIsPaused(false);
      setTotalBytes({});
      setBytesTransferred(0);
      return;
    }

    if (!isRunning) {
      uploader.current.click();
      return;
    }
    setPauseUpload(!pauseUpload); // Toggle pause
  };

  const getIcon = () => {
    if (progressStatus === "exception") {
      return <XIcon size={22} style={{color: 'orange'}} />;
    }
    switch (true) {
      case isPaused:
        return <PlusIcon size={22}/>;
      case isRunning:
        return <PauseIcon size={22}/>;
      default:
        return <UploadIcon
          size={20}
          className={progressStatus === "success" ? "success-color" : ""}
        />;
    }
  }

  const allBytes =
    sum(Object.values(totalBytes).filter(Boolean));

  const percentComplete = (bytesTransferred + allBytes) > 0 ?
    (bytesTransferred / allBytes) * 100 :
    0;

  return <div className="relative">
    <Popover
      content={
        <Card
          bordered={false}
          style={{width: 400}}
          className="card-simple card-transparent card-is-tooltip card-no-header"
        >
          <span>
            Upload a document, worksheet, audio recording, or other file
          </span>
          {progressStatus === "exception" &&
            <div className="space space-col">
              <Divider className="small-top small-bottom"/>
              <span className="ai-text"> Please try again</span>
            </div>}
        </Card>
      }
      placement="topLeft"
      trigger={["hover"]}
    >
      <div>
        <Progress
          type="circle"
          width={42}
          strokeLinecap={"circle"}
          status={progressStatus}
          percent={Math.floor(percentComplete)}
          showInfo={false}
          format={(value) =>
            value ? `${value}%` : ""}
        />
        <div
          className="absolute"
          style={{top: 0, left: "1px"}}
        >
          <Button
            className="upload-button icon-btn icon-left icon-right"
            size={props.size || "large"}
            onClick={onClickUpload}
            ghost={props.ghost}
            shape={"circle"}
          >
            {getIcon()}
          </Button>
        </div>
      </div>
    </Popover>
    <input
      hidden
      multiple
      ref={uploader}
      type="file"
      style={{visibility: "hidden", position: "absolute", height: 0, width: 0, left: -1000}}
      onChange={onChange}
    />
  </div>;
}

export default UploadButton;
