import './App.less';
import React from "react";
import {Layout} from "antd";
import useWindowSize from "@web/hooks/useWindowSize";
import {WindowContext} from "@web/hooks/context";
import {isInStandaloneMode} from "@web/lib/app";
import Background from "@ai-antd/components/Background";
import DashboardHeader from "@ai-antd/components/DashboardHeader";
import MobileSidebar from "@ai-antd/components/MobileSidebar";
import MobileHeader from "@ai-antd/components/MobileHeader";
const {Content } = Layout;
const headerHeight = 64;

const FeedLayout = (props) => {
  const { width, height, isMobile } = useWindowSize();
  const isStandalone = isInStandaloneMode()
  const screen = [isMobile, width, height, headerHeight, isStandalone];
  return <div id="root-wrapper-scroll" style={{width, height: isMobile ? height + 10 : height, maxHeight: isMobile ? '101vh' : '100vh', overflow: 'auto'}}>
    <Background>
      <Layout className={props.className} hasSider={!isMobile}>
        {isMobile ? <MobileSidebar /> : <DashboardHeader />}
        {isMobile && <MobileHeader />}
        <Content className="layout-wrapper" style={{padding: 0, minHeight: height - headerHeight}}>
          <Layout style={{padding: "0", minHeight: height - headerHeight}}>
            <Content style={{padding: '0', minHeight: height - headerHeight, position: "relative"}}>
              <WindowContext.Provider value={screen}>
                {props.children}
              </WindowContext.Provider>
            </Content>
          </Layout>
        </Content>
      </Layout>
    </Background>
  </div>
};
export default FeedLayout;
