import React from 'react';
// import DashboardCenterRow from "./DashboardCenterRow";
// import HotlineIdentity from "./HotlineIdentity";
import GuidesManager from "./guides/GuidesManager";
import {AppContext, LinesContext} from "@web/hooks/context";
import {buildQuery, Fields, setPath} from "@web/lib/firestore.db";
// import now from "lodash/now";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import {useLocation} from "wouter";
// import AudioStreamData from "@web/ui/containers/AudioStreamData";
// import AudioStreamManager from "./AudioStreamManager";
// import {api} from "@web/lib/api";
import {AppAddTask} from "./guides/AppCards";
import {UserTask} from "./guides/GuideCards";
import PropTypes from "prop-types";
// import DashboardFullRow from "./DashboardFullRow";
// import ChatPage from "@ai-antd/page/ChatPage";
// import {notifyAndTrack} from "./notification";
// import VoiceShortcutsTips from "./VoiceShortcutsTips";

// const endpoint = (process.env.NODE_ENV === "production") ?
//   "https://line-v1-7zujc5azra-uw.a.run.app/apps" :
//   "http://127.0.0.1:5001/admin-85663/us-west1/line-v1/apps";

LineAppTasks.propTypes = {
  list: PropTypes.array,
}

function LineAppTasks(props) {
  const [, user] = React.useContext(AppContext);
  // const linesContext = React.useContext(LinesContext);
  const [, setLocation] = useLocation()
  const [appTasksList, loadingAppTasksList] = useQueryFirestore(buildQuery(["user", user.uid, "apps", "ai-tasks", "tasks"], {}, {limit: 30}));

  const getComponent = (taskData) => {
    switch (taskData?.component) {
      case "add-task":
        return AppAddTask;
      case "user-task":
        return UserTask;
      default:
        return taskData ? UserTask : AppAddTask;
    }
  }

  const onCompleteStep = React.useCallback((path) => {
    console.log("onCompleteStep", path);
    // const stepName = path.split("/").pop();
    // switch (stepName) {
    //   default:
    //     setPath(path, {seen: true, date: now()}, true)
    //     .then(() => console.log("completed:", path));
    // }
  }, []);

  /**
   * @param {string} step
   * @param {object} updates
   * @type {(function(*, *): void)|*}
   * @return {object}
   */
  const onUpdateStep = React.useCallback((step, updates) => {
    // return api.POST(endpoint, {step, updates})
    // .then((response) => {
    //   console.log("onUpdateStep", response);
    //   return response;
    // })
    // .catch((error) => error);
  }, []);

  const onExit = React.useCallback(() => {
    setLocation("/");
  }, []);

  if (loadingAppTasksList) {
    return null;
  }

  const steps = props.list?.map?.(([path, taskData]) => {
    return [
      getComponent(taskData),
      path,
      appTasksList.find(([id]) => (path.split("/").pop() === id))?.[1]];
  });

  console.log("LineAppTasks:steps", props.list, steps);

  return <GuidesManager
    asList
    list={[...steps || []]}
    onCompleteStep={onCompleteStep}
    onUpdateStep={onUpdateStep}
    onExit={onExit}
  />
}

export default LineAppTasks;
