import {Avatar, Button, Card, List, notification} from "antd";
import React from 'react'
import Icon from './Icon'
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";
import {
  AppstoreIcon, ContactsOutlinedIcon, EmailIconOutlined,
  MessageOutlined, PaymentIcon,
  PhoneCallIcon,
} from "@web/assets/icons";
import {AppContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import {api} from "@web/lib/api";

MessengerVCard.propTypes = {
  drawer: PropTypes.bool,
  contact: PropTypes.object,
  onClose: PropTypes.func,
  asCard: PropTypes.bool,
  contactPath: PropTypes.string,
  line: PropTypes.object,
}

function MessengerVCard(props) {
  const dispatch = useDispatch();
  const [, user] = React.useContext(AppContext);
  const assistantPath = ["user", user?.uid, "lines", "assistant"].join("/");
  const assistant = useSelector((state) => state.Lines.data?.[assistantPath]);
  const isSubscriber = useSelector((state) => state.Content.activity?.lines?.subscribers?.find(([linePath]) => linePath === props.path));
  // const messenger = useSelector((state) => state.Content.messengers?.[props.contact?.messenger]);
  const [messenger, setMessenger] = React.useState(null);
  const [showInfo, setShowInfo] = React.useState(false);
  // const [editing, setEditing] = React.useState(false);

  const onCollapse = () => {
    props.onClose ?
      props.onClose() :
      dispatch(appActions.toggleMobileMenu());
  }

  const launchSms = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const body = isSubscriber ? "" : "START";
    location.href = [`sms:${props.line?.number}`, body].filter(Boolean).join("?body=");
    // console.log("launchSms", [`sms:${props.line?.number}`, body].filter(Boolean).join("?body="));
  }

  const launchPhoneCall = (e) => {
    e.preventDefault();
    e.stopPropagation();
    location.href = `tel:${props.line?.number}`;
  }

  const onClickNav = (path) => {
    props.onNav && props.onNav(path);
  }

  const onClickShare = () => {
    try {
      if (!navigator.share) {
        notification.warn({message: "Share not supported on this device"});
      }
      navigator.share({
        title: "Share Contact",
        text: "Share this contact",
        url: `https://getline.ai/${props.line?.number}`
      });
    } catch (e) {
      console.error("Share not supported", e);
      notification.warn({message: e.message});
    }
  }

  const onClickFavorite = () => {
    notification.success({message: "Added to Favorites"});
  }

  // React.useEffect(() => {
  //   console.log("MessengerVCard", props);
  //   api.LINE(`/contacts/${props.contactPath?.split("/").pop()}/messenger`)
  //   .then((data) => {
  //     console.log("MessengerVCard", data);
  //     setMessenger(data.messenger);
  //   });
  // }, []);

  // if (!messenger) return null;

  const contactName = props.contact?.name?.replaceAll('"', '') || props.contact?.email;

  return <section className="w-full" style={{backgroundColor: "#000", paddingTop: props.drawer ? 0 : 60}}>
    <div className={`${!props.asCard ? "drawer" : "as-card"} ${messenger || !props.contact ? "sky-blue-bg" : "bg-baby-grayer"}`}>
      <div className={`space space-col space-between`} style={{height: 320, padding: "6px 6px 16px 6px"}}>
        {props.drawer ?
          <div className="space space-center-y space-between w-full">
            <Button onClick={onCollapse} ghost icon={<Icon name="LeftArrowOutlined" />} />
            <Button ghost><span className="font-xl">{contactName}</span></Button>
            <Button ghost></Button>
            {/*<Button shape="round" onClick={onClickEdit}>Edit</Button>*/}
            {/*<img width={82} src={"/icons/line-on-dark.png"} />*/}
          </div> :
          <div style={{height: 30}}></div>
        }
        <div className="space space-col w-full center">
          <Avatar size={80} />
          <span className="font-xl bolded">{props.line?.assistantName || "Line"}</span>
        </div>
        <div className="space space-center-y space-evenly">
          <Button size="large" bordered={false} className="bga space space-col center middle no-border" onClick={launchSms} style={{height: 70, width: 80, borderRadius: 10}}>
            <MessageOutlined size={28} />
            <span className="font-sm">Message</span>
          </Button>
          <Button size="large" className="bga  space space-col center middle no-border" onClick={launchPhoneCall} style={{height: 70, width: 80, borderRadius: 10}}>
            <PhoneCallIcon size={28} />
            <span className="font-sm">Call</span>
          </Button>
          {/*<Button size="large" className="bga  space space-col center middle no-border"*/}
          {/*        onClick={() => onClickNav("/apps")} style={{height: 70, width: 80, borderRadius: 10}}>*/}
          {/*  <AppstoreIcon size={28} />*/}
          {/*  <span className="font-sm">Apps</span>*/}
          {/*</Button>*/}
          <Button size="large" className="bga space space-col center middle no-border" onClick={() => onClickNav("/today")} style={{height: 70, width: 80, borderRadius: 10}}>
            <EmailIconOutlined size={28} />
            <span className="font-sm">Mail</span>
          </Button>
          <Button size="large" className="bga space space-col center middle no-border" onClick={() => setShowInfo(true)} style={{height: 70, width: 80, borderRadius: 10}}>
            <ContactsOutlinedIcon size={28} />
            <span className="font-sm">Info</span>
          </Button>
        </div>
      </div>
    </div>
    <div className="w-full space space-col large">
      <section className="px-5">
        {props.children}
      </section>
    </div>
  </section>
};
export default MessengerVCard
