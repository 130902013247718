import React from "react";
import PropTypes from "prop-types";
import {Button, List, Tag} from "antd";
import ContentData from "@web/ui/containers/ContentData";
import ContactInfoCard from "./ContactInfoCard";
import ListItemActive from "./ListItemActive";
import appActions from "@web/redux/app/actions";
import {useDispatch, useSelector} from "react-redux";
import Day from "./Day";
import {CheckIcon, DeleteIcon, FlagIcon, PhoneCallIcon, RightArrowOutlined, TodoIcon, XIcon} from "@web/assets/icons";
import UserAvatar from "./UserAvatar";
import now from "lodash/now";
import useDomainIcon from "@web/hooks/useDomainIcon";

AssistantListItem.propTypes = {
  path: PropTypes.string,
  index: PropTypes.number,
  date: PropTypes.number,
};

function AssistantListItem(props) {
  const dispatch = useDispatch();
  const activity = useSelector((state) => state.Content?.data?.[props.path]);
  const onClick = () => {
    dispatch(appActions.setActiveDetail({path: props.path, activity}));
  };

  const getIcon = (activity) => {
    return activity.taskReview ?
      <UserAvatar
        icon={activity.taskReview?.successful ?
          <CheckIcon className="muted-icon" /> :
          activity.taskReview?.retry ?
            <FlagIcon
              key="urgent"
              style={(activity?.date > (now() - (1000 * 60 * 60 * 48))) ? {color: "orange"} : null}
              size={18}
            /> :
            activity?.recommendedTodo ?
              <TodoIcon key="todo" style={{color: "white"}} /> :
              null
        }
        userId={props.path.split("/").pop()}
        // name={contact.name}
        // displayName={displayName}
        // src={contact.photoURL}
      /> :
      <UserAvatar />;
  }

  return <List.Item
    key={props.path}
    onClick={onClick}
    style={{width: "98%"}}
    className="relative contacts-list-item isMobile"
    // extra={<RightArrowOutlined />}
  >
    <List.Item.Meta
      description={
        activity.taskReview ?
          <span className="ai-text font-lg">{activity.taskReview?.summary}</span> :
          <span className="ai-text font-lg">{activity.activitySummary || activity.title}</span>
      }
      title={
        <span className="space space-center-y font-lg bolded">
          {getIcon(activity)}
          {activity.serviceId === "audio-streams" && <PhoneCallIcon />} <Day ts={activity.date || props.date} />
        </span>
      }
    />
    {/*{!!activity.taskReview && <Tag color="magenta">Task</Tag>}*/}
    {/*<h6 className="ai-text">{activity.title}</h6>*/}
    {/*<ContactInfoCard path={props.path} date={props.date} contact={contact} />*/}
    <ListItemActive path={props.path} offset={-8} />
  </List.Item>
}

export default AssistantListItem;
