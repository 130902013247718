import React from "react";
import PropTypes from "prop-types";
import {Layout, Card, Typography, Button, Divider, Row, notification} from "antd";
import {DetailContext, WindowContext} from "@web/hooks/context";
// import Slides from "../components/Slides";
// import SlideCardWrapper from "../components/SlideCardWrapper";
import {
  // CheckIcon,
  DeleteIcon,
  // AttachmentIcon,
  // ForwardIcon,
  // InvoiceIcon,
  // LeftArrowOutlined,
  // LinkOutlined,
  // PlusIcon,
  // RightArrowOutlined
} from "@web/assets/icons";
// import DashboardCenterRow from "../components/DashboardCenterRow";
import HoverStrip from "../components/HoverStrip";
// import DashboardFullRow from "../components/DashboardFullRow";
import startCase from "lodash/startCase";
// import DashboardRow from "@ai-antd/components/DashboardRow";
// import StatusActions from "@ai-antd/components/StatusActions";
// import DashboardFullRow from "@ai-antd/components/DashboardFullRow";
// import QuickPrompts from "@ai-antd/components/QuickPrompts";
import appActions from "@web/redux/app/actions";
import {deletePath} from "@web/lib/firestore.db";
import MarkdownTimeline from "@ai-antd/components/MarkdownTimeline";
import DatasetTable from "@ai-antd/components/DatasetTable";
import DatasetCard from "@ai-antd/components/DatasetCard";
import DatasetData from "@web/ui/containers/DatasetData";
import {useDispatch} from "react-redux";

Analyst.propTypes = {
  data: PropTypes.object,
  path: PropTypes.string,
};

function Analyst(props) {
  const dispatch = useDispatch();
  const [,, height] = React.useContext(WindowContext);
  // const detailContext = React.useContext(DetailContext);
  // const {services} = useContext(SourcesContext);

  React.useEffect(() => {
    dispatch(appActions.setActiveDetail({data: props.data, path: props.path}))
    // detailContext?.onSetContextPaths &&
    // detailContext?.onSetContextPaths([props.path]);
  }, [props.path]);

  const onClickDelete = (dataItem) => {
    // console.log("Delete", dataItem);
    deletePath(dataItem[2])
    .then(() => notification.success({message: "Dataset deleted"}));
  }
  console.log('Analyst', props);

  // /** @type {models.File.Data} */
  // const file = props.file;
  // /** @type {Array<string,models.File.Data,string>[]} */
  // const files = props.files;
  // /** @type {models.Activity.Data} */
  // const activity = props.activity;
  // /** @type {models.EmailMessage.Data} */
  // const message = props.message;
  // /** @type {models.EmailMessage.Data[]} */
  // const messages = props.messages;

  // console.log('FileDetails', props);
  // console.log('path', path);

  // if (!file && !loading) {
  //   return <Alert type="info" message="Not found" description="File was not found. It may have been removed or is no longer available." showIcon/>
  // }
  // if (file?.spreadsheet) {
  //   return <SpreadsheetExplorer file={file} path={path} />
  // }
  const onClickEmail = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    // detailContext?.onSetContextPaths &&
    // detailContext?.onSetContextPaths([path]);
    return false;
  }

  return <Layout.Content style={{position: 'relative'}}>
    <section style={{position: "relative", width: '100%', boxSizing: 'border-box'}}>
      {/*<Affix target={() => mainContent.current} offsetTop={40} style={{ width: ((width - 800)/2) * .65, position: 'absolute', top: 65, left: 40 }}>*/}
      {/*  <div className="space space-col">*/}
      {/*    /!*<Attachment mediaId={mediaId} messageId={media?.messageId} />*!/*/}
      {/*    <Card className="card-simple card-rounded">*/}
      {/*      <Typography.Title level={5}>Work Smarter</Typography.Title>*/}
      {/*      /!*<div className="space space-center-y">*!/*/}
      {/*      /!*  {media.messageId && <LinkOutlined size={16} className="muted-icon" style={{marginBottom: "-3px"}}/>}*!/*/}
      {/*      /!*  /!*<Typography.Link href={`${media.publicUrl}`} target="_blank">*!/*!/*/}
      {/*      /!*  /!*  <Attachment mediaId={mediaId} messageId={media?.messageId} />*!/*!/*/}
      {/*      /!*  /!*</Typography.Link>*!/*!/*/}
      {/*      /!*</div>*!/*/}
      {/*      <Typography.Text type="secondary">Enable {documentType} AI and make this instantly searchable. Instantly find details in photos and important files. Automate accounting, invoicing, and other tedious back-office tasks.</Typography.Text>*/}
      {/*      <Link href="/"> More</Link>*/}
      {/*    </Card>*/}
      {/*  </div>*/}
      {/*</Affix>*/}
      {/*<DashboardFullRow><HoverStrip onClick={detailContext.onClose} /></DashboardFullRow>*/}
      {/*<DashboardRow>*/}
      {/*  <div className="space space-center-y gap-8" style={{paddingTop: '14px'}}>*/}
      {/*    <Typography.Title level={5} className="green-color">{startCase(props.data.name)}</Typography.Title>*/}
      {/*  </div>*/}
      {/*</DashboardRow>*/}
      {/*<DashboardRow>*/}
        <Card
          style={{width: '96%', margin: "120px auto 0 auto", maxWidth: '1600px'}}
          bordered={false}
          title={<Typography.Title level={5} className="ai-texts">X Profiler</Typography.Title>}
          className="card-rounded card-simple extra-round card-body-scroll"
          // extra={
          // <div className="space space-center-y">
          //   {/*{props.data?.name ?*/}
          //   {/*  <Tag color="green" title={"Ready"} className="icon-btn" icon={<CheckIcon className="green-icon" />}>Ready</Tag> :*/}
          //   {/*  <Tag color="orange">Building</Tag>}*/}
          //   {/*<span className="muted font-sm uppercase">{props.data?.documentCount} records</span>*/}
          //   {/*<Divider type="vertical" />*/}
          //   {/*<Button size="small" ghost onClick={() => onClickDelete(dataItem)} className="icon-btn icon-left">*/}
          //   {/*  <DeleteIcon className="green-icon" />*/}
          //   {/*</Button>*/}
          //   {/*<Button size="small" ghost><ForwardIcon className="green-icon" /></Button>*/}
          // </div>}
        >
          <div className="space space-col space-center">
            {(props.data?.datasetSummary || props.data?.description) &&
              <MarkdownTimeline
                className="font-lg"
                markdown={props.data?.datasetSummary || props.data?.description}
              // components={{
              //   p: (props) => {
              //     return <Typography.Paragraph
              //       className="ai-text"
              //       style={{fontSize: '30px', lineHeight: '1.4em', marginBottom: '15px'}}
              //       ellipsis={{rows: 6, expandable: true}}
              //     >{props.children}
              //     </Typography.Paragraph>
              //   }}}
              />}
              {/*<div className="space space-col">*/}
                {/*  <Typography.Text className="ai-text font-lg">{props.data?.datasetSummary}</Typography.Text>*/}
                {/*  <br/>*/}
                {/*{props.data?.questions?.map((question) => {*/}
                {/*  return <div style={{padding: '10px 0', marginBottom: '10px'}}>*/}
                {/*    <span className="fat-chip" style={{padding: '10px 20px 10px 20px'}}>*/}
                {/*      <Typography.Text className="underline font-lg">{question.question}</Typography.Text>*/}
                {/*    </span>*/}
                {/*  </div>*/}
                {/*})}*/}
                {/*</div>*/}
              {/*</Col>*/}
            {/*</Row>*/}
            {/*<section style={{height: '700px'}}>*/}
            {/*  <QuickPrompts*/}
            {/*    questions={props.data?.questions}*/}
            {/*    agentId="ai-analyst"*/}
            {/*    collectionPath={`${props.path}/ai-analyst`}*/}
            {/*    limit={5}*/}
            {/*    onClick={appActions.enterChatMode}*/}
            {/*  />*/}
            {/*  /!*{!media?.content &&*!/*/}
            {/*  /!*  <LoaderButton loader={getContentLoader(media)} title={`Synthesize ${media?.documentType || 'document'}`} loaderData={{path}} size="medium">*!/*/}
            {/*  /!*    <div className="space space-center-y"><FeatherIcon className="ai-text" size={18} /><h6 className="ai-text">Synthesize</h6></div>*!/*/}
            {/*  /!*  </LoaderButton>}*!/*/}
            {/*  /!*<DocumentSummary summary={file?.summary?.text}/>*!/*/}
            {/*</section>*/}
            {/*<div style={{padding: '20px 20px 20px 0'}}>*/}
            {/*  <MarkdownTimeline markdown={props.title} components={{*/}
            {/*    p: (props) => {*/}
            {/*      return <Typography.Text className="ai-text" style={{*/}
            {/*        fontSize: '40px',*/}
            {/*        lineHeight: '1.4em',*/}
            {/*        marginBottom: '15px'*/}
            {/*      }}>{props.children}</Typography.Text>*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  />*/}
            {/*  {!!props.subtitle &&*/}
            {/*    <MarkdownTimeline markdown={props.subtitle?.substring(0, 500)} components={{*/}
            {/*      p: (props) => {*/}
            {/*        return <Typography.Text className="ai-text" style={{*/}
            {/*          fontSize: '26px',*/}
            {/*          lineHeight: '1.45em'*/}
            {/*        }}>{props.children}</Typography.Text>*/}
            {/*      }*/}
            {/*    }}*/}
            {/*    />}*/}
            {/*  /!*<Typography.Title*!/*/}
            {/*  /!*  level={(event.update?.summary || event.summary).length < 300 ? 1 : 2}*!/*/}
            {/*  /!*  className="ai-text"*!/*/}
            {/*  /!*>{event.update?.summary || event.summary}*!/*/}
            {/*  /!*</Typography.Title>*!/*/}
            {/*  <br/>*/}
            {/*  <div className="space space-center-y">*/}
            {/*    /!*  <span*!/*/}
            {/*    /!*    className="icon-btn icon-left"*!/*/}
            {/*    /!*    onClick={props.onClick}*!/*/}
            {/*    /!*  >*!/*/}
            {/*    /!*  <SpaceBarIcon size={50}/>*!/*/}
            {/*    /!*</span>*!/*/}
            {/*    {props.hasTodo && <h6*/}
            {/*      // style={{position: "absolute", bottom: "30px", left: "0", right: "40px", paddingTop: "6px"}}*/}
            {/*    >*/}
            {/*      <div className="space space-center-y space-between">*/}
            {/*        <StatusActions*/}
            {/*          path={props.itemPath}*/}
            {/*          itemId={props.itemPath}*/}
            {/*          initialStatus={props.status}*/}
            {/*          iconClassName="green-icon"*/}
            {/*          actions={['done', 'dismiss']}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </h6>}*/}
            {/*    /!*{!!props.dueDate && <span>Due {dayjs(props.dueDate).format('M D')}</span>}*!/*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<span>{(event.updates?.activitySummary || event.summary).length}</span>*/}
            {/*<Space>{event.labels?.map((label, i) => <Tag key={i}><h4>{label}</h4></Tag>)}</Space>*/}
          </div>
          {/*<br/>*/}
          {/*<br/>*/}
          <DatasetData
            width={"100%"}
            path={props.path}
            component={DatasetTable}
            bordered={false}
          />
        </Card>
            {/*<Card*/}
            {/*  bordered={false}*/}
            {/*  title={*/}
            {/*    <div className="space space-center-y">*/}
            {/*      <AttachmentIcon/>*/}
            {/*      /!*<label className="card-header-title-text">{file?.form?.name || file?.other?.name}</label>*!/*/}
            {/*    </div>*/}
            {/*  } className="card-rounded card-simple extra-round extra-padding card-h-600">*/}
            {/*  /!*<FileCard*!/*/}
            {/*  /!*  showDetail*!/*/}
            {/*  /!*  file={file}*!/*/}
            {/*  /!*  size="xl"*!/*/}
            {/*</Card>*/}

          {/*{files.map(([,file]) => <SlideCardWrapper key="previews" size="xl">*/}
          {/*  <Card*/}
          {/*    bordered={false}*/}
          {/*    title={*/}
          {/*      <div className="space space-center-y">*/}
          {/*        <AttachmentIcon/>*/}
          {/*        <label className="card-header-title-text">{file?.form?.name || file?.other?.name}</label>*/}
          {/*      </div>*/}
          {/*    } className="card-rounded card-simple extra-round extra-padding card-h-600">*/}
          {/*    <FileCard*/}
          {/*      showDetail*/}
          {/*      file={file}*/}
          {/*      size="xl"*/}
          {/*    />*/}
          {/*  </Card>*/}
          {/*</SlideCardWrapper>)}*/}
          {/*<SlideCardWrapper key="3">*/}
          {/*  <Card*/}
          {/*    bordered={false}*/}
          {/*    title={<div className="space space-center-y"><AttachmentIcon/><label className="card-header-title-text">March*/}
          {/*      18</label></div>}*/}
          {/*    className="card-rounded card-simple extra-round extra-padding card-h-600"*/}
          {/*  >*/}
          {/*  </Card>*/}
          {/*</SlideCardWrapper>*/}
          {/*<SlideCardWrapper key="3a">*/}
          {/*  <div className="space space-col">*/}
          {/*    <Card*/}
          {/*      bordered={false}*/}
          {/*      // title={<div className="space space-center-y"><AttachmentIcon/><label className="card-header-title-text">March 18</label></div>}*/}
          {/*      className="card-rounded card-simple extra-round extra-padding card-h-300"*/}
          {/*    >*/}
          {/*    </Card>*/}
          {/*    <Card*/}
          {/*      bordered={false}*/}
          {/*      // title={<div className="space space-center-y"><AttachmentIcon/><label className="card-header-title-text">March 18</label></div>}*/}
          {/*      className="card-rounded card-simple extra-round extra-padding card-h-300"*/}
          {/*    >*/}
          {/*    </Card>*/}
          {/*  </div>*/}
          {/*</SlideCardWrapper>*/}

      {/*</DashboardRow>*/}
      {/*<Divider className="small-top" />*/}
      {/*{!media?.content && <LoaderButton loader="attachments-synthesize" title={`Synthesize ${media?.documentType || 'document'}`} loaderData={{path}} size="medium">*/}
      {/*  <div className="space space-center-y"><FeatherIcon className="ai-text" size={18} /><h6 className="ai-text">Synthesize</h6></div>*/}
      {/*</LoaderButton>}*/}
      {/*<Divider />*/}
      {/*<Row>*/}
      {/*  <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} />*/}
      {/*  <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={14}>*/}
      {/*  <Toggle trigger={<div className="space space-center-y">*/}
      {/*    <h6 className="muted font-xl">{file?.form?.name} Brief</h6>*/}
      {/*    <LineConnector />*/}
      {/*    <Icon name="DownArrowOutlined" size={26} />*/}
      {/*    </div>}*/}
      {/*    defaultExpanded={true}*/}
      {/*  >*/}
      {/*    <section>*/}
      {/*      /!*{!media?.content &&*!/*/}
      {/*      /!*  <LoaderButton loader={getContentLoader(media)} title={`Synthesize ${media?.documentType || 'document'}`} loaderData={{path}} size="medium">*!/*/}
      {/*      /!*    <div className="space space-center-y"><FeatherIcon className="ai-text" size={18} /><h6 className="ai-text">Synthesize</h6></div>*!/*/}
      {/*      /!*  </LoaderButton>}*!/*/}
      {/*      <DocumentSummary summary={file?.summary?.text} />*/}
      {/*    </section>*/}
      {/*  </Toggle>*/}
      {/*  </Col>*/}
      {/*  <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1} />*/}
      {/*  <Col xs={0} sm={0} md={0} lg={6} xl={6} xxl={7}>*/}
      {/*    /!*<Card className="card-transparent card-rounded card-simple extra-round">*!/*/}
      {/*      <FileCard*/}
      {/*        showDetail*/}
      {/*        media={file}*/}
      {/*        mediaId={mediaId}*/}
      {/*        path={path}*/}
      {/*        size="xl"*/}
      {/*      />*/}
      {/*    /!*</Card>*!/*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {/*<br />*/}
      {/*<a href={media?.webViewLink || "#"} className="external-link splash-extra-link">*/}
      {/*  <Image src={media?.iconLink} width={40} height={40} />*/}
      {/*</a>*/}
      {/*<br />*/}
      {/*</div>*/}
      {/*<br />*/}
      {/*<br />*/}
      {/*<div style={{width: '100%'}}>*/}
      {/*<Tabs*/}
      {/*  items={[*/}
      {/*    // {*/}
      {/*    //   label: <Button ghost size="small"><Icon name="FeatherIcon" /></Button>,*/}
      {/*    //   key: "chat",*/}
      {/*    //   children: <Fragment>*/}
      {/*    //     <ChatContextX.Provider value={[,chatCollectionPath,null]}>*/}
      {/*    //       <ChatThread*/}
      {/*    //         limit={3}*/}
      {/*    //         collectionPath={chatCollectionPath}*/}
      {/*    //         style={{height: 'auto'}}*/}
      {/*    //         chatTip="I can answer questions about this document, share, detect changes, and more."*/}
      {/*    //         filters={{">": {"prompt.createdTs": cutOff}}}*/}
      {/*    //       />*/}
      {/*    //     </ChatContextX.Provider>*/}
      {/*    //     /!*<ScribeWorkflow onInfo={onInfo} collectionPath={chatCollectionPath} input={{pills: ['Which tasks can you help with?', 'Dismiss', 'Resolved', 'Remind me later']}}/>*!/*/}
      {/*    //     <ScribeWorkflow*/}
      {/*    //       collectionPath={chatCollectionPath}*/}
      {/*    //       input={{pills: ['Which tasks can you help with?', `Summarize this ${documentType || 'document'}`]}}*/}
      {/*    //       showThread={false}*/}
      {/*    //     />*/}
      {/*    //   </Fragment>,*/}
      {/*    // },*/}
      {/*    // {*/}
      {/*    //   label: <Button ghost size="small"><LayersIcon /></Button>,*/}
      {/*    //   key: "documents",*/}
      {/*    //   children: <MediaList mediaItems={documents} pageSize={1} showSummary />,*/}
      {/*    // },*/}
      {/*    // {*/}
      {/*    //   label: <Button ghost size="small"><EmailIconOutlined size={24} /></Button>,*/}
      {/*    //   key: "messages",*/}
      {/*    //   children: <EmailsCard messages={messages || []} pageSize={1} title="" card />,*/}
      {/*    // },*/}
      {/*    // {*/}
      {/*    //   label: <Button ghost size="small"><TodoIcon /></Button>,*/}
      {/*    //   key: "todos",*/}
      {/*    //   children: <TodosList list={todos} pageSize={2} grid={{gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2}} />,*/}
      {/*    // },*/}
      {/*  ]}*/}
      {/*/>*/}
      {/*<br />*/}
      {/*<br />*/}
      {/*</div>*/}
    </section>
  </Layout.Content>
}

export default Analyst;
