import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
// import LineAppTasks from "./LineAppTasks";
import CallsList from "./CallsList";

function CallsContent() {
  return <DashboardFullRow>
    <section style={{width: "100%", margin: "0 auto"}}>
      <ServiceData serviceId="calls" component={CallsList} />
    </section>
  </DashboardFullRow>
}

export default CallsContent;
