/** @namespace user.onboarding */
import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {Button, Card, Col, Divider, Input, List, Progress, Row, Statistic, Tabs, Tag, Tooltip, Typography} from "antd";
import {Link, useLocation} from "wouter";
import {AppContext, WindowContext} from "@web/hooks/context";
import DashboardRow from "../DashboardRow";
import {notifyAndTrack} from "../notification";
// import {useInView} from 'react-intersection-observer';
import Icon from "../Icon";
import {
  ApplicationIcon, AppstoreIcon, EmbedIcon,
  FeatherIcon, LeftArrowOutlined,
  PlusCircle,
  PlusIcon, QuoteLeftIcon,
  RightArrowOutlined, WebsiteIcon,
  XIcon,
} from "@web/assets/icons";
import MemoryOriginDate from "../MemoryOriginDate";
import AppIntegrationsData from "@web/ui/containers/AppIntegrationsData";
import AppIntegrationsList from "../AppIntegrationsList";
import {updatePath, Fields, buildQuery} from "@web/lib/firestore.db";
import {useDispatch, useSelector} from "react-redux";
import UserTaskProgressManager from "../UserTaskProgressManager";
import Toggle from "../Toggle";
import {useAuthOven} from "@web/hooks/useAuthOven";
import {auth} from "@web/lib/firebase";
import PaymentMiniCard from "../PaymentMiniCard";
import DashboardCenterRow from "../DashboardCenterRow";
import InputSaveButton from "../InputSaveButton";
import aiActions from "@web/redux/ai/actions";
import useChatStream from "@web/hooks/useChatStream";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import dayjs from "dayjs";
// import AssistantCallManager from "../AssistantCallManager";
import Hotline from "../Hotline";

const aboutLineAiMarketplace = [
  "A New Real-Time Marketplace",
  "The Line AI Marketplace is a new kind of marketplace that connects consumers with thousands of micro-entrepreneurs. It is a real-time, AI-driven platform that dynamically matches consumer preferences with small businesses. The platform automates matchmaking, discovery, and negotiation between consumers and micro-brands. It also handles logistics, payments, and compliance.",
  "a. A Real-Time, AI-Driven Platform",
  "1. Matchmaking & Discovery: Imagine a marketplace where AI not only handles logistics and payments but also dynamically matches consumer preferences with small businesses. Think of it as a “personal shopper AI” that scours thousands of mini-brands in real time to find the perfect fit for each consumer.",
  "2. Micro-Payments & Subscription Models: With frictionless digital payments (possibly micro-payments or subscription-based), consumers can seamlessly sample products or services from many small players.",
  "3. Automated Negotiation & Pricing: AI systems could dynamically negotiate prices, shipping terms, or even content licensing between micro-brands and consumers. This might replace or augment the usual static pricing in e-commerce sites.",
  "b. Characteristics of This Marketplace",
  "1. Line AI Agents on Both Sides: Consumers have personal AI that hunts for deals or curates brand recommendations. Sellers have AI that adjusts inventory, pricing, or promotional offers in real time.",
  "2. Fragmented Yet Interconnected: Thousands (or millions) of micro-entrepreneurs are networked in a platform that automates the “back office” (fulfillment, compliance, returns).",
  "3. Personalized Branding: Tools to let each brand project its unique persona at scale: custom chatbots with the founder’s voice, AR/VR experiences, or personalized video messages triggered by AI upon purchase.",
];

OnboardingFooter.propTypes = {
  onNext: PropTypes.func.isRequired,
  onSkip: PropTypes.func,
  onExit: PropTypes.func,
  nextText: PropTypes.string,
  hide: PropTypes.bool,
}

function OnboardingFooter(props) {
  return props.hide ?
    <div style={{height: 100}}>
      <div className="space space-center-y space-between">
        {!!props.onPrev && props.index > 0 ?
          <Button ghost size="large" shape="circle" className="icon-btn" onClick={() => props.onPrev()}>
            <LeftArrowOutlined className="musted-icon" />
            <span className="space space-center-y green-color">{props.prevText || ""}</span>
          </Button> : <div/>}
        {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
      </div>
    </div> :
    <div style={{height: 100}}>
      <Divider/>
      <div className="space space-center-y space-between">
        <div className="space space-center-y">
          {!!props.onPrev && props.index > 0 &&
            <Button ghost size="large" shape="circle" className="icon-btn" onClick={() => props.onPrev()}>
              <LeftArrowOutlined className="musted-icon" />
              <span className="space space-center-y green-color">{props.prevText || ""}</span>
            </Button>}
          <Button size="large" shape="round"  className="icon-btn" onClick={() => props.onNext()}>
            <span className="space space-center-y green-color">{props.nextText || "Next"}</span>
            <RightArrowOutlined className="green-icon"/>
          </Button>
        </div>
        <div>
          {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
          {props.onExit && <Button size="large" ghost onClick={props.onExit}><span className="muted">Exit</span></Button>}
        </div>
      </div>
    </div>
  // return props.hide ?
  //   <div style={{height: 100}}>
  //     <div className="space space-center-y space-between">
  //       <div/>
  //       {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
  //     </div>
  //   </div> :
  //   <div style={{height: 100}}>
  //     <Divider/>
  //     <div className="space space-center-y space-between">
  //       <Button size="large" shape="round" onClick={() => props.onNext()}>
  //         <h4 className="space space-center-y green-color">{props.nextText || "Next"} <RightArrowOutlined/></h4>
  //       </Button>
  //       <div>
  //         {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
  //         {props.onExit && <Button size="large" ghost onClick={props.onExit}><span className="muted">Exit</span></Button>}
  //       </div>
  //     </div>
  //   </div>
}

function CenterWrapper(props) {
  // const [isMobile, width, height] = useContext(WindowContext);
  return <Row className="w-full">
    <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3} />
    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18} className="overscroll-contain">
      <div
        className={`splash-card-wrapper flex-col middle${props.isMobile ? " splash-card-wrapper-mobile" : " center"}`}
        style={{minHeight: props.isMobile ? "101lvh" : props.height + "px", padding: "20px 10px", width: "100%"}}
      >
        {props.children}
        {/*{props.floatRight &&*/}
        {/*  <div className="w-full">*/}
        {/*    <div style={{position: "fixed", left: width / 2, top: 0}}>*/}
        {/*      {props.floatRight}*/}
        {/*    </div>*/}
        {/*  </div>}*/}
      </div>
    </Col>
  </Row>
}

function LeftWrapper(props) {
  return <Row className="w-full">
    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14} className="overscroll-contain">
      <div
        className={`splash-card-wrapper flex-col middle${props.isMobile ? " splash-card-wrapper-mobile" : " center"}`}
        style={{minHeight: props.isMobile ? "101lvh" : props.height + "px", padding: "20px 10px", width: "100%"}}
      >
        {props.children}
      </div>
    </Col>
    <Col xs={0} sm={0} md={0} lg={0} xl={10} xxl={10} />
  </Row>
}

PropTypes.Welcome = {
  setDisableNavigation: PropTypes.func.isRequired,
  pauseAnimation: PropTypes.func.isRequired,
  startAnimation: PropTypes.func.isRequired,
  navigateNext: PropTypes.func.isRequired,
  markCompleteAndNavigateNext: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export function Welcome(props) {
  console.log("Welcome:", props);
  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "92%", maxWidth: 800, margin: "0 auto"}}>
        <div className="space space-col splash-home-title-wrapper">
          <Typography.Title level={1}>Let's get started</Typography.Title>
          <Typography.Text>
            You'll set up your Line and launch your new AI ally in just a few minutes
          </Typography.Text>
          <br/>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onSkip={props.onExit}
            />
          </div>
          {/*<Markdown markdown={`# A [brain](#video-link?url=${encodeURIComponent("https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479")}) for your business.`}/>}*/}
        </div>
      </div>
    </DashboardRow>
  </LeftWrapper>
}

export function UserTask(props) {
  const [isMobile, width, height] = useContext(WindowContext);
  const dispatch = useDispatch();
  const task = useSelector((state) => state.Content?.data?.[props.path]);
  // console.log(">>>>>UserTask:", props.isActive);

  React.useEffect(() => {
    // console.log("UserTask:useEffect:", props.path, props.isActive);
    if (props.isActive === false) return;
    // console.log(">>>>>>>>>>UserTask:isActive:", "isActive");
    props.setContext &&
    props.setContext({task, path: props.path, response: {}});
    props.path &&
    dispatch(aiActions.setActiveUserTask(props.path));
  }, [task, props.path, props.isActive, props.setContext]);

  const bigScreen = width > 1728;

  return <CenterWrapper isMobile={isMobile} height={height}>
      <div className="space space-col" style={{width: "100%", margin: "0 auto"}}>
        <Typography.Title
          level={bigScreen ? 1 : 2} style={bigScreen ? {marginBottom: "0.25em"} : {
          fontSize: "50px",
          marginBottom: "0.25em",
        }}
        >
          {task.summary}
        </Typography.Title>
        <span className="font-xl ai-text">
        <Tag color="magenta">GOAL</Tag>
          {task.goal}
        </span>
        <br />
        <Toggle
          hideTriggerOnExpand
          // showExpandCaret={false}
          trigger={
            task.isCompleted ?
              <div style={{width: 260}}>
                <h4 className="space space-center-y font-xl space-between green-color">View Completion
                  History <RightArrowOutlined size={28} /></h4>
              </div> :
              <div style={{width: 205}}>
                <h4 className="space space-center-y font-xl space-between green-color">Work on this
                  task <RightArrowOutlined size={28} /></h4>
              </div>
          }
        >
          <UserTaskProgressManager
            agentId={"ai-progress"}
            path={props.path}
            onAddContextPath={(path) => console.log("UserTask:add-context-path:", path)}
            onRemoveContextPath={(path) => console.log("UserTask:remove-context-path", path)}
          />
        </Toggle>
      </div>
  </CenterWrapper>
}

export const Community = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        summary: [
          "Explain the Line AI community and marketplace experience and how to get the most from it.",
          ...aboutLineAiMarketplace,
        ].join("\n"),
      },
      path: props.path,
    });
  }, []);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>The Exchange</Typography.Title>
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <Card.Meta title="Share my bio"></Card.Meta>
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <Typography.Text className="muted">
              The Exchange is a new type of content agnostic network where you can share anything in any format at any
              time. All Line Agents are connected to the exchange where they interact with other Agents to find business
              opportunities, complete your requests, retrieve information and perform tasks. An unbelievably fast and
              simple way to post or find something, buy, sale, get help, or just share what is on your mind.
            </Typography.Text>
          </div>
          <div style={{width: "100%", paddingTop: "0"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </LeftWrapper>;
};

export const Origin = (props) => {
  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Origin Date</Typography.Title>
          <Typography.Text>
            How far back should we go when fetching data from email, drives, and other apps that you choose to connect
            to your AI brain?
          </Typography.Text>
          <br />
          <MemoryOriginDate />
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </LeftWrapper>
};

export const Assistant = (props) => {
  const [assistantName, setAssistantName] = React.useState("");
  const personasMap = {
    visionary: "Your forward-thinking partner, perfect for business owners looking to stay ahead of the curve. Thrives on innovation, offering proactive insights and strategic advice. Specializes in breaking down complex problems, identifying trends, and helping businesses plan for sustainable growth. Their tone is confident, inspiring, and subtly playful, keeping you engaged and motivated.",
    efficient: "Your go-to for getting things done quickly and smoothly. Detail-oriented, reliable, and resourceful—like that coworker who always knows where to find the answer. Friendly, approachable, and straight to the point - perfect when you want a fast, no-nonsense assistant.",
    creative: "The imaginative and empathetic partner, ideal for fresh ideas and collaboration. Loves brainstorming, exploring creative solutions, and finding ways to turn big ideas into reality. A warm tone, encouraging, and curious, fostering a sense of camaraderie that inspires you to think outside the box.",
  }

  const assistantNameOnChange = (e) => {
    const name = e.target.value.trim();
    setAssistantName(name);
  }

  const getVoiceName = (persona = "efficient", gender = "neutral") => {
    const voiceKey = `${persona}-${gender}`;
    switch (voiceKey) {
      case "efficient-man":
        return "echo";
      case "efficient-woman":
        return "sage";
      case "efficient-neutral":
        return "alloy";
      case "visionary-man":
        return "verse";
      case "visionary-woman":
        return "shimmer";
      case "visionary-neutral":
        return "alloy";
      case "creative-man":
        return "ash";
      case "creative-woman":
        return "shimmer";
      case "creative-neutral":
        return "alloy";
    }
  }

  const saveVoice = ({gender = props.assistant?.gender, persona = props.assistant?.persona}) => {
    updatePath(props.assistantPath, {
      voice: {
        ...props.assistant?.voice || null,
        name: getVoiceName(persona, gender),
      }
    });
  }

  const saveGender = (gender) => {
    updatePath(props.assistantPath, {gender: gender})
    .then(() => notifyAndTrack.success("Success", "Voice updated"))
    .then(() => saveVoice({gender}))
  };

  const saveAssistantName = () => {
    props.onUpdateStep(props.path, {
      assistantName: assistantName.trim(),
    })
    .then(() => {
      setAssistantName("");
      notifyAndTrack.success("Success", "Assistant Name updated");
    });
  };

  const savePersona = (persona) => {
    updatePath(props.assistantPath, {persona, personaDescription: personasMap[persona]})
    .then(() => notifyAndTrack.success("Success", `Persona ${persona}`))
    .then(() => saveVoice({persona}))
  };

  const isComplete = () => {
    return props.assistant.gender &&
      props.assistant.persona &&
      props.assistant.assistantName &&
      (!assistantName || props.assistant.assistantName === assistantName);
  }

  const onClickContinue = () => {
    props.markCompleteAndNavigateNext(props.path);
  }

  React.useEffect(() => {
    if (!props.isActive) return;
    props.showDrawer(false);
  }, [props.path, props.isActive]);

  const onFocus = () => {
    props.pauseAnimation();
  }

  const onBlur = () => {
    if (assistantName && assistantName !== props.assistant?.assistantName) {
      return;
    }
    props.startAnimation();
  }

  return <CenterWrapper isMobile={props.isMobile} height={props.height}>
    <div style={{width: "100%", maxWidth: 900, margin: "0 auto"}}>
      <div className="space space-col">
        {!props.isMobile && <Typography.Title>Line</Typography.Title>}
        <br/>
        <List size="large">
          <List.Item
            actions={
            Object.keys(personasMap).map((persona) => {
              return <Button key={persona} size="large" className="icon-btn" ghost disabled={props.assistant.persona === persona} onClick={(e) => savePersona(persona)}>
                {persona}
              </Button>
            })}>
            <div className="space space-center-y"><h2>Persona</h2></div>
          </List.Item>
          <List.Item
            actions={[
              <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>
                Man</Button>,
              <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>
                Neutral</Button>,
              <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>
                Woman
              </Button>,
            ]}>
            <div className="space space-center-y"><h2>Voice</h2></div>
          </List.Item>
          <List.Item actions={[
            <div className="space space-center-y relative">
              <Input
                maxLength={10}
                value={assistantName}
                placeholder={props.assistant?.assistantName || "Type assistant name"}
                size="large"
                bordered={false}
                className={`input-bold`}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={assistantNameOnChange}
              />
              <InputSaveButton
                onClick={saveAssistantName}
                show={!!assistantName}
                disabled={assistantName?.length < 3}
              />
            </div>,
          ]}>
            <div className="space space-center-y"><h2>Name</h2></div>
          </List.Item>
        </List>
        <div style={{width: "100%", paddingTop: "10px", height: 100}}>
          <OnboardingFooter
            onNext={onClickContinue}
            nextText={`Start ${props.assistant?.assistantName}`}
            // onExit={props.onExit}
            hide={!isComplete()}
          />
        </div>
      </div>
    </div>
  </CenterWrapper>
};

export const Messenger = (props) => {
  const [, user] = React.useContext(AppContext);
  const messengerPath = ["user", user.uid, "lines", "messenger"];
  const messenger = useSelector((state) => state.Lines.data?.[messengerPath]);
  // const lines = useSelector((state) => state.Lines.items);

  const saveCallRecordings = (callRecordings) => {
    updatePath(messengerPath, {callRecordings})
    .then(() => notifyAndTrack.success("Success", "Messenger updated"))
  };

  const onClickContinue = () => {
    // dispatch(voiceActions.connectCall({To: props.assistant.number}));
    props.markCompleteAndNavigateNext(props.path);
  }

  React.useEffect(() => {
    props.setContext({
      task: {
        summary: "Messenger answers calls and texts to your Line. Messenger will also discuss any topics you provide with callers making it fun and easy to communicate social or business information.",
        steps: [
          "Choose whether to transcribe calls by pressing Yes or No.",
          "The messenger will be available after onboarding is complete.",
        ],
      },
      path: props.path,
    });
  }, [props.path]);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Messenger</Typography.Title>
          <Typography.Text className="muted">Messenger handles inbound calls and text messages. It will take messages, transcribe calls so they are searchable, and even discuss any topics you provide.</Typography.Text>
          <List size="large">
            <List.Item
              actions={[
                <Button key="man" size="large" ghost disabled={messenger.callRecordings === "save"} className="icon-btn" onClick={(e) => saveCallRecordings("save")}>
                  Yes
                </Button>,
                <Button key="man" size="large" ghost disabled={messenger.callRecordings === "delete"} className="icon-btn" onClick={(e) => saveCallRecordings("delete")}>
                  No
                </Button>,
              ]}>
              <div className="space space-center-y"><h2>Transcribe Calls</h2></div>
            </List.Item>
            {/*<List.Item*/}
            {/*  actions={*/}
            {/*    Object.keys(personasMap).map((persona) => {*/}
            {/*      return <Button key={persona} size="large" className="icon-btn" ghost disabled={props.assistant.persona === persona} onClick={(e) => savePersona(persona)}>*/}
            {/*        {persona}*/}
            {/*      </Button>*/}
            {/*    })}>*/}
            {/*  <div className="space space-center-y"><h2>Message</h2></div>*/}
            {/*</List.Item>*/}
            {/*<List.Item*/}
            {/*  actions={[*/}
            {/*    // Add anything your Line assistant can discuss with subscribers who call or message your Line.*/}
            {/*    <div className="space space-center-y relative">*/}
            {/*    <Button key="man" size="large" shape="round" className="icon-btn green-color">*/}
            {/*      Files*/}
            {/*    </Button>*/}
            {/*    <Button key="man" size="large" shape="round" className="icon-btn green-color">*/}
            {/*      Message*/}
            {/*    </Button>*/}
            {/*    <Button key="man" size="large" shape="round" className="icon-btn green-color">*/}
            {/*      URL*/}
            {/*    </Button>*/}
            {/*    </div>*/}
            {/*    // <div className="space space-center-y relative">*/}
            {/*    //   <Input*/}
            {/*    //     maxLength={10}*/}
            {/*    //     value={assistantName}*/}
            {/*    //     placeholder={"Add content"}*/}
            {/*    //     size="large"*/}
            {/*    //     bordered={false}*/}
            {/*    //     className={`input-bold`}*/}
            {/*    //     onFocus={props.pauseAnimation}*/}
            {/*    //     onBlur={props.startAnimation}*/}
            {/*    //     onChange={assistantNameOnChange}*/}
            {/*    //   />*/}
            {/*    //   {assistantName && (assistantName.length >= 3) && <Button size="large" ghost className="icon-btn" style={{position: "absolute", right: 5, top: 5}} onClick={saveAssistantName}><CheckIcon /></Button>}*/}
            {/*    // </div>,*/}
            {/*    // <UploadButton />,*/}
            {/*    // <div className="space space-center-y relative" style={{width: 400}}>*/}
            {/*    //   <ChatInputManager*/}
            {/*    //     pushPrompt={{}}*/}
            {/*    //     onSend={onSend}*/}
            {/*    //     onFocus={onFocus}*/}
            {/*    //     onError={onError}*/}
            {/*    //     onMediaReady={onMediaReady}*/}
            {/*    //     placeholder="File text audio"*/}
            {/*    //   >*/}
            {/*    //     <ChatInputTextarea dictate={true} primary ai />*/}
            {/*    //   </ChatInputManager></div>,*/}
            {/*  ]}>*/}
            {/*  <div className="space space-col">*/}
            {/*    <h2>Topics</h2>*/}
            {/*  </div>*/}
            {/*</List.Item>*/}
            {/*<List.Item>*/}
            {/*  <div className="space space-center-y relative">*/}
            {/*    <ChatInputManager*/}
            {/*      pushPrompt={{}}*/}
            {/*      onSend={onSend}*/}
            {/*      onFocus={onFocus}*/}
            {/*      onError={onError}*/}
            {/*      onMediaReady={onMediaReady}*/}
            {/*      placeholder="Add files, audio, text"*/}
            {/*    >*/}
            {/*      <ChatInputTextarea dictate={true} primary ai />*/}
            {/*    </ChatInputManager>*/}
            {/*  </div>*/}
            {/*</List.Item>*/}
            {/*<List.Item*/}
            {/*  actions={[*/}
            {/*    <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>*/}
            {/*      Man</Button>,*/}
            {/*    <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>*/}
            {/*      Neutral</Button>,*/}
            {/*    <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>*/}
            {/*      Woman*/}
            {/*    </Button>,*/}
            {/*  ]}>*/}
            {/*  <div className="space space-center-y"><h2>Topic</h2></div>*/}
            {/*</List.Item>*/}
          </List>
          {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
          {/*  <Typography.Text className="muted">*/}
          {/*    You are a member of a new community and marketplace where you can share anything in any format at any*/}
          {/*    time. An unbelievably fast and simple way to post or find something for sale, get help, or just share what*/}
          {/*    is on your mind.*/}
          {/*  </Typography.Text>*/}
          {/*</div>*/}
          <div style={{width: "100%", paddingTop: "10px", height: 100}} className="chat-feedback-view">
            <OnboardingFooter
              onNext={onClickContinue}
              // onExit={props.onExit}
              // hide={!isComplete()}
            />
            {/*{isComplete() && <Divider className="small-top" />}*/}
            {/*{isComplete() &&*/}
            {/*  <Button size="large" className="icon-btn" shape="round" onClick={onClickContinue}>*/}
            {/*    <h4 className="space space-center-y green-color">Continue <RightArrowOutlined /></h4>*/}
            {/*  </Button>}*/}
          </div>
        </div>
      </div>
    </DashboardRow>
  </LeftWrapper>
};

export const Features = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Ensure the client is familiar with all available tools and features.",
        summary: "Explain the tools and features that you can use to get things done 24/7 as a Line AI assistant. Provide some specific examples relevant to the client's profile and goals to show how you can help the client work smarter and live better."},
      path: props.assistantPath,
    });
  }, []);

  return <Wrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>{props.assistant.assistantName || "Line"} is there 24/7</Typography.Title>
          <List size="large">
            <List.Item
              // actions={[
              //   <Button key="remove" shape="round" size="large" className="green-color" ghost onClick={(e) => null}>
              //     Call
              //   </Button>,
              //   <Button key="remove" shape="round" size="large" className="green-color" ghost onClick={(e) => null}>
              //     Text
              //   </Button>,
              //   <Button key="remove" shape="round" size="large" className="green-color" ghost onClick={(e) => null}>
              //     Chat
              //   </Button>,
              // ]}
            >
              <div className="space space-center-y">
                <h2>Using an ever growing <span className="underline">toolkit</span> to find information, plan and perform tasks on demand.</h2>
                {/*<h2>Make and receive calls</h2>*/}
              </div>
            </List.Item>
          </List>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </Wrapper>
};

export const Connect = (props) => {
  const agentId= "ai-line-connect-email-guide";
  const [, sendRequest] = useChatStream(agentId, true);
  const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  // const [dataItems, loadingChat] = useQueryFirestore(buildQuery(collectionPath, {}, {limit: 1, order: [["date", "desc"]]}));
  const [sent, setSent] = React.useState(false);

  /**
   * @function onSend
   * @param {object} updates
   * @type {(function(*, *): void)|*}
   */
  const onSend = (updates) => {
    if (!props.isActive) return;
    !sent && sendRequest(
      `New profile update: ${JSON.stringify(updates)}`,
      {
        history: 3,
        collectionPath,
        contextPaths: [clientLinePath],
      });
    setSent(true);
  };

  React.useEffect(() => {
    if (!props.isActive) {
      return;
    }
    const chat = {
      prompt: {
        text: "Connect your email",
      },
      answers: [{
        agentId: "ai-line-connect-email-guide",
        text: "Connecting your email allows your Line to help you get work done.",
        role: "assistant",
      }]
    };
    props.showDrawer(true, {chat, agentId, collectionPath});

    // if (loadingChat) {
    //   return;
    // }
    //
    // console.log("Connect:", dataItems);
    //
    // if (dataItems?.[0]?.[1]?.updatedTs > dayjs().subtract(1, "hour").valueOf()) {
    //   return;
    // }
    // !sent && onSend();
    // props.setContext({
    //   task: {
    //     goal: "Successfully Connect Email",
    //     summary: "Connect Email: Connect their email and other business tools so that you, the Line AI assistant, can help them. Connecting email is secure (OAuth2) and unlocks the most powerful features of Line AI.",
    //     steps: [
    //       "Click the connect button for the email provider you use",
    //       "Follow the prompts to connect your email account",
    //     ],
    //   },
    //   path: props.path,
    // });
  }, [props.isActive, sent]);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    {/*<DashboardRow leftSpan={2}>*/}
      <div style={{width: "100%", maxWidth: 700}}>
        <div className="space space-col">
          <Typography.Title>Connect Email</Typography.Title>
          <Typography.Text className="muted">
            Securely connect your email. Google or Microsoft.
          </Typography.Text>
          <br/>
          <Card className="card-simple card-rounded card-no-header card-glass" bordered={false}>
            <AppIntegrationsData filter="all" tags={["email"]}>
              <AppIntegrationsList/>
            </AppIntegrationsData>
          </Card>
          {/*<Typography.Text>*/}
          {/*  <Markdown*/}
          {/*    markdown={`to gathers valuable insights to help you visualize the people, activities, and patterns that shape your days, so that you can become more deliberate in your choices - [work smarter and live better.](#video-link?url=${encodeURIComponent(workSmarterUrl.toString())})`}/>*/}
          {/*</Typography.Text>*/}
        </div>
        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <br/>*/}
        {/*  /!*<Typography.Text className="muted">*!/*/}
        {/*  /!*  Securely connect your email. Google (Workspace) or Microsoft.*!/*/}
        {/*  /!*</Typography.Text>*!/*/}
        {/*</div>*/}
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            hide={!props.assistant?.userEmail}
            // onSkip={!props.user?.email ? props.navigateNext : null}
            onSkip={props.navigateNext}
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            nextText="Continue"
            // onExit={props.onExit}
          />
        </div>
      </div>
    {/*</DashboardRow>*/}
  </LeftWrapper>
};

export const Chat = (props) => {
  const agentId = "ai-line-onboarding-tasks-guide";
  const [, sendRequest] = useChatStream(agentId, true);
  const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const [dataItems, loadingChat] = useQueryFirestore(buildQuery(collectionPath, {}, {limit: 1, order: [["updatedTs", "desc"]]}));
  const [sentDefault, setSentDefault] = React.useState(false);

  /**
   * @function onSend
   * @param {string} [prompt]
   * @type {(function(*, *): void)|*}
   */
  const onSend = (prompt) => {
    if (!props.isActive) return;
    if (!prompt && sentDefault) {
      return;
    }

    props.showDrawer(true, {agentId, collectionPath}, "chat");
    sendRequest(
      prompt || `What can you help with?`,
      {
        history: 3,
        contextPaths: [clientLinePath],
        collectionPath,
      });
    setSentDefault(true);
  };

  React.useEffect(() => {
    if (!props.isActive) {
      return;
    }
    // const chat = {
    //   prompt: {
    //     text: "Connect your email",
    //   },
    //   answers: [{
    //     agentId: "ai-line-connect-email-guide",
    //     text: "Connecting your email allows your Line to help you get work done.",
    //     role: "assistant",
    //   }]
    // };
    props.showDrawer(true, {agentId, collectionPath}, props.path);

    if (loadingChat) {
      return;
    }

    if (dataItems?.[0]?.[1]?.updatedTs > dayjs().subtract(1, "hour").valueOf()) {
      console.log("Chat:DataItems:", dataItems);
      return props.showDrawer(true, {chat: dataItems[0][1]});
    }
    !sentDefault && onSend();
  }, [props.isActive, loadingChat, sentDefault]);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    {/*<DashboardRow leftSpan={1}>*/}
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Chat</Typography.Title>
          <Typography.Text className="muted">
            Line AI includes the best of the world's top models.
          </Typography.Text>
          <br/>
          <Card
            // title={<div className="space space-center-y relative">
            //   <Input
            //     // value={bio}
            //     maxLength={180}
            //     placeholder="Ask a question"
            //     size="large"
            //     bordered={false}
            //     className="input-bold"
            //     // onFocus={onFocus}
            //     // onBlur={onBlur}
            //     // onChange={(e) => setBio(e.target.value)}
            //     // onPressEnter={saveProfile}
            //   />
            //   <InputSaveButton
            //     // onClick={saveProfile}
            //     // show={!!bio}
            //     // disabled={bio?.length < 3}
            //   />
            // </div>}
            className="card-simple card-rounded card-no-header card-no-separator" bordered={false}>
            <List size="large">
              <List.Item
                actions={[
                  <div className="space space-center-y">
                    <Typography.Text>Great At &nbsp;</Typography.Text>
                  </div>
                ]}>
                <div className="space space-center-y"><Typography.Text className="muteds">Top models</Typography.Text><h2></h2><span className="muted"></span></div>
              </List.Item>
              <List.Item
                actions={[
                  <div className="space space-center-y">
                    <Tag className="muted rounded">CHAT</Tag>
                    <Tag className="muted rounded">REASONING</Tag>
                    {/*<Tag className="muted">LINE</Tag>*/}
                    {/*<Button size="large" ghost className="hidden"><Icon name="RightArrowOutlined"/></Button>*/}
                  </div>
                ]}>
                <div className="space space-center-y"><h2>Open AI</h2><span className="muted"></span></div>
              </List.Item>
              <List.Item
                actions={[
                  <div className="space space-center-y">
                    <Tag className="muted rounded">CHAT</Tag>
                    <Tag className="muted rounded">REASONING</Tag>
                    {/*<Button size="large" ghost className="hidden"><Icon name="RightArrowOutlined"/></Button>*/}
                  </div>
                ]}>
                <div className="space space-center-y">
                  <h2>Deepseek</h2>
                  {/*<Tag className="muted"> ALL</Tag>*/}
                </div>
              </List.Item>
              <List.Item
                actions={[
                  <div className="space space-center-y">
                    <Tag className="muted rounded">DOCUMENTS</Tag>
                    {/*<Button size="large" ghost className="hidden"><Icon name="RightArrowOutlined"/></Button>*/}
                  </div>
                ]}>
                <div className="space space-center-y">
                  <h2>Vertex</h2>
                  {/*<Tag className="muted">DOCS</Tag>*/}
                </div>
              </List.Item>
              <List.Item
                actions={[
                  <div className="space space-center-y">
                  <Tag className="muted rounded"> CODE</Tag>
                  {/*<Tag className="muted"> OPENAI</Tag>*/}
                  {/*  <Tag className="muted"> OPENAI</Tag>*/}
                  {/*<Button size="large" ghost className="hidden"><Icon name="RightArrowOutlined"/></Button>*/}
                  </div>
                ]}>
                <div className="space space-center-y">
                  <h2>Anthropic</h2>
                </div>
              </List.Item>
              {/*<List.Item*/}
              {/*  actions={[*/}
              {/*    <div className="space space-center-y">*/}
              {/*      <Tag className="muted"> CHAT</Tag>*/}
              {/*      <Tag className="muted"> TRAINING</Tag>*/}
              {/*      <Tag className="muted"> AGENTS</Tag>*/}
              {/*      <Button size="large" ghost><Icon name="RightArrowOutlined"/></Button>*/}
              {/*    </div>*/}
              {/*  ]}>*/}
              {/*  <div className="space space-center-y">*/}
              {/*    <h2>Line</h2>*/}
              {/*  </div>*/}
              {/*</List.Item>*/}
            </List>
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            {/*<Typography.Text className="ai-texts">*/}
            {/*  Connect the apps, drives, and other tools that you use for work.*/}
            {/*  /!*Click on the buttons as needed above, follow the prompts to connect your tools and apps.*!/*/}
            {/*</Typography.Text>*/}
          </div>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              // onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    {/*</DashboardRow>*/}
  </LeftWrapper>
};

export const Terms = (props) => {
  React.useEffect(() => {
    // props.setContext({
    //   task: {
    //     goal: "The client accepts the terms of service and privacy policy.",
    //     summary: "Ask that they review the standard Line AI privacy policy and terms of service",
    //   },
    //   path: props.path,
    //   response: {
    //     instructions: "Work on the active task",
    //   },
    // });
  }, [props.path]);

  useEffect(() => {
    props.setDisableNavigation(false);
  }, []);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Privacy</Typography.Title>
          <Typography.Text>
            We do not sell or share user data<br/><span className="muted">By continuing you agree to our <a href="/legal/terms.html">Terms of Service</a> and <a href="/legal/privacy.html">Privacy policy</a></span>
          </Typography.Text>
          {/*<Switch style={{width: 80}} checkedChildren="Agree" unCheckedChildren="Disagree" defaultChecked />*/}
          <br/>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            // onExit={props.onExit}
          />
          {/*<Divider/>*/}
          {/*<Button size="large" shape="round" onClick={() => props.markCompleteAndNavigateNext(props.path)}>*/}
          {/*  <h4 className="space space-center-y green-color">Continue <RightArrowOutlined/></h4>*/}
          {/*</Button>*/}
          {/*{props.onExit ? <Button size="large" ghost onClick={props.onExit}>Skip</Button> : <div/>}*/}
        </div>
        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <span className="ai-text">You can add a linked account later</span>*/}
        {/*</div>*/}
      </div>
    </DashboardRow>
  </LeftWrapper>
};

export const Agents = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {
        goal: "Learn about Custom Agents",
        summary: "Explain how to create and use custom agents to automate tasks and much more. This feature requires upgrade to Line AI Pro."},
      path: props.path,
    });
  }, []);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <div style={{width: "100%", maxWidth: 800}}>
      <div className="space space-col">
        <Typography.Title>Agent Apps</Typography.Title>
        <Typography.Text className="muted">
          Use Apps to give your Line Agent new skills and abilities.
        </Typography.Text>
        <br/>
        <List size="large">
          <List.Item actions={[<Button shape="round">GET</Button>]}>
            <div className="space space-center-y"><h2>Lookup LinkedIn when someone new emails me</h2><span className="muted"></span></div>
          </List.Item>
          <List.Item
            actions={[<Button shape="round">GET</Button>]}>
            <div className="space space-center-y"><h2>Plan weekly hikes based on weather forecast</h2></div>
          </List.Item>
          <List.Item
            actions={[<Button shape="round">GET</Button>]}>
            <div className="space space-center-y"><h2>Create daily summary for tech stocks</h2></div>
          </List.Item>
          <List.Item
            actions={[<Button shape="round">GET</Button>]}>
            <div className="space space-center-y"><h2>Remind Sally to take her umbrella if it will rain</h2></div>
          </List.Item>
          <List.Item
            actions={[<Button shape="round">GET</Button>]}>
            <div className="space space-center-y"><h2>Create social media posts based on my best work</h2></div>
          </List.Item>
        </List>
      </div>
      <div style={{width: "100%", paddingTop: "10px"}}>
        <OnboardingFooter
          onPrev={props.navigatePrev}
          onNext={() => props.markCompleteAndNavigateNext(props.path)}
          onExit={props.onExit}
        />
      </div>
    </div>
  </LeftWrapper>;
};

export const Sms = (props) => {
  const agentId = "ai-line-chat-assistant";
  const [sentDefault, setSentDefault] = React.useState(false);
  const [, sendRequest] = useChatStream(agentId, true);
  const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [clientLinePath, "managers", agentId, "ai-workflow"].join("/");

  /**
   * @function onSend
   * @param {string} [prompt]
   * @type {(function(*, *): void)|*}
   */
  const onSend = (prompt) => {
    if (!props.isActive) return;
    if (!prompt && sentDefault) {
      return;
    }

    props.showDrawer(true, {agentId, collectionPath}, "sms");
    sendRequest(
      prompt,
      {
        history: 3,
        contextPaths: [clientLinePath],
        collectionPath,
      });
  };

  React.useEffect(() => {
    props.showDrawer(true, {agentId}, "sms");
  }, []);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <div style={{width: "100%", maxWidth: 800}}>
      <div className="space space-col">
        <Typography.Title>Text Messages</Typography.Title>
        <Typography.Text className="muted">
          Text with {props.assistant?.assistantName} for super powers on the go.
        </Typography.Text>
        {/*<Input*/}
        {/*  maxLength={100}*/}
        {/*  placeholder={"Filter"}*/}
        {/*  size="large"*/}
        {/*  bordered={false}*/}
        {/*  className={`input-bold ${props.isMobile ? "mt-12" : "mt-20"}`}*/}
        {/*  onFocus={props.pauseAnimation}*/}
        {/*  onBlur={props.startAnimation}*/}
        {/*  // onChange={(e) => saveEmail(e.target.value)}*/}
        {/*/>*/}
        <br/>
        <List size="large">
          <List.Item actions={[<Button size="large" ghost><Tag className="muted rounded">TRY</Tag></Button>]} onClick={() => onSend("text me a surprise")}>
            <div className="space space-center-y cursor-pointer"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;<span className="underline">text me a surprise</span></h2></div>
          </List.Item>
          <List.Item
            // onClick={() => onSend("I'm bored lets text")}
            actions={[<Tag className="muted rounded">TEXT {props.assistant?.number.replace("+1", "")}</Tag>]}>
            <div className="space space-center-y cursor-pointer">
              <h2>
                <span>Text {props.assistant?.assistantName} anytime</span>
              </h2>
            </div>
          </List.Item>
          <List.Item actions={[<Button size="large" ghost><Tag className="muted rounded hidden">TRY</Tag></Button>]}>
            <div className="space space-center-y"><h2>Retrieve a file or photo, get reminders, vent or talk to when you're bored</h2><span className="muted"></span></div>
          </List.Item>
          {/*<List.Item*/}
          {/*  actions={[<Button size="large" ghost></Button>]}>*/}
          {/*  <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;send me a reminder text at 10am tomorrow</h2></div>*/}
          {/*</List.Item>*/}
          {/*<List.Item*/}
          {/*  actions={[<Button size="large" ghost></Button>]}>*/}
          {/*  <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;what happened since I left the office?</h2></div>*/}
          {/*</List.Item>*/}
        </List>
      </div>
      <div style={{width: "100%", paddingTop: "10px"}}>
        <OnboardingFooter
          onNext={() => props.markCompleteAndNavigateNext(props.path)}
          onExit={props.onExit}
        />
      </div>
    </div>
  </LeftWrapper>;
};

export const Calls = (props) => {
  const agentId = "ai-line-chat-assistant";
  const [sentDefault, setSentDefault] = React.useState(false);
  const [, sendRequest] = useChatStream(agentId, true);
  const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const activeCalls = useSelector((state) => state.Voice.activeCalls);
  // const [showCallManager, setShowCallManager] = React.useState(false);

  /**
   * @function onSend
   * @param {string} [prompt]
   * @type {(function(*, *): void)|*}
   */
  const onSend = (prompt) => {
    if (!props.isActive) return;
    if (activeCalls.length) {
      return;
    }
    if (!prompt && sentDefault) {
      return;
    }

    props.showDrawer(true, {agentId, collectionPath}, props.path);
    sendRequest(
      prompt,
      {
        history: 3,
        contextPaths: [clientLinePath],
        collectionPath,
      });
  };

  React.useEffect(() => {
    props.showDrawer(true, {agentId}, props.path);
  }, []);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
      <div style={{width: "100%", maxWidth: 700}}>
        <div className="space space-col">
          <Typography.Title>Voice Calls</Typography.Title>
          <Typography.Text className="muted">
            {props.assistant?.assistantName} can make and receive calls for you.
          </Typography.Text>
          <br/>
          <List size="large">
            {/*<List.Item*/}
            {/*  actions={[*/}
            {/*    <div className="space space-center-y">*/}
            {/*      /!*<Typography.Text>Great At &nbsp;</Typography.Text>*!/*/}
            {/*    </div>*/}
            {/*  ]}>*/}
            {/*  <div className="space space-center-y"><Typography.Text className="muteds">Examples</Typography.Text><h2></h2><span className="muted"></span></div>*/}
            {/*</List.Item>*/}
            <List.Item
              actions={[
                // <Button size="large" ghost>
                  <Tag className="muted rounded">CLICK TO TRY</Tag>
                // </Button>
              ]}>
              <div className="space space-center-y cursor-pointer">
                {/*<h2>*/}
                {/*  <QuoteLeftIcon size={18} className="muted"/> &nbsp;*/}
                {/*</h2>*/}
                <h2>
                  <Hotline text={<h2 className="underline">Call {props.assistant?.assistantName ? " " + props.assistant?.assistantName : ""}</h2>} />
                </h2>
                <span className="muted"></span>
              </div>
            </List.Item>
            <List.Item
              onClick={() => onSend("what number can I call to reach you?")}
              actions={[
                // <Button size="large" ghost>
                  <Tag className="rounded muted" style={{fontSize: 16}}>Dial {props.assistant?.number.replace("+1", "")}</Tag>
                // </Button>
              ]}
            >
              <div className="space space-center-y pointer underline"><h2>Call Lazarus from your phone</h2></div>
            </List.Item>
            <List.Item
              actions={[<Button size="large" ghost className="hidden"><Icon name="DownArrowOutlined"/></Button>]}>
              <div className="space space-center-y">
                <h2>Have {props.assistant?.assistantName} make a call and stay on hold for you, or make a reservation</h2>
              </div>
            </List.Item>
            {/*<List.Item*/}
            {/*  actions={[<Button size="large" ghost></Button>]}>*/}
            {/*  <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted" /> &nbsp;call and make a reservation</h2></div>*/}
            {/*</List.Item>*/}
          </List>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            onExit={props.onExit}
          />
        </div>
      </div>
    {/*</DashboardRow>*/}
  </LeftWrapper>;
};

export const Bio = (props) => {
  const agentId= "ai-line-onboarding-client-guide";
  const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const clientLine = useSelector((state) => state.Lines.data?.[clientLinePath]);
  const [bio, setBio] = React.useState("");
  const [clientName, setClientName] = React.useState("");
  const [isFocused, setFocused] = React.useState(false);
  const [, sendRequest] = useChatStream(agentId, true);

  /**
   * @function onSend
   * @param {object} updates
   * @type {(function(*, *): void)|*}
   */
  const onSend = (updates) => {
    if (!props.isActive) return;
    sendRequest(
      `New profile update: ${JSON.stringify(updates)}`,
      {
        history: 3,
        contextPaths: [clientLinePath],
        collectionPath,
      });
  };

  const saveProfile = () => {
    !!bio &&
    props.onUpdateStep(props.path, {
      profile: bio.split("|").map((item) => item.trim()).filter(Boolean) || [],
    })
    .then((result) => {
      console.log("result", result);
      result.updates && onSend(result.updates);
      result instanceof Error ?
        notifyAndTrack.error("Oops", result.message) :
        setBio("");
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  const saveClientName = () => {
    !!clientName && props.onUpdateStep(props.path, {clientName})
    .then((result) => {
      result.updates && onSend(result.updates);
      result instanceof Error ?
        notifyAndTrack.error("Oops", result.message) :
        setClientName("");
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  const onClickRemove = (item) => {
    const clientPath = props.assistantPath.replace("/assistant", "/client");
    updatePath(clientPath, {profile: Fields.arrayRemove(item)})
    .then(() => null);
  }

  React.useEffect(() => {
    console.log("Bio effect");
    if (!props.isActive) return;
    props.showDrawer(true, {agentId, collectionPath});
    props.setActiveDetail &&
    props.setActiveDetail({
      path: ["user", props.user.uid, "lines", "client"].join("/"),
      data: clientLine,
    }, false);
  }, [props.isActive, collectionPath]);

  const onFocus = () => {
    setFocused(true);
    props.pauseAnimation();
  }

  const onBlur = () => {
    setFocused(false);
    props.startAnimation();
  }

  return <LeftWrapper
    isMobile={props.isMobile}
    height={props.height}
  >
    {/*<DashboardSplitRow*/}
    {/*  leftSpan={14}*/}
    {/*  left={<ServiceContent />}*/}
    {/*  right={<ChatPane ghost headerHeight={80} center centerOffset={200} />} // <ChatPage asOverlay />*/}
    {/*/>*/}
    {/*<DashboardRow>*/}
    <div style={{width: "100%", maxWidth: 900}}>
      <div className="space space-col" style={{width: "100%"}}>
        <Typography.Title>About you</Typography.Title>
        <Typography.Text>
          Tell {props.assistant?.assistantName || "your assistant"} a little bit about yourself
        </Typography.Text>
        <Divider className="no-bottom hidden" />
        <div className="space space-center-y relative" style={{width: "100%", maxWidth: 500}}>
          <Input
            value={clientName}
            maxLength={40}
            placeholder={props.assistant.clientName || "Your name"}
            size="large"
            bordered={false}
            className="input-bold"
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e) => setClientName(e.target.value)}
            onPressEnter={saveClientName}
          />
          <InputSaveButton
            onClick={saveClientName}
            show={!!clientName && clientName !== props.assistant.clientName}
            disabled={clientName?.length < 3}
          />
        </div>
        {!!props.assistant.clientName &&
          <div className="space space-center-y relative">
            <Input
              value={bio}
              maxLength={100}
              placeholder={clientLine?.profile?.length ?? 0 <= 3 ? "Title | Company | Goals | etc." : "Add something new"}
              size="large"
              bordered={false}
              className="input-bold"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) => setBio(e.target.value)}
              onPressEnter={saveProfile}
            />
            <InputSaveButton
              onClick={saveProfile}
              show={!!bio}
              disabled={bio?.length < 3}
            />
          </div>
        }
        <div className="space space-center-y flex-wrap gap-8" style={{marginTop: 5}}>
          {clientLine?.profile?.map?.((item) => <span key={item} className="muted fat-chip font-lg">
        {item} <Button size="small" ghost className="relative" onClick={() => onClickRemove(item)} style={{top: 4}}><XIcon size={20} style={{color: "gray"}} /></Button>
      </span>)}
        </div>
          {/*<Input*/}
          {/*  maxLength={100}*/}
          {/*  placeholder="Facebook"*/}
          {/*  size="large"*/}
          {/*  bordered={false}*/}
          {/*  className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}*/}
          {/*  onFocus={props.pauseAnimation}*/}
          {/*  onBlur={props.startAnimation}*/}
          {/*  onChange={(e) => saveWebsite(e.target.value)}*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  maxLength={100}*/}
          {/*  placeholder="Instagram"*/}
          {/*  size="large"*/}
          {/*  bordered={false}*/}
          {/*  className={`input-bold ${isMobile ? "mt-12" : "mt-20"}`}*/}
          {/*  onFocus={props.pauseAnimation}*/}
          {/*  onBlur={props.startAnimation}*/}
          {/*  onChange={(e) => saveWebsite(e.target.value)}*/}
          {/*/>*/}
          <br />
      </div>
      <div style={{width: "100%", paddingTop: "10px"}}>
        <OnboardingFooter
          hide={!clientLine?.profile?.length}
          onNext={() => props.markCompleteAndNavigateNext(props.path)}
          // onExit={props.onExit}
        />
          {/*<Divider/>*/}
          {/*{props.assistant.persona &&*/}
          {/*  props.assistant.assistantName &&*/}
          {/*  props.assistant.gender &&*/}
          {/*  <AudioStreamManager primary />*/}
          {/*}*/}
          {/*<AudioStreamData component={AudioStreamManager} primary />*/}
          {/*<Button size="large" shape="round" onClick={() => props.markCompleteAndNavigateNext(props.name)}>*/}
          {/*  <h4 className="space space-center-y green-color">Next <RightArrowOutlined/></h4></Button>*/}
        </div>
        {/*<div style={{width: "100%", paddingTop: "10px"}}>*/}
        {/*  <span className="ai-text">You can add websites to your account later</span>*/}
        {/*</div>*/}
      </div>
      {/*</Col>*/}
      {/*<Col xs={0} md={0} lg={0} xl={10} xxl={10}>*/}
      {/*<div style={{position: "absolute", width: "100%", maxHeight: 800, overflowY: "auto", left: 0}}>*/}
      {/*{chat.prompt.text}*/}
      {/*</div>*/}
      {/*</Col>*/}
      {/*</Row>*/}
    {/*</DashboardRow>*/}
  </LeftWrapper>;
};

export const Training = (props) => {
  React.useEffect(() => {
    props.setContext({
      task: {summary: "Explain that the client can periodically train the Line assistant to be perfectly tuned to their needs. This feature requires upgrade to Line AI Pro."},
      path: props.path,
    });
  }, []);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Train</Typography.Title>
          <Typography.Text>
            {props.assistant.assistantName} continuously learns to be perfectly tuned to you, and you can opt for custom training to perform sophisticated tasks and business workflows.
          </Typography.Text>
          <br />
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <div className="space space-center-y space-between">
              <Typography.Text>Available for Line AI Pro users</Typography.Text>
              <Button className="green-color" shape={"round"} size="large">Upgrade</Button>
            </div>
          </Card>
          {/*<Button*/}
          {/*  size="large"*/}
          {/*  type="primary"*/}
          {/*  disabled={!!userInfo?.initialLoadTs}*/}
          {/*  onClick={startTraining}*/}
          {/*>*/}
          {/*  <h4>Start</h4>*/}
          {/*</Button>*/}
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    </DashboardRow>
  </LeftWrapper>;
};

export const Tools = (props) => {
  const agentId = "ai-line-onboarding-tasks-guide";
  const [, sendRequest] = useChatStream(agentId, true);
  const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const [dataItems, loadingChat] = useQueryFirestore(buildQuery(collectionPath, {}, {limit: 1, order: [["updatedTs", "desc"]]}));
  const [sentDefault, setSentDefault] = React.useState(false);
  const [sampleSearch, setSampleSearch] = React.useState("");
  const [performedInternetSearch, setPerformedInternetSearch] = React.useState(false);

  /**
   * @function onSend
   * @param {string} [prompt]
   * @type {(function(*, *): void)|*}
   */
  const onSend = (prompt) => {
    if (!props.isActive) return;
    if (!prompt && sentDefault) {
      return;
    }
    setSampleSearch("");
    props.showDrawer(true, {agentId, collectionPath}, "tasks");
    sendRequest(
      prompt || `What can you help with?`,
      {
        history: 3,
        contextPaths: [clientLinePath],
        collectionPath,
      });
    setSentDefault(true);
  };

  React.useEffect(() => {
    if (!props.isActive) {
      return;
    }
    props.showDrawer(true, {agentId, collectionPath});

    if (loadingChat) {
      return;
    }

    if (dataItems?.[0]?.[1]?.updatedTs > dayjs().subtract(1, "hour").valueOf()) {
      return props.showDrawer(true, {chat: dataItems[0][1]});
    }
    !sentDefault && onSend();
  }, [props.isActive, loadingChat, sentDefault]);

  const onFocus = () => {
    props.pauseAnimation();
    if (!performedInternetSearch) {
      setPerformedInternetSearch(true);
      setSampleSearch("photos of Santorini");
    }
  }

  const onBlur = () => {
    props.startAnimation();
  }

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    {/*<DashboardRow leftSpan={1}>*/}
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col" style={{width: "100%"}}>
          <Typography.Title>Tools</Typography.Title>
          <Typography.Text className="muted">Your Line Agent combines these tools to handle your simple and complex tasks once or a hundred times in mere moments.</Typography.Text>
          <br />
          <Card className="card-simple card-rounded card-no-header" bordered={false}>
            <List size="large">
              <List.Item
                actions={[
                  <Button key="man" size="large" ghost disabled={false} className="icon-btn underline" onClick={(e) => onSend("about document search")}>
                    Documents</Button>,
                  <Button key="man" size="large" ghost disabled={false} className="icon-btn underline" onClick={(e) => onSend("about semantic photo search")}>
                    Photos</Button>,
                  <Button key="man" size="large" ghost disabled={false} className="icon-btn underline" onClick={(e) => onSend("find anything?")}>
                    Anything</Button>,
                ]}>
                <div className="space space-center-y"><h2>Search</h2></div>
              </List.Item>
              <List.Item actions={[
                <div className="space space-center-y relative" style={{width: 400}}>
                  <Input
                    maxLength={100}
                    value={sampleSearch}
                    placeholder={"Try a search"}
                    size="large"
                    bordered={false}
                    className={`input-bold`}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={(e) => setSampleSearch(e.target.value)}
                    onPressEnter={() => onSend(`sample internet search: ${sampleSearch}`)}
                  />
                  <InputSaveButton
                    onClick={() => onSend(`sample internet search: ${sampleSearch}`)}
                    show={!!sampleSearch}
                    disabled={!sampleSearch}
                  />
                </div>,
              ]}>
                <div className="space space-center-y"><h2>Internet</h2></div>
              </List.Item>
              {/*<List.Item*/}
              {/*  actions={[*/}
              {/*    <Button key="man" size="large" ghost disabled={false} className="icon-btn" onClick={(e) => onSend("set a reminder to something cool next Sunday")}>*/}
              {/*      Reminder</Button>,*/}
              {/*    <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>*/}
              {/*      Meeting</Button>,*/}
              {/*    <Button key="man" size="large" ghost className="icon-btn" onClick={(e) => saveGender("neutral")}>*/}
              {/*      Task</Button>,*/}
              {/*  ]}>*/}
              {/*  <div className="space space-center-y"><h2>Schedule</h2></div>*/}
              {/*</List.Item>*/}
              <List.Item
                actions={[
                  <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn underline" onClick={() => onSend("Let's analyze a dataset")}>
                    Documents</Button>,
                  <Button key="man" size="large" ghost className="icon-btn underline" onClick={(e) => saveGender("neutral")}>
                    Datasets</Button>,
                  // <Button key="man" size="large" ghost className="icon-btn" onClick={(e) => saveGender("neutral")}>
                  //   Task</Button>,
                ]}>
                <div className="space space-center-y"><h2>Analyze</h2></div>
              </List.Item>
              <List.Item
                actions={[
                  <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn underline" onClick={(e) => onSend("draft an example email")}>
                    Draft</Button>,
                  // <Button key="man" size="large" ghost className="icon-btn" onClick={(e) => saveGender("neutral")}>
                  //   Task</Button>,
                  // // <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>
                  //   Send
                  // </Button>,
                ]}>
                <div className="space space-center-y"><h2>Email</h2></div>
              </List.Item>
            <List.Item
              // actions={[
              //   <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>
              //     Call Me</Button>,
              //   <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>
              //     Friend</Button>,
              //   <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>
              //     Follow-up</Button>,
              //   <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>
              //     Task
              //   </Button>,
              // ]}
            >
              <div className="space space-center-y"><h2>Call</h2></div>
            </List.Item>
              <List.Item
                // actions={[
                //   <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>
                //     Text Me</Button>,
                //   // <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>
                //   //   Friend</Button>,
                //   <Button key="man" size="large" ghost className="icon-btn" onClick={(e) => saveGender("neutral")}>
                //     File</Button>,
                //   <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>
                //     Task
                //   </Button>,
                // ]}
              >
                <div className="space space-center-y"><h2>Text</h2></div>
              </List.Item>
              {/*<List.Item*/}
              {/*  actions={[*/}
              {/*    <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>*/}
              {/*      Personal</Button>,*/}
              {/*    // <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>*/}
              {/*    //   Business</Button>,*/}
              {/*    <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>*/}
              {/*      Business*/}
              {/*    </Button>,*/}
              {/*  ]}>*/}
              {/*  <div className="space space-center-y"><h2>Plan and Execute</h2></div>*/}
              {/*</List.Item>*/}
          </List>
            {/*<Button size="large" className="green-color" shape="round">Email</Button>*/}
            {/*<Button size="large" className="green-color" shape="round">Search</Button>*/}
            {/*<Button size="large" className="green-color" shape="round">Internet</Button>*/}
            {/*<Button size="large" className="green-color" shape="round">Reminders</Button>*/}
            {/*<Button size="large" className="green-color" shape="round">Calls</Button>*/}
            {/*<Button size="large" className="green-color" shape="round">Documents</Button>*/}
            {/*<Button size="large" className="green-color" shape="round">Communication</Button>*/}
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            <OnboardingFooter
              nextText={"Try Examples"}
              onNext={() => props.markCompleteAndNavigateNext(props.path)}
              // onExit={props.onExit}
            />
          </div>
        </div>
      </div>
    {/*</DashboardRow>*/}
  </LeftWrapper>;
};

export const Summary = (props) => {
  const [, setLocation] = useLocation();
  // const [, user] = useContext(AppContext);

  React.useEffect(() => {
    props.setDisableNavigation(false);
  }, [props.user]);

  return <LeftWrapper isMobile={props.isMobile} height={props.height}>
    <DashboardRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title level={2} className="green-icon">
            <div className="space space-center-y"><FeatherIcon size={30} className="green-icon" /> Onboarding Success
            </div>
          </Typography.Title>
          <Progress percent={100} status="success" showInfo />
          {/*<Divider />*/}
          <Card className="card-rounded card-simple card-inline card-no-separator"
                extra={<Button className="green-color" onClick={() => setLocation("/line")}>Enter</Button>}>
            {/*<Typography.Text>Hi <span className="capitalize">{user.displayName?.split(" ")?.[0] || ""}</span>, training and evaluation completed successfully. A representative will contact you to schedule your walkthrough of the app, discuss it's features, and answer any questions you may have about the Genesis Beta.</Typography.Text>*/}
          </Card>
        </div>
      </div>
    </DashboardRow>
  </LeftWrapper>;
};

export const Subscribe = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, claims, loadingUser, errorLoadingUser] = useAuthOven(auth);
  const subscribed = !!claims?.data?.subs?.length;

  React.useEffect(() => {
    props.setDisableNavigation(!!subscribed);
  }, [subscribed]);

  const paymentTabItems = [
    {
      key: "me",
      label: "Solo",
      children: <PaymentMiniCard
        buttonId="buy_btn_1NjaTaJEZgSQPP4EYRyhfJ3s"
        amount={600}
        text="Access for you"
      />,
    },
    {
      key: "me-plus-one",
      label: "Duo",
      children: <PaymentMiniCard
        buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
        amount={1200}
        text="You plus 1"
      />,
    },
    // {
    //   key: "me-plus-one",
    //   label: "Max",
    //   children: <PaymentMiniCard
    //     buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
    //     amount={2400}
    //     text="Access for five"
    //   />,
    // },
    // {
    //   key: "team",
    //   label: "Team",
    //   children: <PaymentMiniCard
    //     buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
    //     amount={3000}
    //     text="Team, up to 10"
    //   />,
    // },
  ];

  if (loadingUser) return null;
  if (errorLoadingUser) return null;

  return <LeftWrapper isMobile={isMobile} height={height}>
    <DashboardCenterRow>
      <div style={{width: "100%", maxWidth: 800}}>
        <div className="space space-col">
          <Typography.Title>Secure your Line</Typography.Title>
          {/*<Typography.Text>You are #{invitesCount}</Typography.Text>*/}
          <Typography.Text>
            One year Line AI membership. Full platform access for 12-months (beta) plus guaranteed same-rate renewal.
          </Typography.Text>
          <br />
        </div>
        <Tabs
          className="tabs-bar-no-underline payment-mini-tabs"
          items={paymentTabItems}
          type="card"
        />
        <div style={{width: "100%", paddingTop: "10px"}}>
          <span className="muted">This is a one-time payment</span>
        </div>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <OnboardingFooter
            hide={!subscribed}
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            // onExit={props.onExit}
          />
        </div>
      </div>
    </DashboardCenterRow>
  </LeftWrapper>;
};

export const Plans = (props) => {
  const [isMobile, , height] = useContext(WindowContext);
  const [, claims, loadingUser, errorLoadingUser] = useAuthOven(auth);
  const [location, setLocation] = useLocation();
  const [activeKey, setActiveKey] = React.useState("monthly");
  const subscribed = !!claims?.data?.subs?.length;
  // props.showDrawer(true, {agentId, collectionPath}, "calls");

  React.useEffect(() => {
    props.showDrawer(false);
    props.pauseAnimation();
  }, []);

  React.useEffect(() => {
    props.setDisableNavigation(!!subscribed);
  }, [subscribed]);

  // dev price_1QnUiQJEZgSQPP4EqcsdtEHD
  // prod price_1Qoq6qJEZgSQPP4EPWbJ3xdK
  // const paymentTabItems = [
  //   {
  //     key: "me",
  //     label: "Monthly",
  //     children: <div className="space space-center-y">
  //       <Card
  //         bordered={false}
  //         title="Solo"
  //         actions={[<Button block shape="round" size="large" type="primary" className="input-bold">Pay</Button>]}
  //         style={{width: 400}}
  //         className="card-simple card-rounded card-glass card-no-separator"
  //         // extra={<Statistic value={20} prefix={<DollarCircleOutlined />} />}
  //         // buttonId="buy_btn_1NjaTaJEZgSQPP4EYRyhfJ3s"
  //         // amount={600}
  //         // text="Access for you"
  //       >
  //         <Card.Meta
  //           title={"A Line for you"}
  //           description={<span className="font-lg">Your Line AI assistant and dedicated phone number to call, text, chat and perform tasks. Ideal for personal and lite business use. Connect your email and other tools as needed.</span>} />
  //         {/*<Button block shape="round" size="large" type="primary">Pay</Button>*/}
  //       </Card>
  //       <Card
  //         bordered={false}
  //         title="Grow"
  //         actions={[<Button block shape="round" size="large" className="input-bold">Pay</Button>]}
  //         style={{width: 400}}
  //         className="card-simple card-rounded card-glass card-no-separator"
  //         // extra={<Statistic value={50} prefix={<DollarCircleOutlined />} />}
  //         // buttonId="buy_btn_1NjaTaJEZgSQPP4EYRyhfJ3s"
  //         // amount={600}
  //         // text="Access for you"
  //       >
  //         <Card.Meta
  //           title={"Confidence to grow"}
  //           description={<span className="font-lg">Everything in solo, plus upgraded business Line features to perform customer support, handle inquiries, publish updates, and much more 24/7. Available custom training to your unique business needs.</span>}
  //         />
  //         {/*<Button block shape="round" size="large" type="primary">Pay</Button>*/}
  //       </Card>
  //     </div>,
  //   },
  //   // {
  //   //   key: "me-plus-one",
  //   //   label: "Annual",
  //   //   children: <div className="space space-center-y">
  //   //     <Card
  //   //       bordered={false}
  //   //       title="Me"
  //   //       actions={[<Button block shape="round" size="large" type="primary">Pay</Button>]}
  //   //       style={{width: 400}}
  //   //       extra={<Statistic value={600} prefix={<DollarCircleOutlined />} />}
  //   //       className="card-simple card-rounded card-glass card-no-separator"
  //   //       // buttonId="buy_btn_1NjaTaJEZgSQPP4EYRyhfJ3s"
  //   //       // amount={600}
  //   //       // text="Access for you"
  //   //     >
  //   //       <Card.Meta title={"Access for you"} />
  //   //       {/*<Button block shape="round" size="large" type="primary">Pay</Button>*/}
  //   //     </Card>
  //   //     <Card
  //   //       bordered={false}
  //   //       title="Many"
  //   //       actions={[<Button block shape="round" size="large" type="primary">Pay</Button>]}
  //   //       style={{width: 400}}
  //   //       className="card-simple card-rounded card-glass card-no-separator"
  //   //       // buttonId="buy_btn_1NjaTaJEZgSQPP4EYRyhfJ3s"
  //   //       // amount={600}
  //   //       // text="Access for you"
  //   //     >
  //   //       <Card.Meta
  //   //         // title={"Access for you"}
  //   //         description={"Access for you"}
  //   //       />
  //   //       {/*<Button block shape="round" size="large" type="primary">Pay</Button>*/}
  //   //     </Card>
  //   //   </div>,
  //   // },
  // // {
  // //   key: "me-plus-one",
  // //   label: "Max",
  // //   children: <PaymentMiniCard
  // //     buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
  // //     amount={2400}
  // //     text="Access for five"
  // //   />,
  // // },
  // // {
  // //   key: "team",
  // //   label: "Team",
  // //   children: <PaymentMiniCard
  // //     buttonId="buy_btn_1NjaYTJEZgSQPP4Eki4Q6Shv"
  // //     amount={3000}
  //   //     text="Team, up to 10"
  //   //   />,
  //   // },
  // ];

  if (loadingUser) return null;
  if (errorLoadingUser) return null;

  return <CenterWrapper isMobile={isMobile} height={height}>
    <div style={{width: "100%", maxWidth: 1200, margin: "0 auto"}}>
      <div className="space space-col">
        <Typography.Title>{props.assistant.assistantName} <span className="font-xl">{claims.data?._h?.slice(2)}</span></Typography.Title>
        <br />
      </div>
      <Tabs
        onChange={(key) => setActiveKey(key)}
        activeKey={activeKey}
        tabBarExtraContent={{
          right: <Button className="text-color" type="link" onClick={() => setActiveKey("genesis")}>Genesis Membership</Button>,
        }}
        items={[
        {
          key: "monthly",
          label: "Monthly",
          children: <div className={`space ${isMobile ? "space-col" : "space-center-y"}`} style={{minHeight: 500}}>
            <Card
              bordered={false}
              title={<h3>Free</h3>}
              actions={[<Button block disabled shape="round" size="large" onClick={() => setLocation("/")} type="primary">Your current plan</Button>]}
              style={{width: 400}}
              className="card-simple card-rounded card-glass card-no-separator extra-padding card-h-400"
            >
              <div className="space space-col space-between" style={{height: "100%"}}>
                <Card.Meta description={<span className="font-lg">Explore how Line AI assistant can help you with everyday tasks. Ideal for students and basic use.</span>} />
                <Statistic
                  valueStyle={{fontSize: 40}}
                  value={0}
                  formatter={(value) => `$${value}`}
                  suffix={<div style={{lineHeight: "0.4em"}}><div className="font-sm font-thin muted">USD/</div><div className="font-sm font-thin muted">month</div></div>}
                />
              </div>
            </Card>
            <Card
              title={<h3 className="space space-center-y">Solo &nbsp;<Tag className="rounded" color="green">Powerful</Tag></h3>}
              actions={[<Button block shape="round" size="large" onClick={() => setLocation("/pay/price_1Qoq6qJEZgSQPP4EPWbJ3xdK")} type="primary" className="input-bold">Pay</Button>]}
              style={{width: 400}}
              className="card-simple card-rounded card-glass card-no-separator extra-padding card-h-400 card-highlight"
            >
              <div className="space space-col space-between" style={{height: "100%"}}>
                <Card.Meta description={<span className="font-lg">Your Line AI assistant and dedicated phone number to call, text, chat and perform tasks. Ideal for personal and even lite business use. Connect your email and other tools as needed.</span>} />
                <Statistic
                  valueStyle={{fontSize: 40}}
                  value={20}
                  formatter={(value) => `$${value}`}
                  suffix={<div style={{lineHeight: "0.4em"}}><div className="font-sm font-thin muted">USD/</div><div className="font-sm font-thin muted">month</div></div>}
                />
              </div>
            </Card>
            <Card
              bordered={false}
              title={<h3 className="space space-center-y">Pro &nbsp;<Tag className="rounded" color="green">Great for Business</Tag></h3>}
              actions={[<Button block shape="round" size="large" onClick={() => setLocation("/pay/price_1QphSSJEZgSQPP4EuC9rXDMI")} className="input-bold">Pay</Button>]}
              style={{width: 400}}
              className="card-simple card-rounded card-glass card-no-separator extra-padding card-h-400"
            >
              <div className="space space-col space-between" style={{height: "100%"}}>
                <Card.Meta description={<span className="font-lg">Everything in solo, plus upgraded business Line Agent features to perform customer support, inquiries, publish updates, and much more 24/7. Available custom training to your unique business needs.</span>} />
                <Statistic
                  valueStyle={{fontSize: 40}}
                  value={100}
                  formatter={(value) => `$${value}`}
                  suffix={<div style={{lineHeight: "0.4em"}}><div className="font-sm font-thin muted">USD/</div><div className="font-sm font-thin muted">month</div></div>}
                />
              </div>
            </Card>
          </div>
        },
        {
          key: "yearly",
          label: "Yearly",
          children: <div className={`space ${isMobile ? "space-col" : "space-center-y"}`}  style={{minHeight: 500}}>
            <Card
              bordered={false}
              title={<h3>Solo</h3>}
              actions={[<Button block shape="round" size="large" onClick={() => setLocation("/pay/price_1Qz4XNJEZgSQPP4EBMDRwFWe")} className="input-bold">Pay</Button>]}
              style={{width: 400}}
              className="card-simple card-rounded card-glass card-no-separator extra-padding card-h-400"
            >
              <div className="space space-col space-between" style={{height: "100%"}}>
                <Card.Meta description={<span className="font-lg">Your Line AI assistant and dedicated phone number to call, text, chat and perform tasks. Ideal for personal and lite business use. Connect your email and other tools as needed.</span>} />
                <Statistic
                  valueStyle={{fontSize: 40}}
                  value={200}
                  formatter={(value) => `$${value}`}
                  suffix={<div style={{lineHeight: "0.4em"}}><div className="font-sm font-thin muted">USD/</div><div className="font-sm font-thin muted">year</div></div>}
                />
              </div>
            </Card>
            <Card
              bordered={false}
              title={<h3>Pro</h3>}
              actions={[<Button block shape="round" size="large" onClick={() => setLocation("/pay/price_1QphWRJEZgSQPP4EiXosV6G3")} className="input-bold">Pay</Button>]}
              style={{width: 400}}
              className="card-simple card-rounded card-glass card-no-separator extra-padding card-h-400"
            >
              <div className="space space-col space-between" style={{height: "100%"}}>
                <Card.Meta description={<span className="font-lg">Everything in solo, plus upgraded business Line Agent features to perform customer support, inquiries, publish updates, and much more 24/7. Available custom training to your unique business needs.</span>} />
                <Statistic
                  valueStyle={{fontSize: 40}}
                  value={999}
                  formatter={(value) => `$${value}`}
                  suffix={<div style={{lineHeight: "0.4em"}}><div className="font-sm font-thin muted">USD/</div><div className="font-sm font-thin muted">year</div></div>}
                />
              </div>
            </Card>
            <Card
              // bordered={false}
              title={<h3 className="space space-center-y">Genesis <Tag color="gold" className="rounded">Limited Time</Tag></h3>}
              actions={[<Button block shape="round" size="large" onClick={() => setLocation("/pay/price_1Nfq8UJEZgSQPP4EWVtORLOf")} type="primary" className="input-bold">Pay</Button>]}
              style={{width: 400}}
              className="card-simple card-rounded card-glass card-no-separator extra-padding card-h-400 card-highlight"
            >
              <div className="space space-col space-between" style={{height: "100%"}}>
                <Card.Meta description={<span className="font-lg">A pro plan at a discounted membership price for participation in our Genesis program which gives you full access to our latest and best offerings, sometimes pre-release, with hands-on support, in exchange for your feedback and participation in the Genesis member community. </span>} />
                <Statistic
                  valueStyle={{fontSize: 40}}
                  value={600}
                  formatter={(value) => `$${value}`}
                  suffix={<div style={{lineHeight: "0.4em"}}><div className="font-sm font-thin muted">USD/</div><div className="font-sm font-thin muted">year</div></div>}
                />
              </div>
            </Card>
          </div>
      },
          {
            key: "genesis",
            // label: "Yearly",
            children: <div className="space space-center-y" style={{minHeight: 500}}>
              <Card
                bordered={false}
                title={<h3>Genesis Membership</h3>}
                actions={[<Button block shape="round" size="large" onClick={() => setLocation("/pay/price_1QphSSJEZgSQPP4EuC9rXDMI")} className="input-bold">Pay</Button>]}
                style={{width: "100%"}}
                className="card-simple card-rounded card-glass card-no-separator extra-padding card-h-400"
              >
                <div className="space space-col space-between" style={{height: "100%"}}>
                  <Card.Meta description={<span className="font-lg">A pro plan at a discounted membership price for participation in our Genesis program which gives you full access to our latest and best offerings, sometimes pre-release, with hands-on support, in exchange for your feedback and participation in the Genesis member community. </span>} />
                  <Statistic
                    valueStyle={{fontSize: 40}}
                    value={660}
                    formatter={(value) => `$${value}`}
                    suffix={<div style={{lineHeight: "0.4em"}}><div className="font-sm font-thin muted">USD/</div><div className="font-sm font-thin muted">year</div></div>}
                  />
                </div>
              </Card>
            </div>
          },
        ]}
        />
      <div style={{width: "100%", paddingTop: "10px"}}>
        {/*<span className="muted">This is a one-time payment</span>*/}
      </div>
      <div style={{width: "100%", paddingTop: "10px"}}>
        <OnboardingFooter
          hide
          onPrev={props.navigatePrev}
          // onNext={() => props.markCompleteAndNavigateNext(props.path)}
          // onExit={props.onExit}
        />
      </div>
    </div>
    {/*</DashboardCenterRow>*/}
  </CenterWrapper>;
};
