import React, {useContext} from "react";
import {Card, Typography, Empty, List, Button, Divider} from "antd";
import {WindowContext} from "@web/hooks/context";
// import ContactActivityListItem from "./ContactActivityListItem";
import Loading from "./Loading";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import PropTypes from "prop-types";
// import ActivityData from "@web/ui/containers/ActivityData";
import LaunchSourceManagerButton from "./LaunchSourceManagerButton";
import AppsListItem from "./AppsListItem";
import ContentData from "@web/ui/containers/ContentData";
import {RightArrowOutlined} from "@web/assets/icons";

AppsList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
}

function AppsList(props) {
  const [isMobile,, height] = useContext(WindowContext);
  // console.log("AppsList", props);
  return <div
    id="services-contacts-scroll-target"
    style={{
      height: height - 3,
      overflow: 'auto',
      // display: 'flex', flexDirection: 'column',
    }}>
    <Pager
      height={height - 3}
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      // initialScrollY={200}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "6px", left: 26}}
        >
          <h6 className="muted">End</h6>&nbsp;<LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-contacts-scroll-target"
    >
      <List
        // style={{minHeight: height / 2}}
        header={
          // <div className="w-full">
          !isMobile && <Divider style={{marginTop: 56}} className="small-bottom" />
          //   <Card
          //     title={<h6>Store</h6>}
          //     extra={<Button ghost className="icon-btn icon-right"
          //                    onClick={() => setLocation("/lines")}>Browse <RightArrowOutlined /></Button>}
          //     bordered={false}
          //     style={{margin: "0 10px", backgroundColor: "#0e161f"}}
          //     className="card-simple card-rounded card-inline card-no-separator"
          //   >
          //     {/*<List*/}
          //     {/*  // header={<h6>Lines</h6>}*/}
          //     {/*  className="list-item-no-divider">*/}
          //     {/*  <List.Item extra={<Button ghost className="icon-btn icon-right"><RightArrowOutlined /></Button>}>*/}
          //     {/*    <List.Item.Meta avatar={<UserAvatar />} title={"Fiona"} description={"+1(628) 444-1211"}></List.Item.Meta>*/}
          //     {/*  </List.Item>*/}
          //     {/*  <List.Item extra={<Button ghost className="icon-btn icon-right"><RightArrowOutlined /></Button>}>*/}
          //     {/*    <List.Item.Meta avatar={<UserAvatar />} title={"Daphne"} description={"+1(415) 322-7655"}></List.Item.Meta>*/}
          //     {/*  </List.Item>*/}
          //     {/*</List>*/}
          //   </Card>
          // </div>
          // <Card
          //   bordered={false}
          //   style={{width: "100%", margin: "0", height: 60}}
          //   className="card-simple card-rounded card-no-header card-transparent card-no-separator card-slim-title"
          // >
          //   <div className="cursor-pointer space space-center-x" style={{padding: "12px 0"}}>
          //     {/*<LaunchSourceManagerButton key="sources-manager-x" serviceId={"apps"}>*/}
          //     {/*  <Typography.Title style={{marginBottom: 11}}>Browse Apps</Typography.Title>*/}
          //     {/*</LaunchSourceManagerButton>*/}
          //   </div>
          // </Card>
        }
        className="services-contacts-list list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date], index) => {
          return <ContentData component={AppsListItem} key={path} path={path} />;
          // return <ActivityData key={path} path={path} date={date}>
          //   <AppsListItem />
          // </ActivityData>;
        }}
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper space space-col middle"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{width: "100%", maxWidth: "100%", height: height - 140, margin: "0 auto"}}
          >
            {/*<div className="bgx cursor-pointer" style={{width: "100%", padding: "12px 0"}}>*/}
            {/*  <LaunchSourceManagerButton key="sources-manager-x" serviceId={"contacts"}>*/}
            {/*    <Typography.Title style={{marginBottom: 11}}>Apps</Typography.Title>*/}
            {/*  </LaunchSourceManagerButton>*/}
            {/*</div>*/}

            {/*<Card title="Connect your Email" className="card-simple card-rounded card-no-header" bordered={false}>*/}
            {/*  <Link to="/">Connect your email to use assistant features</Link>*/}
            {/*  /!*<SourceManager group={{sourceIds:["gmail", "outlook", "web"]}} serviceId="contacts" />*!/*/}
            {/*</Card>*/}
          </Empty>
        }}
        // footer={
        // <div className="bgx cursor-pointer space space-center-x" style={{padding: "12px 0"}}>
        //   <LaunchSourceManagerButton key="sources-manager-x" serviceId={"apps"}>
        //     <Typography.Title style={{marginBottom: 11}}>Browse Apps</Typography.Title>
        //   </LaunchSourceManagerButton>
        // </div>}
      />
    </Pager>
  </div>
}

export default AppsList;
