/** @namespace user.onboarding */
import React, {useContext} from "react";
import PropTypes from "prop-types";
import {Avatar, Button, Card, Col, Divider, Input, InputNumber, List, Row, Tag, Tooltip, Typography} from "antd";
import {AppContext, SandboxContext, WindowContext} from "@web/hooks/context";
// import DashboardRow from "../DashboardRow";
import {notifyAndTrack} from "../notification";
import {DeleteIcon, LeftArrowOutlined, PlusIcon, QuoteLeftIcon, RightArrowOutlined} from "@web/assets/icons";
import {updatePath, addPath, Fields, buildQuery, deletePath, setPath} from "@web/lib/firestore.db";
import {useDispatch, useSelector} from "react-redux";
import InputSaveButton from "../InputSaveButton";
import useChatStream from "@web/hooks/useChatStream";
import useQueryFirestore from "@web/hooks/useQueryFirestore";
import TimeAgo from "@web/ui/components/TimeAgo";
import now from "lodash/now";
import AgentBuilder from "../AgentBuilder";
import ServiceInputsManager from "../ServiceInputsManager";
import {api} from "@web/lib/api";
import dayjs from "dayjs";
// import UserAvatar from "../UserAvatar";
// import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import ActiveAppCard from "../ActiveAppCard";
import SandboxActiveAppCard from "../SandboxActiveAppCard";

PropTypes.AppWelcome = {
  setDisableNavigation: PropTypes.func.isRequired,
  pauseAnimation: PropTypes.func.isRequired,
  startAnimation: PropTypes.func.isRequired,
  navigateNext: PropTypes.func.isRequired,
  navigatePrev: PropTypes.func,
  markCompleteAndNavigateNext: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export function AppWelcome(props) {
  return <AppCenterWrapper isMobile={props.isMobile} height={props.height}>
    <div style={{width: "92%", maxWidth: 800, margin: "0 auto"}}>
      <div className="space space-col splash-home-title-wrapper">
        <Typography.Title level={1}>Let's get started</Typography.Title>
        <Typography.Text>
          You'll set up your Line and launch your new AI ally in just a few minutes
        </Typography.Text>
        <br/>
        <div style={{width: "100%", paddingTop: "10px"}}>
          <AppFooter
            onPrev={props.navigatePrev}
            onNext={() => props.markCompleteAndNavigateNext(props.path)}
            onSkip={props.onExit}
          />
        </div>
        {/*<Markdown markdown={`# A [brain](#video-link?url=${encodeURIComponent("https://player.vimeo.com/video/872735639?h=9d90918c85&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479")}) for your business.`}/>}*/}
      </div>
    </div>
  </AppCenterWrapper>
}

AppFooter.propTypes = {
  index: PropTypes.number,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onSkip: PropTypes.func,
  onExit: PropTypes.func,
  nextText: PropTypes.string,
  prevText: PropTypes.string,
  hide: PropTypes.bool,
}

function AppFooter(props) {
  return props.hide ?
    <div style={{height: 100}}>
      <div className="space space-center-y space-between">
        {!!props.onPrev && props.index > 0 ?
          <Button ghost size="large" shape="circle" className="icon-btn" onClick={() => props.onPrev()}>
            <LeftArrowOutlined className="musted-icon" />
            <span className="space space-center-y green-color">{props.prevText || ""}</span>
          </Button> : <div/>}
        {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
      </div>
    </div> :
    <div style={{height: 100}}>
      <Divider/>
      <div className="space space-center-y space-between">
        <div className="space space-center-y">
        {!!props.onPrev && props.index > 0 &&
          <Button ghost size="large" shape="circle" className="icon-btn" onClick={() => props.onPrev()}>
            <LeftArrowOutlined className="musted-icon" />
            <span className="space space-center-y green-color">{props.prevText || ""}</span>
          </Button>}
          <Button size="large" shape="round"  className="icon-btn" onClick={() => props.onNext()}>
            <span className="space space-center-y green-color">{props.nextText || "Next"}</span>
            <RightArrowOutlined className="green-icon"/>
        </Button>
        </div>
        <div>
          {props.onSkip && <Button size="large" ghost onClick={props.onSkip}><span className="muted">Later</span></Button>}
          {props.onExit && <Button size="large" ghost onClick={props.onExit}><span className="muted">Exit</span></Button>}
        </div>
      </div>
    </div>
}

export function AppServiceContainer(props) {
  const service = useSelector((state) => state.Content.data?.[props.path]);
  const getComponents = (method) => {
    switch (method) {
      case "user:text":
      case "user:file":
      case "user:auth":
      case "user:approve":
          return AppHumanInTheLoop;
      case "http:get":
      case "http:post":
        return AppDataSourceHttp;
      case "web:search":
      case "web:scrape":
        return AppDataSourceWeb;
      case "reasoning:basic":
      case "reasoning:medium":
      case "reasoning:expert":
        return ReasoningService;
      case "to:dataset":
      case "to:webpage":
      case "to:spreadsheet":
      case "to:json":
      case "to:pdf":
        return AppPersistenceService;
      default:
        return null;
    }
  }

  if (!service) return null;

  return React.createElement(getComponents(service?.method), {
    ...props, service,
  });
}

const AddServiceButton = () => {
  return <div style={{position: "absolute", bottom: "-36px", width: "100%", zIndex: 1}}>
    <div style={{width: "100%", height: 60}} />
    <Tooltip
      title={<div><AppAddService /></div>}
      trigger={["click"]}
    >
      <div className="space space-center-y space-center-x rounded" style={{padding: "10px 0", backgroundColor: "#0e161f", border: "dashed 1px rgba(255, 255, 255, 0.1)"}}><PlusIcon /> Add step</div>
    </Tooltip>
  </div>
}

function AppServiceHeader(props) {
  const sandbox = React.useContext(SandboxContext);
  const [edit, setEdit] = React.useState(false);
  const [editStepNumber, setEditStepNumber] = React.useState(sandbox.app?.servicesPaths?.indexOf(props.path) + 1);
  const onClickEdit = () => setEdit(!edit);
  const onSaveStepNumber = () => {
    setEdit(false);
    const servicesPaths = sandbox.app.servicesPaths?.reduce(($acc, path, index) => {
      if ((index + 1) === editStepNumber) {
        $acc.push(props.path);
        $acc.push(path);
      } else {
        (path !== props.path) && $acc.push(path);
      }
      return $acc;
    }, []);

    props.onUpdateStep(sandbox.appPath, {
      servicesPaths,
    });
  }

  // console.log("AppServiceHeader", sandbox.app);

  return <div className="space space-center-y space-between">
    <div className="space space-center-y">
      {edit ?
        <div className="space space-center-y">
          <InputNumber
            size="large"
            max={sandbox.app?.servicesPaths?.length || 1}
            min={1}
            value={editStepNumber}
            onChange={setEditStepNumber}
            onPressEnter={onSaveStepNumber}
          />
          <Button size="large" shape="round" onClick={onSaveStepNumber}><span className="green-color">Save</span></Button>
          <Button size="large" shape="round" onClick={onClickEdit}><span className="green-color">Cancel</span></Button>
        </div>:
        <Avatar
          size={50}
          onClick={onClickEdit}
        >
          <span className="font-xl bolded">{sandbox.app?.servicesPaths?.indexOf(props.path) + 1}</span>
        </Avatar>
      }
      &nbsp;
      <Typography.Title level={2}>{props.text}</Typography.Title>
    </div>
    {props.extra}
  </div>;
}

AppCenterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  height: PropTypes.number,
  app: PropTypes.object,
}

function AppCenterWrapper(props) {
  const [,, height] = useContext(WindowContext);
  const sandbox = React.useContext(SandboxContext);
  return <Row className="w-full relative bgb" style={{paddingTop: "20px", paddingBottom: "12px"}}>
    <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3} />
    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18} className="overscroll-contain">
      <div
        className={`splash-card-wrapper space space-col space center-y space-center-x${props.isMobile ? " splash-card-wrapper-mobile" : " center"}`}
        style={{
          minHeight: props.isMobile ? "101lvh" : props.height || height + "px",
          padding: "20px 10px",
          width: "100%",
          // borderLeft: `solid 4px ${props.servicePath ? "rgba(255, 255, 255, 0.5)" : "transparent"}`,
        }}
      >
        {props.children}
        {sandbox.app?.appName && sandbox.app?.description && sandbox.app?.access && <AddServiceButton />}
      </div>
    </Col>
  </Row>
}

function AppLeftWrapper(props) {
  return <Row className="w-full">
    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14} className="overscroll-contain">
      <div
        className={`splash-card-wrapper flex-col middle${props.isMobile ? " splash-card-wrapper-mobile" : " center"}`}
        style={{minHeight: props.isMobile ? "101lvh" : props.height + "px", padding: "20px 10px", width: "100%"}}
      >
        {props.children}
      </div>
    </Col>
    <Col xs={0} sm={0} md={0} lg={0} xl={10} xxl={10} />
  </Row>
}

export function AppAddTask(props) {
  const [isMobile, width, height] = useContext(WindowContext);
  // const dispatch = useDispatch();
  const task = useSelector((state) => state.Content?.data?.[props.path]);

  // React.useEffect(() => {
  //   if (props.isActive === false) return;
  //   props.setContext &&
  //   props.setContext({task, path: props.path, response: {}});
  //   props.path &&
  //   dispatch(aiActions.setActiveUserTask(props.path));
  // }, [task, props.path, props.isActive, props.setContext]);

  const bigScreen = width > 1728;

  return <AppCenterWrapper isMobile={isMobile} height={height}>
    <div style={{width: "100%", maxWidth: 900}}>
      <div className="space space-col">
        <Typography.Title>Agents</Typography.Title>
        <Typography.Text className="muted">
          Line Agents do all those things you want to do, but don't have the time
        </Typography.Text>
        <br/>
        <List size="large">
          <List.Item actions={[<Button size="large" ghost><Tag className="muted rounded">ADD</Tag></Button>]}>
            <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;Lookup LinkedIn when someone new emails me</h2><span className="muted"></span></div>
          </List.Item>
          <List.Item
            actions={[<Button size="large" ghost><Tag className="muted rounded">ADD</Tag></Button>]}>
            <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;Weekly hikes based on weather forecast</h2></div>
          </List.Item>
          <List.Item
            actions={[<Button size="large" ghost><Tag className="muted rounded">ADD</Tag></Button>]}>
            <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;Daily market summary for tech stocks</h2></div>
          </List.Item>
          <List.Item
            actions={[<Button size="large" ghost><Tag className="muted rounded">ADD</Tag></Button>]}>
            <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;Remind Sally to take her umbrella if it will rain</h2></div>
          </List.Item>
          <List.Item
            actions={[<Button size="large" ghost><Tag className="muted rounded">ADD</Tag></Button>]}>
            <div className="space space-center-y"><h2><QuoteLeftIcon size={18} className="muted"/> &nbsp;Create social media posts based on my best work</h2></div>
          </List.Item>
        </List>
      </div>
      <div style={{width: "100%", paddingTop: "10px"}}>
        <AppFooter
          onNext={() => props.markCompleteAndNavigateNext(props.path)}
          onExit={props.onExit}
        />
      </div>
    </div>
  </AppCenterWrapper>
}

export function AppService(props) {
  const agentId = "ai-line-app-service-guide"
  const sandbox = React.useContext(SandboxContext);
  const [appName, setAppName] = React.useState("");
  const [appDescription, setAppDescription] = React.useState("");
  const [edit, setEdit] = React.useState(!sandbox.app?.appName || !sandbox.app?.description || !sandbox.app?.access);

  // console.log("AppService", props);
  const [service, setService] = React.useState(null);
  const [activeTabKey, setActiveTabKey] = React.useState("app");

  const onFocus = () => {
    props?.pauseAnimation?.();
  }

  const onBlur = () => {
    props?.startAnimation?.();
  }

  // React.useEffect(() => {
  //   if (!props.isActive) return;
  //   // if (!props.app && props.appPath.split("/").length % 2 === 1) {
  //   //   set
  //   // }
  //   // ?.showDrawer?.(.(false);
  //   // props.setContext({
  //   //   task: {
  //   //     goal: "Get to know The Client and complete onboarding",
  //   //     summary: [
  //   //       "Go through onboarding together.",
  //   //       "You will be receive the slides one by one as the user moves through the onboarding process.",
  //   //     ].join("/"),
  //   //   },
  //   //   // response: {
  //   //   //   instructions: "Without interrupting yourself, transition to introductions, and perform the onboarding tasks.",
  //   //   // },
  //   //   path: props.path,
  //   // });
  // }, [props.path, props.isActive]);

  const saveSchedule = (schedule) => {
    setPath(sandbox.appPath, {schedule}, true)
    .then(() => notifyAndTrack.success("Saved"));
  }

  const saveAppName = () => {
    props?.onUpdateStep?.(sandbox.appPath, {appName})
    .then(() => setAppName(""))
    .then(() => notifyAndTrack.success("Saved"));
  }

  const saveAppDescription = () => {
    props?.onUpdateStep?.(sandbox.appPath, {description: appDescription})
    .then(() => setAppDescription(""))
    .then(() => notifyAndTrack.success("Saved"));
  }

  const saveAccess = (access) => {
    props?.onUpdateStep?.(sandbox.appPath, {access})
    .then(() => setAppDescription(""))
    .then(() => notifyAndTrack.success("Saved"));
  }

  const publishService = () => {
    const appId = sandbox?.appPath?.appPath?.("/")?.pop();
    api.LINE(`/apps/${appId}/publish`, {})
    .then(() => notifyAndTrack.success("Published"));
  }

  const runService = () => {
    const appId = sandbox?.appPath?.split?.("/")?.pop();
    api.LINE(`/apps/${appId}/run`, {})
    .then(() => notifyAndTrack.success("Running"));
  }

  const onClickSave = () => {
    setEdit(false);
  }

  const onClickEdit = React.useCallback(() => {
    setEdit(true);
  }, [edit]);

  const onClickClear = React.useCallback(() => {
    props.onExit();
  }, [props.onExit]);

  // React.useEffect(() => {
  //   if (!props?.isActive) {
  //     return;
  //   }
  // }, [agentId, props.path]);

  const tabList = [
  {
    key: "app",
    tab: "App",
  },
  //   {
  //   key: "job",
  //   tab: "Job",
  // },
  ];

  const scheduleOptions = [
    {key: "*/5 * * * *", label: "5 minutes", description: "Every 5 minutes"},
    {key: "0 * * * *", label: "Hourly", description: "Every hour, on the hour"},
    {key: "0 0 * * *", label: "Daily", description: "At midnight every day"},
    {key: "0 0 0 * *", label: "Weekly", description: "At midnight on Sunday"},
    {key: "0 0 0 1 *", label: "Monthly", description: "At midnight on the first day of every month"},
    // {key: "0 0 0 1 1", label: "Year", description: "At midnight on January 1st every year"},
  ];

  const triggerOptions = [
    {key: "email", label: "Email", description: "When an email is received, sent"},
    {key: "phone-call", label: "Phone Call", description: "When a call is received. made"},
    {key: "text-message", label: "Text Message", description: "Text message received, sent"},
    {key: "logic", label: "Logic", description: "A logic based trigger"},
    // {key: "custom", label: "Custom", description: "A custom trigger"},
    // {key: "0 0 0 1 1", label: "Year", description: "At midnight on January 1st every year"},
  ];

  const appNameOnChange = (e) => setAppName(e.target.value);
  const appDescriptionOnChange = (e) => setAppDescription(e.target.value);

  const tabsContent = {
    app: <div className="space space-col">
      {/*<Divider className="hidden" />*/}
      {edit ?
      <List
        size="large"
        footer={
        <div className="space space-col">
          <Divider />
          <div className="space space-center-y space-between">
            {edit &&
              <Button className="green-color" shape="round" size="large" onClick={onClickSave}>Continue</Button>}
            {sandbox.appPath && sandbox.app?.servicesPaths?.length &&
              <div className="space space-center-y">
                <Button size="large" className="icon-btn green-color" shape="round" onClick={publishService}>
                  <span>Publish</span>
                </Button>
                <Button size="large" className="icon-btn green-color" shape="round" onClick={runService}>
                  <span>Run</span>
                </Button>
              </div>}
          </div>
        </div>
      }>
        <List.Item
          actions={[
            <div className="space space-center-y relative" style={{width: 440}}>
              <Input
                maxLength={30}
                value={appName}
                placeholder={appName || sandbox.app?.appName || "Name your app"}
                size="large"
                bordered={false}
                className={`input-bold`}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={appNameOnChange}
              />
              <InputSaveButton
                onClick={saveAppName}
                show={!!appName}
                disabled={appName?.length < 3}
              />
            </div>,
          ]}
        >
          {/*<div className="space space-center-y">*/}
          {/*  <h2>Data</h2>*/}
          {/*</div>*/}
          <List.Item.Meta
            title={<h2>Name</h2>}
            description={service?.type === "data" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
        <List.Item
          actions={[
            <div className="space space-center-y relative" style={{width: 440}}>
              <Input
                maxLength={200}
                value={appDescription}
                placeholder={appDescription || "What does your app do?"}
                size="large"
                bordered={false}
                className={`input-bold`}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={appDescriptionOnChange}
              />
              <InputSaveButton
                onClick={saveAppDescription}
                show={!!appDescription}
                disabled={appDescription?.length < 3}
              />
            </div>,
          ]}
        >
          {/*<div className="space space-center-y">*/}
          {/*  <h2>Data</h2>*/}
          {/*</div>*/}
          <List.Item.Meta
            title={<h2>Description</h2>}
            description={service?.type === "data" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
        <List.Item
          actions={[
            <Button
              key="public"
              size="large"
              className="icon-btn"
              ghost
              disabled={sandbox.app?.access === "public"}
              onClick={() => saveAccess("public")}
            >
              Public
            </Button>,
            <Button
              key="private"
              size="large"
              className="icon-btn"
              ghost
              disabled={sandbox.app?.access === "private"}
              onClick={() => saveAccess("private")}
            >
              Private
            </Button>
          ]}
        >
          {/*<div className="space space-center-y">*/}
          {/*  <h2>Data</h2>*/}
          {/*</div>*/}
          <List.Item.Meta
            title={<h2>Access</h2>}
            // description={service?.type === "data" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
      </List> :
        <SandboxActiveAppCard path={sandbox.appPath} collapsed onClickEdit={onClickEdit} onClickClear={onClickClear} />}
    </div>,
    job: <div className="space space-col">
      <Divider className="hidden" />
      <List
        size="large"
        // header={<div/>}
        className="list-item-no-divider"
      >
        <List.Item
          actions={[
            ...scheduleOptions.map((option) =>
            <Button
              ghost
              key={option.key}
              size="large"
              className="icon-btn"
              onClick={() => saveSchedule(option.key)}
            >
              {option.label}
            </Button>),
            ]}>
          {/*<div className="fat-chip w-full">*/}
            <List.Item.Meta
              title={<h2>Scheduled</h2>}
              description={service?.type === "reasoning" && <span className="font-lg ai-text">{service?.description}</span>}
            />
          {/*</div>*/}
        </List.Item>
        <List.Item
          // className="fat-chip"
          actions={[
            ...triggerOptions.map((option) =>
              <Button
                ghost
                key={option.key}
                size="large"
                className="icon-btn"
                onClick={() => saveSchedule(option.key)}
              >
                {option.label}
              </Button>),
          ]}>
          {/*<div className="fat-chip w-full">*/}
            <List.Item.Meta
              title={<h2>Triggered</h2>}
              description={service?.type === "reasoning" &&
                <span className="font-lg ai-text">{service?.description}</span>}
            />
          {/*</div>*/}
        </List.Item>
      </List>
      <div style={{width: "100%", paddingTop: "10px"}}>
        {/*<Divider />*/}
      </div>
    </div>
  }

  return <AppCenterWrapper isMobile={props.isMobile} height={props.height}>
    <div style={{width: "100%", maxWidth: 900, margin: "0 auto", overflow: "auto"}}>
      <div className="space space-col relative" style={{maxWidth: 900}}>
        <div style={{width: "100%", maxWidth: 900, overflow: "hidden", height: 30}}>
          <Typography.Title
            className="absolute"
            // editable={{
            //   onChange: saveAppName,
            //   onCancel: () => null,
            //   tooltip: "Click to edit",
            //   // icon: <EditIcon size={26} className="green-icon" />,
            //   maxLength: 24,
            //   triggerType: ["text", "icon"],
            //   autoSize: {minRows: 1, maxRows: 1},
            // }}
            level={2}
            style={{margin: 0}}
          >
            {sandbox.app?.appName ?? sandbox.app?.name ?? "My App"}
          </Typography.Title>
        </div>
        <br/>
        <Card
          // title={
          //   <div className="space space-center-y relative" style={{marginBottom: 10}}>
          //   </div>
          // }
          className="card-simple card-rounded card-no-header card-no-separator card-h-400"
          bordered={false}
          tabList={tabList}
          onTabChange={setActiveTabKey}
          activeTabKey={activeTabKey}
          tabBarExtraContent={{
            right: sandbox.appPath && sandbox.app?.servicesPaths?.length &&
              <div className="space space-center-y">
              <Button size="large" className="icon-btn green-color" shape="round" onClick={publishService}>
                <span>Publish</span>
              </Button>
              <Button size="large" className="icon-btn green-color" shape="round" onClick={runService}>
                <span>Run</span>
              </Button>
            </div>,
          }}
          tabProps={{
            // tabPosition: "left",
            renderTabBar: (props) => null,
          }}
          // tabProps={{tabBarPosition: "left"}}
        >
          {tabsContent[activeTabKey]}
        </Card>
        <div style={{width: "100%", maxWidth: 900, paddingTop: "10px", height: 100}} className="relative">
          {/*<AddServiceButton />*/}
          {/*<AppFooter*/}
          {/*  index={props.index}*/}
          {/*  onPrev={props.navigatePrev}*/}
          {/*  // onNext={addService}*/}
          {/*  nextText={"Add"}*/}
          {/*  // onExit={props.onExit}*/}
          {/*  hide={!service}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
    {/*</DashboardFullRow>*/}
  </AppCenterWrapper>
}

export const AppAddService = (props) => {
  // const agentId = "ai-line-app-service-guide";
  const [, user] = useContext(AppContext);
  const sandbox = React.useContext(SandboxContext);
  // const [appName, setAppName] = React.useState("");
  console.log("AppAddService", props);
  // const [app, loadingApp, errorLoadingApp, appDoc] = useDocumentFirestore(["user", user.uid, "apps", generateID()]);
  const [service, setService] = React.useState(null);
  const [activeTabKey, setActiveTabKey] = React.useState("services");

  const sources = [
    {type: "human", method: "user:text", description: "Get text input from the user"},
    {type: "human", method: "user:file", description: "Get a file from the user"},
    {type: "human", method: "user:auth", description: "Get authorization from the user"},
    {type: "human", method: "user:approve", description: "Get approval from the user"},
    {type: "data", method: "http:get", description: "Load data from a URL"},
    {type: "data", method: "http:post", description: "Load data from a URL"},
    // {type: "data", method: "file:upload", description: "Upload a document or dataset"},
    // {type: "data", method: "account:content", description: "Data from my connected tools"},
    {type: "data", method: "web:search", description: "Search the internet"},
    // {type: "data", method: "web:search", description: "Search the internet"},
    {type: "data", method: "web:scrape", description: "Crawl the internet"},
    // {type: "internet", method: "web:search", description: "Search the internet"},
    {type: "act", method: "phone:call", description: "Make phone calls"},
    {type: "act", method: "phone:answer", description: "Make Answer calls"},
    {type: "act", method: "sms:send", description: "Send text messages"},
    {type: "act", method: "sms:receive", description: "Receive text messages"},
    // {type: "act", method: "email:send", description: "Send email"},
    {type: "reasoning", method: "reasoning:basic", description: "Fast reasoning and decision making"},
    {type: "reasoning", method: "reasoning:medium", description: "Standard reasoning and decision making"},
    {type: "reasoning", method: "reasoning:expert", description: "Expert reasoning and decision making"},
    {type: "persistence", method: "to:dataset", description: "Save to a dataset or collection"},
    {type: "persistence", method: "to:json", description: "Save data as JSON"},
    {type: "persistence", method: "to:pdf", description: "Save data to PDF"},
    {type: "persistence", method: "to:webpage", description: "Save as html webpage"},
    // {type: "persistence", method: "to:spreadsheet", description: "Output to a spreadsheet"},
  ];

  const addService = () => {
    addPath(["user", user.uid, "sandbox-services"].join("/"),
      {...service, date: now()})
    .then((ref) =>
      updatePath(sandbox.appPath, {
        servicesPaths: Fields.arrayUnion(ref.path),
      }))
    .then(() => setService(null))
    .then(() => notifyAndTrack.success("Added"))
  };

  const tabList = [
   {
      key: "services",
      tab: "Steps",
    }];

  const tabsContent = {
    services: <div>
      <Divider className="hidden" />
      <List size="large">
        <List.Item
          actions={
            sources.filter((source) => source.type === "human").map((source) => {
              return <Button
                key={source.method}
                size="large"
                className="icon-btn"
                ghost
                onClick={() => setService(source)}
              >
                {source.method.split(":")[1]}
              </Button>;
            })}>
          {/*<div className="space space-center-y">*/}
          {/*  <h2>Data</h2>*/}
          {/*</div>*/}
          <List.Item.Meta
            title={<h2>Human</h2>}
            description={service?.type === "data" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
        <List.Item
          actions={
            sources.filter((source) => source.type === "data").map((source) => {
              return <Button
                key={source.method}
                size="large"
                className="icon-btn"
                ghost
                onClick={() => setService(source)}
              >
                {source.method.split(":")[1]}
              </Button>;
            })}>
          {/*<div className="space space-center-y">*/}
          {/*  <h2>Data</h2>*/}
          {/*</div>*/}
          <List.Item.Meta
            title={<h2>Data</h2>}
            description={service?.type === "data" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
        <List.Item
          actions={
            sources.filter((source) => source.type === "act").map((source) => {
              return <Button
                key={source.method}
                size="large"
                className="icon-btn"
                ghost
                onClick={() => setService(source)}
              >
                {source.method.split(":")[1]}
              </Button>
            })}>
          {/*<div className="space space-center-y">*/}
          {/*  <h2>Data</h2>*/}
          {/*</div>*/}
          <List.Item.Meta
            title={<h2>Act</h2>}
            description={service?.type === "phone" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
        {/*<List.Item*/}
        {/*  actions={*/}
        {/*    sources.filter((source) => source.type === "sms").map((source) => {*/}
        {/*      return <Button*/}
        {/*        key={source.method}*/}
        {/*        size="large"*/}
        {/*        className="icon-btn"*/}
        {/*        ghost*/}
        {/*        onClick={() => setService(source)}*/}
        {/*      >*/}
        {/*        {source.method}*/}
        {/*      </Button>*/}
        {/*    })}>*/}
        {/*  /!*<div className="space space-center-y">*!/*/}
        {/*  /!*  <h2>Data</h2>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*  <List.Item.Meta*/}
        {/*    title={<h2>SMS</h2>}*/}
        {/*    description={service?.type === "sms" && <span className="font-lg ai-text">{service?.description}</span>}*/}
        {/*  />*/}
        {/*</List.Item>*/}
        <List.Item
          actions={
            sources.filter((source) => source.type === "reasoning").map((source) => {
              return <Button
                key={source.method}
                size="large"
                className="icon-btn"
                ghost
                onClick={() => setService(source)}
              >
                {source.method.split(":")[1]}
              </Button>
            })}>
          <List.Item.Meta
            title={<h2>Reasoning</h2>}
            description={service?.type === "reasoning" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
        <List.Item
          actions={
            sources.filter((source) => source.type === "persistence").map((source) => {
              return <Button
                key={source.method}
                size="large"
                className="icon-btn"
                ghost
                onClick={() => setService(source)}
              >
                {source.method.split(":")[1]}
              </Button>
            })}>
          {/*<div className="space space-center-y">*/}
          {/*  <h2>Data</h2>*/}
          {/*</div>*/}
          <List.Item.Meta
            title={<h2>Save</h2>}
            description={service?.type === "internet" && <span className="font-lg ai-text">{service?.description}</span>}
          />
        </List.Item>
      </List>
    </div>,
  }

  return <div style={{width: "700px", maxWidth: "100%", overflow: "auto"}}>
      <div className="space space-col relative" style={{maxWidth: 700}}>
        <br/>
        <Card
          className="card-simple card-rounded card-no-header card-no-separator card-h-600" bordered={false}
          tabList={tabList}
          onTabChange={setActiveTabKey}
          activeTabKey={activeTabKey}
          tabBarExtraContent={{
            right: <Button className={service ? "green-color" : ""} onClick={addService} shape="round">Add</Button>,
          }}
        >
          {tabsContent[activeTabKey]}
        </Card>
        {/*<div style={{width: "100%", paddingTop: "10px", height: 100}}>*/}
        {/*  <AppFooter*/}
        {/*    index={props.index}*/}
        {/*    onPrev={props.navigatePrev}*/}
        {/*    onNext={addService}*/}
        {/*    nextText={"Add"}*/}
        {/*    // onExit={props.onExit}*/}
        {/*    hide={!service}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  // </AppCenterWrapper>
};

export const AppDataSourceHttp = (props) => {
  const agentId = "ai-line-app-data-manager";
  const sandbox = React.useContext(SandboxContext);
  // const loadUrl = props?.data?.urls?.[0];
  const [, sendRequest] = useChatStream(agentId, true);
  const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const [chatItems, loadingChat] = useQueryFirestore(buildQuery(collectionPath, {}, {limit: 1, order: [["updatedTs", "desc"]]}));
  const [recordsList, loadingRecordsList] = useQueryFirestore(
    buildQuery(
      ["user", props.user.uid, "app-data"].join("/"),
      {"==": {serviceId: props.path.split("/").pop()}}, {
        limit: 30,
        order: [["date", "desc"]],
      }));

  const [sentDefault, setSentDefault] = React.useState(false);
  // const [dataSourceType, setDataSourceType] = React.useState("http:get");
  const [url, setUrl] = React.useState("");
  const [error, setError] = React.useState("");
  const [showTip, setShowTip] = React.useState(false);
  // const [instructions, setInstructions] = React.useState("");
  const [activeTabKey, setActiveTabKey] = React.useState("verifier");

  const validUrl = () => {
    return !!url?.startsWith?.("http");
  }

  const fetchUrl = () => {
    console.log("fetch url", url);
    setError("");
    if (url && !validUrl()) {
      setError("Invalid url");
      return;
    }
    setActiveTabKey("output");
    api.POST(url || props.service?.urls?.[0], {})
    .then((res) => {
      if (res instanceof Error) {
        setError(res.message);
        return;
      }
      url && setPath(props.path, {
        urls: Fields.arrayUnion(url),
      }, true);
      // .then(() => {
      Object.entries(res.data)
      .map(([key, item]) => {
        return setPath(["user", props.user.uid, "app-data", key].join("/"), {
          url,
          ...item,
          serviceId: props.path.split("/").pop(),
          date: new Date(item?.tweet?.created_at || now()).getTime(),
          createdTs: now(),
        }, true);
      });
    })
    .catch((err) => {
      console.log("err", err);
      setError(err.message);
    });
  }

  /**
   * @function onSend
   * @param {string} [prompt]
   * @type {(function(*, *): void)|*}
   */
  const onSend = (prompt) => {
    if (!props.isActive) return;
    if (!prompt && sentDefault) {
      return;
    }

    // ?.showDrawer?.(.(true, {agentId, collectionPath}, "chat");
    sendRequest(
      prompt || `What can you help with?`,
      {
        contextPaths: [clientLinePath],
        history: 3,
        collectionPath,
      }
    );
    setSentDefault(true);
  };

  const onTextChange = (e) => {
    setUrl(e.target.value);
    error && setShowTip(false);
    !url && setError("");
  }

  const onClickDeleteService = () => {
    console.log("delete service");
    const sandbox = React.useContext(SandboxContext);
    deletePath(props.path)
    .then(() => props.onUpdateStep(sandbox.appPath, {
      servicesPaths: [sandbox.app.servicesPaths.filter((path) => path !== props.path)]
    }))
    .then(() => notifyAndTrack.success("Removed"))
  }

  const onFocus = () => {
    props.pauseAnimation();
  }

  const onBlur = () => {
    props.startAnimation();
  }

  const onError = (error) => {
    setError(error);
  }

  const saveInstructions = (instructions) => {
    // setInstructions(instructions);
    !!instructions &&
    props.onUpdateStep(props.path, {
      instructions,
    })
    .then((response) => {
      if (response instanceof Error) {
        notifyAndTrack.error("Oops", response.message);
        // return;
      }
      // onSend();
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  /**
   * @function onMediaReady
   * @type {(function(...filePaths:string): void)|*}
   */
  const onMediaReady = React.useCallback((...filePaths) => {
    // setFiles(filePaths);
    // addChatWidgetItemPath(...filePaths);
  }, []);

  React.useEffect(() => {
    if (!props.isActive) {
      return;
    }
    // ?.showDrawer?.(.(true, {agentId}, props.path);
    if (loadingChat) {
      return;
    }
    if (chatItems?.[0]?.[1]?.updatedTs > dayjs().subtract(1, "hour").valueOf()) {
      return // ?.showDrawer?.(.(true, {chat: chatItems[0][1]});
    }
  }, [props.isActive, loadingChat, sentDefault]);

  const onChangePerformance = (modelPerformance) => {
    props.onUpdateStep(props.path, {
      modelPerformance,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Saved"))
  }

  const tabList = [
    {
      key: "inputs",
      tab: "Inputs",
    },
    {
      key: "agent",
      tab: "Agent",
    },
    {
      key: 'verifier',
      tab: 'Verifier',
    },
  ];

  const tabsContent = {
    verifier: <List
      size="large"
      locale={{emptyText: "No data yet"}}
      title={
      !!url &&
        <div className="space space-center-y">
        <Button shape="rounded" ghost size="large">More</Button>
        </div>}
      dataSource={recordsList}
      renderItem={([path, item]) => {
        return <List.Item key={path}>
          <List.Item.Meta
            avatar={<Avatar src={item.author?.profile_image_url} size={50} />}
            title={
              <div className="space space-center-y space-between">
                <span className="boldeds">
                  {item.author?.name}
                </span>
                <span>
                  <TimeAgo live date={new Date(item?.tweet?.created_at).getTime()} />
                </span>
              </div>}
            description={item.tweet?.text}
          />
        </List.Item>
      }}
    >
    </List>,
    inputs: <div className="space space-col">
      <ServiceInputsManager
        key="inputs"
        servicePath={props.path}
        appPath={sandbox.appPath}
        excludePaths={[props.path]}
        onUpdateStep={props.onUpdateStep}
      />
    </div>,
    agent: <AgentBuilder
      key="agent"
      onSave={saveInstructions}
      instructions={props.service.instructions}
      onFocus={onFocus}
      onBlur={onBlur}
      onError={onError}
      onChangePerformance={onChangePerformance}
      onMediaReady={onMediaReady}
    >
      <Card title="Datasource">
        <Tooltip
          title={
            <Card bordered={false} className="card-simple card-is-tooltip card-inline orange-color">{error}</Card>}
          open={showTip ? showTip : !!error}
          placement="bottomLeft"
        >
          <div className="space space-center-y relative" style={{marginBottom: 20}}>
            <Input
              maxLength={100}
              placeholder={props.service.urls?.[0] || "Enter a url"}
              size="large"
              bordered={false}
              className="input-bold"
              onChange={onTextChange}
              onPressEnter={fetchUrl}
            />
            <InputSaveButton
              onClick={() => fetchUrl()}
              show={!!url || !!props.service.urls?.[0]}
              disabled={(url &&!validUrl()) || !props.service.urls?.[0]}
            />
          </div>
        </Tooltip>
      </Card>
    </AgentBuilder>,
  }

  return <AppCenterWrapper isMobile={props.isMobile} height={props.height} servicePath={props.path}>
    <div style={{width: "100%", maxWidth: 900}}>
      <div className="space space-col">
        <AppServiceHeader
          stepNumber={props.app?.servicesPaths?.indexOf?.(props.path) + 1}
          text={"Data from URL"}
          path={props.path}
          // appPath={sandbox.appPath}
          // app={sandbox.app}
          onUpdateStep={props.onUpdateStep}
        />
        <br />
        <Card
          centered
          className={"card-simple card-rounded card-no-header card-no-separator card-h-600 card-body-scroll"}
          bordered={false}
          // title={}
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={setActiveTabKey}
          tabBarExtraContent={{
            right: <Button ghost className="icon-btn" onClick={onClickDeleteService}>
              <DeleteIcon className="orange-icon" />
            </Button>
          }}
        >
          {tabsContent[activeTabKey]}
        </Card>
        <div style={{width: "100%", paddingTop: "10px"}}>
        </div>
        <div className="space space-col relative" style={{width: "100%", maxWidth: 900,paddingTop: "10px"}}>
          {/*<AddServiceButton />*/}
          {/*<AppFooter*/}
          {/*  index={props.index}*/}
          {/*  onPrev={() => props.navigatePrev()}*/}
          {/*  onNext={() => props.markCompleteAndNavigateNext(props.path)}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  </AppCenterWrapper>
};

export const AppDataSourceWeb = (props) => {
  const agentId = "ai-line-app-data-search";
  const sandbox = React.useContext(SandboxContext);
  // const linesContext = React.useContext(LinesContext);
  const [, sendRequest] = useChatStream(agentId, true);
  // const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [props.path, "managers", agentId, "ai-workflow"].join("/"); // [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  // const [dataItems, loadingChat] = useQueryFirestore(buildQuery(collectionPath, {}, {limit: 1, order: [["updatedTs", "desc"]]}));
  const [error, setError] = React.useState("");
  const [editing, setEditing] = React.useState(false);
  // const [dataSourceType, setDataSourceType] = React.useState(props.data.dataSource);
  const [activeTabKey, setActiveTabKey] = React.useState("inputs");

  /**
   * @function onSend
   * @type {(function(*, *): void)|*}
   */
  const onSend = () => {
    if (!props.isActive) return;
    // ?.showDrawer?.(.(true, {agentId, collectionPath}, props.path);
    sendRequest(
      "build a detailed profile for each user",
      {
        contextPaths: [props.path],
        history: 3,
        collectionPath,
      }
    );
  };

  const onFocus = () => {
    props.pauseAnimation();
  }

  const onBlur = () => {
    props.startAnimation();
  }

  const onError = (error) => {
    setError(error);
  }

  const saveInstructions = (instructions) => {
    !!instructions &&
    props.onUpdateStep(props.path, {
      instructions,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  const onClickDeleteService = () => {
    console.log("delete service");
    deletePath(props.path)
    .then(() => props.onUpdateStep(sandbox.appPath, {
      servicesPaths: [sandbox.app.servicesPaths?.filter?.((path) => path !== props.path)]
    }))
    .then(() => notifyAndTrack.success("Deleted"))
  }

  React.useEffect(() => {
    if (!props.isActive) return;
    // ?.showDrawer?.(.(true, {agentId, collectionPath}, props.path);
  }, [props.isActive]);

  /**
   * @function onMediaReady
   * @type {(function(...filePaths:string): void)|*}
   */
  const onMediaReady = React.useCallback((...filePaths) => {
    // setFiles(filePaths);
    // addChatWidgetItemPath(...filePaths);
  }, []);

  const onChangePerformance = (modelPerformance) => {
    props.onUpdateStep(props.path, {
      modelPerformance,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Saved"))
  }

  const tabList = [
    {
      key: "inputs",
      tab: "Inputs",
    },
    {
      key: "agent",
      tab: "Agent",
    },
    {
      key: "verifier",
      tab: "Verifier",
    },
  ];

  const tabsContent = {
    inputs: <div className="space space-col">
      <ServiceInputsManager
        key="inputs"
        servicePath={props.path}
        appPath={sandbox.appPath}
        excludePaths={[props.path]}
        onUpdateStep={props.onUpdateStep}
      />
    </div>,
    agent: <AgentBuilder
      key="agent"
      onSave={saveInstructions}
      instructions={props.service?.instructions}
      onFocus={onFocus}
      onBlur={onBlur}
      onError={onError}
      onChangePerformance={onChangePerformance}
      onMediaReady={onMediaReady}
    >
      <Card title="Websites"></Card>
    </AgentBuilder>,
  }

  return <AppCenterWrapper isMobile={props.isMobile} height={props.height} servicePath={props.path}>
    <div style={{width: "100%", maxWidth: 900}}>
      <div className="space space-col">
        <AppServiceHeader
          path={props.path}
          stepNumber={sandbox.app?.servicesPaths?.indexOf?.(props.path) + 1}
          // stepNumber={props.index + 1}
          text={"Data from Internet"}
          // appPath={props.appPath}
          // app={sandbox.app}
          onUpdateStep={props.onUpdateStep}
          // extra={
          //   <Button size="large" shape="round" onClick={onClickDeleteService}>
          //     Delete
          //   </Button>
          // }
        />
        <br />
        <Card
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={setActiveTabKey}
          className="card-simple card-rounded card-no-header card-no-separator card-h-600"
          bordered={false}
          tabBarExtraContent={{
            right: <Button ghost className="icon-btn" onClick={onClickDeleteService}>
              <DeleteIcon className="orange-icon" />
            </Button>
          }}
        >
          {tabsContent[activeTabKey]}
        </Card>
        <div style={{width: "100%", paddingTop: "0"}}>
        </div>
        <div className="relative" style={{width: "100%", paddingTop: "10px"}}>
          {/*<AddServiceButton />*/}
          {/*<AppFooter*/}
          {/*  index={props.index}*/}
          {/*  onPrev={() => props.navigatePrev()}*/}
          {/*  onNext={() => props.markCompleteAndNavigateNext(props.path)}*/}
          {/*  hide={!props.service.instructions}*/}
          {/*  onExit={props.onExit}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  </AppCenterWrapper>
};

export const AppHumanInTheLoop = (props) => {
  const agentId = "ai-line-app-hitl-action";
  const sandbox = React.useContext(SandboxContext);
  const [, sendRequest] = useChatStream(agentId, true);
  const collectionPath = [props.path, "managers", agentId, "ai-workflow"].join("/"); // [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const [error, setError] = React.useState("");
  // const [editing, setEditing] = React.useState(false);
  const [activeTabKey, setActiveTabKey] = React.useState("inputs");

  /**
   * @function onSend
   * @type {(function(*, *): void)|*}
   */
  const onSend = () => {
    if (!props.isActive) return;
    // props?.showDrawer?.(true, {agentId, collectionPath}, props.path);
    sendRequest(
      "save the search query",
      {
        contextPaths: [props.path],
        history: 3,
        collectionPath,
      });
  };

  const onFocus = () => {
    props.pauseAnimation();
  }

  const onBlur = () => {
    props.startAnimation();
  }

  const onError = (error) => {
    setError(error);
  }

  const saveInstructions = (instructions) => {
    !!instructions &&
    props.onUpdateStep(props.path, {
      instructions,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  const onClickDeleteService = () => {
    console.log("delete service");
    deletePath(props.path)
    .then(() => props.onUpdateStep(sandbox.appPath, {
      servicesPaths: [sandbox.app.servicesPaths?.filter?.((path) => path !== props.path)]
    }))
    .then(() => notifyAndTrack.success("Deleted"))
  }

  React.useEffect(() => {
    if (!props.isActive) return;
    // ?.showDrawer?.(.(true, {agentId, collectionPath}, props.path);
  }, [props.isActive]);

  /**
   * @function onMediaReady
   * @type {(function(...filePaths:string): void)|*}
   */
  const onMediaReady = React.useCallback((...filePaths) => {
    // setFiles(filePaths);
    // addChatWidgetItemPath(...filePaths);
  }, []);

  const onChangePerformance = (modelPerformance) => {
    props.onUpdateStep(props.path, {
      modelPerformance,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Saved"))
  }

  const tabList = [
    {
      key: "inputs",
      tab: "Inputs",
    },
    {
      key: "agent",
      tab: "Agent",
    },
    // {
    //   key: "service",
    //   tab: "Service",
    // },
  ];

  const tabsContent = {
    inputs: <div className="space space-col">
      <ServiceInputsManager
        key="inputs"
        servicePath={props.path}
        appPath={sandbox.appPath}
        excludePaths={[props.path]}
        onUpdateStep={props.onUpdateStep}
      />
    </div>,
    agent: <AgentBuilder
      key="agent"
      onSave={saveInstructions}
      instructions={props.service?.instructions}
      onFocus={onFocus}
      onBlur={onBlur}
      onError={onError}
      onChangePerformance={onChangePerformance}
      onMediaReady={onMediaReady}
    >
      <Card style={{height: 300}} title={<Typography.Text className="space space-center-y">
        <RightArrowOutlined />
        <span>Human action</span>
      </Typography.Text>}>
        <div className="space space-col">
          <div className="space space-col">
            <Typography.Text className="space space-center-y">
              <RightArrowOutlined />
              <span>Specific person</span>
            </Typography.Text>
          </div>
        </div>
      </Card>
    </AgentBuilder>,
  }

  return <AppCenterWrapper isMobile={props.isMobile} height={props.height} servicePath={props.path}>
    <div style={{width: "100%", maxWidth: 900}}>
      <div className="space space-col">
        <AppServiceHeader
          // stepNumber={props.index + 1}
          // stepNumber={props.app?.servicesPaths?.indexOf?.(props.path) + 1}
          text={"Human in the Loop"}
          // appPath={props.appPath}
          // app={props.app}
          path={props.path}
          onUpdateStep={props.onUpdateStep}
          // extra={
          //   <Button size="large" shape="round" onClick={onClickDeleteService}>
          //     Delete
          //   </Button>
          // }
        />
        <br />
        <Card
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={setActiveTabKey}
          className="card-simple card-rounded card-no-header card-no-separator card-h-600"
          bordered={false}
          tabBarExtraContent={{
            right: <Button ghost className="icon-btn" onClick={onClickDeleteService}>
              <DeleteIcon className="orange-icon" />
            </Button>
          }}
        >
          {tabsContent[activeTabKey]}
        </Card>
        <div style={{width: "100%", paddingTop: "0"}}>
        </div>
        <div className="relative" style={{width: "100%", paddingTop: "10px"}}>
          {/*<AddServiceButton />*/}
          {/*<AppFooter*/}
          {/*  index={props.index}*/}
          {/*  onPrev={() => props.navigatePrev()}*/}
          {/*  onNext={() => props.markCompleteAndNavigateNext(props.path)}*/}
          {/*  hide={!props.service.instructions}*/}
          {/*  onExit={props.onExit}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  </AppCenterWrapper>
};

export const ReasoningService = (props) => {
  const agentId = "ai-line-app-reasoning-service";
  const sandbox = React.useContext(SandboxContext);
  const [, sendRequest] = useChatStream(agentId, true);
  // const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [props.path, "managers", agentId, "ai-workflow"].join("/"); // [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const [error, setError] = React.useState("");
  // const [editing, setEditing] = React.useState(false);
  const [instructions, setInstructions] = React.useState("");
  const [activeTabKey, setActiveTabKey] = React.useState("inputs");

  /**
   * @function onSend
   * @type {(function(*, *): void)|*}
   */
  const onSend = () => {
    if (!props.isActive) return;
    sendRequest(
      instructions,
      {
        contextPaths: [props.path],
        history: 3,
        collectionPath,
      });
  };

  const onFocus = () => {
    props.pauseAnimation();
  }

  const onBlur = () => {
    props.startAnimation();
  }

  const onError = (error) => {
    setError(error);
  }

  const saveInstructions = (instructions) => {
    setInstructions(instructions);
    !!instructions &&
    props.onUpdateStep(props.path, {
      instructions,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  const onChangePerformance = (modelPerformance) => {
    props.onUpdateStep(props.path, {
      modelPerformance,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Saved"))
  }

  const onClickDeleteService = () => {
    console.log("delete service");
    deletePath(props.path)
    .then(() => props.onUpdateStep(sandbox.appPath, {
      servicesPaths: [sandbox.app.servicesPaths.filter((path) => path !== props.path)]
    }))
    .then(() => notifyAndTrack.success("Deleted"))
  }

  // React.useEffect(() => {
  //   if (!props.isActive) return;
  //   // ?.showDrawer?.(.(true, {agentId, collectionPath}, props.path);
  // }, [props.isActive]);

  /**
   * @function onMediaReady
   * @type {(function(...filePaths:string): void)|*}
   */
  const onMediaReady = React.useCallback((...filePaths) => {
    // setFiles(filePaths);
    // addChatWidgetItemPath(...filePaths);
  }, []);

  const tabList = [
    {
      key: "inputs",
      tab: "Inputs",
    },
    {
      key: "agent",
      tab: "Agent",
    },
  ];

  const tabsContent = {
    inputs: <div className="space space-col">
      <ServiceInputsManager
        key="inputs"
        servicePath={props.path}
        appPath={sandbox.appPath}
        excludePaths={[props.path]}
        onUpdateStep={props.onUpdateStep}
      />
    </div>,
    agent: <AgentBuilder
      key="agent"
      onSave={saveInstructions}
      instructions={props.service.instructions}
      onFocus={onFocus}
      onBlur={onBlur}
      onError={onError}
      onChangePerformance={onChangePerformance}
      onMediaReady={onMediaReady}
    />,
  }

  return <AppCenterWrapper isMobile={props.isMobile} height={props.height} servicePath={props.path}>
    {/*<DashboardRow>*/}
      <div style={{width: "100%", maxWidth: 900}}>
        <div className="space space-col">
          <AppServiceHeader
            // stepNumber={props.index + 1}
            stepNumber={props.app?.servicesPaths?.indexOf?.(props.path) + 1}
            text={"Reasoning & Logic"}
            // appPath={props.appPath}
            // app={props.app}
            path={props.path}
            onUpdateStep={props.onUpdateStep}
            // extra={
            //   <Button size="large" shape="round" onClick={onClickDeleteService}>
            //     Delete
            //   </Button>
            // }
            description={"Create a reasoning agent to automate tasks and much more."}
          />
          <br />
          <Card
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={(key) => setActiveTabKey(key)}
            className="card-simple card-rounded card-no-header card-h-600"
            bordered={false}
            tabBarExtraContent={{
              right: <Button ghost className="icon-btn" onClick={onClickDeleteService}>
                <DeleteIcon className="orange-icon" />
              </Button>
            }}
          >
            {tabsContent[activeTabKey]}
          </Card>
          <div style={{width: "100%", paddingTop: "10px"}}>
            {/*<AddServiceButton />*/}
            {/*<AppFooter*/}
            {/*  index={props.index}*/}
            {/*  onPrev={() => props.navigatePrev()}*/}
            {/*  onNext={() => props.markCompleteAndNavigateNext(props.path)}*/}
            {/*  onExit={props.onExit}*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    {/*</DashboardRow>*/}
  </AppCenterWrapper>;
};

export const AppPersistenceService = (props) => {
  const agentId = "ai-line-app-persistence-service";
  const sandbox = React.useContext(SandboxContext);
  const [, sendRequest] = useChatStream(agentId, true);
  // const clientLinePath = ["user", props.user.uid, "lines", "client"].join("/");
  const collectionPath = [props.path, "managers", agentId, "ai-workflow"].join("/"); // [clientLinePath, "managers", agentId, "ai-workflow"].join("/");
  const [error, setError] = React.useState("");
  // const [editing, setEditing] = React.useState(false);
  const [instructions, setInstructions] = React.useState("");
  const [activeTabKey, setActiveTabKey] = React.useState("inputs");

  /**
   * @function onSend
   * @type {(function(*, *): void)|*}
   */
  const onSend = () => {
    if (!props.isActive) return;
    sendRequest(
      instructions,
      {
        contextPaths: [props.path],
        history: 3,
        collectionPath,
      });
  };

  const onFocus = () => {
    props.pauseAnimation();
  }

  const onBlur = () => {
    props.startAnimation();
  }

  const onError = (error) => {
    setError(error);
  }

  const saveInstructions = (instructions) => {
    setInstructions(instructions);
    !!instructions &&
    props.onUpdateStep(props.path, {
      instructions,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Nice"))
  };

  const onChangePerformance = (modelPerformance) => {
    props.onUpdateStep(props.path, {
      modelPerformance,
    })
    .then((response) => {
      (response instanceof Error) &&
      notifyAndTrack.error("Oops", response.message);
    })
    .then(() => notifyAndTrack.success("Saved"))
  }

  const onClickDeleteService = () => {
    console.log("delete service");
    deletePath(props.path)
    .then(() => props.onUpdateStep(sandbox.appPath, {
      servicesPaths: [sandbox.app.servicesPaths.filter((path) => path !== props.path)]
    }))
    .then(() => notifyAndTrack.success("Deleted"))
  }

  // React.useEffect(() => {
  //   if (!props.isActive) return;
  //   // ?.showDrawer?.(.(true, {agentId, collectionPath}, props.path);
  // }, [props.isActive]);

  /**
   * @function onMediaReady
   * @type {(function(...filePaths:string): void)|*}
   */
  const onMediaReady = React.useCallback((...filePaths) => {
    // setFiles(filePaths);
    // addChatWidgetItemPath(...filePaths);
  }, []);

  const tabList = [
    {
      key: "inputs",
      tab: "Inputs",
    },
    {
      key: "agent",
      tab: "Agent",
    },
  ];

  const tabsContent = {
    inputs: <div className="space space-col">
      <ServiceInputsManager
        key="inputs"
        servicePath={props.path}
        appPath={sandbox.appPath}
        excludePaths={[props.path]}
        onUpdateStep={props.onUpdateStep}
      />
    </div>,
    agent: <AgentBuilder
      key="agent"
      onSave={saveInstructions}
      instructions={props.service.instructions}
      onFocus={onFocus}
      onBlur={onBlur}
      onError={onError}
      onChangePerformance={onChangePerformance}
      onMediaReady={onMediaReady}
    />,
  }

  return <AppCenterWrapper isMobile={props.isMobile} height={props.height} servicePath={props.path}>
    {/*<DashboardRow>*/}
    <div style={{width: "100%", maxWidth: 900}}>
      <div className="space space-col">
        <AppServiceHeader
          // stepNumber={props.index + 1}
          stepNumber={props.app?.servicesPaths?.indexOf?.(props.path) + 1}
          text={"Persistence"}
          // appPath={props.appPath}
          // app={props.app}
          path={props.path}
          onUpdateStep={props.onUpdateStep}
          // extra={
          //   <Button size="large" shape="round" onClick={onClickDeleteService}>
          //     Delete
          //   </Button>
          // }
          description={"Create a persistence agent to store your apps output."}
        />
        <br />
        <Card
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={(key) => setActiveTabKey(key)}
          className="card-simple card-rounded card-no-header card-h-600"
          bordered={false}
          tabBarExtraContent={{
            right: <Button ghost className="icon-btn" onClick={onClickDeleteService}>
              <DeleteIcon className="orange-icon" />
            </Button>
          }}
        >
          {tabsContent[activeTabKey]}
        </Card>
        <div style={{width: "100%", paddingTop: "10px"}}>
          {/*<AddServiceButton />*/}
          {/*<AppFooter*/}
          {/*  index={props.index}*/}
          {/*  onPrev={() => props.navigatePrev()}*/}
          {/*  onNext={() => props.markCompleteAndNavigateNext(props.path)}*/}
          {/*  onExit={props.onExit}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
    {/*</DashboardRow>*/}
  </AppCenterWrapper>;
};
