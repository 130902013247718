import React from 'react';
import {AppContext, LinesContext, WindowContext} from "@web/hooks/context";
import PropTypes from "prop-types";
import SandboxEditor from "./SandboxEditor";
// import {useLocation} from "wouter";
import {Card} from "antd";
// import {addPath, ServerTimestamp, generateID} from "@web/lib/firestore.db";
import ServiceData from "@web/ui/containers/ServiceData";
import SandboxList from "./SandboxList";

CreateApp.propTypes = {
  list: PropTypes.array,
}

function CreateApp(props) {
  // const [, user] = React.useContext(AppContext);
  const [,, height] = React.useContext(WindowContext);
  const linesContext = React.useContext(LinesContext);
  // const [location, setLocation] = useLocation();

  return <div className="space space-col" style={{height}}>
    {linesContext.appId ?
      <SandboxEditor /> :
      <div className="space space-col middle" style={{height}}>
        <Card
          style={{width: 600, margin: "0 auto"}}
          bordered={false}
          className="card-simple card-rounded card-h-600s card-body-scrolls"
        >
          <ServiceData serviceId="sandbox" component={SandboxList} />
        </Card>
      </div>
    }
  </div>;
}

export default CreateApp;
