import React from "react";
import PropTypes from "prop-types";
import ChatInputTextarea from "./ChatInputTextarea";
import ChatInputManager from "@web/ui/containers/ChatInputManager";
import {Button, Card, List, Space} from "antd";
import Selector from "./Selector";
import {ActivityIcon, CPUIcon, DownArrowOutlined, GlobeIcon} from "@web/assets/icons";

// const modelPerformanceItems = [
//   {
//     label: "Fastest",
//     key: "model:fastest",
//     icon: <ActivityIcon />,
//   },
//   {
//     label: "Standard",
//     key: "model:standard",
//     icon: <GlobeIcon />,
//   },
//   {
//     label: "Pro",
//     key: "model:pro",
//     icon: <CPUIcon />,
//   },
// ];

AgentBuilder.propTypes = {
  instructions: PropTypes.string,
  modelPerformance: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
  onMediaReady: PropTypes.func,
  onChangePerformance: PropTypes.func,
};

function AgentBuilder(props) {
  return <Card
    bordered={false}
    className="card-simple card-rounded card card-transparent"
    // title={
    // <div className="space space-between">
    //   <span className="font-lg bolded"></span>
    //   {/*<Selector*/}
    //   {/*  items={modelPerformanceItems}*/}
    //   {/*  placeholder="Model performance"*/}
    //   {/*  value={props.modelPerformance}*/}
    //   {/*  onChange={(value) => props.onModelPerformanceChange(value)}*/}
    //   {/*/>*/}
    //   <Button ghost><DownArrowOutlined /></Button>
    // </div>
  >
    <List
      size="large"
      header={
        <ChatInputManager
          pushPrompt={props.instructions ? {text: props.instructions} : null}
          onSend={props.onSave}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onError={props.onError}
          onMediaReady={props.onMediaReady}
          onTextChange={(e) => props.onTextChange?.(e.target.value)}
          placeholder={"Write instructions for your agent"}
        >
          <ChatInputTextarea dictate={false} primary ai />
        </ChatInputManager>
      }
      // locale={{
      //   emptyText: <div className="space space-center-y space-center-x" style={{height: 200}}>
      //     <Empty description="No data" />
      //   </div>,
      // }}
    >
      {/*<List.Item actions={[*/}
      {/*  <div className="space space-center-y relative" style={{width: 400}}>*/}
      {/*    <Input*/}
      {/*      maxLength={100}*/}
      {/*      // value={sampleSearch}*/}
      {/*      placeholder={"Try a search"}*/}
      {/*      size="large"*/}
      {/*      bordered={false}*/}
      {/*      className={`input-bold`}*/}
      {/*      // onFocus={onFocus}*/}
      {/*      // onBlur={onBlur}*/}
      {/*      // onChange={(e) => setSampleSearch(e.target.value)}*/}
      {/*      // onPressEnter={() => onSend(`sample internet search: ${sampleSearch}`)}*/}
      {/*    />*/}
      {/*    <InputSaveButton*/}
      {/*      // onClick={() => onSend(`sample internet search: ${sampleSearch}`)}*/}
      {/*      // show={!!sampleSearch}*/}
      {/*      // disabled={!sampleSearch}*/}
      {/*    />*/}
      {/*  </div>,*/}
      {/*]}>*/}
      {/*  <div className="space space-center-y"><h2>Internet</h2></div>*/}
      {/*</List.Item>*/}
      <List.Item
        actions={[
          <Button key="fastest" size="large" ghost disabled={props.modelPerformance === "model:fastest"} className="icon-btn" onClick={(e) => props.onChangePerformance("model:fastest")}>
            Fastest</Button>,
          <Button key="man" size="large" ghost disabled={props.modelPerformance === "model:standard"} className="icon-btn" onClick={(e) => props.onChangePerformance("model:standard")}>
            Standard</Button>,
          <Button key="man" size="large" ghost disabled={props.modelPerformance === "model:pro"} className="icon-btn" onClick={(e) => props.onChangePerformance("model:pro")}>
            Pro</Button>,
        ]}>
        <div className="space space-center-y"><h2>Performance</h2></div>
      </List.Item>
      {/*<List.Item*/}
      {/*  actions={[*/}
      {/*    <Button key="man" size="large" ghost disabled={props.assistant.gender === "man"} className="icon-btn" onClick={(e) => saveGender("man")}>*/}
      {/*      Personal</Button>,*/}
      {/*    // <Button key="man" size="large" ghost disabled={props.assistant.gender === "neutral"} className="icon-btn" onClick={(e) => saveGender("neutral")}>*/}
      {/*    //   Business</Button>,*/}
      {/*    <Button key="woman" size="large" className="icon-btn" ghost disabled={props.assistant.gender === "woman"} onClick={(e) => saveGender("woman")}>*/}
      {/*      Business*/}
      {/*    </Button>,*/}
      {/*  ]}>*/}
      {/*  <div className="space space-center-y"><h2>Plan and Execute</h2></div>*/}
      {/*</List.Item>*/}
    </List>
    {props.children}
  </Card>
}

export default AgentBuilder;
