import React from 'react'
import FaqForm from './FaqForm'
import {Button, Modal} from 'antd'
import {PlusOutlined} from '@ant-design/icons'

export default function FaqFormModal({faqId, show, onShow}) {
  const showModal = () => {
    onShow(true)
  };
  const handleOk = () => {
    onShow(false)
  };
  const handleCancel = () => {
    onShow(false)
  };
  return (
    <>
      <Button
        icon={<PlusOutlined/>}
        onClick={showModal}>
        Add New
      </Button>
      <Modal
        title="Add / Edit Faq"
        open={show}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
      >
        <FaqForm faqId={faqId} />
      </Modal>
    </>
  )
}