import React from "react";
import PropTypes from "prop-types";
import {Button, Tabs} from "antd";
import DashboardFullRow from "@ai-antd/components/DashboardFullRow";
import ServiceData from "@web/ui/containers/ServiceData";
import StoreList from "@ai-antd/components/StoreList";
import {WindowContext} from "@web/hooks/context";
// import {PlusIcon} from "@web/assets/icons";
import {useLocation} from "wouter";
import AppsList from "@ai-antd/components/AppsList";
import AppStoreListData from "@web/ui/containers/AppStoreListData";
import contentActions from "@web/redux/content/actions";
import {useDispatch} from "react-redux";

AppStore.propTypes = {
  list: PropTypes.array,
  activeKey: PropTypes.string,
};

function AppStore(props) {
  const dispatch = useDispatch();
  const [isMobile,, height] = React.useContext(WindowContext);
  const [activeKey, setActiveKey] = React.useState(props.activeKey || "published");
  const [, setLocation] = useLocation();

  React.useEffect(() => {
    dispatch(contentActions.loadContent({serviceId: "store", searchOptions: {tags: [activeKey]}}));
  }, [activeKey]);

  return <DashboardFullRow>
    <section className="is-desktop" style={{backgroundColor: "#0e161f"}}>
      <div style={{height: 20}}></div>
      <Tabs
        destroyInactiveTabPane
        centered
        activeKey={activeKey}
        onChange={setActiveKey}
        renderTabBar={(props, DefaultTabBar) => isMobile ? null : <DefaultTabBar {...props} />}
        tabBarExtraContent={<Button className="icon-btn green-color" shape="round" onClick={() => setLocation("/apps/create")} style={{position: "relative", left: -20}}>Create</Button>}
        // style={{marginRight: 20}}
        items={[{
          key: "installed",
          label: <label className="font-lgs">Installed</label>,
          children: <ServiceData key="apps" serviceId="apps" component={AppsList} height={isMobile ? height - 30 : height - 80} />,
        },{
          key: "published",
          label: <label className="font-lgs">Discover</label>,
          children: <AppStoreListData tag={"published"} component={StoreList} height={isMobile ? height - 30 : height - 80} />,
        },{
          key: "core",
          label: <label className="font-lgs">Core</label>,
          children: <AppStoreListData tag={"core"} component={StoreList} height={isMobile ? height - 30 : height - 80} />,
        },{
          key: "integrations",
          label: <label className="font-lgs">Integrations</label>,
          children: <AppStoreListData tag={"integrations"} component={StoreList} height={isMobile ? height - 30 : height - 80} />,
          // children: <ServiceData key="store-core" serviceId="store" searchOptions={{tags: ["connect"]}} height={isMobile ? height - 30 : height - 100} component={StoreList} />,
        },{
          key: "work",
          label: <label className="font-lgs">Work</label>,
          children: <AppStoreListData tag={"work"} component={StoreList} height={isMobile ? height - 30 : height - 80} />,
          // children: <ServiceData key="store-work" serviceId="store" searchOptions={{tags: ["work"]}} height={isMobile ? height - 30 : height - 100} component={StoreList} />,
        },
        //   {
        //   key: "play",
        //   label: "Play",
        // },
        //   {
        //   key: "utilities",
        //   label: "Utilities",
        // },
        //   {
        //   key: "business",
        //   label: "Business",
        // },
          {
          key: "social",
          label: "Social",
            children: <AppStoreListData tag={"social"} component={StoreList} height={isMobile ? height - 30 : height - 80} />,
            // children: <ServiceData key="store-social" serviceId="store" searchOptions={{tags: ["social"]}} height={isMobile ? height - 30 : height - 100} component={StoreList} />,
        }, {
          key: "news",
          label: "News",
            children: <AppStoreListData tag={"news"} component={StoreList} height={isMobile ? height - 30 : height - 80} />,
            // children: <ServiceData key="store-news" serviceId="store" searchOptions={{tags: ["news"]}} height={isMobile ? height - 30 : height - 100} component={StoreList} />,
        },{
          key: "shopping",
          label: "Shopping",
            children: <AppStoreListData tag={"shopping"} component={StoreList} height={isMobile ? height - 30 : height - 80}  />,
            // children: <ServiceData key="store-shopping" serviceId="store" searchOptions={{tags: ["shopping"]}} height={isMobile ? height - 30 : height - 100} component={StoreList} />,
        },
        //   {
        //   key: "finance",
        //   label: "Finance",
        // },
        //   {
        //   key: "travel",
        //   label: "Travel",
        // },{
        //   key: "education",
        //   label: "Education",
        // },{
        //   key: "food",
        //   label: "Food",
        // },{
        //   key: "photo",
        //   label: "Photo",
        // },{
        //   key: "art",
        //   label: "Art",
        // },{
        //   key: "productivity",
        //   label: "Productivity",
        // },{
        //   key: "sports",
        //   label: "Sports",
        // },{
        //   key: "health",
        //   label: "Health",
        // },{
        //   key: "fitness",
        //   label: "Fitness",
        // },
        ]}
      />
    </section>
  </DashboardFullRow>
}

export default AppStore;
