import React from 'react';
import DashboardFullRow from "./DashboardFullRow";
import {LinesContext, WindowContext} from "@web/hooks/context";
import AppEditor from "./AppEditor";

function AppContent() {
  const [,, height] = React.useContext(WindowContext);
  // const linesContext = React.useContext(LinesContext);

  return <DashboardFullRow>
    <section style={{width: "100%", backgroundColor: "#0e161f", minHeight: height}}>
      <AppEditor />
    </section>
  </DashboardFullRow>
}

export default AppContent;
