import React, {useCallback, useContext} from "react";
import {AppContext} from '@web/hooks/context'
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import PropTypes from "prop-types";

ServiceData.propTypes = {
  serviceId: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  delay: PropTypes.number,
}

function ServiceData({serviceId, component, ...props}) {
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);
  const list = useSelector((state) => state.Content?.activity?.[serviceId]?.list);
  const hasMore = useSelector((state) => !!state.Content?.activity?.[serviceId]?.startAfter);
  const loadContent = React.useCallback(() => {
    !!serviceId &&
    dispatch(contentActions.loadContent({serviceId}))
  }, [serviceId, dispatch]);

  const pageNext = useCallback(() =>
    dispatch(contentActions.pageContentNext()), [dispatch]);

  React.useEffect(() => {
    if (!user) return;
    loadContent();
  }, [user, serviceId])

  if (!user?.uid) {
    return null;
  }

  return React.createElement(
    component,
    {...props, list, pageNext, hasMore},
  );
}

export default ServiceData;
