import React, {useContext} from "react";
import ServicesTabs from "@ai-antd/page/ServicesTabs";
import {WindowContext} from "@web/hooks/context";
import SourceManager from "./SourceManager";
import OptionsDrawer from "./OptionsDrawer";

function TodayContent() {
  const [isMobile] = useContext(WindowContext);
  return <React.Fragment>
    <ServicesTabs />
    <OptionsDrawer mask placement="left" closeable>
      <SourceManager />
    </OptionsDrawer>
  </React.Fragment>;
}

export default TodayContent;
