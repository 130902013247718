import React from "react";
import PropTypes from "prop-types";
import MessengerVCard from "./MessengerVCard";
import ContactDetailsListItem from "./ContactDetailsListItem";

LineDetail.propTypes = {
  onClose: PropTypes.func,
};

function LineDetail(props) {
  return <section style={{position: "relative", maxWidth: '1200px', minHeight: '100vh', background: "#000"}}>
    <div style={{width: '100%', height: 10}}>
      {/*<div className="space space-between space-center-y">*/}
      {/*  <div className="space space-center-y">*/}
      {/*    <Button*/}
      {/*      ghost*/}
      {/*      className="icon-btn icon-left"*/}
      {/*      onClick={props.onClose}*/}
      {/*    >*/}
      {/*      <LeftArrowOutlined />*/}
      {/*    </Button>*/}
      {/*    <Typography.Title level={4}>*/}
      {/*      {displayName}*/}
      {/*    </Typography.Title>*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <ExternalLink*/}
      {/*      href={`https://${props.contact.domain}/`}*/}
      {/*      target="_blank"*/}
      {/*      className="lowercase"*/}
      {/*      showIcon*/}
      {/*    >*/}
      {/*      {props.contact?.domain}*/}
      {/*    </ExternalLink>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
    <div>
      <MessengerVCard
        onClose={props.onClose}
        drawer
        asCard
        line={props.line}
        path={props.path}
        // contactPath={props.contextPath}
      >
        {/*<List*/}
        {/*  className="list-item-no-divider"*/}
        {/*  header={<Divider className="hidden small-top" />}*/}
        {/*  loading={!props.itemsList}*/}
        {/*  dataSource={props.itemsList || []}*/}
        {/*  renderItem={([itemPath], index) =>*/}
        {/*    <ContactDetailsListItem*/}
        {/*      key={itemPath}*/}
        {/*      contextPath={props.contextPath}*/}
        {/*      itemPath={itemPath}*/}
        {/*      primary={index === 0}*/}
        {/*    />*/}
        {/*  }*/}
        {/*  locale={{*/}
        {/*    emptyText: <Empty*/}
        {/*      description={<span className="ai-text">No relevant activity</span>}*/}
        {/*      imageStyle={{display: 'none'}}*/}
        {/*    >*/}
        {/*      <div className="space space-center-x">*/}
        {/*        <Button*/}
        {/*          ghost*/}
        {/*          onClick={props.loadAllContent}*/}
        {/*        >*/}
        {/*          <span className="muted font-thin underline">Load promotional low-relevance content</span>*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*      {props.empty}*/}
        {/*    </Empty>*/}
        {/*  }}*/}
        {/*/>*/}
      </MessengerVCard>
    </div>
  </section>
}

export default LineDetail;
