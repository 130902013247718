import useQueryFirestore from './useQueryFirestore'
import {buildQuery, colls} from '@web/lib/firestore.db'

/**
 * Returns a list of available subscriptions for the user
 * @param {User} user
 * @return {[*[],boolean,*]|[unknown[],*,*]|[*[],boolean,boolean]}
 */
export default function useAvailableStripeSubscriptions(user) {
  const [userSubscriptionPairs, loadingUserSubscriptions, errorLoadingUserSubscriptions] = useQueryFirestore(user?.uid && buildQuery(
    [colls["stripe-customers"], user.uid, "subscriptions"],
    {"==":
        {"status": "active"},
    }, {
      orderBy: [["created", "desc"]],
      limit: 100,
    })
  )
  const [myScribePairs, loadingScribes, errorLoadingScribes] = useQueryFirestore(
    buildQuery(colls["ai-scribe"], {
      "==": {userId: user.uid},
    })
  )
  if (loadingUserSubscriptions) return [[], true, false]
  if (errorLoadingUserSubscriptions) return [[], false, errorLoadingUserSubscriptions]
  if (!userSubscriptionPairs?.length) return [[], false, false]
  if (loadingScribes) return [[], true, false]
  if (errorLoadingScribes) return [[], false, errorLoadingScribes]
  const availableSubsPairs = userSubscriptionPairs?.filter(([subscriptionId, _]) => {
    const scribe = myScribePairs?.find(([_, scribe]) => scribe.subscriptionId === subscriptionId)
    return !scribe
  })
  console.log("useAvailableStripeSubscriptions", availableSubsPairs)
  return [availableSubsPairs, loadingUserSubscriptions, errorLoadingUserSubscriptions]
}
