import React, {useContext} from 'react'
import {Modal, Input, Typography, notification, Space} from 'antd'
import {addDocument, colls} from '@web/lib/firestore.db'
import {AppContext} from '@web/hooks/context'
import PropTypes from 'prop-types'
import LoginLinkButton from './LoginLinkButton'
import HotClick from '@web/ui/containers/HotClick'

FeedbackModal.prototypes = {
  onClose: PropTypes.func.isRequired
}
const defaultTriggerText = "Request a Feature"
function FeedbackModal(props) {
  const [text, setText] = React.useState('')
  const [show, setShow] = React.useState(false)
  const [app, user] = useContext(AppContext)
  const [success, setSuccess] = React.useState(false)

  if (!user) return <LoginLinkButton withMask />
  const close = () => setShow(false)
  const open = () => setShow(true)
  const saveFeedback = () => {
    if (!text) return notification.info({message: 'Please enter feedback.'})
    addDocument(colls['user-feedback'], {text, userId: user?.uid || null, app: app?.key || null})
      .then(() => setSuccess(true))
      .then(() => props.onSend && props.onSend(text))
      .then(close)
  }
  const defaultTriggerComponent = <Typography.Link
    type="success"
    className="dotted"
    disabled={show}
    underline
    onClick={open}
  ><HotClick>{props.text || defaultTriggerText}</HotClick>
  </Typography.Link>
  const triggerComponent = props.children ?
    React.cloneElement(props.children, {onClick: open}) :
    defaultTriggerComponent
  return (<>{triggerComponent}
    <Modal
      destroyOnClose
      open={show}
      onCancel={close}
      title={props.title || "Your Message"}
      onOk={saveFeedback}
      okText="Send"
      afterClose={() => props.onClose && props.onClose(success)}
    >
      <Space direction="vertical">
        <Input.TextArea
          autoSize={{minRows: 5, maxRows: 10}}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      <Typography.Text type="secondary">
        Request a feature, tell us what you think. We promptly review all submissions.
      </Typography.Text>
      </Space>
    </Modal>
    </>
  )
}

export default FeedbackModal
