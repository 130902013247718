import React from "react";
import PropTypes from "prop-types";
import {List} from "antd";
import ListItemActive from "./ListItemActive";
import appActions from "@web/redux/app/actions";
import {useDispatch, useSelector} from "react-redux";
import VoiceCallButton from "./VoiceCallButton";

CallsListItem.propTypes = {
  path: PropTypes.string,
  index: PropTypes.number,
};

function CallsListItem(props) {
  const dispatch = useDispatch();
  const voiceCall = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const onClick = () => {
    dispatch(appActions.setActiveDetail({path: props.path, voiceCall}));
  };

  return <List.Item
    key={props.path}
    onClick={onClick}
    className="relative contacts-list-item isMobile"
    extra={<VoiceCallButton
      size="small"
      to={voiceCall.memberPhoneNumber || voiceCall.parameters?.From}
      {...voiceCall.memberInfo || {}}
    />
    }>
    <List.Item.Meta
      title={<div className="space space-center-y">
        <span className="bolded">{voiceCall.memberInfo?.name}</span>
        <span className="muted">{voiceCall.memberPhoneNumber || voiceCall.parameters?.From}</span>
      </div>}
      // description={item.emailAddress}
    />
    <ListItemActive path={props.path} />
  </List.Item>
}

export default CallsListItem;
