import React from 'react';
import PropTypes from 'prop-types';
import {List, Tag, Typography} from "antd";
import UserAvatar from "./UserAvatar";
import {
  // AttachmentIcon,
  // CalendarIcon,
  // EmailIconOutlined,
  FlagIcon, TodoIcon, XIcon,
  // LayersIcon,
  // PaymentIcon,
  // TruckIcon
} from "@web/assets/icons";
import now from "lodash/now";
import Day from "./Day";
import MarkdownTimeline from "./MarkdownTimeline";
// import Toggle from "./Toggle";
// import StatusActions from "./StatusActions";
import {useSelector} from "react-redux";
// import {api} from "@web/lib/api";
import RecommendedTodo from "./RecommendedTodo";
import ActivityIconStrip from "./ActivityIconStrip";
import FileCard from "./FileCard";
import TimelineActivityEvent from "./TimelineActivityEvent";
import useDomainIcon from "@web/hooks/useDomainIcon";

ContactDetailsListItemMeta.propTypes = {
  path: PropTypes.string.isRequired,
  // activityPath: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  // displayName: PropTypes.string.isRequired,
  // name: PropTypes.string.isRequired,
  // email: PropTypes.string.isRequired,
  // contactIcon: PropTypes.node,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
};

function ContactDetailsListItemMeta(props) {
  const event = useSelector((state) => state.Content.events[props.eventId]);
  const isDismissed = event?.recommendedTodo?.status === "dismissed";
  // console.log("ContactDetailsListItemMeta:event", props.contactIcon);
  // const contact = useSelector((state) => {
  //   return state.Content?.data?.[props.path];
  // });
  // const item = useSelector((state) => {
  //   console.log("ContactActivityListItem:item", state.Content.activity?.[props.path]);
  //   return state.Content.activity?.[props.path]?.itemsList?.[0];
  // });
  // console.log("ContactDetailsListItemMeta:contact", contact);
  // const DomainIcon = useDomainIcon(contact?.domain, contact?.indicators);

  // const item = useSelector((state) => {
  //   return state.Content?.data?.[props.path];
  // });

  let memberExtraCount = 0;
  const memberNames = Object.values(event?.members || {}).filter(({displayName}) => displayName !== props.name)
  .map(({displayName}, index) => {
    if (index < 3) {
      return <span key={displayName}>{!!index && ", "}{displayName}</span>;
    }
    memberExtraCount++;
    return null;
  });

  // const contact =
  //   useSelector((state) => {
  //     return state.Content?.data?.[path];
  //   });


  return <List.Item.Meta
    // style={{border: 'solid 1px white'}}
    // onClick={props.onClick}
    avatar={
      <UserAvatar
        // size={50}
        userId={props.path.split("/").pop()}
        path={props.path}
        displayName={
          props.displayName ||
            event.memberUserIds?.[0] ||
            event.members?.[event.memberUserIds?.[0]]?.displayName
        }
        email={props.email}
        icon={
          isDismissed ?
            <XIcon className="muted-icon" /> :
          event.isUrgent ?
          <FlagIcon
            key="urgent"
            style={(event?.activityTs > (now() - (1000 * 60 * 60 * 48))) ? {color: "orange"} : null}
            size={18}
          /> :
          event?.recommendedTodo ?
            <TodoIcon key="todo" style={{color: "white"}} /> :
          props.contactIcon
        }
        extra={event?.isUrgent && <Tag color="orange">Urgent</Tag>}
      />
    }
    title={
      <div className="space space-col">
        <div className="space space-center-y" style={{minHeight: '30px'}}>
          <Typography.Title level={5}>
            {memberNames}
            {!!memberExtraCount &&
              <span className="muted"> +{memberExtraCount}</span>}
          </Typography.Title>
          {/*<ActivityIconStrip eventId={props.eventId} />*/}
          <span className="font-sm muted">
            <Day ts={event?.date} />
          </span>
          {/*<span>{event.itemPath.split("/").slice(-2)}</span>*/}
        </div>
        {!!event.title &&
          <Typography.Title level={5} className="mb-0"><span className={isDismissed ? "muted" : ""}>{event.title}</span></Typography.Title>}
      </div>
    }
    description={
    <React.Fragment>
      {!!event.attachments?.length &&
        <div className="space space-center-y" style={{paddingTop: '8px', paddingBottom: '4px'}}>
        {event.attachments.slice(0, 4).map((attachment) => {
          // console.log("ContactDetailsListItemMeta:attachment", attachment);
          return attachment ?
            <FileCard
              file={attachment}
              key={attachment.fileId || attachment.filePath}
              preview
              size="medium"
              // size={attachment.mimeType.split("/")[0] === "image" ? "medium" : "large"}
              path={attachment.filePath}
              direction="horizontal"
            /> :
            null;
        })}
        </div>
        }
      {/*<TimelineActivityEvent*/}
      {/*  eventId={props.eventId}*/}
      {/*  cardTitle={props.cardTitle}*/}
      {/*  primary={props.primary}*/}
      {/*  onClick={props.onClick}*/}
      {/*/>*/}
    </React.Fragment>
    }>
  </List.Item.Meta>
}

export default ContactDetailsListItemMeta;
