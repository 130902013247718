import React from "react";
import {Button, Layout} from "antd";
// import PropTypes from "prop-types";
import {AccountIcon, InboxIcon, SidebarIcon} from "@web/assets/icons";
// import AppSelectorList from "./AppSelectorList";
import {useLocation} from "wouter";
import appActions from "@web/redux/app/actions";
import {useDispatch} from "react-redux";
// import {AppContext} from "@web/hooks/context";

MobileHeader.propTypes = {

};

function MobileHeader(props) {
  const dispatch = useDispatch();
  // const [, user] = React.useContext(AppContext);
  const [location, setLocation] = useLocation();
  const showMenu = () => {
    dispatch(appActions.toggleMobileMenu());
  }
  const showLogo = location === "/";
  return <Layout.Header className="with-drawer transparent-bg" style={{position: "fixed", top: 0, left: 0, right: 0, zIndex: 1000}}>
    <div className="space space-center-y space-between">
      <Button ghost onClick={showMenu}><SidebarIcon /></Button>
      <div>
        <a href="/" style={{position: "relative", top: "-3px"}}>
          {/*{!showLogo ?*/}
          {/*  <AppSelectorList /> :*/}
            <img width={82} src={"/icons/line-on-dark.png"} />
          {/*}*/}
        </a>
      </div>
      <Button ghost onClick={() => setLocation("/links")}><InboxIcon /></Button>
    </div>
  </Layout.Header>
}

export default MobileHeader;
