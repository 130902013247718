import React, {useContext, useState} from "react";
import {WindowContext} from "@web/hooks/context";
import {Drawer} from 'antd'
import {useLocation} from 'wouter'
import PageDrawerBackButton from '@ai-antd/components/PageDrawerBackButton'
import PropTypes from 'prop-types'

const headerStyle = {backgroundColor: '#000'}

PageDrawer.propTypes = {
  title: PropTypes.string,
  agentId: PropTypes.string,
  placement: PropTypes.string,
};

function PageDrawer(props) {
  // const drawer = React.useRef({});
  const [isMobile, width, height] = useContext(WindowContext)
  // const [app] = useContext(AppContext)
  const [show, setShow] = useState(true)
  const [location, setLocation] = useLocation()
  // useEffect(() => {
  //   console.log("Saving return path", location);
  //   drawer.current = {path: location};
  // }, []);
  // const router = useRouter();
  // if (base && location > '/') desktopContentStyle.position = 'absolute';
  // const desktopContentStyle = {
  //   height: `${height - headerHeight}px`,
  //   overflowX: "auto",
  // }
  const drawerTransparent = {
    height: `${height}px`,
    backgroundColor: 'transparent',
  }
  const onClose = () => {
    setShow(false)
  }
  const updateLocation = (open) => {
    // console.log("Returning to", drawer.current.path);
    // !open && !props.noNav && app.navigateTo(drawer.current.path)
    !open && !props.noNav && setLocation('/')
    !open && props.onClose && props.onClose()
  }
  // if (!isMobile) return <>
  //   <section id="page-drawer-desktop" style={desktopContentStyle}>
  //     <Row id="view-top-panel">
  //       <Col xs={20} sm={20} md={20} lg={23} xl={23} xxl={23}></Col>
  //       <Col xs={4} sm={4} md={4} lg={1} xl={1} xxl={1}>{props.desktop?.extra || null}</Col>
  //     </Row>
  //     {props.children}
  //   </section>
  // </>
  if (props.noDesktop) return props.children;
  return <Drawer
    id="page-drawer-mobile"
    width={width}
    className="page-drawer"
    title={<PageDrawerBackButton
      onClick={onClose}
      hideText={props.hideBackText}
      text={props.title || props.backText}
    />}
    destroyOnClose={false}
    open={show}
    onClose={onClose}
    placement={props.placement || "right"}
    closable={false}
    headerStyle={headerStyle}
    drawerStyle={drawerTransparent}
    contentWrapperStyle={drawerTransparent}
    afterOpenChange={updateLocation}
    extra={props?.mobile?.extra}
    // animation="slide"
  >
    {props.children}
  </Drawer>
}

export default PageDrawer
