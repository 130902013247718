import React from "react";
import PropTypes from "prop-types";
import DashboardCenterRow from "./DashboardCenterRow";

AppEditor.propTypes = {

};

function AppEditor(props) {
  return <DashboardCenterRow></DashboardCenterRow>
}

export default AppEditor;
