import React from 'react';
import ServiceData from "@web/ui/containers/ServiceData";
import DashboardFullRow from "./DashboardFullRow";
import ContactActivityList from "./ContactActivityList";

function ActivityContent() {
  console.log("ActivityContent");
  return <DashboardFullRow>
    <section style={{width: "100%"}}>
      <ServiceData serviceId="activity" component={ContactActivityList} />
    </section>
  </DashboardFullRow>
}

export default ActivityContent;
