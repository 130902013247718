import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Table, Tooltip} from "antd";
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";
import {LineChartIcon, TerminalIcon} from "@web/assets/icons";
import {SourcesContext} from "@web/hooks/context";

DatasetTable.propTypes = {
  dataset: PropTypes.object,
  sampleRecords: PropTypes.array,
  path: PropTypes.string,
  bordered: PropTypes.bool,
};

function DatasetTable(props) {
  const dispatch = useDispatch();
  const {services} = useContext(SourcesContext);
  const isActive = useSelector((state) => {
    return state.App[state.App.activeDetailServiceId]?.props?.path === props.path;
  });

  console.log("DatasetTable:", props.dataset, props.sampleRecords, props.path);

  const setActiveDetail = () => {
    dispatch(appActions.setActiveDetail(
      {path: props.path, data: props.dataset},
      true),
    );
  };

  const getTitle = () => {
    return <div className="space space-between">
      <span className="bolded capitalize ai-text">
        Sample Records
        {/*{props.dataset?.name}*/}
      </span>
      {!isActive &&
        <Tooltip
          title={<Card
            title="Launch AI Analyst"
            className="card-simple card-rounded"
            style={{width: 300}}
          >
            <Card.Meta
              description="Analyst is your powerful AI to explore data and find insights using natural language"/>
          </Card>}
          trigger={["hover"]}
        >
          <Button
            shape="round"
            onClick={setActiveDetail}
            className="green-color icon-btn"
            size={"middle"}
          >
            <TerminalIcon className="menu-icon" size={18}/> Launch Analyst
          </Button>
        </Tooltip>}
    </div>;
  }

  return <Table
    style={{width: props.width || 900, flex: 'none'}}
    title={getTitle}
    bordered={!!props.bordered}
    loading={false}
    dataSource={props.sampleRecords || []}
    columns={
      (props.dataset?.rankedFields || props.dataset?.fields)
      ?.sort((a, b) => b.rank - a.rank)
      .map((field, index) => ({
        title: field.fieldName,
        dataIndex: field.fieldName,
        key: field.fieldName,
        width: props.dataset.schema[field.fieldName]?.type === "string" ? 200 : 100,
        fixed: index === 0 ? "left" : undefined,
        render: (text) => <label className="line-clamp-1 muted">{text}</label>,
      })) || []
    }
    pagination={false}
    scroll={{
      x: 600,
      y: 1260,
    }}
  />
}

export default DatasetTable;
