import React from 'react';
import PropTypes from 'prop-types';
import {LeftArrowOutlined} from '@web/assets/icons'
import {Button, Typography} from 'antd'
import HotClick from '@web/ui/containers/HotClick'

PageDrawerBackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};
function PageDrawerBackButton({hideText = false, text = "Back", onClick}) {
  return <div className="space space-center-y" onClick={onClick}>
    <HotClick>
      <Button ghost><LeftArrowOutlined/></Button>
    </HotClick>
    {!hideText &&
      <HotClick>
        <Typography.Text>{text}</Typography.Text>
      </HotClick>}
  </div>
}

export default PageDrawerBackButton;
