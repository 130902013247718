import React, {useContext} from "react";
import {Typography, Empty, List, Divider, Comment, Card, Avatar, Button} from "antd";
import {WindowContext} from "@web/hooks/context";
// import ContactActivityListItem from "./ContactActivityListItem";
import Loading from "./Loading";
import Pager from "@web/ui/containers/Pager";
import LineConnector from "./LineConnector";
import PropTypes from "prop-types";
// import ActivityData from "@web/ui/containers/ActivityData";
// import LaunchSourceManagerButton from "./LaunchSourceManagerButton";
// import ContactInfoCard from "./ContactInfoCard";
// import ContentData from "@web/ui/containers/ContentData";
// import ListItemActive from "./ListItemActive";
// import ContactsListItem from "./ContactsListItem";
// import UserAvatar from "./UserAvatar";
import {RightArrowOutlined} from "@web/assets/icons";
import {useLocation} from "wouter";
import AssistantListItem from "./AssistantListItem";

AssistantList.propTypes = {
  list: PropTypes.array,
  pageNext: PropTypes.func,
  hasMore: PropTypes.bool,
  preview: PropTypes.bool,
  height: PropTypes.number,
}

function AssistantList(props) {
  const [isMobile,, height] = useContext(WindowContext);
  const [location, setLocation] = useLocation();

  if (props.preview) {
    return <List
      // layout="horizontal"
      // header={<Divider/>}
      // header={!!props.list?.length && <Divider className="no-bottom no-top hidden" />}
      className="services-contacts-list list-empty-no-padding list-item-no-divider"
      dataSource={props.list?.slice?.(0, 2)}
      renderItem={([path, date], index) => {
        return <AssistantListItem path={path} date={date} index={index} />
      }}
      locale={{
        emptyText: <Empty
          className="add-integrations-empty-wrapper space space-col middle"
          description={null}
          image={null}
          imageStyle={{display: "none"}}
          style={{width: "100%", maxWidth: "100%", margin: "0 auto"}}
        >
          <div className="cursor-pointer" style={{width: "96%", padding: "0 0 12px"}}>
            <span>Useful updates from your Line AI assistant and agents will appear here</span>
            {/*<Typography.Title style={{marginBottom: 11}}>Chat</Typography.Title>*/}
            {/*<LaunchSourceManagerButton key="sources-manager-x" serviceId={"contacts"}>*/}
            {/*  <Typography.Title style={{marginBottom: 11}}>Contacts</Typography.Title>*/}
            {/*</LaunchSourceManagerButton>*/}
          </div>

          {/*<Card title="Connect your Email" className="card-simple card-rounded card-no-header" bordered={false}>*/}
          {/*  <Link to="/">Connect your email to use assistant features</Link>*/}
          {/*  /!*<SourceManager group={{sourceIds:["gmail", "outlook", "web"]}} serviceId="contacts" />*!/*/}
          {/*</Card>*/}
        </Empty>
      }}
    />
  }

  return <div
    id="services-assistant-scroll-target"
    style={{
      height: props.height || height - 3,
      overflow: 'auto',
      // display: 'flex', flexDirection: 'column',
    }}>
    <Pager
      height={props.height || height - 3}
      loader={<Loading inline />}
      length={props.list?.length || 0}
      hasMore={props.hasMore}
      // initialScrollY={200}
      endMessage={
        !!props.list?.length &&
        <div
          className="space space-center-y"
          style={{position: "relative", top: "26px", left: 10,}}
        >
          <h6 className="muted">
            End</h6>&nbsp;
          <LineConnector />
        </div>
      }
      pageNext={props.pageNext}
      scrollContainerId="services-assistant-scroll-target"
    >
      <List
        // layout="horizontal"
        // header={<Divider/>}
        header={
          <div className="w-full">
            {<Card title={location === "/assistant" ? <h6 className="bolded">Assistant</h6> : <h6></h6>} bordered={false} className="card-simple card-rounded card-transparent card-no-body">
              {/*<Divider className="no-top no-bottom" />*/}
            </Card>}
            {isMobile && <Card
              title={<h6>Lines</h6>}
              extra={<Button ghost className="icon-btn icon-right"
                             onClick={() => setLocation("/lines")}>Browse <RightArrowOutlined /></Button>}
              bordered={false}
              style={{margin: "0 10px", backgroundColor: "#0e161f"}}
              className="card-simple card-rounded card-inline card-no-separator"
            >
            </Card>}
          </div>
        }
        className="services-contacts-list list-empty-no-padding list-item-no-divider"
        dataSource={props.list}
        renderItem={([path, date], index) => {
          return <AssistantListItem path={path} date={date} index={index} />
        }}
        locale={{
          emptyText: <Empty
            className="add-integrations-empty-wrapper space space-col middle"
            description={null}
            image={null}
            imageStyle={{display: "none"}}
            style={{width: "100%", maxWidth: "100%", margin: "0 auto"}}
          >
            <div className="bgx cursor-pointer" style={{width: "100%", padding: "12px 0"}}>
              <span>Useful updates from your assistant and agents will appear here</span>
              {/*<Typography.Title style={{marginBottom: 11}}>Chat</Typography.Title>*/}
              {/*<LaunchSourceManagerButton key="sources-manager-x" serviceId={"contacts"}>*/}
              {/*  <Typography.Title style={{marginBottom: 11}}>Contacts</Typography.Title>*/}
              {/*</LaunchSourceManagerButton>*/}
            </div>

            {/*<Card title="Connect your Email" className="card-simple card-rounded card-no-header" bordered={false}>*/}
            {/*  <Link to="/">Connect your email to use assistant features</Link>*/}
            {/*  /!*<SourceManager group={{sourceIds:["gmail", "outlook", "web"]}} serviceId="contacts" />*!/*/}
            {/*</Card>*/}
          </Empty>
        }}
      />
    </Pager>
  </div>
}

export default AssistantList;
