import {Drawer} from "antd";
import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import appActions from "@web/redux/app/actions";
import AssistantVCard from "./AssistantVCard";
import {useLocation} from "wouter";
import VCardInfo from "./VCardInfo";

const MobileSidebar = ({placement = 'left', width = '100%'}) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useLocation();
  const showMobileMenu = useSelector((state) => state.App.showMobileMenu);

  const onCollapse = () => {
    dispatch(appActions.toggleMobileMenu());
  }

  const onNavigate = (path) => {
    setLocation(path);
    onCollapse();
  }

  return <Drawer
    id={"line-contact-drawer"}
    placement={placement}
    width={width}
    onClose={onCollapse}
    open={showMobileMenu}
    className="mobile-drawer"
    closable={false}
  >
    <AssistantVCard drawer onNav={onNavigate}>
      <VCardInfo onNav={onNavigate} />
    </AssistantVCard>
  </Drawer>
};
export default MobileSidebar
